import PageHeading from "../../components/__simple/_pageheading";
import { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../components/__simple/breadcrumb";
import Banner from "../../components/banner";
import AdviceTile from "./components/adviceTile";
import { WhyHJSection } from "../../sections/whyHJSection";
import axios from "axios";
import { useTranslation } from "react-i18next";
import config from "../../configuration/config.json";
import { Helmet } from "react-helmet";
export function HJAdvisesPage() {
    const { t: trans,i18n } = useTranslation(["common"]);

    const [banner, setBanner] = useState({});
    const [advises, setAdvises] = useState([]);
    let lang =  (i18n.language || 'ar');
    const languageDir = "language-" + lang;



    useEffect(async () => {
        try {
            const response = await axios.request({
                baseURL: config.controllURL,
                url: lang + "/api/hj/advises",
                method: "get",
            });
            setBanner(response.data.banner)
            setAdvises(response.data.tips)
        } catch (error) {

        }


    }, []);


    const [seoPageInfo, setSeoPageInfo] = useState({});
    useEffect(async () => {

        var page = "hassan jameel tips"


        const response = await axios.post(
            config.baseURL + 'func',
            {
                "Name": "SiteFetchSeoPages",
                "Values": {
                    "page": page
                }
            }
        );
        if (response.status == 200) {

            var resdata = response.data[0];

            setSeoPageInfo(resdata);


        }
    }, []);
    return (
        <Fragment>

            {
                seoPageInfo &&

                <Helmet>

                    <meta charSet="utf-8" />
                    {
                        seoPageInfo?.title &&
                        <title>{seoPageInfo?.title}</title>
                    }
                    {
                        seoPageInfo?.link &&
                        <link rel="canonical" href={seoPageInfo?.link} />

                    }
                    {
                        seoPageInfo?.MetaDescription &&
                        <meta name="description" content={seoPageInfo?.MetaDescription} />
                    }
                    {
                        seoPageInfo?.MetaGroups &&
                        <meta name="keywords" content={seoPageInfo?.MetaGroups} />
                    }

                </Helmet>


            }
            <Banner
                subtitle={banner?.subtitle}
                title={banner?.title}
                desc={banner?.excerpt}
                background={banner?.imagePath}
                backOpasity={banner?.backOpasity}

            />

            <div className="container">
                <Breadcrumb
                    links={[
                        {

                            name: trans("common:HJ-advises"),
                        }
                    ]}
                />
            </div>
            <div className={"container mb-32 " + languageDir}>

                {
                    advises?.map((advise, index) => {
                        return (
                            <AdviceTile
                                key={index}
                                name={advise.name}
                                content={advise.content}
                            />
                        )
                    })
                }
            </div>
            <WhyHJSection />
        </Fragment>

    )
}

