import { DropDownInput } from "../../components/_input/_dropdown";
import { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import config from '../../configuration/config.json';
import { trimEnd } from "lodash";
import Popup from "../../User/popup";
import { useHistory, useLocation } from "react-router";
import { toast } from 'react-toastify';
import _, { includes } from 'lodash';
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { RadioInputDynamic } from "../../components/_input/_radioInputDynamic";
import PhoneDropDown from "../../components/_input/_phoneDropDown";
import { InputField } from "../../components/_input/_input";
import { UploadControl } from "../../components/_input/_uploadControl";
import { CheckInput } from "../../components/_input/_checkInput";
import Joi from "joi";
import { GetMessages, formValidate, } from '../../utils/validation';
import { Price } from "../../components/__simple/_price";

export function CarCheckoutFinancePage({ vehicle, isToyota, isLexus, selectedColor, Pfinance, minFinance, PfinanceSettings, filterCarImages, banks }) {
    const history = useHistory();
    const location = useLocation();
    const [carInfo, setCarInfo] = useState({});
    const { slug, sn } = useParams();
    const { t: trans, i18n } = useTranslation(["new", "finance", "info", "employment", "error2"]);
    const [dataFullNum, setDataFullNum] = useState(1);
    const [requestForm, setRequestForm] = useState({ selectedNationality: "SAU" });
    const [stepNum, setStepNum] = useState(1);
    const [finance, setFinance] = useState(Pfinance);
    const [financeSettings, setFinanceSettings] = useState(PfinanceSettings);
    const [showPopupMessage, setShowPopupMessage] = useState("");
    const [scrollSnackUp, setScrollSnackUp] = useState({
        show: false,
        height: '16rem'
    });
    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
    const [OTP, setOTP] = useState("");
    const [error, setError] = useState({});
    const schema_1 = Joi.object({
        name: Joi.string().required().messages(GetMessages()),
        phoneNumberOnly: Joi.string().min(9).required().messages(GetMessages()),
        phoneNumberCode: Joi.string().min(2).required().messages(GetMessages()),
        City: Joi.string().required().messages(GetMessages()),
        Gender: Joi.string().required().messages(GetMessages()),
        custType: Joi.string().required().messages(GetMessages()),
        identityNo: Joi.string().min(10).required().messages(GetMessages()),
        cn: Joi.string().min(10).required().messages(GetMessages()),

    });
    const schema_2 = Joi.object({
        WorkType: Joi.string().required().messages(GetMessages()),
        job: Joi.string().required().messages(GetMessages()),
        income: Joi.number().required().messages(GetMessages()),
        SalaryTransfer: Joi.string().required().messages(GetMessages()),
        Questions1: Joi.number().required().messages(GetMessages()),
        Questions2: Joi.number().required().messages(GetMessages()),
        Questions3: Joi.number().required().messages(GetMessages()),
        Questions4: Joi.number().required().messages(GetMessages()),
        Obligations: Joi.number().messages(GetMessages()),
    });
    const schema_3 = Joi.object({
        identificationImageName: Joi.string().required().messages(GetMessages()),
        LicenseName: Joi.string().required().messages(GetMessages()),
        InsuranceName: Joi.string().required().messages(GetMessages()),
        SalaryDefinitionletterName: Joi.string().required().messages(GetMessages()),
        AccountStatementName: Joi.string().required().messages(GetMessages()),
        BankID: Joi.string().required().messages(GetMessages()),
    });
    const [propsData, setPropsData] = useState({});
    const [checkoutType, setCheckoutType] = useState(null);
    const [dataFinalPopup, setDataFinalPopup] = useState({ });
    const [showFinalPopup, setShowFinalPopup] = useState(false);
    const [showFinalPopupMessage, setShowFinalPopupMessage] = useState(trans('new:You-will-be-contacted'));
    const [accountTypes, setAccountTypes] = useState([]);

    const [selectedFinancePeriod, setSelectedFinancePeriod] = useState(60);

    const reCountFinanceSettings = (financeSettings, selectedFinancePeriod, currentFirstPayPrice) => {

        try {
            //////console.log('reCountFinanceSettings', financeSettings);
            if (selectedColor && financeSettings) {
                let financeData = { ...finance };
                if (selectedColor?.NetSalePrice && financeSettings?.ManagementFees >= 0) {

                    //selectedFinancePeriod = financeSettings?.MaxFinancePeriod;

                    const firstPay = (financeSettings?.AdvancedPaymentRate / 100) ?? 0;
                    const lastPay = (financeSettings?.LastPaymentRate / 100) ?? 0;
                    const managementFees = (financeSettings?.ManagementFees / 100) ?? 0; // الرسوم الادارية
                    const financeRate = (financeSettings?.FinanceRate / 100) ?? 0; //نسبة الفائدة

                    const firstPayPrice = currentFirstPayPrice ? currentFirstPayPrice : (firstPay * parseFloat(selectedColor?.NetSalePrice))
                    const lastPayPrice = (lastPay * parseFloat(selectedColor?.NetSalePrice));

                    const financeAmount = (parseFloat(selectedColor?.NetSalePrice) - parseFloat(firstPayPrice));

                    var addAmount = (financeAmount * financeRate) * (selectedFinancePeriod / 12);
                    var managementFeesAmount = 0;
                    if (addAmount && managementFees && managementFees > 0) {
                        // الرسوم الادارية
                        managementFeesAmount = (financeAmount * managementFees);
                        managementFeesAmount = managementFeesAmount + (managementFeesAmount * 0.15);
                        addAmount = addAmount + managementFeesAmount;

                    }

                    const mounthBrach = ((financeAmount + addAmount) - lastPayPrice) / (selectedFinancePeriod - 1)

                    const finalAmount = ((mounthBrach * (selectedFinancePeriod - 1)) + lastPayPrice + firstPayPrice)


                    financeData.period = selectedFinancePeriod;
                    financeData.mangFees = managementFeesAmount;
                    financeData.totalPrice = finalAmount;
                    financeData.first = firstPayPrice;
                    financeData.last = lastPayPrice;
                    financeData.price = mounthBrach;

                    setFinance(financeData);
                }
            }
        } catch (error) {

            ////console.log("error:", error.messages);

        }

    }
    useEffect(() => {
        setFinance(Pfinance);
    }, [Pfinance]);

    useEffect(() => {
        setFinanceSettings(PfinanceSettings);
    }, [PfinanceSettings]);
    //It's used to fetch all the customer types
    const initialCustGroups = () => {
        axios.post(
            config.baseURL + 'func',
            {
                "name": "SiteGetCustGroupsCollection"
            }
        ).then(response => {
            setAccountTypes(response.data);

        }).catch(err => {
            ////////console.log(err);
        })
    };

    const getOrderNumberTest = async (valObject) => {

        try {
            await axios.post(
                config.baseURL + "details",
                {
                    "Object": "SiteFinanceOrders",
                    "Option": "Column",
                    "ObjectSettings": {
                        "MetaData": false,
                        "Limit": {
                            "Value": 1,
                            "Optional": "Top"
                        }
                    },
                    "Fields": "GUID,OrderID",
                    "Filters": ` order by OrderID DESC `
                }
            ).then(response => {

                try {

                    if (response.data.ApiObjectData && response.data.ApiObjectData.length > 0 && response.data.ApiObjectData[0]) {

                        setDataFinalPopup(response.data.ApiObjectData[0]);
                        setShowFinalPopup(true);
                    }
                } catch (error) {

                }


            }).catch(error => {
                console.log(error);


            });

        } catch (error) {
            console.log("error:", error);

        }


    }


    const isDataSentBefore = async (valObject) => {
        let isExisted = true;
        try {
            const response = await axios.post(
                config.baseURL + "details",
                {
                    "Object": "SiteFinanceOrders",
                    "Option": "Column",
                    "ObjectSettings": {
                        "MetaData": false,
                        "Limit": {
                            "Value": 1,
                            "Optional": "Top"
                        }
                    },
                    "Fields": "GUID,OrderID",
                    "Filters": `Where ColorID='${valObject?.ColorID}' and ProductID='${valObject?.ProductID}' and ModelYear='${valObject?.ModelYear}'  and PhoneNumber ='${valObject?.PhoneNumber}' order by OrderID DESC `
                }
            );
            if (response.status == 200) {
                isExisted = response.data.ApiObjectData.length > 0;
            }
        } catch (err) {
            console.log("isDataSentBefore catch", err);
        }
        return isExisted;
    };
    const getOrderNumber = async (valObject) => {

        try {
            await axios.post(
                config.baseURL + "details",
                {
                    "Object": "SiteFinanceOrders",
                    "Option": "Column",
                    "ObjectSettings": {
                        "MetaData": false,
                        "Limit": {
                            "Value": 1,
                            "Optional": "Top"
                        }
                    },
                    "Fields": "GUID,OrderID",
                    "Filters": `Where ColorID='${valObject?.ColorID}' and ProductID='${valObject?.ProductID}' and ModelYear='${valObject?.ModelYear}'  and PhoneNumber ='${valObject?.PhoneNumber}' order by OrderID DESC `
                }
            ).then(response => {

                try {

                    if (response.data.ApiObjectData && response.data.ApiObjectData.length > 0 && response.data.ApiObjectData[0]) {

                        setDataFinalPopup(response.data.ApiObjectData[0]);
                        setShowFinalPopup(true);
                    }
                } catch (error) {

                }


            }).catch(error => {
                console.log(error);


            });

        } catch (error) {
            console.log("error:", error);

        }


    }
    const [isLoadingSendOrder, setLoadingSendOrder] = useState(false);
    // to sumbit finance car order
    const handleSubmit = async () => {
        try {

            if (!isLoadingSendOrder) {
                //////console.log("handleSubmit:", requestForm);
                setLoadingSendOrder(true);
                var newstep = await checkStepsErrors();

                console.log("handleSubmit:", newstep);
                if (newstep >= 3) {
                    if (requestForm.income > 0) {

                        console.log("carInfo:", carInfo);
                        console.log("financeSettings:", financeSettings);
                        var valObject = {

                            //-----------car info
                            "ProductID": carInfo.productID,
                            "ModelYear": carInfo.year,
                            "ColorGroup": carInfo.carGroupID != '' || carInfo.carGroupID != undefined || carInfo.carGroupID != null ? carInfo.carGroupID : null,
                            "ColorID": carInfo.colorID != '' || carInfo.colorID != null || carInfo.colorID != undefined ? carInfo.colorID : null,
                            //---------------------customer info
                            "CustType": requestForm.custType,
                            "IdentityNo": requestForm.identityNo,
                            "CN": requestForm.cn,
                            "Email": requestForm.email,
                            "FullNameAr": requestForm.name,
                            "FullNameEn": requestForm.fullNameEn,
                            "PhoneNumber": requestForm.phoneNumber,
                            "City": requestForm.City,
                            "Gender": Number(requestForm.Gender),
                            "NotificationWhatsApp": requestForm.NotificationWhatsApp == true ? 1 : 0,
                            //----------------------------
                            "OfferID": carInfo.offerID,
                            "BankID": requestForm.BankID ? requestForm.BankID : financeSettings.BankID,
                            "FirstPayment": finance?.first,
                            "MonthlyAmount": finance?.price,
                            "Period": finance?.period,
                            "Message": requestForm.note ?? null,
                            // //----------------------
                            "WorkType": requestForm.WorkType,
                            "Job": requestForm.job,
                            "Income": Number(requestForm.income),
                            "SalaryTransfer": requestForm.SalaryTransfer,
                            "Questions1": Number(requestForm.Questions1),
                            "Questions2": Number(requestForm.Questions2),
                            "Questions3": Number(requestForm.Questions3),
                            "Questions4": Number(requestForm.Questions4),
                            "Obligations": Number(requestForm.Obligations),
                            //---------
                            "Identity_Image": requestForm.identificationImage,
                            "License": requestForm.License,
                            "Insurance": requestForm.Insurance,
                            "SalaryDefinitionletter": requestForm.SalaryDefinitionletter,
                            "AccountStatement": requestForm.AccountStatement,
                            //-------------------------
                        }
                        console.log("valObject:", valObject);
                        if (!(await isDataSentBefore(valObject))) {
                            axios.post(
                                config.baseURL + 'add',
                                {
                                    "object": "SiteFinanceOrders",
                                    "values": valObject
                                }
                            ).then(response => {
                                toast.success(trans("common:order-request-success"));

                                // --------------------------
                                getOrderNumber(valObject);
                                resetControls();
                                setLoadingSendOrder(false);
                            }).catch(err => {
                                setLoadingSendOrder(false);
                                console.error("catch:>>>>");
                                console.error(err);

                            });

                        } else {
                            toast.error(trans("employment:data-sent-before"));
                            setLoadingSendOrder(false);
                        }




                    } else {
                        toast.error(trans("error2:not_accept_less_than_0_error"));
                        setLoadingSendOrder(false);
                    }
                } else {
                    //////console.log("newErrors>>:", newErrors);
                    setLoadingSendOrder(false);
                }

            }
        } catch (err) {
            setLoadingSendOrder(false);
            console.error("catch:>>>>");
            console.error(err);
        }
    };

    // to reset controls 
    const resetControls = () => {
        setError({});
        setRequestForm({
            ...requestForm,
            custType: null,
            identityNo: '',
            cn: '',
            fullNameAr: '',
            fullNameEn: '',
            phoneNumber: '',
            email: '',
            income: null,
            colorID: '',
            advancedPayment: null,
            lastPayment: null,
            note: ''

        });
    };
    // to remove the unneeded props
    const removeUnspecificError = (errorList, values = []) => {
        let errorResult = {};
        if (values?.length > 0) {
            const lowerValues = values.map(item => {
                return item.toLowerCase()
            });
            Object.assign(errorResult, errorList);
            if (errorList) {
                for (let index = 0; index < Object.keys(errorList).length; index++) {
                    const key = Object.keys(errorList)[index];
                    if (includes(lowerValues, key.toLowerCase())) {
                        delete errorResult[key];
                    } else {
                        continue;
                    }
                }
            }
        }
        return errorResult;
    };
    //to select the type of identity
    const handleCustType = (option) => {
        const val = { ...requestForm };
        val.custType = option;
        setRequestForm(val);

        try {
            if (option == '' || option == null || option == '0') {
                setIdentityType(null);
                return;
            }
            let value = null;
            if (option == "G1") {
                value = 1;
                val.cn = null;
            } else {
                value = 2;
                val.identityNo = null;
            }

            setRequestForm(val);
            setIdentityType(value);

        } catch (err) {
        }
    };

    useEffect(() => {
        handleFetchCountries();
        initialCustGroups();
    }, []);
    useEffect(() => {
        var mm = {
            ...propsData,
            vehicle, isToyota, isLexus, selectedColor, finance, financeSettings, filterCarImages
        }
        setPropsData(mm);
        setCarInfo({ ...vehicle });
    }, [vehicle, isToyota, isLexus, selectedColor, finance, financeSettings, filterCarImages]);
    let lang = (i18n.language || 'ar');
    const [identityType, setIdentityType] = useState(null);
    const cheangeSteps = async (step) => {

        if (step > 0 && step <= 4 && step != stepNum) {

            const statusStep = await checkStepsErrors();
            if (statusStep >= step) {
                setStepNum(step);
            }

        }

    }
    const [Shipping, setShipping] = useState({ UnitTime: "", CountTime: 0, Cost: 0, DateTimeDelevry: "" });
    const [dataShippingInfo, setDataShippingInfo] = useState({ "": [] });
    const [selectcity, setSelectcity] = useState(null);
    async function CarShippingInfo(City) {

        setSelectcity(City)

        const val = { ...requestForm };
        val.City = City.value;
        setRequestForm(val);

        if (!dataShippingInfo[City]) {

            var currentvalue = { ...Shipping };
            currentvalue.Cost = 0
            // currentvalue.UnitTime = trans("new:not_avilable");
            currentvalue.UnitTime = null;
            currentvalue.CountTime = null
            currentvalue.DateTimeDelevry = ""


            let newData = { ...dataShippingInfo };
            newData[City] = currentvalue;
            setDataShippingInfo(newData);

            const Body = {
                "name": "Site_CarShippingInfo",
                "values": {
                    "City": `${City}`,
                    "categore": `${vehicle?.carCategories}`
                }
            }
            await axios.request({
                url: config.baseURL + 'func',
                data: Body, method: "POST", responseType: "json"
            })
                .then(function (response) {
                    // ////console.log(response.data.ApiObjectData);
                    ////console.log('CarShippingInfo response:', response);
                    var currentvalue = { ...Shipping };
                    currentvalue.Cost = response.data[0].CostShipping
                    currentvalue.UnitTime = response.data[0].Type
                    currentvalue.CountTime = response.data[0].TimeDelivary
                    currentvalue.DateTimeDelevry = ""
                    setShipping(currentvalue);
                    ////console.log(currentvalue);

                    let newData2 = { ...dataShippingInfo };
                    newData2[City] = currentvalue;
                    setDataShippingInfo(newData2);
                })
                .catch(function (error) {
                    ////console.log(error);
                    setShipping(currentvalue);
                    ////console.log(currentvalue);
                });

        } else {

            ////console.log('CarShippingInfo local:', dataShippingInfo[Citey]);
            setShipping(dataShippingInfo[City]);

        }
    }
    //-------------------------------------------
    const [cities, setCities] = useState([]);
    // to fetch all SAU countries
    const handleFetchCountries = async () => {
        let countries = [];
        try {
            const response = await axios.post(
                config.baseURL + 'func',
                {
                    "Name": "site_ShippingCities"
                }
            );
            if (response.status == 200) {
                countries = response.data.map(item => {
                    return { value: item.ID, text: item[`${trans("model:description")}`] }
                });
            }
        } catch (error) {
            //console.log('error in fetching the countries of SAU', error);
        }
        setCities(countries);
    };
    useEffect(async () => {

        await checkStepsErrorsDataFullNum();
    }, [requestForm]);

    const checkStepsErrorsDataFullNum = async () => {
        var result = formValidate(schema_1, requestForm) ?? {};

        let unspesificErrors = null;
        let newErrors = {};
        if (identityType == 1) {
            // identity type is (identity card)
            unspesificErrors = ['cn'];

        } else if (identityType == 2) {

            unspesificErrors = ['identityNo'];

        }
        newErrors = unspesificErrors ? removeUnspecificError(result, unspesificErrors) : result;


        var srempNumber = 0
        if (Object.keys(newErrors).length === 0) {
            srempNumber = 1;
        } else {
            setDataFullNum(srempNumber);
            return srempNumber;
        }

        //---------------------2222
        result = formValidate(schema_2, requestForm) ?? {};


        if (Object.keys(result).length === 0) {
            srempNumber = 2;
        } else {
            setDataFullNum(srempNumber);
            return srempNumber;
        }

        //---------------------3333333333
        result = formValidate(schema_3, requestForm) ?? {};

        if (Object.keys(result).length === 0) {
            srempNumber = 3;
        } else {
            setDataFullNum(srempNumber);
            return srempNumber;
        }
        if (Object.keys(result).length === 0) {
            srempNumber = 3;
        } else {
            setDataFullNum(srempNumber);
            return srempNumber;
        }
        //---------------------
        setDataFullNum(srempNumber);
        return srempNumber;
    }
    const checkStepsErrors = async () => {
        console.log("checkStepsErrors>>>>>>>>>>>>>>:", requestForm);
        setOTP("");
        var result = formValidate(schema_1, requestForm) ?? {};

        let unspesificErrors = null;
        let newErrors = {};
        if (identityType == 1) {
            // identity type is (identity card)
            unspesificErrors = ['cn'];

        } else if (identityType == 2) {

            unspesificErrors = ['identityNo'];

        }
        newErrors = unspesificErrors ? removeUnspecificError(result, unspesificErrors) : result;

        if (stepNum == 1)
            setError(newErrors);


        //console.log("result>>>>>>>>>>>>>>:", result);

        var srempNumber = 0
        if (Object.keys(newErrors).length === 0) {
            srempNumber = 1;
        } else {
            setDataFullNum(srempNumber);
            setStepNum(srempNumber + 1);
            return srempNumber;
        }
        result = formValidate(schema_2, requestForm) ?? {};
        console.log("result>>>>>>>>>>>>>>:", result);
        if (stepNum == 2)
            setError(result);

        if (Object.keys(result).length === 0) {
            srempNumber = 2;
        } else {
            setDataFullNum(srempNumber);
            setStepNum(srempNumber + 1);
            return srempNumber;
        }
        result = formValidate(schema_3, requestForm) ?? {};
        //console.log("result>>>>>>>>>>>>>>:", result);
        if (stepNum == 3)
            setError(result);

        if (Object.keys(result).length === 0) {
            srempNumber = 3;
        } else {
            setDataFullNum(srempNumber);
            setStepNum(srempNumber + 1);
            return srempNumber;
        }

        if (stepNum == 3)
            setError(result);
        //console.log("result", result);
        if (Object.keys(result).length === 0) {
            srempNumber = 3;
        } else {
            setDataFullNum(srempNumber);
            setStepNum(srempNumber + 1);
            return srempNumber;
        }

        setDataFullNum(srempNumber);
        return srempNumber;
    }
    return (
        <>
            <section className="carOverview-page carCheckout-page flex flex-col   ">

                <div className="nice-sticky mb-40 show-xs">

                    <div className=" flex flex-row justify-between nice-align-item-center py-10 px-10 nice-fixed flex-item-center nice-shadow-3 bg-white w-100">

                        <img className=" rotate-180" src="/assets/icons/arrow-right.svg" width="7" height="7"
                            alt="arrow" onClick={() => {
                                history.goBack();
                            }} />
                        <span className="text-30"> طلب تمويل سيارة  </span>
                        <span className=" text-24 text-gray"
                            onClick={() => {

                                history.push({
                                    pathname: `/car/overview/${sn}/${slug}`,
                                    search: ""
                                });

                            }}
                        >الغاء  </span>
                    </div>
                </div>
                <div className="carOverview-page-content grid grid-cols-12 gap-5
                          justify-items-auto mx-30 mt-20">



                    <div className="  h-vh border-radius col-span-12 md:col-span-8 sm:col-span-8 lg:col-span-7 xl:col-span-7">

                        <div className="flex mb-10 hide-xs">
                            <div className={"btn nice-flex-row border-radius nice-shadow-1 nice-border-checkout-gray  bg-white px-10  py-5  text-24 "}
                                onClick={() => {

                                    history.push({
                                        pathname: `/car/overview/${sn}/${slug}`,
                                        search: ""
                                    });
                                }} >{trans("info:الغاء")} </div>
                        </div>
                        <div className=" px-20 carOverview-page-main nice-shadow-3 bg-white  h-vh border-radius col-span-12">


                            <div className="flex flex-col py-20  px-10  ">
                                <div className="checkoutStepper">
                                    <div className={"checkoutStepper-part " + (dataFullNum > 0 ? "active" : " ")}
                                        onClick={() => cheangeSteps(1)}
                                    >
                                        <div className={`checkoutStepper-part-titel ${stepNum == 1 ? " active" : null}`}>
                                            <span>{trans("employment:المعلومات الشخصية")}</span>
                                        </div>
                                        <div className={"checkoutStepper-part-connector  "}></div>
                                    </div>
                                    <div className={"checkoutStepper-part " + (dataFullNum > 1 ? "active" : " ")}
                                        onClick={() => cheangeSteps(2)}
                                    >
                                        <div className={`checkoutStepper-part-titel ${stepNum == 2 ? " active" : null}`}>
                                            <span> {trans("employment:معلومات العمل")}</span>
                                        </div>
                                        <div className={"checkoutStepper-part-connector  "}></div>
                                    </div>

                                    <div className={"checkoutStepper-part " + (dataFullNum > 2 ? "active" : " ")}
                                        onClick={() => cheangeSteps(3)}
                                    >
                                        <div className={`checkoutStepper-part-titel ${stepNum == 3 ? " active " : null}`}>
                                            <span>{trans("employment:الوثائق المطلوبة")}</span></div>
                                        <div className={"checkoutStepper-part-connector  "}></div>
                                    </div>


                                </div>
                                <div className="ruler-2 mb-20"></div>

                                <form className="flex flex-col gap-1  my-5 " onSubmit={(e) => {
                                    // submitRequest(e);
                                }}>

                                    {
                                        stepNum == 1 &&
                                        <>

                                            <h3 className="text-30 text-bold mb-10  ">{"ادخل معلوماتك"}</h3>
                                            <div className="flex flex-row grid-1-cols-importent nice-gap-2rem  mt-20  ">
                                                <InputField
                                                    placeholder={""}
                                                    moduleLabel={"الاسم بالكامل"}
                                                    value={requestForm.name}
                                                    errorLabel={error.name}
                                                    extraClasses={"Input-module"}
                                                    onChangeValue={(e) => {
                                                        const val = { ...requestForm };
                                                        val.name = e.target.value;
                                                        setRequestForm(val);
                                                    }}
                                                    name='name'
                                                />
                                            </div>

                                            <div className="flex flex-row grid-1-cols-importent nice-gap-2rem mt-20 ">

                                                <DropDownInput
                                                    title={trans("finance:cust_type")}

                                                    extraClasses={"dropdown-Input-module"}
                                                    defaultOption={requestForm?.custType == null ? { text: trans("finance:cust_type") + '*', value: '0' } : null}
                                                    errorLabel={error?.custType}
                                                    options={[...accountTypes.map(item => {
                                                        return { text: item[`${trans("model:description")}`], value: item.CustGroupCollectingID }
                                                    })]}
                                                    onSelect={handleCustType}

                                                    selectedValue={requestForm?.custType}
                                                />

                                            </div>

                                            <>
                                                {

                                                    (identityType && identityType == 1) ?
                                                        <div className="flex flex-row grid-1-cols-importent nice-gap-2rem mt-20 ">

                                                            <InputField
                                                                key={"identityNo"}
                                                                errorLabel={error?.identityNo}
                                                                value={requestForm.identityNo}
                                                                length={10}
                                                                name={"identityNo"}
                                                                moduleLabel={trans("form:identity-no") + '*'}
                                                                extraClasses={"Input-module"}
                                                                placeholder={""}
                                                                onChangeValue={(e) => {
                                                                    const val = { ...requestForm };
                                                                    val.identityNo = e.target.value;
                                                                    setRequestForm(val);
                                                                }}
                                                            />

                                                        </div>
                                                        : (identityType && identityType == 2) ?
                                                            <div className="flex flex-row grid-1-cols-importent nice-gap-2rem mt-20 ">

                                                                <InputField
                                                                    key={"cn"}
                                                                    errorLabel={error?.cn}
                                                                    value={requestForm.cn}
                                                                    length={20}
                                                                    name={"cn"}
                                                                    extraClasses={"Input-module"}
                                                                    placeholder={""}
                                                                    moduleLabel={trans("finance:cn") + '*'}
                                                                    onChangeValue={(e) => {
                                                                        const val = { ...requestForm };
                                                                        val.cn = e.target.value;
                                                                        setRequestForm(val);
                                                                    }}
                                                                />

                                                            </div>
                                                            : null


                                                }

                                            </>


                                            <div className="flex flex-row grid-1-cols-importent nice-gap-2rem  mt-20 px-5 ">
                                                <RadioInputDynamic
                                                    onChangeValue={(e) => {
                                                        const val = { ...requestForm };
                                                        val.Gender = String(e);
                                                        setRequestForm(val);
                                                        //console.log(e);

                                                    }}
                                                    extraClasses={"Radio-Input-module"}
                                                    selectValue={requestForm.Gender}
                                                    name="Gender"
                                                    groupName="Gender"
                                                    title={trans("employment:type")}
                                                    ListValues={[{ text: trans("employment:man"), value: 0 }, {
                                                        text: trans("employment:female"),
                                                        value: 1
                                                    }]}
                                                    errorLabel={error.Gender}
                                                />
                                            </div>

                                            <div className="flex flex-row grid-1-cols-importent nice-gap-2rem mt-20 ">
                                                {

                                                    < PhoneDropDown
                                                        mandotory={true}
                                                        name='phoneNumber'
                                                        moduleLabel={"رقم الجوال"}
                                                        extraClasses={"phone-Input-module"}
                                                        defaultFlag={requestForm.phoneNumberCode}
                                                        defaultValue={requestForm.phoneNumberOnly}
                                                        errorLabel={error?.phoneNumberOnly}
                                                        onChangeValue={(countryCode, phoneNumber) => {
                                                            const val = { ...requestForm };
                                                            val.phoneNumberOnly = phoneNumber;
                                                            val.phoneNumberCode = countryCode;
                                                            val.phoneNumber = `+${countryCode}${phoneNumber}`;
                                                            setRequestForm(val);
                                                        }}

                                                    />
                                                }
                                            </div>


                                            <div className="flex flex-row grid-1-cols-importent nice-gap-2rem mt-20 px-5 ">
                                                <CheckInput
                                                    className="text-primary cursor-pointer"
                                                    checked={requestForm.NotificationWhatsApp}
                                                    oncheng={async (target) => {
                                                        console.log("NotificationWhatsApp:", target.checked);
                                                        const val = { ...requestForm };
                                                        val.NotificationWhatsApp = target.checked;
                                                        setRequestForm(val);
                                                    }}
                                                    Name={"check-conectWat"}
                                                >
                                                    <span class="whatsapp-icon">
                                                        <img className="object-max-full " src='/assets/icons/social/wpImg.svg' width='25' height='25' />
                                                    </span>
                                                    <span className='text-20 nice-pointer text-inline-block'>
                                                        <span className='text-20 nice-pointer  text-inline-block'>{trans("info:  أود التواصل واستقبال تحديثات طلبي عن طريق الواتساب")}</span>
                                                    </span>
                                                </CheckInput>
                                            </div>
                                            <div className="flex flex-row grid-1-cols-importent nice-gap-2rem mt-20 ">
                                                <DropDownInput
                                                    title={trans("new:city")}
                                                    errorLabel={error.City}
                                                    extraClasses={"dropdown-Input-module"}
                                                    selectedValue={requestForm.City}
                                                    options={[...cities]}
                                                    onfilter={(Citey) => CarShippingInfo(Citey)}
                                                />
                                            </div>

                                            <div class="ruler-2 mt-20 mb-5"></div>

                                            <div className="flex flex-row justify-between nice-align-item-center bg-secondary my-5 px-10 py-5  border-radius-sm   nice-pointer "
                                                onClick={() => {
                                                    setShowPopupMessage(true)
                                                }}
                                            >
                                                <div className="flex flex-row text-16 nice-pointer nice-align-item-center gap-1">
                                                    <i className="icon-documents"></i>
                                                    <p className="text-20 nice-pointer text-info text-bold  text-inline-block">ما هي متطلبات التمويل و الوثائق المطلوبة؟</p>
                                                </div>

                                                <span className="text-16  nice-line-underline nice-pointer text-info  text-inline-block">اعرف أكثر</span>
                                            </div>

                                            <div className=" grid nice-align-item-center grid-cols-12 gap-5 justify-items-auto p-10   my-20 ">
                                                <div className="border-box-periodic-checkInput col-span-12 md:col-span-8 sm:col-span-8 lg:col-span-8 xl:col-span-8">
                                                </div>

                                                <div className="col-span-12 md:col-span-4 sm:col-span-4 lg:col-span-4 xl:col-span-4">

                                                    <div className={"btn nice-flex-row  py-6 px-5 text-white text-24 " + (dataFullNum > 0 ? " bg-primary " : " bg-gray ")}
                                                        onClick={async () => {
                                                            const num = await checkStepsErrors();

                                                            if (num >= (stepNum)) {

                                                                if (stepNum <= 3)
                                                                    setStepNum(stepNum + 1);

                                                                if (Object.keys(error).length > 0) {
                                                                    // setError({});

                                                                }
                                                            } else {
                                                                // checkErrors();
                                                            }
                                                        }}




                                                    >{trans("info:الخطوة التالية")} </div>
                                                </div>
                                            </div>


                                        </>}


                                    {
                                        stepNum == 2 &&
                                        <>
                                            <h3 className="text-30 text-bold mb-10  ">{"ادخل معلومات العمل"}</h3>

                                            <div className="flex flex-row grid-1-cols-importent nice-gap-2rem mt-20 ">
                                                <DropDownInput
                                                    title={trans("new:جهة العمل")}
                                                    errorLabel={error.WorkType}
                                                    extraClasses={"dropdown-Input-module"}
                                                    selectedValue={requestForm.WorkType}
                                                    options={[{ text: trans("employment:خاص"), value: "قطاع خاص" }, {
                                                        text: trans("employment:حكومي"),
                                                        value: "حكومي"
                                                    }]}
                                                    onfilter={(e) => {

                                                        const val = { ...requestForm };
                                                        val.WorkType = e.value;
                                                        setRequestForm(val);
                                                    }}

                                                />
                                            </div>
                                            <div className="flex flex-row grid-1-cols-importent nice-gap-2rem  mt-20 ">
                                                <InputField
                                                    placeholder={""}
                                                    moduleLabel={"اسم جهة العمل "}
                                                    value={requestForm.job}
                                                    errorLabel={error.job}
                                                    extraClasses={"Input-module"}
                                                    onChangeValue={(e) => {
                                                        const val = { ...requestForm };
                                                        val.job = e.target.value;
                                                        setRequestForm(val);
                                                    }}
                                                    name='job'
                                                />
                                            </div>

                                            <div className="flex flex-row grid-1-cols-importent nice-gap-2rem  mt-20 ">
                                                <InputField
                                                    placeholder={"ريال"}
                                                    moduleLabel={" الراتب بدون غلاء معيشة "}
                                                    value={requestForm.income}
                                                    errorLabel={error.income}
                                                    extraClasses={"Input-module"}
                                                    onChangeValue={(e) => {
                                                        const val = { ...requestForm };
                                                        val.income = e.target.value;
                                                        setRequestForm(val);
                                                    }}
                                                    name='salary'
                                                />
                                            </div>

                                            <div className="flex flex-row grid-1-cols-importent nice-gap-2rem mt-20 ">
                                                <DropDownInput
                                                    title={trans("new:طريقة استلام الراتب")}
                                                    errorLabel={error.SalaryTransfer}
                                                    extraClasses={"dropdown-Input-module"}
                                                    selectedValue={requestForm.SalaryTransfer}
                                                    options={[...banks.map(b => {
                                                        return {
                                                            text: lang == "ar" ? b.DescriptionAr : b.DescriptionEn
                                                            , value: b.BankID
                                                        }
                                                    })]}
                                                    onfilter={(e) => {

                                                        const val = { ...requestForm };
                                                        val.SalaryTransfer = e.value;
                                                        setRequestForm(val);
                                                    }}

                                                />
                                            </div>

                                            <div className="flex flex-row grid-2-cols nice-gap-2rem  mt-20 px-5 ">
                                                <RadioInputDynamic
                                                    onChangeValue={(e) => {
                                                        const val = { ...requestForm };
                                                        val.Questions1 = String(e);
                                                        setRequestForm(val);
                                                        //console.log(e);

                                                    }
                                                    }
                                                    extraClasses={"Radio-Input-module"}
                                                    selectValue={requestForm.Questions1}
                                                    name="Questions1"
                                                    groupName="Questions1"
                                                    title={trans("new:question1")}
                                                    ListValues={[{ text: trans("employment:نعم, لدي "), value: 1 }, {
                                                        text: trans("employment:لا, لايوجد "),
                                                        value: 0
                                                    }]}
                                                    errorLabel={error.Questions1}
                                                />


                                                <RadioInputDynamic
                                                    onChangeValue={(e) => {
                                                        const val = { ...requestForm };
                                                        val.Questions2 = String(e);
                                                        setRequestForm(val);
                                                        //console.log(e);

                                                    }
                                                    }
                                                    extraClasses={"Radio-Input-module"}
                                                    selectValue={requestForm.Questions2}
                                                    name="Questions2"
                                                    groupName="Questions2"
                                                    title={trans("new:question2")}
                                                    ListValues={[{ text: trans("employment:نعم, لدي مخالفات"), value: 1 }, {
                                                        text: trans("employment:لا, لايوجد مخالفات"),
                                                        value: 0
                                                    }]}
                                                    errorLabel={error.Questions2}
                                                />
                                            </div>


                                            <div className="flex flex-row grid-2-cols nice-gap-2rem  mt-20 px-5 ">
                                                <RadioInputDynamic
                                                    onChangeValue={(e) => {
                                                        const val = { ...requestForm };
                                                        val.Questions3 = String(e);
                                                        setRequestForm(val);
                                                        //console.log(e);

                                                    }
                                                    }
                                                    extraClasses={"Radio-Input-module"}
                                                    selectValue={requestForm.Questions3}
                                                    name="Questions3"
                                                    groupName="Questions3"
                                                    title={trans("new:question3")}
                                                    ListValues={[{ text: trans("employment:نعم, لدي قرض"), value: 1 }, {
                                                        text: trans("employment:لا, لايوجد قرض"),
                                                        value: 0
                                                    }]}
                                                    errorLabel={error.Questions3}
                                                />

                                                <RadioInputDynamic
                                                    onChangeValue={(e) => {
                                                        const val = { ...requestForm };
                                                        val.Questions4 = String(e);
                                                        setRequestForm(val);
                                                        //console.log(e);

                                                    }
                                                    }
                                                    extraClasses={"Radio-Input-module"}
                                                    selectValue={requestForm.Questions4}
                                                    name="Questions4"
                                                    groupName="Questions4"
                                                    title={trans("new:question4")}
                                                    ListValues={[{ text: trans("employment:نعم,يوجد التزامات"), value: 1 }, {
                                                        text: trans("employment:لا,لايوجد التزامات"),
                                                        value: 0
                                                    }]}
                                                    errorLabel={error.Questions4}
                                                />
                                            </div>

                                            {
                                                requestForm.Questions4 == 1 &&
                                                <div className="flex flex-row grid-1-cols-importent nice-gap-2rem  mt-20 ">
                                                    <InputField
                                                        placeholder={"ريال"}
                                                        moduleLabel={"قيمة الالتزامات "}
                                                        value={requestForm.Obligations}
                                                        errorLabel={error.Obligations}
                                                        extraClasses={"Input-module"}
                                                        onChangeValue={(e) => {
                                                            const val = { ...requestForm };
                                                            val.Obligations = e.target.value;
                                                            setRequestForm(val);
                                                        }}
                                                        name='Obligations'
                                                    />
                                                </div>
                                            }


                                            <div className=" grid nice-align-item-center grid-cols-12 gap-5 justify-items-auto p-10   my-20 ">
                                                <div className="border-box-periodic-checkInput col-span-12 md:col-span-8 sm:col-span-8 lg:col-span-8 xl:col-span-8">
                                                </div>

                                                <div className="col-span-12 md:col-span-4 sm:col-span-4 lg:col-span-4 xl:col-span-4">

                                                    <div className={"btn nice-flex-row  py-6 px-5 text-white text-24 " + (dataFullNum > 1 ? " bg-primary " : " bg-gray ")}
                                                        onClick={async () => {
                                                            const num = await checkStepsErrors();
                                                            //console.log(" checkStepsErrors num:", num);
                                                            if (num >= (stepNum)) {

                                                                if (stepNum <= 3)
                                                                    setStepNum(stepNum + 1);

                                                                if (Object.keys(error).length > 0) {
                                                                    // setError({});

                                                                }
                                                            } else {
                                                                // checkErrors();
                                                            }
                                                        }}




                                                    >{trans("info:الخطوة التالية")} </div>
                                                </div>
                                            </div>

                                        </>}


                                    {
                                        stepNum == 3 &&
                                        <>

                                            {/* <h3 className="text-30 text-bold mb-10  ">{" الدفع "}</h3> */}


                                            <p className='text-20 text-gray text-inline-block' >{"لتسريع اجراءات الطلب رجاء رفع الملفات كاملة"}</p>

                                            <div class="ruler-2 mt-5 mb-5"></div>


                                            <div className="flex flex-row grid-1-cols-importent nice-gap-2rem mt-20 ">
                                                <DropDownInput
                                                    title={trans("new:جهة التمويل")}
                                                    errorLabel={error.BankID}
                                                    extraClasses={"dropdown-Input-module"}
                                                    selectedValue={requestForm.BankID}
                                                    options={[...banks.map(b => {
                                                        return {
                                                            text: lang == "ar" ? b.DescriptionAr : b.DescriptionEn
                                                            , value: b.BankID, ...b
                                                        }
                                                    })]}
                                                    onfilter={(bank) => {
                                                        const val = { ...requestForm };
                                                        val.BankID = bank.value;
                                                        setRequestForm(val);
                                                        var DefaultFinancePeriod = bank.DefaultFinancePeriod ? bank.DefaultFinancePeriod : 60;
                                                        setFinanceSettings(bank);
                                                        setSelectedFinancePeriod(DefaultFinancePeriod);
                                                        reCountFinanceSettings(bank, DefaultFinancePeriod);
                                                    }}

                                                />
                                            </div>

                                            <div className="grid grid-cols-12 gap-5 justify-items-auto mx-30 mt-20">

                                                <div className="grow input border-radius col-span-6 md:col-span-6 sm:col-span-4 lg:col-span-4 xl:col-span-4">

                                                    {/* <ErrorLabel errorTitle={error?.file} /> */}
                                                    <UploadControl
                                                        placeholder={trans("employment:الهوية الشخصية")}
                                                        errorLabel={error?.identificationImageName}
                                                        extraClasses={"border-none border-radius-sm  text-24 "}
                                                        value={requestForm?.identificationImageName}
                                                        // imgValue={requestForm?.file}
                                                        modelClasses={"Input-upload-module"}
                                                        onPicker={async (e) => {
                                                            const { target } = e;
                                                            const { files } = target;
                                                            const loadedImage = files[0];

                                                            // loadedImage will be a image file.
                                                            if (target.value && loadedImage?.name) {
                                                                const size = loadedImage.size;
                                                                const finalCheck = Math.round((size / 1024));
                                                                if (finalCheck >= 4096) {
                                                                    toast.error(trans('new:file_size_error'));
                                                                    return;
                                                                }

                                                                const val = { ...requestForm };
                                                                val.identificationImageName = loadedImage?.name;
                                                                const fileBase64 = await toBase64(loadedImage);
                                                                const finalFile = fileBase64.substr(fileBase64.indexOf(',') + 1, fileBase64.length);
                                                                val.identificationImage = finalFile;
                                                                setRequestForm(val);
                                                            } else {
                                                                const val = { ...requestForm };
                                                                val.identificationImageName = "";
                                                                val.identificationImage = "";
                                                                setRequestForm(val);
                                                            }
                                                        }}

                                                        onRemove={(e) => {
                                                            const val = { ...requestForm };
                                                            val.identificationImageName = "";
                                                            val.identificationImage = "";
                                                            setRequestForm(val);

                                                        }}
                                                        accept="*" />
                                                </div>

                                                <div className="grow input  border-radius  col-span-6 md:col-span-6 sm:col-span-4 lg:col-span-4 xl:col-span-4">

                                                    {/* <ErrorLabel errorTitle={error?.file} /> */}
                                                    <UploadControl
                                                        placeholder={trans("employment:رخصة قيادة")}
                                                        errorLabel={error?.LicenseName}
                                                        extraClasses={"border-none border-radius-sm  text-24 "}
                                                        value={requestForm?.LicenseName}
                                                        // imgValue={requestForm?.License}
                                                        modelClasses={"Input-upload-module"}
                                                        onPicker={async (e) => {
                                                            const { target } = e;
                                                            const { files } = target;
                                                            const loadedImage = files[0];

                                                            // loadedImage will be a image file.
                                                            if (target.value && loadedImage?.name) {
                                                                const size = loadedImage.size;
                                                                const finalCheck = Math.round((size / 1024));
                                                                if (finalCheck >= 4096) {
                                                                    toast.error(trans('new:file_size_error'));
                                                                    return;
                                                                }

                                                                const val = { ...requestForm };
                                                                val.LicenseName = loadedImage?.name;
                                                                const fileBase64 = await toBase64(loadedImage);
                                                                const finalFile = fileBase64.substr(fileBase64.indexOf(',') + 1, fileBase64.length);
                                                                val.License = finalFile;
                                                                setRequestForm(val);
                                                            } else {
                                                                const val = { ...requestForm };
                                                                val.LicenseName = "";
                                                                val.License = "";
                                                                setRequestForm(val);
                                                            }
                                                        }}

                                                        onRemove={(e) => {
                                                            const val = { ...requestForm };
                                                            val.LicenseName = "";
                                                            val.License = "";
                                                            setRequestForm(val);

                                                        }}
                                                        accept="*" />
                                                </div>
                                                <div className="grow input border-radius col-span-6 md:col-span-6 sm:col-span-4 lg:col-span-4 xl:col-span-4">

                                                    {/* <ErrorLabel errorTitle={error?.file} /> */}
                                                    <UploadControl
                                                        placeholder={trans("employment:برنت تأميتات ")}
                                                        errorLabel={error?.InsuranceName}
                                                        extraClasses={"border-none border-radius-sm  text-24 "}
                                                        value={requestForm?.InsuranceName}
                                                        // imgValue={requestForm?.Insurance}
                                                        modelClasses={"Input-upload-module"}
                                                        onPicker={async (e) => {
                                                            const { target } = e;
                                                            const { files } = target;
                                                            const loadedImage = files[0];

                                                            // loadedImage will be a image file.
                                                            if (target.value && loadedImage?.name) {
                                                                const size = loadedImage.size;
                                                                const finalCheck = Math.round((size / 1024));
                                                                if (finalCheck >= 4096) {
                                                                    toast.error(trans('new:file_size_error'));
                                                                    return;
                                                                }

                                                                const val = { ...requestForm };
                                                                val.InsuranceName = loadedImage?.name;
                                                                const fileBase64 = await toBase64(loadedImage);
                                                                const finalFile = fileBase64.substr(fileBase64.indexOf(',') + 1, fileBase64.length);
                                                                val.Insurance = finalFile;
                                                                setRequestForm(val);
                                                            } else {
                                                                const val = { ...requestForm };
                                                                val.InsuranceName = "";
                                                                val.Insurance = "";
                                                                setRequestForm(val);
                                                            }
                                                        }}

                                                        onRemove={(e) => {
                                                            const val = { ...requestForm };
                                                            val.InsuranceName = "";
                                                            val.Insurance = "";
                                                            setRequestForm(val);

                                                        }}
                                                        accept="*" />
                                                </div>

                                                <div className="grow input  border-radius  col-span-6 md:col-span-6 sm:col-span-4 lg:col-span-4 xl:col-span-4">

                                                    {/* <ErrorLabel errorTitle={error?.file} /> */}
                                                    <UploadControl
                                                        placeholder={trans("employment:تعريف بالراتب")}
                                                        errorLabel={error?.SalaryDefinitionletterName}
                                                        extraClasses={"border-none border-radius-sm  text-24 "}
                                                        value={requestForm?.SalaryDefinitionletterName}
                                                        // imgValue={requestForm?.SalaryDefinitionletter}
                                                        modelClasses={"Input-upload-module"}
                                                        onPicker={async (e) => {
                                                            const { target } = e;
                                                            const { files } = target;
                                                            const loadedImage = files[0];

                                                            // loadedImage will be a image file.
                                                            if (target.value && loadedImage?.name) {
                                                                const size = loadedImage.size;
                                                                const finalCheck = Math.round((size / 1024));
                                                                if (finalCheck >= 4096) {
                                                                    toast.error(trans('new:file_size_error'));
                                                                    return;
                                                                }

                                                                const val = { ...requestForm };
                                                                val.SalaryDefinitionletterName = loadedImage?.name;
                                                                const fileBase64 = await toBase64(loadedImage);
                                                                const finalFile = fileBase64.substr(fileBase64.indexOf(',') + 1, fileBase64.length);
                                                                val.SalaryDefinitionletter = finalFile;
                                                                setRequestForm(val);
                                                            } else {
                                                                const val = { ...requestForm };
                                                                val.SalaryDefinitionletterName = "";
                                                                val.SalaryDefinitionletter = "";
                                                                setRequestForm(val);
                                                            }
                                                        }}

                                                        onRemove={(e) => {
                                                            const val = { ...requestForm };
                                                            val.SalaryDefinitionletterName = "";
                                                            val.SalaryDefinitionletter = "";
                                                            setRequestForm(val);

                                                        }}
                                                        accept="*" />
                                                </div>
                                                <div className="grow input  border-radius  col-span-6 md:col-span-6 sm:col-span-4 lg:col-span-4 xl:col-span-4">

                                                    {/* <ErrorLabel errorTitle={error?.file} /> */}
                                                    <UploadControl
                                                        placeholder={trans("employment:كشف حساب")}
                                                        errorLabel={error?.AccountStatementName}
                                                        extraClasses={"border-none border-radius-sm  text-24 "}
                                                        value={requestForm?.AccountStatementName}
                                                        // imgValue={requestForm?.AccountStatement}
                                                        modelClasses={"Input-upload-module"}
                                                        onPicker={async (e) => {
                                                            const { target } = e;
                                                            const { files } = target;
                                                            const loadedImage = files[0];

                                                            // loadedImage will be a image file.
                                                            if (target.value && loadedImage?.name) {
                                                                const size = loadedImage.size;
                                                                const finalCheck = Math.round((size / 1024));
                                                                if (finalCheck >= 4096) {
                                                                    toast.error(trans('new:file_size_error'));
                                                                    return;
                                                                }

                                                                const val = { ...requestForm };
                                                                val.AccountStatementName = loadedImage?.name;
                                                                const fileBase64 = await toBase64(loadedImage);
                                                                const finalFile = fileBase64.substr(fileBase64.indexOf(',') + 1, fileBase64.length);
                                                                val.AccountStatement = finalFile;
                                                                setRequestForm(val);
                                                            } else {
                                                                const val = { ...requestForm };
                                                                val.AccountStatementName = "";
                                                                val.AccountStatement = "";
                                                                setRequestForm(val);
                                                            }
                                                        }}

                                                        onRemove={(e) => {
                                                            const val = { ...requestForm };
                                                            val.AccountStatementName = "";
                                                            val.AccountStatement = "";
                                                            setRequestForm(val);

                                                        }}
                                                        accept="*" />
                                                </div>
                                            </div>
                                            <div className=" grid nice-align-item-center grid-cols-12 gap-5 justify-items-auto p-10   my-20 ">

                                                <div className="border-box-periodic-checkInput col-span-12 md:col-span-8 sm:col-span-8 lg:col-span-8 xl:col-span-8">
                                                </div>

                                                <div className="col-span-12 md:col-span-4 sm:col-span-4 lg:col-span-4 xl:col-span-4">

                                                    <div className={"btn nice-flex-row  py-6 px-5 text-white text-24" + (dataFullNum > 2 ? " bg-primary " : " bg-gray ")}
                                                        onClick={async () => {

                                                            const num = await checkStepsErrors();
                                                            if (num >= (stepNum)) {

                                                                if (stepNum <= 3) {

                                                                    handleSubmit();
                                                                    setStepNum(3);
                                                                }


                                                                if (Object.keys(error).length > 0) {
                                                                    // setError({});

                                                                }
                                                            } else {
                                                                // checkErrors();
                                                            }
                                                        }}




                                                    >
                                                        {
                                                            isLoadingSendOrder && <span className='mx-2 loading-btn '>
                                                                <img className="object-max-full " src='/assets/icons/loading.svg' width='25' height='25' />
                                                            </span>
                                                        }
                                                        {trans("info:تقديم الطلب")} </div>
                                                </div>

                                            </div>


                                        </>}
                                </form>



                            </div>




                            {/* //------------------------ */}

                            <div id="parent-footer"
                                className="eparts-page-footer bg-theme-up show-xs"
                                style={{ height: scrollSnackUp.height }}
                                onTouchEnd={(event) => {

                                    if (!event?.target?.id.includes("footer-actions")) {
                                        const currentValue = { ...scrollSnackUp };
                                        currentValue.show = !currentValue.show;
                                        if (currentValue.show) {
                                            currentValue.height = '112rem';
                                        } else {
                                            currentValue.height = '16rem';
                                        }
                                        setScrollSnackUp(currentValue);

                                    }

                                }}>

                                {

                                    (


                                        ((selectedColor?.buyOnline == true)) ? (
                                            <>


                                                <div className="flex flex-row justify-between">


                                                    <div className="" >

                                                        <div className="carOverview-page-body-desc">
                                                            <div className="carOverview-page-body-name">
                                                                <div className="carOverview-page-body-type">
                                                                    <div className="carOverview-page-body-type-content nice-gap-1rem">
                                                                        <h2 className="text-20 text-bold">{propsData?.vehicle?.brand && propsData?.vehicle?.brand}</h2>
                                                                        {/* <img src={vehicle?.brandIconPath && vehicle?.brandIconPath} /> */}
                                                                        <img className="" src={`/assets/icons/brands/${(isToyota ? "toyota" : "") + (isLexus ? "lexus" : "")}.png`} />
                                                                    </div>
                                                                </div>

                                                                <h3 className="text-30 text-bold">{vehicle?.name && vehicle?.name}</h3>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="flex flex-col justify-center">
                                                        {<>
                                                            <div className="flex flex-row gap-1">
                                                                <div className="flex flex-col  gap-1">
                                                                    <h4 className="text-20">{trans("يبدأ القسط من")} </h4>
                                                                    <span className="text-30 font-bold text-primary">{(minFinance?.price) && <Price price={parseFloat(minFinance?.price.toFixed(2))} />}{" " + trans("new:monthly")}</span>
                                                                </div>
                                                            </div>
                                                        </>}
                                                    </div>

                                                </div >
                                                {

                                                    <div className="flex flex-col nice-gap-1rem py-5">
                                                        {

                                                            (
                                                                propsData?.vehicle ?
                                                                    <>

                                                                        <div className="carOverview-page-body-desc">

                                                                            <div className="nice-container carOverview-page-body-number flex flex-row nice-gap-1rem my-10">
                                                                                <span className="text-20 text-gray"> {trans("new:item_no")}</span>
                                                                                <span className="text-20 text-gray">{vehicle?.productNo ? vehicle?.productNo : "########"}</span>
                                                                            </div>
                                                                        </div>

                                                                        <div className="carOverview-page-body-img">
                                                                            <img className="carOverview-page-header-img"
                                                                                // src={"data:image;base64," + image.DATA}
                                                                                src={config.FilePathURL + filterCarImages[0]?.Image_URL}
                                                                            />

                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <div className="container search-line my-40"><h4 className="text-gray text-20 py-2">{trans("new:no_data")}</h4></div>
                                                            )


                                                        }

                                                        {
                                                            finance?.totalPrice ?
                                                                <>
                                                                    <p className="text-center text-24 mt-10">
                                                                        {trans("new:installment_price")}
                                                                    </p>
                                                                    <div className="nice-ruler-base bg-secondary"></div>

                                                                    <div className="nice-flex nice-flex-column nice-gap-1rem">

                                                                        <div className="nice-flex nice-flex-row nice-flex-space-between my-4">
                                                                            <h3 className="text-gray-dark text-16">{trans("finance:period")}</h3>
                                                                            <p className={"text-primary-dark text-16"}>{finance?.period}</p>
                                                                        </div>
                                                                        <div className="nice-flex nice-flex-row nice-flex-space-between my-4">
                                                                            <h3 className="text-gray-dark text-16">{trans("finance:management_fees")}</h3>
                                                                            <p className={"text-primary-dark text-16"}><Price price={finance?.mangFees} showZero={true} /></p>
                                                                        </div>
                                                                        <div className="nice-flex nice-flex-row nice-flex-space-between my-4">
                                                                            <h3 className="text-gray-dark text-16">{trans("finance:monthPayment")}</h3>
                                                                            <p className={"text-primary-dark text-16"}><Price price={finance?.price} showZero={true} /></p>
                                                                        </div>
                                                                        <div className="nice-flex nice-flex-row nice-flex-space-between my-4">
                                                                            <h3 className="text-gray-dark text-16">{trans("finance:advance_payment")}</h3>
                                                                            <p className={"text-primary-dark text-16"}> <Price price={finance?.first} showZero={true} /></p>
                                                                        </div>
                                                                        <div className="nice-flex nice-flex-row nice-flex-space-between my-4">
                                                                            <h3 className="text-gray-dark text-16">{trans("finance:lastPayment")}</h3>
                                                                            <p className={"text-primary-dark text-16"}> <Price price={finance?.last} showZero={true} /></p>
                                                                        </div>

                                                                        <div class="mx-2 my-10 nice-flex nice-flex-row">
                                                                            <img src="/assets/icons/info.svg" class="mx-3 text-inline-block nice-max-icon" />
                                                                            <p class="text-12 text-bold text-gray text-inline-block">{trans("finance:This_calculation_is_estimate")} </p>
                                                                        </div>
                                                                    </div>

                                                                    <div className="nice-ruler-base bg-secondary"></div>

                                                                </>
                                                                : null

                                                        }


                                                    </div>
                                                }
                                            </>
                                        )

                                            :
                                            (

                                                <div className="flex flex-row justify-between">
                                                    <div className="flex flex-col justify-center">


                                                    </div>


                                                    <div id="footer-actions-buy" className="flex flex-col btn-notfound bg-gray-light p-5">
                                                        <div id="footer-actions-buy" className="flex flex-row items-center justify-center btn-notfound-available">
                                                            <p id="footer-actions-buy" className="text-center  text-24  text-danger ">
                                                                {trans("new:not_available")}
                                                            </p>
                                                        </div>

                                                        <div className="border-l-5 my-5 mx-5 text-gray"></div>


                                                        <div id="footer-actions-buy" className="flex flex-row basis-2/4 items-center btn-notfound-alert"

                                                            onClick={() => {

                                                            }}
                                                        >
                                                            <p id="footer-actions-buy" className="text-center text-alert text-24  text-line-clamp   ">
                                                                {trans("new:alert_when_available")}
                                                            </p>
                                                            <img className="  " src="/assets/icons/alert-icon.svg" />
                                                        </div>
                                                    </div>


                                                </div>
                                            )




                                    )

                                }


                            </div >




                            {/* //----------------- */}
                        </div >
                    </div >
                    <div className=" hide-xs col-span-0  md:col-span-4 sm:col-span-4 lg:col-span-5 xl:col-span-5 ">
                        <div className="carOverview-page-sub pt-20 nice-shadow-3 border-radius bg-white ">
                            <div className=" py-10 px-5 ">
                                <div className="py-5">
                                    <h3 className="text-30 text-bold ">{"تفاصيل الطلب"}</h3>
                                </div>

                                <div class="ruler-2 mb-5"></div>
                                <div className="carOverview-page-body">
                                    {

                                        (
                                            propsData?.vehicle ?
                                                <>

                                                    <div className="carOverview-page-body-desc">
                                                        <div className="carOverview-page-body-name">
                                                            <div className="carOverview-page-body-type">
                                                                <div className="carOverview-page-body-type-content nice-gap-1rem">
                                                                    <h2 className="text-20 text-bold">{propsData?.vehicle?.brand && propsData?.vehicle?.brand}</h2>
                                                                    {/* <img src={vehicle?.brandIconPath && vehicle?.brandIconPath} /> */}
                                                                    <img className="" src={`/assets/icons/brands/${(isToyota ? "toyota" : "") + (isLexus ? "lexus" : "")}.png`} />
                                                                </div>
                                                            </div>

                                                            <h3 className="text-20 text-gray">{vehicle?.name && vehicle?.name}</h3>
                                                        </div>
                                                        <div className="nice-container carOverview-page-body-number flex flex-row nice-gap-1rem my-10">
                                                            <span className="text-20 text-gray"> {trans("new:item_no")}</span>
                                                            <span className="text-20 text-gray">{vehicle?.productNo ? vehicle?.productNo : "########"}</span>
                                                        </div>
                                                    </div>

                                                    <div className="carOverview-page-body-img">
                                                        <img className="carOverview-page-header-img"
                                                            // src={"data:image;base64," + image.DATA}
                                                            src={config.FilePathURL + filterCarImages[0]?.Image_URL}
                                                        />

                                                    </div>
                                                </>
                                                :
                                                <div className="container search-line my-40"><h4 className="text-gray text-20 py-2">{trans("new:no_data")}</h4></div>
                                        )


                                    }
                                </div>
                                <div class="ruler-2 mb-5"></div>
                                <div className="nice-container flex flex-col gap-3 justify-between">
                                    <div className="flex flex-col justify-center">
                                        {<>

                                            <div className="nice-flex nice-flex-row nice-flex-space-between mb-10">
                                                <h4 className="text-20">{trans("new:cash_price")}&nbsp;<span className="text-14 text-gray mx-1">{trans("new:total_include_tax")}</span> </h4>
                                                <span className="text-20 font-bold text-primary">{(selectedColor?.NetSalePrice) && <Price price={parseFloat(selectedColor?.NetSalePrice)} />}</span>
                                            </div>

                                            <div className="nice-flex nice-flex-row nice-flex-space-between mb-10">
                                                <h4 className="text-20">{trans("يبدأ القسط من")} </h4>
                                                <span className="text-20 font-bold text-primary">{(minFinance?.price) && <Price price={parseFloat(minFinance?.price.toFixed(2))} />}{" " + trans("new:monthly")}</span>
                                            </div>

                                        </>}
                                    </div>

                                </div>

                                <div class="ruler-2 mb-5"></div>

                                {
                                    <div className="flex flex-col nice-gap-1rem py-5">


                                        {
                                            finance?.totalPrice ?
                                                <>
                                                    <p className="text-center text-24 mt-10">
                                                        {trans("new:installment_price")}
                                                    </p>
                                                    <div className="nice-ruler-base bg-secondary"></div>

                                                    <div className="nice-flex nice-flex-column nice-gap-1rem">

                                                        <div className="nice-flex nice-flex-row nice-flex-space-between my-4">
                                                            <h3 className="text-gray-dark text-16">{trans("finance:period")}</h3>
                                                            <p className={"text-primary-dark text-16"}>{finance?.period}</p>
                                                        </div>
                                                        <div className="nice-flex nice-flex-row nice-flex-space-between my-4">
                                                            <h3 className="text-gray-dark text-16">{trans("finance:management_fees")}</h3>
                                                            <p className={"text-primary-dark text-16"}><Price price={finance?.mangFees} showZero={true} /></p>
                                                        </div>
                                                        <div className="nice-flex nice-flex-row nice-flex-space-between my-4">
                                                            <h3 className="text-gray-dark text-16">{trans("finance:monthPayment")}</h3>
                                                            <p className={"text-primary-dark text-16"}><Price price={finance?.price} showZero={true} /></p>
                                                        </div>
                                                        <div className="nice-flex nice-flex-row nice-flex-space-between my-4">
                                                            <h3 className="text-gray-dark text-16">{trans("finance:advance_payment")}</h3>
                                                            <p className={"text-primary-dark text-16"}> <Price price={finance?.first} showZero={true} /></p>
                                                        </div>
                                                        <div className="nice-flex nice-flex-row nice-flex-space-between my-4">
                                                            <h3 className="text-gray-dark text-16">{trans("finance:lastPayment")}</h3>
                                                            <p className={"text-primary-dark text-16"}> <Price price={finance?.last} showZero={true} /></p>
                                                        </div>

                                                        <div class="mx-2 my-10 nice-flex nice-flex-row">
                                                            <img src="/assets/icons/info.svg" class="mx-3 text-inline-block nice-max-icon" />
                                                            <p class="text-12 text-bold text-gray text-inline-block">{trans("finance:This_calculation_is_estimate")} </p>
                                                        </div>
                                                    </div>

                                                    <div className="nice-ruler-base bg-secondary"></div>

                                                </>
                                                : null

                                        }


                                    </div>
                                }


                            </div>
                        </div>
                    </div>
                </div>
            </section >

            <Popup show={showPopupMessage}>

                <div className="offer-popup  nice-flex nice-flex-center finance-requirements-modal">
                    <div className="offer-popup-container p-4">
                        <div className="offer-popup-container-header">
                            <h1 className="text-balck text-bold text-30 text-center header">{trans("finance:متطلبات التمويل و الوثائق المطلوبة")}</h1>
                            <span className='offer-popup-container-header-close text-block text-gray' onClick={() => {
                                setShowPopupMessage(false)
                            }}>&times;</span>
                        </div>
                        <div className="ruler-2 mb-3"></div>
                        <div className='popup-content'>
                            <Tabs selectedTabClassName='tab-active' defaultIndex={0} onSelect={(position) => {
                            }}>

                                <TabList className='tabs'>

                                    <Tab className='tab '>
                                        {trans("new:سعودي")}
                                    </Tab>

                                    <Tab className='tab'>
                                        {trans("new:مقيم")}
                                    </Tab>
                                    <span className='vertical-separater'></span>

                                </TabList>



                                <TabPanel className='nice-container-fluid'>

                                    <div className="mx-10 my-10">
                                        <div className=" flex flex-col text-gray-dark text-20">

                                            <div className=" requirements-section flex flex-col text-gray-dark text-20">
                                                <h4 className=" section-title flex flex-col text-gray-dark text-30">متطلبات التمويل:</h4>
                                                <ul className="flex flex-col text-gray-dark text-30">
                                                    <li className=" text-20">عمر العميل يبدأ من 21 سنة</li>
                                                    <li className=" text-20">مدة العمل 95 يوم</li>
                                                    <li className=" text-20">الراتب يبدا من 3000 ريال</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className=" requirements-section flex flex-col text-gray-dark text-20">
                                            <h4 className=" section-title flex flex-col text-gray-dark text-30">الوثائق المطلوبة:</h4>
                                            <ul className="flex flex-col text-gray-dark text-30">
                                                <li className=" text-20" >الهوية الشخصية سارية المفعول</li>
                                                <li className=" text-20">رخصة قيادة سارية المفعول</li>
                                                <li className=" text-20">برنت تأميتات حديث لا تتجاوز مدته 10 ايام</li>
                                                <li className=" text-20">تعريف بالراتب مصدق من الغرفة التجارية لا تتجاوز مدته شهرين</li>
                                                <li className=" text-20">كشف حساب اخر 3 اشهر</li>
                                            </ul>
                                        </div>

                                    </div>



                                </TabPanel>

                                <TabPanel className='nice-container-fluid'>

                                    <div className="mx-10 my-10">
                                        <div className=" flex flex-col text-gray-dark text-20">

                                            <div className=" requirements-section flex flex-col text-gray-dark text-20">
                                                <h4 className=" section-title flex flex-col text-gray-dark text-30">متطلبات التمويل:</h4>
                                                <ul className="flex flex-col text-gray-dark text-30">
                                                    <li className=" text-20">عمر العميل يبدأ من 21 سنة</li>
                                                    <li className=" text-20">مدة العمل 95 يوم</li>
                                                    <li className=" text-20">الراتب يبدا من 5000 ريال</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className=" requirements-section flex flex-col text-gray-dark text-20">
                                            <h4 className=" section-title flex flex-col text-gray-dark text-30">الوثائق المطلوبة:</h4>
                                            <ul className="flex flex-col text-gray-dark text-30">
                                                <li className=" text-20" >الهوية الشخصية سارية المفعول</li>
                                                <li className=" text-20">رخصة قيادة سارية المفعول</li>
                                                <li className=" text-20">برنت تأميتات حديث لا تتجاوز مدته 10 ايام</li>
                                                <li className=" text-20">تعريف بالراتب مصدق من الغرفة التجارية لا تتجاوز مدته شهرين</li>
                                                <li className=" text-20">كشف حساب اخر 3 اشهر</li>
                                            </ul>
                                        </div>

                                    </div>


                                </TabPanel>


                            </Tabs>

                        </div>
                    </div>
                </div>
            </Popup>


            <Popup show={showFinalPopup}>
                <div className="w-full  flex flex-column justify-center items-center" >
                    <div className="cart-finalized" >
                        <div className="cart-finalized-container flex flex-col">

                            <div className="cart-finalized-container-img flex flex-col">
                                <img src="/assets/images/finalized.svg" />
                            </div>

                            <div className="flex flex-wrap justify-center">
                                <span className="text-20 font-bold text-black mx-2 unselectable ">{trans("new:thank_to_choose")}</span>
                                <span className="text-20 font-bold text-primary mx-2"> {trans("new:HJ_cars")}</span>
                            </div>
                            {
                                <>
                                    <div className="flex flex-wrap justify-center mx-20">
                                        <span className="text-24 text-gray  mx-2"> {showFinalPopupMessage}</span>
                                    </div>

                                </>

                            }

                            {
                                dataFinalPopup &&
                                <>

                                    {
                                        dataFinalPopup?.OrderID &&
                                        <div className="flex flex-row justify-center cart-finalized-code notify-button active "
                                            onClick={() => {

                                                navigator.clipboard.writeText(dataFinalPopup?.OrderID);
                                            }}

                                        >

                                            <span className="text-20 font-bold text-white  mx-2"> {trans("info:order-number") + " :"}</span>
                                            <span className="text-20 font-bold text-white  mx-2">{dataFinalPopup?.OrderID && dataFinalPopup?.OrderID}</span>
                                            <img src="/assets/icons/copy.svg" alt='' />
                                            <div class="notification">copy</div>
                                        </div>
                                    }
                                </>

                            }

                            <div className="ruler-2 my-5"></div>
                            <div className="flex flex-center flex-col  w-80">
                                <div className="  w-100">
                                    <div className="nice-sm-btn cart-notification-go  bg-primary text-white text-16" onClick={() => {

                                        setShowFinalPopup(false);
                                        if (location.pathname)
                                            history.push("/");

                                    }}>
                                        {trans("cartFile:Continue_browsing")}
                                    </div>
                                </div>
                            </div>


                        </div>

                    </div>
                </div>
            </Popup>
        </>
    );
}