import { Fragment, useCallback, useEffect, useState } from "react";
import { CarSpecsTile } from "../components/carSpecsTile";
import { useParams } from "react-router";
import axios from "axios";
import { slugSplitter } from "../../../utils/slugManager";
import config from "../../../configuration/config.json";
import { useTranslation } from "react-i18next";
export function VehicleSpecsPage({ modelslug, vehicle }) {
    const {i18n } = useTranslation();
    const { slug, brand } = useParams();
    const [models, setModels] = useState([]);
    const [backUpImage, setBackUpImage] = useState("");
    let lang = (i18n.language || 'ar');

    useEffect(() => {

        setModels([]);
        setBackUpImage("");

        if (vehicle?.group)
            callback(vehicle);
    }, [slug, vehicle]);
    const callback = useCallback(async (vehicle) => {
        try {
            setModels([]);
            setBackUpImage("");


            try {
                if ("caches" in window) {
                    var curl = lang + "/api/VehicleSpecs/" + slugSplitter(vehicle?.group) + "/" + vehicle?.year + "/specs";

                    // Opening that particular cache
                    const cacheStorage = await caches.open("VehicleSpecsPage");

                    // Fetching that particular cache data
                    const cachedResponse = await cacheStorage.match(
                        curl
                    );
                    var datacash = await cachedResponse?.json();

                    if (datacash) {
                        setModels(datacash.models);
                        setBackUpImage(datacash.backupImage);

                    }



                }
            } catch (error) {

            }

            const response = await axios.request({
                baseURL: config.controllURL,
                url: lang + "/api/VehicleSpecs/" + slugSplitter(vehicle?.group) + "/" + vehicle?.year + "/specs",
                method: "get",
            });

            setModels(response.data.models);
            setBackUpImage(response.data.backupImage);

            const dataResponse = new Response(JSON.stringify(response.data));

            if ("caches" in window) {
                // Opening given cache and putting our data into it
                caches.open("VehicleSpecsPage").then((cache) => {
                    cache.put(lang + "/api/VehicleSpecs/" + slugSplitter(vehicle?.group) + "/" + vehicle?.year + "/specs", dataResponse);

                });
            }
        } catch (error) {
        }
    }, [slug, brand]);
    return (
        <Fragment>

            <div className="container mb-32">

                {
                    models?.map((model, index) => {
                        return (

                            <CarSpecsTile
                                key={index}
                                group={model.group}
                                type={model.type}
                                brand={model.brand}
                                newCar={model.newCar}
                                hybird={model.hybird}
                                modelYear={model.year}
                                productTarget={model.productTarget}
                                colorID={model.colorID}
                                productID={model.productID}
                                backupImage={backUpImage}
                                image={model.imagePath}
                                name={(model["modelDescription"])}
                                spec={model.note}
                                price={model.minPrice}
                                hp={model.hp ?? 0}
                                cylinders={model.cylinders ?? 0}
                                liter={model.liter ?? 0}
                                seats={model.seatsNumber ?? 0}
                                petrol={model.petrol ?? 0}
                                offerIndex={++index}
                                showPric={model.showPric}
                                modelslug={modelslug}

                                buyOnline={model?.BuyOnline}
                            />
                        );
                    })
                }
            </div>
        </Fragment>
    );
}