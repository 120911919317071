const Arabic_Patter = /[\u0600-\u06FF]/i;
// this function is used to combine two slugs in url
export const  slugCombine = (SlugPart1,SlugPart2)=>{
 return (SlugPart1 + ',' + SlugPart2??'');
};
//this function is used to get the specific slug
export const getCleanSlug = ()=>{
    const url = decodeURI(window.location.href);
    const urlLanguage = localStorage.getItem("i18nextLng"); 
    const urlLastSegment =  url.substring(url.lastIndexOf('/') + 1).split(',');
    let arIndex = -1 ,enIndex = -1;
    urlLastSegment.forEach((item,index)=>{
     if(Arabic_Patter.test(item)){
      arIndex = index;
     } 
    });
    enIndex = arIndex == 0 ? 1 : 0;
    if(urlLanguage.toLowerCase() == 'ar' && urlLastSegment[arIndex]){
     return urlLastSegment[arIndex];
    }
    return urlLastSegment[enIndex];
}
// this function is used to get the correct slug based on the language
export const slugSplitter = (sugments)=>{
const slugs = sugments.split(',');
const urlLanguage = localStorage.getItem("i18nextLng"); 
let arIndex = -1 ,enIndex = -1;
slugs.forEach((item,index)=>{
    if(Arabic_Patter.test(item)){
     arIndex = index;
    } 
   });
   enIndex = arIndex == 0 ? 1 : 0;
   if(urlLanguage.toLowerCase() == 'ar'){
    return slugs[arIndex] ? slugs[arIndex] :sugments ;
   }
   return slugs[enIndex] ?slugs[enIndex] :sugments;
}


