import { useTranslation } from "react-i18next";
import { Link, NavLink, useRouteMatch } from "react-router-dom";
import { Fragment, useEffect, useRef, useState, useContext } from "react";
import Banner from "../../../components/banner";
import Breadcrumb from "../../../components/__simple/breadcrumb";
import { useDispatch } from "react-redux";
import { changeHeader } from "../../../_redux/slices/navSlice";
import OverViewContext from "../overviewContext";
import config from "../../../configuration/config.json";
export function CarBanner({ name, desc, bg, url, brand, onBuyClick, pdf = null, hasOffers = false, breadcrumb, setSectionActive, logo, siteDesc }) {
    const { t: trans,i18n } = useTranslation(["common", "vehicales", "info"]);
    const [onHamburger, setOnHamburger] = useState(false);
    const navRef = useRef(null);
    const [navFlex, setNavFlex] = useState(false);
    const [navTop, setNavTop] = useState(0);
    const [lastScrollY, setLastScrollY] = useState(0);
    let lang = (i18n.language || 'ar');
    const languageDir = 'language-' + lang;
    const dispatch = useDispatch();
    const context = useContext(OverViewContext);
    const [pageName, setPageName] = useState(breadcrumb ?? trans("vehicales:veh-d-home-public"));
    useEffect(() => {
        dispatch(
            changeHeader({ isFixed: false })
        )
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll)
            dispatch(
                changeHeader({ isFixed: true })
            )
        };
    }, [url]);

    useEffect(() => {
        setPageName(breadcrumb ?? trans("vehicales:veh-d-home-public"));

    }, [breadcrumb]);

    const handleScroll = () => {

        const currentScrollY = window.scrollY;

        if (currentScrollY - 540 > lastScrollY) {
            setNavFlex(true)

            setNavTop(1);
        } else {
            if (currentScrollY <= lastScrollY + 540) {
                setNavFlex(false);
            }
            setNavTop(0);
        }
        setLastScrollY(currentScrollY);

    };

    return (
        <Fragment>
            <div className={"margin-top-for-under-header"}>
                <Banner
                    background={bg}
                    overlay={false}

                />
            </div>
            <div className={"container "}>
                <div className={"  mb-16  car-navbar  " + (navFlex || "hidden")}>
                    <span style={{ opacity: "0" }}>navbar holder</span>
                </div>

                <div ref={navRef}
                    style={{ top: navTop + "rem" }}
                    className={" mb-16 bg-white car-navbar " + (navFlex && "car-navbar-fixed ")}>
                    <div className="car-navbar-links">
                        <div className={"Hamburger black " + (onHamburger && "open")}
                            onClick={() => setOnHamburger(!onHamburger)}>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                        <h2 className={"name text-24 font-bold " + languageDir}>{name}</h2>
                        <div className={"car-navbar-links-group " + languageDir}>
                            <NavLink onClick={(e) => { setSectionActive("public"); setOnHamburger(false); setPageName(`${trans("vehicales:veh-d-home-public")}`) }}
                                to={`${url}/public`}>{trans("vehicales:veh-d-home-public")}</NavLink>
                            <NavLink onClick={(e) => { setSectionActive("gallery"); setOnHamburger(false); setPageName(`${trans("vehicales:veh-d-home-gallery")}`) }}
                                to={`${url}/gallery`}
                            >{trans("vehicales:veh-d-home-gallery")}</NavLink>
                            <NavLink onClick={(e) => { setSectionActive("feature"); setOnHamburger(false); setPageName(`${trans("vehicales:veh-d-home-feature")}`) }}
                                to={`${url}/feature`}
                            >{trans("vehicales:veh-d-home-feature")}</NavLink>
                            <NavLink  onClick={(e) => { setSectionActive("specs"); setOnHamburger(false); setPageName(`${trans("vehicales:veh-d-home-specs")}`) }}
                                to={`${url}/specs`}
                            >{trans("vehicales:veh-d-home-specs")}</NavLink>
                            {/* {
                                hasOffers ?
                                    <NavLink onClick={(e) => { setSectionActive("offers"); setOnHamburger(false); setPageName(`${trans("vehicales:veh-d-home-offers")}`) }} to={`${url}/offers`}>{trans("vehicales:veh-d-home-offers")}</NavLink>
                                    : null
                            } */}

                        </div>
                        {/* { 
                        <button
                        onClick={()=>{
                         onBuyClick()
                        }} className={"btn btn-primary"}>{trans("vehicales:veh-d-home-Purchase")}</button>
                        } */}

                        {(logo) &&
                            <div className={"car-navbar-links-logo"}>
                                <img className={''} src={config.FilePathURL + logo} alt="" />
                            </div>
                        }
                    </div>
                </div>

            </div>

            <div className="container mb-32">
                <Breadcrumb
                    links={[
                        {
                            name: brand,
                            to: "/vehicles/" + brand
                        }, {
                            name: name,
                            to: url
                        },
                        {
                            name: pageName,
                        }
                    ]}
                />
                <div className={"car-heading -mt-8 " + languageDir}>
                    <div className="car-heading-name  w-100 language-en">
                        <h1 className="text-ellipsis" style={{ maxWidth: '90rem' }} title={context.vehicleInfo?.name ?? name}>{context.vehicleInfo?.name ?? name}</h1>
                        <div className={"car-tags mb-4"}>
                            {
                                context.vehicleInfo?.isNew && <span className={"bg-toyota tag text-white"}>{trans("info:new")}</span>
                            }
                            {
                                context.vehicleInfo?.isHybird && <span className={"bg-info tag text-white"}>{trans("vehicales:veh-hybrid")}</span>
                            }
                            {/* <span className={"tag bg-white "}>سيدان</span> */}
                        </div>
                    </div>
                    <p className="car-heading-desc">
                        {
                            siteDesc
                        }
                    </p>
                    {
                        pdf && (<div className="car-heading-menu">
                            <a target={"_blank"} style={{ minHeight: '6rem' }} href={config.mainURL + pdf} download={name?.replaceAll(" ", "-") + '_user-manual.pdf'} className={"btn btn-primary p-2 text-size-16r"}>{trans("vehicales:veh-d-home-userGuid")}</a>
                        </div>)
                    }
                </div>
            </div>

        </Fragment>
    );
}

