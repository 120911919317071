import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Popup from "../../User/popup";
import { DropDownInput } from "../../components/_input/_dropdown";
import { TextArea } from "../../components/_input/_textArea";
import { DateInput3 } from "../../components/_input/_dateInput_3";
import { InputField } from "../../components/_input/_input";
import { useParams } from "react-router";
import config from '../../configuration/config.json';
import DatePicker from 'react-datepicker';
import { useHistory, useLocation } from "react-router";
import { GetMessages, formValidate } from '../../utils/validation';
import Joi from "joi";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
export function ServiceReservation() {

    let { PVIN, PGroupID, PYear, PServiceID } = useParams();
    const { t: trans, i18n } = useTranslation(["info", "eparts", "common", "global", "offers", "cartFile"]);
    let lang = (i18n.language || 'ar');
    const [showFinalPopup, setShowFinalPopup] = useState(false);
    const popRef = useRef(null);
    const [resOrderID, setResOrderID] = useState(null);
    const history = useHistory();
    const [stepNum, setStepNum] = useState(1);
    const [dataFullNum, setDataFullNum] = useState(0);
    const [VINDetails, setVINDetails] = useState([]);
    const [infoPrice, setinfoPrice] = useState([{ Price: 0 }]);
    const [isSendeng, setIsSendeng] = useState(false);
    const [orderReservation, setOrderReservation] = useState(null);
    const [requestForm, setRequestForm] = useState({
        name: '',
        phone: '',
        email: '',
        subject: '',
        date: '',
        time: '',
        messageContent: '',
        myCarID: '',
        SiteID: null,
        ServiceID: ''
    });
    const [filteredSettings, setFilteredSettings] = useState({
        brands: [],
        groups: [],
        models: [],
        productModels: []
    });


    const [settings, setSettings] = useState({
        brands: [],
        groups: [],
        models: [],
        productModels: []
    });
    const location = useLocation();

    useEffect(() => {
        FetchOrderReservation();

    }, [])

    useEffect(() => {

        if (orderReservation) {
            setResOrderID(orderReservation?.MaintenanceOrderID);
            setShowFinalPopup(true);

        }
        fetchSettings();


        if (PVIN)
            GetVINDetails(PVIN);



    }, [PVIN, orderReservation])
    const [firstByLink22, setFirstByLink22] = useState(true);
    useEffect(() => {

        if (VINDetails && VINDetails.length > 0 && PServiceID && settings && settings?.groups?.length > 0 && firstByLink22 == true) {

            setFirstByLink22(false);
            PGroupID = VINDetails[0].CarGroup;
            PYear = VINDetails[0].Model;
            let PBrandID = VINDetails[0].BrandID;
            let ttmodelType = VINDetails[0].Type;
            var threeDaysFromNow = new Date();

            if (orderReservation) {

                if (orderReservation?.DateIn)
                    threeDaysFromNow = new Date(orderReservation?.DateIn);
                if (orderReservation?.Note)
                    setNote(orderReservation?.Note);
            } else {

                threeDaysFromNow.setDate(threeDaysFromNow.getDate() + 2);

                if (threeDaysFromNow.getDay() == 5) {
                    threeDaysFromNow.setDate(threeDaysFromNow.getDate() + 1);
                }


            }
            if (threeDaysFromNow) {
                webAvailableReshours(moment(threeDaysFromNow).format('yyyy-MM-DD'));
            }
            setRequestForm({
                ...requestForm,
                name: lang == "ar" ? VINDetails[0].CustNameAr : VINDetails[0].CustNameEn,
                phone: VINDetails[0].Mobile,
                email: VINDetails[0].Email,
                myCarID: VINDetails[0].VIN,
                date: threeDaysFromNow,
                time: orderReservation ? threeDaysFromNow : "",
                ServiceID: PServiceID
            });

            FetchInfoService(PServiceID);

            setTimeout(() => {
                handleServiceOrder({
                    showPopup: true,
                    type: "sub2",
                    main: "0a5e145a-bf3f-4a8e-aa7b-d8f0a1e0d9b0",
                    sub1: "eb199b7e-55be-4ac8-b465-9a110b415ca8",
                    sub2: "2b995873-d703-47d8-9f58-f1df7f1e3979",
                    ServiceID: PServiceID,
                    brand: PBrandID,
                    group: PGroupID,
                    year: PYear,
                    modelType: ttmodelType,
                    name: lang == "ar" ? VINDetails[0].CustNameAr : VINDetails[0].CustNameEn,
                    phone: VINDetails[0].Mobile,
                    email: VINDetails[0].Email,
                    myCarID: VINDetails[0].VIN,

                });
            }, 2000);


        }

    }, [PGroupID, PYear, PServiceID, settings, VINDetails, orderReservation])


    async function GetVINDetails(VIN) {



        const Body = {
            "name": "App_GetVINDetails",
            "values": {
                "VIN": VIN
            }
        }

        axios.request({
            url: config.baseURL + 'func',
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {
                //console.log("App_GetVINDetails:", response.data);
                setVINDetails(response.data);
            })
            .catch(function (error) {

                //console.log(error);
            });


    }

    useEffect(() => {

        function handleClickOutside(event) {
            if (popRef.current && !popRef.current.contains(event.target) && showFinalPopup === true) {
                setShowFinalPopup(false);
                setResOrderID(null);
                if (location.pathname)
                    history.push(location.pathname);

            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showFinalPopup]);


    //--------------------------------------------------
    const [maintenanceObj, setMaintenanceObj] = useState({
        showPopup: false, type: "Notset", main: "", sub1: "", sub2: ""
    });
    const [Defult, setDefult] = useState({
        showPopup: false, type: "Notset", main: "", sub1: "", sub2: ""
    });



    const notLoginSchema = Joi.object({
        date: Joi.date().required().messages(GetMessages()),
        time: Joi.date().required().messages(GetMessages()),
        SiteID: Joi.string().required().messages(GetMessages()),
        ServiceID: Joi.string().required().messages(GetMessages()),
        name: Joi.string().required().messages(GetMessages()),
        year: Joi.string().required().messages(GetMessages())
    });



    const [brand, setBrand] = useState(null);
    const [type, setType] = useState(null);
    const [group, setGroup] = useState(null);
    const [vModelCode, setVModelCode] = useState([]);
    const [year, setYear] = useState(null);
    const handleFilteredModels = (option) => {

        setModelCode(vModelCode[option]);
        setGroup(null);
        setType(null);
        setFilteredSettings({ ...filteredSettings, models: [], productModels: [] });
        setGroup(option);

        var fModels = [];
        try {
            const currentgroupData = settings?.groups?.filter(a => a.brandID == brand && a.productGroupID == option);


            const ListTreeGroups = (currentgroupData && currentgroupData?.length > 0) ? currentgroupData[0].ListTreeGroups : option;
            // Split the comma-separated string into an array
            const listVArray = ListTreeGroups.split(',');

            // Assuming settings.productModels is an array of objects with properties productGroupID and Model
            fModels = settings?.productModels?.filter(a =>
                listVArray.includes(a.productGroupID)
            );


        } catch {

            fModels = settings?.productModels?.filter(a => a.productGroupID == option);
        }


        setFilteredSettings({ ...filteredSettings, productModels: fModels });

    };

    const handelSelectBrand = (option, vYear = year) => {
        setBrand(null);
        setGroup(null);
        setType(null);

        setFilteredSettings({ ...settings, groups: [], models: [], productModels: [] });

        setBrand(option);


        const fGroups = settings?.groups?.filter(a => a.brandID == option && a.Year == vYear);

        setFilteredSettings({ ...settings, groups: fGroups, models: [], productModels: [] });


    };


    useEffect(() => {

        if (settings?.brands && settings?.brands.length > 0 && Defult?.first != true
        ) {
            if (Defult?.year) {
                setYear(Defult?.year)
                const val = { ...requestForm };
                val.year = Defult?.year;
                setRequestForm(val);
            }
            if (Defult?.brand)
                handelSelectBrand(Defult?.brand, Defult?.year);
        }

    }, [Defult, settings]);


    useEffect(() => {

        try {

            if (requestForm?.date) {

                var md = new Date(requestForm?.date);
                try {
                    if (requestForm?.time) {

                        var Hours = requestForm?.time.split(":");

                        md.setHours(Hours[0], Hours[1], 0);
                    }
                } catch (error) {

                }


            }

        } catch (error) {
        }


    }, [requestForm]);

    useEffect(() => {


        if (settings?.brands && settings?.brands.length > 0 && filteredSettings?.groups?.length > 0 && Defult?.first != true) {

            setDefult({ ...Defult, first: true });
            if (Defult?.group)
                handleFilteredModels(Defult?.group);

            if (Defult?.modelType) {
                setType(Defult?.modelType);
            }
        }
    }, [filteredSettings]);
    const FetchInfoService = async (PServiceID) => {


        setinfoPrice([{ Price: 0 }]);
        setLodingService(true);
        const Body = {
            "name": "site_FetchInfoService",
            "values": {
                "ServiceID": PServiceID
            }
        }

        axios.request({
            url: config.baseURL + 'func',
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {
                setService(response?.data[0]);
                setServiceID(response?.data[0].ServiceID);
                setLodingService(false);
            })
            .catch(function (error) {
                console.log("FetchOrderReservation>>>>", error);
                setLodingService(false);
            });
    };

    const FetchOrderReservation = async () => {
        const Body = {
            "name": "site_FetchOrderReservationByVIN_ServiceID",
            "values": {
                "VIN": PVIN,
                "ServiceID": PServiceID
            }
        }

        axios.request({
            url: config.baseURL + 'func',
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {
                console.log("FetchOrderReservation>>>>", response.data);
                if (response.data && response.data.length > 0)
                    setOrderReservation(response.data[0]);

            })
            .catch(function (error) {
                console.log("FetchOrderReservation>>>>", error);
            });
    };
    const fetchSettings = async () => {
        try {

            if ("caches" in window) {
                var curl = "Settings-" + lang;

                // Opening that particular cache
                const cacheStorage = await caches.open("HJWeb");

                // Fetching that particular cache data
                const cachedResponse = await cacheStorage.match(
                    curl
                );
                var settingResponseCash = await cachedResponse?.json();
                const currentSettings = {};
                console.log("currentSettings:", currentSettings);
                currentSettings.brands = settingResponseCash?.brands;
                currentSettings.groups = settingResponseCash?.groups;
                currentSettings.models = settingResponseCash?.models;
                currentSettings.productModels = settingResponseCash?.productModels;
                if (currentSettings.brands && currentSettings.groups && currentSettings.productModels) {

                    setSettings(currentSettings);


                    //---------------------------------------------------------------
                    let currentvModelCode = {};
                    currentSettings?.productModels?.map((item) => {
                        if (item.ModelCode) {
                            currentvModelCode[item.productGroupID] = item.ModelCode;
                        }
                    })
                    setVModelCode(currentvModelCode);
                }

            }
        } catch (error) {
            //////console.log(error);
        }
        try {
            const settingResponse = await axios.request({
                baseURL: '/',
                url: config.controllURL + lang + "/api/Maintenance/Settings",
                method: "get"
            });

            const currentSettings = { ...settings };
            currentSettings.brands = settingResponse.data?.brands;
            currentSettings.groups = settingResponse.data?.groups;
            currentSettings.models = settingResponse.data?.models;
            currentSettings.productModels = settingResponse.data?.productModels;

            setSettings(currentSettings);

            //---------------------------------------------------------------

            let currentvModelCode = {};
            currentSettings?.productModels?.map((item) => {
                currentvModelCode[item.productGroupID] = item.ModelCode

            })

            //////console.log("currentvModelCode>>>>>>>>>", currentvModelCode);
            setVModelCode(currentvModelCode);

            const data = new Response(JSON.stringify(settingResponse.data));

            if ("caches" in window) {
                // Opening given cache and putting our data into it
                caches.open("HJWeb").then((cache) => {
                    cache.put("Settings-" + lang, data);

                });
            }

        } catch (error) {
            //////console.log(error);
        }

    };
    const handleServiceOrder = (data) => {

        try {
            data['firstFetchPrice'] = null;
            data['first'] = null;
            setStepNum(1);
            setDataFullNum(0);


            setinfoPrice([{ Price: 0 }]);

            const value = { ...maintenanceObj, ...data };
            if (data?.main == '') {

                data['main'] = null;
            }
            if (data?.sub1 == '') {

                data['sub1'] = null;
            }
            if (data?.sub2 == '') {

                data['sub2'] = null;


            }

            setDefult(data);

            GetBranch();


            setTimeout(() => { setMaintenanceObj(value) }, 2000);


        } catch (e) {
            console.error(e)

        }

    };
    const [Note, setNote] = useState("");

    const [lodingService, setLodingService] = useState(false);

    const [Branches, setBranches] = useState([]);
    let [modelCode, setModelCode] = useState("");
    const [ServiceID, setServiceID] = useState(null);
    const [Service, setService] = useState(null);
    async function FetchPrice(modelC, SID) {

        setinfoPrice([{ Price: 0 }]);
        if (!modelC) {
            return;
        }
        if (!SID) return;



        const Body = {
            "name": "Site_fetchPriceService",
            "values": {
                "ModelCode": modelC,
                "ServicID": SID
            }
        }

        axios.request({
            url: config.baseURL + 'func',
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {
                // //////console.log(response.data.ApiObjectData);

                setinfoPrice(response.data);

            })
            .catch(function (error) {
                setinfoPrice([{ Price: 0 }]);

                //////console.log(error);
            });


    }

    const checkDataFullNum = async () => {

        if (group && ServiceID && requestForm?.date && requestForm?.time && requestForm?.SiteID && requestForm?.name) {

            setDataFullNum(2);
            return 2;
        } else if (group && ServiceID && requestForm?.name) {

            setDataFullNum(1);
            return 2;
        } else {
            setDataFullNum(0);
            return 0;
        }


    }


    const [error, setError] = useState({});
    function SendResreve(VIN, ServecID, UserID, Amount) {


        const val = { ...requestForm };
        val.year = year;
        val.ServiceID = ServiceID;
        setRequestForm(val);
        const result = formValidate(notLoginSchema, val) ?? {};

        setError(result);

        if (Object.keys(result).length == 0 && isSendeng == false) {
            if (orderReservation == null) {

                setIsSendeng(true);
                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");

                var raw = {
                    "VIN": VIN,
                    "Model": type,
                    "CarType": modelCode,
                    "UserID": UserID ?? "",
                    "Year": year,
                    "TotalAmount": Amount ? Amount : 0,
                    "RequiredServicesList": ServecID,
                    "Note": Note,
                    "MeterReading": requestForm?.meterReading,
                    "FirstName": requestForm?.name,
                    "phone": requestForm?.phone,
                    "StoreID": requestForm?.SiteID,
                    "Orderdate": moment(requestForm?.date).format('YYYY-MM-DD'),
                    "OrderTime": moment(requestForm?.time).format('HH:mm:ss'),
                    // "PaymentMethodID": paymentType == 1 ? "Credit Card" : (paymentType == 2 ? "Sadad" : (paymentType == 3 ? "Installment" : "CashByBranch")),
                };


                axios.request({
                    url: config.controllURL + "api/Service/ServiceRequestAdd", headers: myHeaders,
                    data: raw, method: "POST", responseType: "json"
                }).then(function (response) {
                    if (response?.data?.OrderID)
                        setResOrderID(response?.data?.OrderID);
                    setShowFinalPopup(true);
                    toast.success(trans("info:reservation_completed_successfully"));

                }
                ).then((response) => {
                })
                    .catch(error => {
                        setIsSendeng(false);
                    }



                    );


            } else {
                setIsSendeng(true);
                try {
                    axios.put(
                        config.baseURL + 'update',
                        {
                            "object": "FleetMaintenanceOrderReservation",
                            "values": {
                                "DateIn": moment(requestForm?.date).format('YYYY-MM-DD') + "T" + moment(requestForm?.time).format('HH:mm:ss')
                            },
                            "filters": `where GUID = '${orderReservation?.GUID}'`
                        }
                    ).then(response => {
                        window.location.reload();
                    }).catch(error => {
                        console.log(error);
                        setIsSendeng(false);
                    })
                } catch (err) {
                    console.log('error in deleting the vehicle', err);
                    setIsSendeng(false);
                }
            }
        }
    }



    const GetUrlfunc = config.baseURL + 'func';
    async function GetBranch() {

        const Body = {
            "name": "site_FetchMaintenanceBranches"
        };

        await axios.request({
            url: GetUrlfunc,
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {

                setBranches(response.data);


            })
            .catch(function (error) {
                //////console.log(error);
            });


    }

    useEffect(() => {

        if (Branches && Branches?.length > 0 && !requestForm?.SiteID) {

            if (Branches?.length == 1) {
                let val = {};
                val.SiteID = Branches[0]?.SiteID;
                setRequestForm({ ...requestForm, ...val });
            }

        }

    }, [Branches, requestForm]);


    useEffect(() => {
        checkDataFullNum();
    }, [requestForm, ServiceID, year, type, requestForm.SiteID]);



    useEffect(() => {
        if (vModelCode != null && ServiceID && Defult?.firstFetchPrice != true) {
            setDefult({ ...Defult, firstFetchPrice: true });
            if (Defult?.modelType) {
                if (vModelCode[Defult?.modelType] && ServiceID)
                    FetchPrice(vModelCode[Defult?.modelType], ServiceID);
            }



        }

    }, [vModelCode, ServiceID]);


    const [listTimeAvailable, setListTimeAvailable] = useState([]);

    const webAvailableReshours = async (date = '') => {
        const Body = {
            "name": "web_AvailableReshours",
            "values": {
                "Resdate": date
            }
        }

        await axios.request({
            url: config.baseURL + 'func',
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {
                //console.log("web_AvailableReshours:response", response);
                if (response.status >= 200 && response.status < 299) {
                    //console.log("web_AvailableReshours:response", response.statusText);
                    if (response?.data && response?.data.length != 0) {
                        response?.data.forEach((val, index) => {
                            //console.log("ForEach:val", parseInt(val?.AvailableHour));
                            setListTimeAvailable(_lstTime => [..._lstTime, parseInt(val?.AvailableHour)]);
                        });

                    }
                }
            })
            .catch(function (error) {
                //console.log("web_AvailableReshours:Error", error);
                toast.warn(trans("form:please-choose-another-date"));
            });
    }


    return (
        <>

            <div className={"protection-and-shading   " + (brand == 1 ? " toyota-theme " : " lexus-theme ")} >
                <div className="protection-and-shading-head" >
                    <div className="protection-and-shading-head-Logo">

                        {
                            lang == "ar" ?

                                <div className="res-Logo-icon ">
                                    <img src="/assets/logo/logo_font_Black_AR.svg"
                                        alt="hassan jameel" className="hidden d-md-inline" />
                                    <img src="/assets/logo/logo_font_Black_AR.svg"
                                        alt="hassan jameel" className="d-inline d-md-hidden" />
                                </div>


                                :

                                <div className="res-Logo-icon ">
                                    <img src="/assets/logo/logo_font_Black_EN.svg"
                                        alt="hassan jameel" className="hidden d-md-inline" />
                                    <img src="/assets/logo/logo_font_Black_EN.svg"
                                        alt="hassan jameel" className="d-inline d-md-hidden" />
                                </div>
                        }
                    </div>

                    <div className="protection-and-shading-head-extra flex flex-worp">
                        <div className="protection-and-shading-head-brand flex flex-worp">
                            {
                                (brand == 1) ?
                                    <>
                                        <img src={`/assets/icons/brands/${"toyota-logo-withName.png"}`}
                                            className="" alt={brand} />
                                    </>
                                    :
                                    (
                                        (brand == 2) ?
                                            <>
                                                <img src={`/assets/icons/brands/${"lexus-logo-withName.png"}`}
                                                    className="" alt={brand} />
                                            </>
                                            :
                                            null

                                    )

                            }
                        </div>

                        <button
                            onClick={() => {

                                if (window.location.href.includes("/ar") || window.location.href.includes("/en")) {

                                    if (window.location.href.includes("/en"))
                                        window.location = window.location.href.replace("/en", "/ar");
                                    if (window.location.href.includes("/ar"))
                                        window.location = window.location.href.replace("/ar", "/en");

                                } else {

                                    window.location = window.location.origin + "/en" + window.location.pathname;
                                }
                            }}
                            className="protection-and-shading-top-lang h-full  flex justify-center items-center">

                            <span className='text-white font-bold pl-2 pr-2 capitalize'>{trans('common:changeLangCode')}</span>
                            <img src="/assets/icons/globle.svg" alt="change language" />
                        </button>
                    </div>


                </div >

                <div className=" nice-container nice-relative">
                    <div className=" p-4">
                        <div className="mainteance-popup-container-header py-5 flex nice-flex-space-between items-center">
                            <h1 className="text-balck text-bold text-24 text-center">{trans("info:appointment-booking") + " " + trans("global:maintenance")}</h1>

                        
                        </div>

                        <div className="my-8  flex flex-col nice-gap-2rem ">
                            {
                                stepNum == 1 &&
                                <>

                                    {
                                        <>
                                            <div className="flex flex-row grid-2-cols nice-gap-2rem ">

                                                <div className="basis-2/4 grow">

                                                    <InputField
                                                        disabled={true}
                                                        errorLabel={error?.name}
                                                        placeholder={trans("form:name")}
                                                        value={requestForm.name}
                                                        length={255}
                                                        onChangeValue={
                                                            (e) => {
                                                                const val = {};
                                                                val.name = e.target.value;
                                                                setRequestForm({ ...requestForm, ...val });
                                                            }
                                                        }
                                                    />
                                                </div>


                                            </div>
                                            <div className="flex flex-row grid-3-cols-importent nice-gap-2rem ">
                                                <div className="basis2/4 grow">
                                                    {/* <DropDownInput

                                                    defaultOption={(brand == '0' || brand == null) ? { text: trans("info:company"), value: '0' } : null}
                                                    options={settings?.brands?.map(item => {
                                                        return { text: item?.description, value: item?.brandID }
                                                    })}
                                                    selectedValue={brand}
                                                    onSelect={handelSelectBrand}
                                                    disabled={true}

                                                /> */}

                                                    <InputField
                                                        disabled={true}
                                                        errorLabel={error?.brand}
                                                        placeholder={trans("info:company")}
                                                        value={settings?.brands.filter(g => g.brandID == brand)[0]?.description}
                                                        length={255}
                                                        onChangeValue={
                                                            (e) => {

                                                            }
                                                        }
                                                    />
                                                </div>
                                                <div className="basis2/4 grow">
                                                    {/* <FildIN
                                                    defaultOption={((brand == '0' || brand == null) || (group == '0' || group == null)) ? {
                                                        text: trans("eparts:vehicle"),
                                                        value: '0'
                                                    } : null}
                                                    options={settings?.groups?.map(item => {
                                                        return { text: item?.description, value: item?.productGroupID }
                                                    })}
                                                    selectedValue={group}
                                                    onSelect={handleFilteredModels}
                                                    disabled={true}
                                                /> */}

                                                    <InputField
                                                        disabled={true}
                                                        errorLabel={error?.group}
                                                        placeholder={trans("eparts:vehicle")}
                                                        value={settings?.groups.filter(g => g.ListTreeGroups?.split(',').includes(group))[0]?.description}
                                                        length={255}
                                                        onChangeValue={
                                                            (e) => {

                                                            }
                                                        }
                                                    />
                                                </div>

                                                <div className="basis2/4 grow">
                                                    {/* <DropDownInput
                                                    errorLabel={error?.year}
                                                    defaultOption={{
                                                        text: trans("info:created_year"),
                                                        value: '0'
                                                    }}
                                                    disabled={true}
                                                    // options={settings?.models?.map(item => {
                                                    //     return { text: item?.modelID, value: item?.modelID }
                                                    // })}

                                                    options={
                                                        settings?.models?.filter(
                                                            item => item?.modelID?.startsWith('20')).map(
                                                                item => ({
                                                                    text: item?.modelID,
                                                                    value: item?.modelID
                                                                })
                                                            )
                                                    }

                                                    selectedValue={year}
                                                    onSelect={(value) => {
                                                        //////console.log("models:", value)

                                                        setYear(value);

                                                        const val = {};
                                                        val.year = value;
                                                        setRequestForm({ ...requestForm, ...val });
                                                    }}
                                                /> */}

                                                    <InputField
                                                        disabled={true}
                                                        errorLabel={error?.year}
                                                        placeholder={trans("info:created_year")}
                                                        value={year}
                                                        length={255}
                                                        onChangeValue={
                                                            (e) => {

                                                            }
                                                        }
                                                    />
                                                </div>

                                            </div>


                                        </>

                                    }
                                </>
                            }

                            {


                                <>
                                    <div className="flex flex-row grid-2-cols-importent nice-gap-2rem ">


                                        {

                                            <InputField
                                                isLoading={lodingService}
                                                disabled={true}
                                                errorLabel={error?.ServiceID}
                                                placeholder={trans("info:servce")}
                                                value={lang === 'ar' ? Service?.ServiceNameAR : Service?.ServiceName}
                                                length={255}
                                                onChangeValue={
                                                    (e) => {
                                                        const val = {};
                                                        val.ServiceID = e.target.value;
                                                        setRequestForm({ ...requestForm, ...val });
                                                    }
                                                }
                                            />
                                        }

                                        <div className="flex flex-row  nice-gap-2rem ">

                                            <DropDownInput
                                                // title={trans("info:branch")}
                                                className={"w-90"}
                                                disabled={true}
                                                errorLabel={error?.SiteID}
                                                options={[
                                                    ...Branches.map(B => {
                                                        return { value: B.SiteID, text: lang === 'ar' ? B.DescriptionAr : B.DescriptionEn };
                                                    })]}
                                                selectedValue={Branches?.length == 1 ? Branches[0]?.SiteID : requestForm?.SiteID}
                                                onSelect={(value) => {

                                                    //////console.log("onSelect:", value);
                                                    const val = {};
                                                    val.SiteID = value;
                                                    setRequestForm({ ...requestForm, ...val });
                                                }}


                                            />

                                            <a target="_blank" href="https://maps.app.goo.gl/TYwNm9SseQqA2WZx6" class="contact-tile">
                                                <div class="location-icon">
                                                    <img src="/assets/icons/contact/location.svg" alt="user profile" />
                                                </div>
                                            </a>

                                        </div>
                                    </div>
                                    <div className="flex flex-row grid-2-cols-importent nice-gap-2rem ">


                                        <div className="grow">
                                            <div className="basis-2/4 grow">

                                                <DatePicker

                                                    isClearable={!!requestForm.date}

                                                    className=" w-100"

                                                    selected={requestForm.date}
                                                    onChange={async (value) => {

                                                        const val = {};
                                                        val.date = value;
                                                        val.time = null;
                                                        setRequestForm({ ...requestForm, ...val });
                                                        setListTimeAvailable([]);

                                                        if (val.date) {
                                                            await webAvailableReshours(moment(value).format('yyyy-MM-DD'));
                                                        }
                                                    }}
                                                    filterDate={(date) => {
                                                        const today = new Date();
                                                        const yesterday = new Date(today);
                                                        yesterday.setDate(today.getDate() - 1);
                                                        const day = date.getDay();
                                                        return (day === 0 || day === 1 || day === 2 || day === 3 || day === 4 || day === 6)
                                                            && (new Date(date).setHours(0, 0, 0) > yesterday.setHours(0, 0, 0)); // Sunday (0), Saturday (6)

                                                    }}
                                                    placeholderText={trans("form:maintenance-date")}
                                                    dateFormat="yyyy-MM-dd"
                                                    customInput={<DateInput3
                                                        value={moment(requestForm.date).format('yyyy-MM-DD')}
                                                    />}
                                                />
                                            </div>
                                        </div>

                                        <div className="grow">
                                            <div className="basis-2/4 grow">
                                                <DatePicker

                                                    showDisabledMonthNavigation={true}
                                                    includeTimes={listTimeAvailable.map((val) => {
                                                        const time = new Date();
                                                        let res;

                                                        if (requestForm.date && new Date(requestForm.date).toDateString() === new Date().toDateString()) {
                                                            if (val > new Date().getHours()) {
                                                                res = time.setHours(val, 0, 0);
                                                            }
                                                        } else if (requestForm.date && new Date(requestForm.date) > new Date()) {
                                                            res = time.setHours(val, 0, 0);
                                                        } else {
                                                            res = time.setHours(val, 0, 0);
                                                        }

                                                        return res;
                                                    })}


                                                    isClearable={!!requestForm.time}
                                                    selected={requestForm.time}
                                                    onChange={(value) => {

                                                        const val = {};
                                                        val.time = value;
                                                        setRequestForm({ ...requestForm, ...val });
                                                    }}
                                                    className="w-100"

                                                    placeholderText={trans("form:maintenance-time")}
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={60}
                                                    timeInputLabel={trans("form:maintenance-time")}
                                                    timeCaption={trans("form:maintenance-time")}
                                                    dateFormat="hh:mm aa"
                                                    customInput={<DateInput3 />}

                                                />
                                            </div>
                                        </div>

                                    </div>

                                    <TextArea
                                        value={Note}
                                        row={3}
                                        placeholder={trans("info:notes")}
                                        onChangeValue={(data) => {
                                            setNote(data);
                                        }}
                                    />

                                </>

                            }
                        </div>
                        <div className="ruler-2 mb-3"></div>
                        {

                            <>
                                {

                                    <div className="nice-row py5 px-5 nice-flex-center ">
                                        <div className="nice-col-md-6 mb-5">
                                            <div className={"nice-bg-btn  text-white text-24 " + (dataFullNum >= 2 ? " bg-primary " : " bg-gray ")} onClick={() => {



                                                if (dataFullNum >= 2 && isSendeng == false)
                                                    SendResreve(PVIN, ServiceID, VINDetails[0]?.CustID, infoPrice[0].Price);

                                            }}>
                                                {
                                                    (orderReservation) ?
                                                        trans("info:save_changes")
                                                        :
                                                        trans("info:reserve")

                                                }

                                                {
                                                    isSendeng && <span className='mx-2 loading-btn '>
                                                        <img src='/assets/icons/loading.svg' width='25' height='25' />
                                                    </span>
                                                }

                                            </div>
                                        </div>

                                    </div>



                                }

                            </>
                        }


                        <ul class="reservation-privacy-link">
                            {

                                <li className="text-balck text-bold text-24 text-center">
                                    <a className={"text-16"} href="/privacy">{trans('offers:terms')}</a>
                                </li>
                            }

                        </ul>

                    </div>


                </div>

            </div>

            <Popup show={showFinalPopup}>
                <div className="w-full h-full flex flex-column justify-center items-center" >
                    <div className="cart-finalized" >
                        <div className="cart-finalized-container flex flex-col">

                            <div className="cart-finalized-container-img flex flex-col">
                                <img src="/assets/images/finalized.svg" />
                            </div>

                            <div className="flex flex-wrap justify-center">
                                <span className="text-20 font-bold text-black mx-2 unselectable ">{trans("new:thank_to_choose")}</span>
                                <span className="text-20 font-bold text-primary mx-2"> {trans("new:HJ_cars")}</span>
                            </div>

                            {
                                <>
                                    <div className="flex flex-row justify-center mb-10">
                                        <span className="text-20 font-bold  mx-2"> {trans("new:The_appointment_has_been_booked")}</span>
                                    </div>

                                    {filteredSettings?.groups?.filter(s => s.productGroupID == group).length > 0 &&
                                        <div className="flex flex-row justify-center">
                                            <span className="text-20 font-bold text-primary  mx-2">
                                                {filteredSettings?.groups?.filter(s => s.productGroupID == group)[0]?.description}
                                                {` (${PVIN}) `}</span>
                                        </div>
                                    }
                                    {Service &&
                                        <div className="flex flex-row justify-center">
                                            <span className="text-20 font-bold  mx-2"> {trans("info:servce")}: </span>
                                            <span className="text-20 font-bold text-primary  mx-2"> {Service?.ServiceNameAR} </span>
                                        </div>
                                    }

                                    <div className="flex flex-row justify-center">
                                        <span className="text-20 font-bold  mx-2">{trans("form:maintenance-date")}: </span>
                                        <span className="text-20 font-bold text-primary  mx-2"> {orderReservation?.DateIn ?
                                            moment(orderReservation?.DateIn).format('YYYY-MM-DD')
                                            : moment(requestForm?.date).format('YYYY-MM-DD')} </span>
                                    </div>
                                    <div className="flex flex-row justify-center">
                                        <span className="text-20 font-bold  mx-2"> {trans("form:maintenance-time")}: </span>
                                        <span className="text-20 font-bold text-primary  mx-2">
                                            {
                                                orderReservation?.DateIn ? moment(orderReservation?.DateIn).format('hh:mm A')
                                                    : moment(requestForm?.time).format('hh:mm A')} </span>
                                    </div>

                                    {

                                        resOrderID &&
                                        <div className="flex flex-row justify-center cart-finalized-code"
                                            onClick={() => {

                                                navigator.clipboard.writeText(resOrderID);
                                            }}

                                        >

                                            <span className="text-20 font-bold text-white  mx-2"> {trans("new:Reservation_number") + " :"}</span>
                                            <span className="text-20 font-bold text-white  mx-2">{resOrderID && resOrderID}</span>
                                            <img src="/assets/icons/copy.svg" alt='' />
                                        </div>
                                    }

                                </>

                            }
                            <div className="flex flex-center flex-col  mt-5 pt-5 w-80">
                                <div className=" mb-5 w-100">
                                    <div className="nice-sm-btn cart-notification-go  bg-primary text-white text-16" onClick={() => {

                                        setShowFinalPopup(false);
                                        setResOrderID(null);
                                        if (location.pathname)
                                            history.push("/");

                                    }}>
                                        {trans("cartFile:Continue_browsing")}
                                    </div>
                                </div>
                            </div>


                        </div>

                    </div>

                    {

                        orderReservation && orderReservation?.OrderStatusID && orderReservation?.OrderStatusID == "Created" &&


                        <div className="flex flex-center flex-col  mt-5 pt-5 w-80">
                            <div className=" mb-5 w-100">
                                <div className="nice-sm-btn cart-notification-go  text-white text-16 gap-1" onClick={() => {

                                    var threeDaysFromNow = new Date();
                                    if (orderReservation?.DateIn)
                                        threeDaysFromNow = new Date(orderReservation?.DateIn);
                                    if (orderReservation?.Note)
                                        setNote(orderReservation?.Note);

                                    setRequestForm({
                                        ...requestForm,
                                        date: threeDaysFromNow,
                                        time: orderReservation ? threeDaysFromNow : ""
                                    });

                                    setShowFinalPopup(false);

                                }}>


                                    {/* <img src="/assets/icons/edit.svg" width={20} height={20} alt=""></img> */}
                                    <svg
                                        id="SvgjsSvg1001"
                                        width="15"
                                        height="15"
                                        xmlns="http://www.w3.org/2000/svg"
                                        version="1.1"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        xmlnsSvgjs="http://svgjs.com/svgjs"
                                    >
                                        <defs id="SvgjsDefs1002"></defs>
                                        <g id="SvgjsG1008" transform="matrix(1,0,0,1,0,0)">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="15" height="15">
                                                <path
                                                    d="M18.293 31.707h6.414l24-24-6.414-6.414-24 24v6.414zm27.586-24l-3.586 3.586-3.586-3.586 3.586-3.586 3.586 3.586zM20.293 26.121l17-17 3.586 3.586-17 17h-3.586v-3.586z"
                                                    fill="#ffffff"
                                                    className="color000 svgShape"

                                                ></path>
                                                <path
                                                    d="M45.293 19.707h-2v27h-38v-40h28v-5h-38v50h60z"
                                                    fill="#ffffff"
                                                    className="color000 svgShape"
                                                ></path>
                                            </svg>
                                        </g>
                                    </svg>
                                    {trans("info:edit") + " " + trans("info:the_appointment")}

                                </div>
                            </div>
                        </div>

                    }
                </div>
            </Popup>
        </>
    );
}