import {configureStore, combineReducers} from '@reduxjs/toolkit'

import navReducer from './slices/navSlice';
import cartReducer from './slices/cartSlice';
import dataSlice from './slices/dataSlice';

const entitiesReducer = combineReducers({

    nav: navReducer,
    cart: cartReducer,

});
const apiDataReducer = combineReducers({

    data: dataSlice,

});
const reducers = combineReducers({
    entities: entitiesReducer,
    apiData:apiDataReducer,
});


export default configureStore({
    reducer: reducers,

},)