import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { InputField } from "../../components/_input/_input";
import PhoneDropDown from "../../components/_input/_phoneDropDown";
import { TextArea } from "../../components/_input/_textArea";
import { DropDownInput } from "../../components/_input/_dropdown";
import { FormButton } from "../../components/_input/_button";
import CarFeature from "../../components/carFeature";
import Breadcrumb from "../../components/__simple/breadcrumb";
import axios from "axios";
import config from '../../configuration/config.json';
import Joi from "joi";
import { groupBy, mapValues, omit } from "lodash";
import { GetMessages, formValidate } from '../../utils/validation';
import { toast } from "react-toastify";
import { useHistory, useLocation } from "react-router";
import { uniqBy } from "lodash";
import { getData, User_Data_Key } from "../../utils/localStorageManager";
import dropDownResetter from "../../utils/resetDropDown";

export function OrderCarPage() {
    const { t: trans,i18n } = useTranslation(["heading", "finance", "form", "model", "inputs", "info", "eparts"]);
    let lang = (i18n.language || 'ar');
    const [error, setError] = useState({});
    const [banner, setbanner] = useState([]);
    const [product, setproduct] = useState(null);
    const [requestForm, setRequestForm] = useState({
        name: '',
        custType: null,
        identityNo: '',
        cn: '',
        address: '',
        email: '',
        phoneNumber: '',
        quantity: 1,
        message: '',
        note: '',
        selectedProduct: '',
        selectedModel: '',
        selectedColor: ''
    });
    const [showProductSection, setShowProductSection] = useState(true);
    const [brands, setBrands] = useState([]);
    const [brand, setBrand] = useState(null);
    const [group, setGroup] = useState(null);
    const [type, setType] = useState(null);
    const [year, setYear] = useState(null);
    const [fColors, setFColors] = useState([]);
    //--------------------------------------------------- 
    const [fProductGroups, setFProductGroups] = useState([]);
    const [productGroups, setProductGroups] = useState([]);
    const [products, setProducts] = useState([]);
    const [fProducts, setFProduct] = useState(null);
    const [fModels, setFModels] = useState([]);
    //---------------------------------------------------
    const [supportedColors, setSupportedColors] = useState([]);
    const [selectedColor, setSelectedColor] = useState(null);
    const [selectedObjectColor, setSelectedObjectColor] = useState(null);
    //---------------------------------------------------
    const [isLoading, setIsLoading] = useState(false);
    //---------------------------------------------------
    const [vehicleInfo, setVehicleInfo] = useState(null);
    //-------------- other declaration------------------
    const [defultMobile, setDefultMobile] = useState("");
    const schema = Joi.object({
        name: Joi.string().required().messages(GetMessages()),
        address: Joi.string().required().messages(GetMessages()),
        email: Joi.string().email({ minDomainSegments: 2, tlds: { allow: false } }).required().messages(GetMessages()),
        phoneNumber: Joi.string().min(12).required().messages(GetMessages()),
        quantity: Joi.number().min(1).required().messages(GetMessages()),
        selectedProduct: Joi.string().required().messages(GetMessages()),
        selectedModel: Joi.string().required().messages(GetMessages()),
        custType: Joi.string().required().messages(GetMessages()),
    });

    const schemaidentityNo = Joi.object({
        identityNo: Joi.string().min(10).required().messages(GetMessages()),

    });
    const schemacn = Joi.object({
        cn: Joi.string().min(10).required().messages(GetMessages()),

    });
    // declarations ver that using in route & searching in url
    const history = useHistory();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    //------------------------------------------------------
    const [userTypeList, setUserTypeList] = useState([]);
    const [userType, setUserType] = useState("");
    const [identityType, setIdentityType] = useState(null);

    const [cnValue, setCnValue] = useState("");
    const [identityValue, setIdentityValue] = useState("");

    // ------------
    useEffect(() => {

        initialBanner();
        getAllUserType();
        handleFilterModelsByProduct();
        getAllGroups();
        getAllBrands();
        getAllProducts();

    }, []);

    useEffect(() => {
        let data = {};

        const val = { ...requestForm };

        try {
            data = JSON.parse(getData(User_Data_Key, true));
            console.log("_userData:", data);


            console.log("requestForm:", val);
            val.name = lang == "ar" ? (data?.arFirstName ?? "") + " " + (data?.arMiddleName ?? "") + " " + (data?.arLastName ?? "") : (data?.enFirstName ?? "") + " " + (data?.enMiddleName ?? "") + " " + (data?.enLastName ?? "");
            val.address = data?.address ?? "";
            val.phoneNumber = data?.phone ?? "";

            console.log("phone>>>>>>>>>>>>:", data?.phone.replace(/\D/g, '').slice(-9).toString());
            if (data?.phone)
                setDefultMobile(data?.phone.replace(/\D/g, '').slice(-9).toString());
            val.email = data?.email ?? "";

            console.log("val:", val);

            setRequestForm(val);


        } catch (error) {


        }


        const productID = query.get('vehicle');
        const typeID = query.get('type');
        const model = query.get('model');
        const color = query.get('color');
        if (productID) {

            val.selectedProduct = String(productID);
            val.selectedModel = String(model);
            val.selectedColor = String(color);
            val.selectedTypeID = String(typeID);
            setproduct(val);
            if (model) {
                handleShowVehicleDetails(productID, model);
            }
            setRequestForm(val);
            setShowProductSection(false);
            setType(typeID);
            handleFetchColors(typeID, model, color);
        }

        if (color)
            setSelectedColor(color);

        fetchCarDesign(color, productID, model);
    }, []);

    useEffect(() => {

        if (products && products.length > 0 && product && product.selectedTypeID && fProducts == null) {

            var GroupID = null;

            const filteredProducts = products.filter(e => e.productTypeID == product.selectedTypeID);

            if (filteredProducts[0])
                GroupID = filteredProducts[0].productGroupID;

            if (GroupID) {
                const filteredProductsbyGroupID = products.filter(e => e.productGroupID == GroupID && e.Model == product.selectedModel);
                setFProduct(filteredProductsbyGroupID.map(product => {
                    return { value: product.productTypeID, text: product[`${trans("model:gamelDescription")}`], ...product }
                }));

                setGroup(GroupID);
            } else {
                setFProduct([]);
            }


        }

    }, [product, products, fProducts]);
    //----------------Functions + Methods------------------------
    // fetch all brands from db
    const getAllBrands = () => {
        axios.post(
            config.baseURL + "details",
            {
                "object": "InvBrands",
                "ObjectSettings": {
                    "MetaData": false
                }
                , "option": "column",
                "Fields": "BrandID,DescriptionAr,DescriptionEn",
                "filters": " where IsEnabled =1"
            }
        ).then(response => {
            const result = response.data.ApiObjectData;
            setBrands(result.map(brand => {
                return {
                    value: brand.BrandID, text: brand[`${trans("model:description")}`]
                }
            }));
        }).catch(err => {
            console.log(err);
        });
    };
    //It's used to intialize the banner
    const initialBanner = () => {
        axios.post(
            config.baseURL + "details",
            {
                "object": "sitePageBanners",
                "ObjectSettings": {
                    "MetaData": false
                },
                "filters": "where bannerId=9"
            }
        ).then(response => {
            setTimeout(setbanner(response.data.ApiObjectData), 500);
        }).catch(err => {
            console.log(err);
        });
    };
    // It's used to filter the groups by brand
    const filterModel = (option) => {

        setVehicleInfo(null);
        setSelectedColor(null);
        setType(null);
        setGroup(null);
        if (option.value == '0') {
            dropDownResetter(() => {
                setFProductGroups();
                setFModels([]);
                setFProduct([]);
                setFColors([]);
            });
        }
        setBrand(option.value);
        const fGroups = productGroups.filter(a => a.BrandID == option.value);
        setFProductGroups(fGroups.map(group => {
            return {
                value: group.ProductGroupID, text: group[`${trans("model:description")}`]
            }
        }));
    };
    // Fetch all groups
    const getAllGroups = () => {

        const Body = {
            "name": "SiteFetchAvailableGroupsOnSite"
        }
        axios.request({
            url: config.baseURL + 'func',
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {
                setProductGroups(response.data);


            })
            .catch(function (error) {
                console.log(error);
            });
    };

    // * siteGetCustGroupsforOrder
    const getAllUserType = () => {
        axios.post(
            config.baseURL + 'func',
            {
                "name": "siteGetCustGroupsforOrder"
            }
        ).then(response => {
            setUserTypeList(response.data);
        }).catch(err => {
            console.log(err);
        })
    };


    const handleCustType = (option) => {
        const val = { ...requestForm };
        val.custType = option;
        setRequestForm(val);
        console.log(userType);
        setUserType("");

        setCnValue("");
        setIdentityValue("");

        try {
            if (option == '' || option == null || option == '0') {
                setIdentityType(null);
                return;
            }

            // const currentValue = accountTypes.find(item => {
            //     return item.ID == option
            // }) ?? {};

            let value = null;
            if (option == "G4") {
                value = 1;
                val.cn = null;
                setUserType(val.cn);
            } else {
                value = 2;
                val.identityNo = null;
                setUserType(val.identityNo);
            }

            setRequestForm(val);
            setIdentityType(value);

        } catch (err) {
            //////console.log(err);
        }
    };


    //Fetch all products
    const getAllProducts = () => {
        // SiteFetchAvailableCarsOnSite
        axios.post(
            config.baseURL + "func",
            {
                "Name": "SiteFetchALLProductModelTypesOnSite",
            }
        ).then(response => {
            const result = response.data;
            setProducts(result);
        }).catch(err => {
            console.log(err);
        });


        // axios.post(
        //     config.baseURL + "details",
        //     {
        //         "object": "InvProducts",
        //         "ObjectSettings": {
        //             "MetaData": false,
        //         },
        //         "Option": "Column",
        //         "Fields": "ProductID,DescriptionAr,DescriptionEn,ProductGroupID,Model",
        //         "Filters": `where ProductID not like 'SP%'`
        //     }
        // ).then(response => {
        //     const result = response.data.ApiObjectData;
        //     setProducts(result);

        // }).catch(err => {
        //     console.log(err);
        // });
    };
    // to get the model of the product
    const handleFilterModelsByProduct = () => {
        axios.post(
            config.baseURL + "details",
            {
                "object": "InvProductModel",
                "ObjectSettings": {
                    "MetaData": false,
                },
                "Option": "Column",
                "Fields": "modelID",
                "Filters": `where  modelID >='2015' `
            }
        ).then(response => {
            const result = response.data.ApiObjectData;
            const models = result.map(item => {
                return item.modelID
            });
            const uniqueModels = uniqBy(models, (mY) => {
                return mY;
            }).filter((value) => {
                return value !== null
            }).map(item => {
                return { text: item, value: item }
            });
            setFModels(uniqueModels);
        }).catch(err => {
            console.log(err);
        });
    }
    //Filter the products by group
    const filterProductsByGroup = (option) => {
        setType(null);
        setGroup(null);
        setVehicleInfo(null);
        setSelectedColor(null);
        if (option.value == '0') {
            dropDownResetter(() => {
                setFProduct([]);
                setFModels([]);
            });
        }
        setGroup(option.value);
        const filteredProducts = products.filter(e => e.productGroupID == option.value && e.Model == requestForm.selectedModel);
        setFProduct(filteredProducts.map(product => {
            return { value: product.productTypeID, text: product[`${trans("model:gamelDescription")}`], ...product }
        }));
    };
    const handleSendRequest = async () => {

        if (isLoading == true)
            return;

        let result = formValidate(schema, requestForm) ?? {};

        // ex:
        // const mergedObj = Object.assign({}, obj1, obj2);
        if (identityType && identityType == 1) {
            const result22 = formValidate(schemaidentityNo, requestForm) ?? {};
            result = { ...result, ...result22 }

        } else {

            const result33 = formValidate(schemacn, requestForm) ?? {};
            result = { ...result, ...result33 }

        }
        setError(result);

        if (Object.keys(result).length == 0) {
            if (supportedColors?.length > 0 && selectedColor == null) {
                toast.error(trans("finance:color_not_select"));
                setIsLoading(false);
                return;
            }
            setIsLoading(true);
            const basicInfo = await getMainUserData();

            axios.post(
                config.baseURL + "add",
                {
                    "Object": "SitePurchaseVehicleOrder",
                    "Values": {
                        "Name": requestForm.name,
                        "Phone": requestForm.phoneNumber,
                        "Email": requestForm.email,
                        "Message": requestForm.message,
                        "Address": requestForm.address,
                        "Quantity": Number(requestForm.quantity),
                        "ColorID": (selectedColor != null || selectedColor != '') ? String(selectedColor) : null,
                        "Web_UserID": basicInfo ? Number(basicInfo.userId) : null,
                        "CustID": basicInfo ? basicInfo.custId ? String(basicInfo.custId) : null : null,
                        "ProductID": requestForm.selectedProduct,
                        "ModelYear": requestForm.selectedModel,
                        "CustGroupID": requestForm.custType,
                        "TaxationNumber": cnValue ?? "",
                        "AddtionalNo": identityValue ?? "",
                    }
                }
            ).then(response => {
                toast.success(trans("common:order-request-success"));
                setIsLoading(false);
                history.push("/");
            }).catch(err => {
                toast.error(trans("finance:error_send_data"));
                setIsLoading(false);
            });

        }
    };
    // get the main essetional info about user [web_user_id & cust_id]
    const getMainUserData = async () => {
        let data = null;
        try {
            const _data = JSON.parse(getData(User_Data_Key, true));
            if (localStorage.getItem("isAuthenticated")) {
                data = {
                    userId: Number(_data.id.toString().split('_')[1]),
                    custId: _data.custID
                };
            } else {
                const response = await axios.post(
                    config.baseURL + "details",
                    {
                        "Object": "web_users",
                        "Option": "Column",
                        "ObjectSettings": {
                            "MetaData": false,
                            "Limit": {
                                "Value": 1,
                                "Optional": "Top"
                            }
                        },
                        "Fields": "Web_UserId,CustID",
                        "Filters": `where lower(email) = lower('${requestForm.email}')`
                    }
                );
                if (response.status == 200) {
                    const result = response.data.ApiObjectData;
                    if (result.length > 0) {
                        const finalResult = result[0];
                        data = {
                            userId: finalResult.Web_UserId,
                            custId: finalResult.CustID
                        };
                    }
                }
            }
        } catch (err) {
            console.log(err);
        }
        return data;
    };
    const handleFetchColors = (productTypeID, modelYear, defultColor) => {

        if (productTypeID != '' && modelYear != '') {
            axios.post(
                config.baseURL + 'func',
                // {
                //     "name": "SiteGetColorsByProduct",
                //     "values": {
                //         "productID": `${productID}`,
                //         "model": `${model}`
                //     }
                // }

                {
                    "Name": "site_fetchColorOfType",
                    "Values": {
                        "Type": productTypeID,
                        "model": modelYear
                    }
                }
            ).then(response => {
                const filterColors = response.data.map((item) => {
                    return {
                        text: (lang == "ar" ? item?.ColorDescriptionAr : item?.ColorDescriptionEn),
                        value: item.ColorID, ...item
                    }
                });
                setFColors(filterColors);

                if (defultColor) {
                    setSelectedColor(defultColor);
                } else {

                    if (filterColors && filterColors[0]) {
                        setSelectedColor(filterColors[0].value);
                    }
                }
                if (response.data[0])
                    handleShowVehicleDetails(response.data[0].ProductID, modelYear);
            }).catch(error => {
                console.log(error);
            })


        }
    };
    //Show the vehicle details[image-features]
    const handleShowVehicleDetails = (productID, model) => {
        axios.post(
            config.baseURL + 'func',
            {
                "name": "SiteGetMoreCarInfo",
                "values": {
                    "productID": `${productID}`,
                    "model": `${model}`
                }
            }
        ).then(response => {
            setVehicleInfo(response.data[0]);
        }).catch(err => {
            console.log(err);
        })
    };



    const [cashCarImages, setCashCarImages] = useState({});
    const [outerCarImagesJson, setOuterCarImagesJson] = useState(null);
    const [isLoadingImages, setIsLoadingImages] = useState(false);
    const fetchCarDesign = async (activeColor, model, year) => {

        // console.log("activeColor>>>>>>>>>>>>>>>>>>>>>>>>>:", activeColor);
        // console.log("model>>>>>>>>>>>>>>>>>>>>>>>>>:", model);
        if (isLoadingImages == false) {

            const Namefiled = "frontImage,leftImage,backImage,sideImage,rightImage,mainColorImage"

            const prefix = 'data:image/png;base64,';
            if (model) {
                if (cashCarImages[model + "-part-" + 0]) {
                    setIsLoadingImages(false);
                    var AllCarImagesOUT = cashCarImages[model + "-part-" + 0];
                    setOuterCarImagesJson(AllCarImagesOUT);

                } else {
                    axios.post(
                        config.baseURL + 'func',
                        {
                            //SiteFetchCarColorImages
                            "Name": "SiteFetchCarColorCodeImages",
                            "Values": {
                                "Id": model,
                                "year": year,
                                // "ColorID": String(codeColor),
                                "ColorID": null,
                                "FieldNameList": null
                            }
                        }
                    ).then(response => {
                        setIsLoadingImages(false);
                        var maincolorimageIndex = 0;
                        // console.log("SiteFetchCarColorCodeImages responseData>>>>>>>>>>>>>>>>>>>>>>>>>:", response.data);
                        if (Array.isArray(response.data)) {
                            const responseData = response.data;
                            const groupResult = mapValues(groupBy(responseData, 'ColorCode'), fList => fList.map(items => omit(items, 'ColorCode')));

                            // console.log("responseData groupResult>>>>>>>>>>>>>>>>>>>>>>>>>:", groupResult);
                            setOuterCarImagesJson(groupResult);
                            cashCarImages[model + "-part-" + 0] = groupResult;
                            setCashCarImages(cashCarImages);
                            setIsLoadingImages(false);

                        }
                    }).catch(error => {
                        // console.log(error);
                        setIsLoadingImages(false);
                        // cashCarImages[model + "-part-" + 0] = {};
                    });

                }

            }
        }
    };

    return (
        <Fragment>

            {/* {banner.map(BN => {

                if (lang === "en") {
                    return (<Banner
                        background={"data:image/png;base64," + BN.image}
                        title={BN.titleEn}
                        subtitle={BN.subTitleEn}
                        desc={BN.excerptEn}
                        backOpasity={BN?.backOpasity}
                    />);
                } else {
                    return (<Banner
                        background={"data:image/png;base64," + BN.image}
                        title={BN.titleAr}
                        subtitle={BN.subTitleAr}
                        desc={BN.excerptAr}
                        backOpasity={BN?.backOpasity}
                    />);
                }
            })} */}
            <div className="container">
                <Breadcrumb className={"mt-16 "}
                    links={[
                        {
                            name: trans("finance:veh-order"),
                        },
                    ]}
                />
            </div>

            <div className="container mb-32 ">

                <div className={"grid-2-cols  gap-10 mb-10"}>
                    <div className={"inputs-group flex-grow-1"}>
                        <InputField
                            value={requestForm.name}
                            errorLabel={error?.name}

                            onChangeValue={(e) => {
                                const val = { ...requestForm };
                                val.name = e.target.value;
                                setRequestForm(val);
                            }}
                            placeholder={trans("form:name")}
                            name='name'
                        />
                        <InputField
                            value={requestForm.address}
                            errorLabel={error?.address}
                            onChangeValue={(e) => {
                                const val = { ...requestForm };
                                val.address = e.target.value;
                                setRequestForm(val);
                            }}
                            placeholder={trans("form:address")}
                            name='name'
                        />

                        <PhoneDropDown
                            placeholder={trans("form:phone")}
                            errorLabel={error?.phoneNumber}
                            defaultValue={defultMobile}
                            onChangeValue={
                                (key, value) => {
                                    const val = { ...requestForm };
                                    val.phoneNumber = `+${key}${value}`;
                                    setRequestForm(val);
                                }
                            }
                        />
                        <InputField
                            value={requestForm.email}
                            type='email'
                            onChangeValue={(e) => {
                                const val = { ...requestForm };
                                val.email = e.target.value;
                                setRequestForm(val);
                            }}
                            errorLabel={error?.email}
                            placeholder={trans("form:email")}
                            name='email'
                        />


                        <TextArea
                            placeholder={trans("finance:note")}
                            value={requestForm?.note}
                            onChangeValue={
                                (e) => {
                                    const val = { ...requestForm };
                                    val.note = e;
                                    setRequestForm(val);
                                }
                            }
                            name='note'

                            row={3}

                        />
                        {/* <TextArea
                            value={requestForm?.message}
                            onChangeValue={
                                (e) => {
                                    const val = { ...requestForm };
                                    val.message = e;
                                    setRequestForm(val);
                                }
                            }
                            name='message'
                            title={trans("form:message")}
                        /> */}
                    </div>
                    <div className={"inputs-group flex-grow-1"}>

                        {
                            // 
                            <div className={`grid ${requestForm?.custType == null ? 'grid-cols-1 ' : 'grid-cols-3'} gap-2`}>

                                <DropDownInput roundedFull={"rounded-full"}
                                    defaultOption={
                                        (requestForm?.custType == null || requestForm?.custType == "")
                                            ? { text: trans("finance:applicant"), value: '0' }
                                            : null}
                                    errorLabel={error?.custType}
                                    options={[...userTypeList.map(item => {
                                        return { text: item.DescriptionAr, value: item.ID }
                                    })]}
                                    onSelect={handleCustType}

                                    selectedValue={requestForm.custType}
                                />

                                {
                                    (requestForm?.custType == null)
                                        ? <></>
                                        : <div className="col-span-2">
                                            <InputField
                                                errorLabel={(identityType && identityType == 1)
                                                    ? error?.identityNo
                                                    : (identityType == 2) ? error?.cn : error?.identityNo
                                                }

                                                value={(identityType && identityType == 1)
                                                    ? identityValue
                                                    : (identityType == 2) ? cnValue : identityValue
                                                }

                                                length={10}
                                                placeholder={
                                                    (identityType && identityType == 1)
                                                        ? trans("form:identity-no")
                                                        : (identityType === 2) ? trans("finance:cn") : trans("form:identity-no")
                                                }
                                                onChangeValue={(e) => {
                                                    const val = { ...requestForm };
                                                    if (identityType && identityType == 1) {

                                                        val.identityNo = e.target.value;
                                                        setIdentityValue(e.target.value);

                                                    } else {

                                                        val.cn = e.target.value;
                                                        setCnValue(e.target.value);
                                                    }
                                                    setRequestForm(val);
                                                }}

                                                onLeave={(e) => {
                                                    console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeeeee", e);
                                                }}
                                            />
                                        </div>
                                }

                            </div>
                            //
                        }

                        {showProductSection && <>
                            <DropDownInput
                                defaultOption={(brand == '0' || brand == null) ? {
                                    text: trans("info:company"),
                                    value: '0'
                                } : null}
                                onfilter={filterModel}
                                options={[...brands]}
                                selectedValue={brand}
                            />
                            <DropDownInput
                                selectedValue={year}
                                errorLabel={error?.selectedModel}

                                defaultOption={{
                                    text: trans("info:created_year"),
                                    value: '0'
                                }}
                                options={[...fModels]}
                                onSelect={
                                    (e) => {
                                        const val = { ...requestForm };
                                        val.selectedModel = String(e);
                                        setYear(e);
                                        setRequestForm(val);
                                        setVehicleInfo(null);
                                        setSelectedColor(null);
                                        handleFetchColors(val.selectedProduct, e);
                                    }
                                }
                            />
                            <DropDownInput
                                selectedValue={group}

                                defaultOption={((brand == '0' || brand == null) || (group == '0' || group == null)) ? {
                                    text: trans("eparts:vehicle"),
                                    value: '0'
                                } : null}
                                options={[...fProductGroups]}
                                onfilter={filterProductsByGroup}
                            />

                        </>
                        }
                        <DropDownInput
                            selectedValue={type}
                            errorLabel={error?.selectedProduct}
                            defaultOption={((brand == '0' || brand == null) || (group == '0' || group == null)) ? {
                                text: trans("eparts:model"),
                                value: '0'
                            } : null}
                            options={fProducts ? [...fProducts] : []}
                            onfilter={
                                (e) => {

                                    if (e.value == '0') {
                                        dropDownResetter(() => {
                                            setFModels([]);
                                            setFColors([]);
                                        });
                                        return;
                                    }
                                    const val = { ...requestForm };
                                    val.productTypeID = String(e.value);
                                    setType(val.productTypeID);
                                    setRequestForm(val);
                                    setVehicleInfo(null);
                                    setSelectedColor(null);
                                    handleFetchColors(e.productTypeID, val.selectedModel);
                                }
                            }
                        />

                        {
                            fColors.length > 0 && (
                                <DropDownInput
                                    selectedValue={selectedColor}
                                    defaultOption={{
                                        text: trans("info:avaliable_colors"),
                                        value: '0'
                                    }}
                                    onSelect={(option) => {
                                        setSelectedColor(option);
                                        // setOuterCarImagesJson(null);
                                        console.log("fColors:", fColors);
                                        console.log("option:", option);
                                        const objectColor = fColors.filter(c => c.value == option)[0]?.ProductID;
                                        console.log("objectColor:", objectColor);
                                        fetchCarDesign(option, objectColor, requestForm.selectedModel);
                                    }}
                                    options={[...fColors]}
                                />
                            )
                        }
                        <InputField
                            title={trans("finance:quantity")}
                            placeholder={trans("finance:quantity")}
                            value={requestForm?.quantity}
                            type='number'
                            onChangeValue={(e) => {
                                const val = { ...requestForm };
                                val.quantity = e.target.value;
                                setRequestForm(val);
                            }}
                            errorLabel={error?.quantity}
                            name='quantity'
                        />


                        <div className="car-heading -mt-8 language-ar">
                            <div className="car-heading-name">
                                <h1 className="text-ellipsis"
                                    title=" ">{lang == "ar" ? vehicleInfo?.brand : vehicleInfo?.brandEn}</h1>
                                <div className="car-tags mb-4">

                                </div>
                            </div>
                            <h3 className="car-heading-desc"> {lang == "ar" ? vehicleInfo?.ModelTypes : vehicleInfo?.ModelTypesEn}</h3>
                        </div>
                        {vehicleInfo && <div className="order-car-container">


                            {outerCarImagesJson &&

                                outerCarImagesJson[selectedColor?.split("/")[0]] ?

                                <img className={"order-car-img"} src={config.FilePathURL + outerCarImagesJson[selectedColor?.split("/")[0]][0]?.Image_URL} alt="car" />
                                :
                                <div className='flex-item-center text-size-16r'>
                                    {/* {
                                        trans("finance:no_image_set")
                                    } */}
                                </div>

                            }
                            <div className="order-specs">
                                <CarFeature value={vehicleInfo?.Petrol ?? '0'} text={trans("vehicales:veh-petrol")}
                                    iconName='petrol' />
                                <CarFeature value={vehicleInfo?.SeatsNumber ?? '0'} text={trans("vehicales:veh-seat")}
                                    iconName='seat' />
                                <CarFeature value={vehicleInfo?.Cylinders ?? '0'} text={trans("vehicales:veh-cle")}
                                    iconName='cylinders' iconStyle={{ minWidth: '4rem' }} />
                                <CarFeature value={vehicleInfo?.HP ?? '0'} text={trans("vehicales:veh-eng")}
                                    iconName='engine' />
                            </div>
                        </div>
                        }
                    </div>
                </div>

                <FormButton
                    name={trans("form:send-order")}
                    onClick={async () => {
                        if (isLoading == false)
                            await handleSendRequest();
                    }}
                />
            </div>
        </Fragment>
    );
}