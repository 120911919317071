import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import config from '../configuration/config.json';
import { FormButton } from "../components/_input/_button";
import { DropDownInput } from "../components/_input/_dropdown";
import { DropDownInput2 } from "../components/_input/_dropdown2";
import { CheckInput } from "../components/_input/_checkInput";
import { InputField } from "../components/_input/_input";
import crypto from 'crypto-js';
import Joi from 'joi';
import { formValidate, GetMessages, validateProperty } from "../utils/validation";
import { toast } from "react-toastify";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import SectionHeading from "../components/sectionHeading";
import PhoneDropDown, { countriesCode } from "../components/_input/_phoneDropDown";
import { FileInput } from "../components/_input/_fileInput";
import { getData, saveData, User_Data_Key } from "../utils/localStorageManager";
import { includes } from "lodash";
import dropDownResetter from "../utils/resetDropDown";
export default function Profile() {
  const defaultProfileImage = '/assets/images/profile.jpg';
  // db.createDbRequest("UserDB", 1);
  const fileInputRef = useRef();
  const { t: trans,i18n } = useTranslation(["common", "form", "model", "user", "info", "hints", "error2", "inputs", "new", "extra"]);
  let lang = (i18n.language || 'ar');
  const languageDir = 'language-' + lang;
  const [showResetPasswordBox, setShowResetPasswordBox] = useState(false);
  const [acceptPasswordStatus, setAcceptPasswordStatus] = useState(false);
  const [genderList, setGenderList] = useState([{}]);
  const [selectedGender, setSelectedGender] = useState(null);
  const [readOnly, setReadOnly] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [imageProfile, setImageProfile] = useState(defaultProfileImage);
  const [editableStatus, setEditableStatus] = useState(0);
  const [isLoading2, setIsLoading2] = useState(false);
  const [accountTypes, setAccountTypes] = useState([]);
  const [identityType, setIdentityType] = useState(null);
  const [settings, setSettings] = useState({
    countries: [],
    cities: [],
    genders: []
  });
  const [defaultGeoLocation, setDefaultGeoLocation] = useState({
    country: null,
    city: null
  });
  const [filteredCities, setFilteredCities] = useState([]);
  const [personalDataRequest, setPersonalDataRequest] = useState({
    arFirstName: '',
    arMiddleName: '',
    arLastName: '',
    enFirstName: '',
    enMiddleName: '',
    enLastName: '',
    genderID: '',
    address: '',
    cityID: '',
    countryID: '',
    logo: defaultProfileImage
  });
  const [error, setError] = useState({});
  //------------------------------------------------------------------------
  const schemaPassword = Joi.object({
    newPassword: Joi.string().min(8).max(20).required().messages(GetMessages()),
    confirmPassword: Joi.string().valid(Joi.ref('newPassword')).required().messages(GetMessages())
  });

  const [changePhoneNumber, setChangePhoneNumber] = useState(false);
  const [changeEmail, setChangeEmail] = useState(false);
  const [requestChangePassword, setRequestChangePassword] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  });
  const [userHasNoPassword, setUserHasNoPassword] = useState(false);
  const [passwordError, setPasswordError] = useState({});
  //--------------------------------------------------------------------
  const [personalPhoneNumber, setPersonalPhoneNumber] = useState({
    phoneNumber: '',
  });
  const schemaPhoneNumber = Joi.object({
    phoneNumber: Joi.string().min(12).required().messages(GetMessages())
  });
  const [phoneError, setPhoneError] = useState({});
  //------------------------------------------------------------------------

  const [personalContactDataRequest, setPersonalContactDataRequest] = useState({
    email: '',
    phoneNumber: '',
    identityNo: '',
    identityImage: null,
  });

  const [personalDocumentDataRequest, setPersonalDocumentDataRequest] = useState({
    custGroupID: '',
    identityNo: '',
    identityImage: null,
    cn: '',
    cnImage: null
  });

  const schemaDocument = Joi.object({
    identityNo: Joi.string().length(10).required().messages(GetMessages()),
    cn: Joi.string().length(10).required().messages(GetMessages()),
    custGroupID: Joi.string().required().messages(GetMessages()),
  });

  const [errorDocument, setErrorDocument] = useState({});

  //-----------------------------------------------------------------
  const [personalEmailDataRequest, setPersonalEmailDataRequest] = useState({
    email: ''
  });

  const schemaEmail = Joi.object({
    email: Joi.string().email({ minDomainSegments: 2, tlds: { allow: false } }).required().messages(GetMessages())
  });

  const [emailError, setEmailError] = useState({});
  //----------------------------------------------------------------

  const [personalIdentityDataRequest, setPersonalIdentityDataRequest] = useState({
    identity: '',
    identityImage: null
  });
  const schemaIdentity = Joi.object({
    identity: Joi.string().length(10).required().messages(GetMessages())
  });
  const [identityError, setIdentityError] = useState({});
  const [showIdentityImage, setShowIdentityImage] = useState(false);
  const [freazeType, setFrazeType] = useState(false);
  const [defaultIdentityType, setDefaultIdentityType] = useState(null);

  //-----------------------------------------------------------------
  const schemaSection1 = Joi.object({
    arFirstName: Joi.string().max(50).required().messages(GetMessages()),
    arMiddleName: Joi.string().max(50).required().messages(GetMessages()),
    arLastName: Joi.string().max(50).required().messages(GetMessages()),
    enFirstName: Joi.string().max(50).required().messages(GetMessages()),
    enMiddleName: Joi.string().max(50).required().messages(GetMessages()),
    enLastName: Joi.string().max(50).required().messages(GetMessages()),
    countryID: Joi.required().messages(GetMessages()),
    cityID: Joi.required().messages(GetMessages()),
  });


  useEffect(async () => {
    try {
      const settingsResponse = await axios.request({
         baseURL: config.controllURL,
        url: lang + "/api/user/getSettings",
        method: "get",
      });

      if (settingsResponse.status === 200) {
        setSettings(settingsResponse.data);
      }

      var mm = settingsResponse?.data?.genders.map(e => {
        return { value: String(e.id), text: e.description }
      });
      setGenderList(mm);
      
      intialUserData(settingsResponse?.data);

    } catch (err) {
      console.log(err);
    }
  }, []);



  const intialIdentityTypes = async () => {
    let responseData = [];
    try {
      const response = await axios.post(
        config.baseURL + 'func',
        {
          "name": "SiteGetUserAccountTypes"
        }
      );
      if (response.status == 200) {
        responseData = response.data;
        setAccountTypes(responseData);
        intialUserContactData();
      }
    } catch (err) {
      console.log(err);
    }
    return responseData;
  };

  const handlePicker = () => {
    fileInputRef.current.click();
  };

  const intialUserData = async (data) => {

    try {
      const genderModel = trans("model:description");
      const userData = JSON.parse(getData(User_Data_Key, true));
   
      if (userData) {
        if (userData.logo) {
          setImageProfile(URL.createObjectURL(b64toBlob(userData?.logo)));
        } else {
          setImageProfile(defaultProfileImage);
        }
        if (userData.genderID) {
          const userGender = data?.genders?.find(item => String(item.id) == String(userData?.genderID));
          if (userGender) {
            setSelectedGender({ text: userGender.description, value: String(userGender.id) });
          }
        }

        const { custGroupID, identity, tradeNo, countryID, cityID } = { ...userData };

        const cResult = data?.countries?.find(item => {
          return item.id == countryID
        }) ?? null;



        setFilteredCities(data?.cities?.filter(item => {
          return item.code == countryID
        }));
        setTimeout(() => {

          setPersonalDataRequest(userData);

          const ccResult = data?.cities?.find(item => {
            return item.id == cityID
          }) ?? null;

          const _userGeo = { ...defaultGeoLocation };

          if (cResult) {
            _userGeo.country = { text: cResult?.description, value: cResult?.id };
          }
          if (ccResult) {
            _userGeo.city = { text: ccResult?.description, value: ccResult?.id };
          }

          setDefaultGeoLocation(_userGeo);
        }, 1000)

        const results = await intialIdentityTypes();
        if (results?.length > 0) {
          const value = results.find(item => {
            return item.ID === custGroupID
          });
          if (value) {
            const finalValue = value.NeedIdentity == true ? 1 : value.NeedIdentity == false ? 2 : null;
            setIdentityType(finalValue);
            setDefaultIdentityType({ text: value[`${trans("model:description")}`], value: value.ID });
            if (finalValue == 1 && identity !== "") {
              // setFrazeType(true);
            } else if (finalValue == 2 && tradeNo !== "") {
              // setFrazeType(true);
            }
          }
        }
      }
    } catch (err) {
      console.log(err);
    }

    console.log(">>>>>>>>>>>>>>>>>GetUserImage>>>>>>>>>>>>>>>>>>:")
    try {
      const userKey = getData('secretKey');
      console.log(">>>>>>>>>>>>>>>>>GetUserImage>>>>>>>>userKey>>>>>>>>>>:", userKey)
      const settingsResponse = await axios.request({
         baseURL: config.controllURL,
        url: lang + "/api/user/GetUserImage",
        method: "get",
        params: {
          ID: userKey
        },
      });

      if (settingsResponse?.status === 200) {
        const imgeUser = settingsResponse?.data?.userImage?.Logo;

        if (imgeUser) {
          setImageProfile(URL.createObjectURL(b64toBlob(imgeUser)));
        } else {
          setImageProfile(defaultProfileImage);
        }
      }

    } catch (err) {
      console.log(err);
    }
  };
  const intialUserContactData = () => {
    const userData = JSON.parse(getData(User_Data_Key, true));
    if (userData) {
      const { email, phone, identity, identityImage, tradeNo, tradeNoImage, custGroupID } = userData;

      setPersonalContactDataRequest({
        phoneNumber: phone,
        identityNo: identity,
        email: email,
        identityImage: identityImage
      });
      setPersonalIdentityDataRequest(
        {
          identity: identity,
          identityImage: identityImage
        }
      );
      setPersonalDocumentDataRequest({
        identityNo: identity ?? '',
        identityImage,
        cn: tradeNo ?? '',
        cnImage: tradeNoImage,
        custGroupID: custGroupID ?? ''
      });
    }
  };
  const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays);
    return blob;
  }
  const handleChangeImage = async (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      const fileURL = URL.createObjectURL(files[0]);
      setImageProfile(fileURL);
      const fileBase64 = await toBase64(files[0]);
      const finalFile = fileBase64.substr(fileBase64.indexOf(',') + 1, fileBase64.length);
      const val = { ...personalDataRequest };
      val.logo = finalFile;
      setPersonalDataRequest(val);
    }
  };
  const handleChangeIdentityImage = async (event) => {
    const files = event.target.files;
    const val = { ...personalDocumentDataRequest };
    if (files.length > 0) {
      const fileBase64 = await toBase64(files[0]);
      const finalFile = fileBase64.substr(fileBase64.indexOf(',') + 1, fileBase64.length);
      val.identityImage = finalFile;
    } else {
      val.identityImage = val.identityImage || null;
    }
    setPersonalDocumentDataRequest(val);
  };
  const handleChangeCRImage = async (event) => {
    const files = event.target.files;
    const val = { ...personalDocumentDataRequest };
    if (files.length > 0) {
      const fileBase64 = await toBase64(files[0]);
      const finalFile = fileBase64.substr(fileBase64.indexOf(',') + 1, fileBase64.length);
      val.cnImage = finalFile;
    } else {
      val.cnImage = val.cnImage || null;
    }
    setPersonalDocumentDataRequest(val);
  };
  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
  const isPasswordMatched = async () => {
    let isMatched = false;
    try {
      const response = await axios.post(
        config.baseURL + "details",
        {
          "Object": "Web_Users",
          "ObjectSettings": {
            "MetaData": false
          },
          "Option": "Column",
          "Fields": "DescriptionAr",
          "Filters": `where GUID = '${getData("secretKey")}'  and password = '${crypto.MD5(requestChangePassword?.oldPassword).toString()}'`
        }
      );
      if (response.status == 200) {
        isMatched = response.data.ApiObjectData?.length != 0;
      }
      return isMatched;
    } catch (err) {
      console.log(err);
    }
  };
  // to change the current user password
  const handleChangePassword = async () => {
    const result = formValidate(schemaPassword, requestChangePassword) ?? {};
    setPasswordError(result);
    console.log("userHasNoPassword:>>>>>>>>>>>>>>>>",userHasNoPassword);
    if (Object.keys(result).length == 0) {
      setIsLoading2(true);
      if (userHasNoPassword) {
        axios.post(
          config.baseURL + 'func',
          {
            "name": "SiteCheckUserHasNoPassword",
            "values": {
              "guid": `${getData('secretKey')}`
            }
          }
        ).then(response => {

          console.log("SiteCheckUserHasNoPassword:>>>>>>>>>>>>>>>>",response?.data);
          console.log("acceptPasswordStatus:>>>>>>>>>>>>>>>>",acceptPasswordStatus);
          if (response?.data) {
            if (acceptPasswordStatus) {
              axios.put(
                config.baseURL + "update",
                {
                  "Object": "Web_users",
                  "Values": {
                    "Password": crypto.MD5(requestChangePassword.newPassword).toString()
                  },
                  "Filters": `where GUID = '${getData("secretKey")}'`
                }
              ).then((response) => {
                toast.success(trans("common:reset-password-successfully"));
                setPasswordError({});
                setRequestChangePassword({
                  oldPassword: '',
                  newPassword: '',
                  confirmPassword: ''
                });
                setShowResetPasswordBox(false);
              }).catch((err) => {
                if (err.response && err.response.status == 400) {
                  toast.error("error:unknown_failure");
                }
              });
            }
          } else {
            toast.error(trans("error2:user_has_pre_password"))
          }
        }).catch(err => {
          console.log(err);
        });
      } else {
        if ((await isPasswordMatched())) {
          if (acceptPasswordStatus) {
            axios.put(
              config.baseURL + "update",
              {
                "Object": "Web_users",
                "Values": {
                  "Password": crypto.MD5(requestChangePassword.newPassword).toString()
                },
                "Filters": `where GUID = '${getData("secretKey")}'`
              }
            ).then((response) => {
              if (response.status == 200) {
                toast.success(trans("common:reset-password-successfully"));
                setPasswordError({});
                setRequestChangePassword({
                  oldPassword: '',
                  newPassword: '',
                  confirmPassword: ''
                });
                setShowResetPasswordBox(false);
              }
            }).catch((err) => {
              if (err.response && err.response.status == 400) {
                toast.error("error:unknown_failure");
              }
            });
          }
        } else {
          toast.error(trans("common:wrong-password"));
        }
      }
    }
    setIsLoading2(false);
  };
  // to change personal data
  const handleChangePersonalData = () => {
    const result = formValidate(schemaSection1, personalDataRequest) ?? {};
    setError(result);
    if (Object.keys(result).length == 0) {
      if (readOnly) {
        toast.error(trans("common:enable-read-only"));
        return;
      }
      setIsLoading(true);
      const userKey = getData('secretKey');
      axios.put(
        config.baseURL + "update",
        {
          "Object": "Web_users",
          "Values": {
            "firstNameAr": personalDataRequest?.arFirstName.trim(),
            "middleNameAr": personalDataRequest?.arMiddleName.trim(),
            "lastNameAr": personalDataRequest?.arLastName.trim(),
            "firstNameEn": personalDataRequest?.enFirstName.trim(),
            "middleNameEn": personalDataRequest?.enMiddleName.trim(),
            "lastNameEn": personalDataRequest?.enLastName.trim(),
            "GenderID": (personalDataRequest?.genderID != '' && personalDataRequest?.genderID != undefined) ? Number(personalDataRequest?.genderID) : Number(genderList[0].value),
            "Address": personalDataRequest.address ?? '',
            "Logo": personalDataRequest?.logo,
            "countryID": personalDataRequest?.countryID ?? null,
            "cityID": personalDataRequest?.cityID ?? null
          },
          "Filters": `where GUID = '${userKey}'`
        }).then((response) => {
          if (response.status == 200) {
            toast.success(trans("common:update-successful"));
            setIsLoading(false);
            setReadOnly(true);
            setEditableStatus(0);
            saveData(User_Data_Key, personalDataRequest, true);
            window.location = '/user/profile';
          }
        }).catch(error => {
          console.log(error);
          setIsLoading(false);
        });
    }
  };
  // to change user phone number
  const handleChangePhoneNumber = async () => {
    const result = formValidate(schemaPhoneNumber, personalPhoneNumber) ?? {};
    setPhoneError(result);
    if (Object.keys(result).length == 0) {
      try {
        const response = await axios.post(
          config.baseURL + 'func',
          {
            "Name": "SiteNewPhoneNumberExisting",
            "Values": {
              "phoneNumber": `${personalPhoneNumber.phoneNumber}`
            }
          }
        );
        if (response.status == 200 ) {
          if (response.data) {
            toast.error(trans("error2:phone_number_existing"));
          } else {
            // the following code is to change the phone number
            axios.put(
              config.baseURL + 'update',
              {
                "Object": "Web_users",
                "Values": {
                  "Phone": `${personalPhoneNumber.phoneNumber}`
                },
                "Filters": `where GUID = '${getData("secretKey")}'`
              }
            ).then(responseData => {
              if (responseData.status == 200) {
                toast.success(trans("info:success_update_phone"));
                const currentValue = JSON.parse(getData(User_Data_Key, true));
                currentValue.phone = personalPhoneNumber.phoneNumber;
                saveData(User_Data_Key, currentValue, true);
                window.location = '/user/profile'
              }
            }).catch(error => {
              console.log(error);
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  // to chnage user email
  const handleChangeEmail = async () => {

    const result = formValidate(schemaEmail, personalEmailDataRequest) ?? {};
    setEmailError(result);
    if (Object.keys(result).length == 0) {
      try {
        const response = await axios.post(
          config.baseURL + 'func',
          {
            "Name": "SiteNewEmailExisting",
            "Values": {
              "email": `${personalEmailDataRequest.email.trim()}`
            }
          }
        );
        if (response.status == 200) {
          if (response.data) {
            toast.error(trans("error2:email_existing"));
          } else {
            // the following code is to change the phone number
            axios.put(
              config.baseURL + 'update',
              {
                "Object": "Web_users",
                "Values": {
                  "email": `${personalEmailDataRequest.email.trim()}`
                },
                "Filters": `where GUID = '${getData("secretKey")}'`
              }
            ).then(responseData => {
              toast.success(trans("info:success_update_email"));
              const currentValue = JSON.parse(getData(User_Data_Key, true));
              currentValue.email = personalEmailDataRequest.email;
              saveData(User_Data_Key, currentValue, true);
              window.location = '/user/profile'
            }).catch(error => {
              console.log(error);
            });

          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  // to change the identity info for the user
  const handleChangeIdentity = async () => {
    const result = formValidate(schemaIdentity, personalIdentityDataRequest) ?? {};
    setIdentityError(result);
    if (Object.keys(result).length == 0) {
      try {
        const response = await axios.post(
          config.baseURL + 'func',
          {
            "Name": "SiteNewIdentityExisting",
            "Values": {
              "identity": `${personalIdentityDataRequest.identity}`,
              "guid": `${getData("secretKey")}`
            }
          }
        );
        if (response.status == 200) {
          if (response.data) {
            toast.error(trans("error2:identity_existing"));
          } else {
            axios.put(

              config.baseURL + 'update',
              {
                "Object": "Web_users",
                "Values": {
                  "IdentityNumber": `${personalIdentityDataRequest.identity}`,
                  "IdentityImage": personalIdentityDataRequest.identityImage
                },
                "Filters": `where GUID = '${getData("secretKey")}'`
              }
            ).then(responseData => {

              toast.success(trans("common:update-successful"));
              const currentValue = JSON.parse(getData(User_Data_Key, true));
              currentValue.identity = personalIdentityDataRequest.identity;
              currentValue.identityImage = personalIdentityDataRequest.identityImage;
              saveData(User_Data_Key, currentValue, true);
              window.location = '/user/profile'
            }).catch(error => {
              console.log(error);
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  // to handle the identity type [cn-identity]
  const handleChangeIdentityType = async () => {
    const val = { ...personalDocumentDataRequest };
    const result = formValidate(schemaDocument, val);
    setIsLoading(true);
    if (freazeType) {
      if (identityType == 1) {
        axios.put(
          config.baseURL + 'update',
          {
            "Object": "Web_users",
            "Values": {
              "CRImage": personalDocumentDataRequest.cnImage
            },
            "Filters": `where GUID = '${getData("secretKey")}'`
          }
        ).then(response => {
          setIsLoading(false);
          toast.success(trans("common:update-successful"));
          const currentValue = JSON.parse(getData(User_Data_Key, true));
          currentValue.identityImage = personalDocumentDataRequest.identityImage;
          saveData(User_Data_Key, currentValue, true);
          window.location = '/user/profile';
        }).catch(err => {
          console.log(err);
          setIsLoading(false);
        })
      } else if (identityType == 2) {
        axios.put(
          config.baseURL + 'update',
          {
            "Object": "Web_users",
            "Values": {
              "CRImage": personalDocumentDataRequest.cnImage
            },
            "Filters": `where GUID = '${getData("secretKey")}'`
          }
        ).then(response => {
          setIsLoading(false);
          toast.success(trans("common:update-successful"));
          const currentValue = JSON.parse(getData(User_Data_Key, true));
          currentValue.tradeNoImage = personalDocumentDataRequest.cnImage;
          saveData(User_Data_Key, currentValue, true);
          window.location = '/user/profile';
        }).catch(err => {
          console.log(err);
          setIsLoading(false);
        })
      }
    } else {
      let values = {};
      let options = [];
      let jsonRequest;
      let messageError;
      // identity card
      if (identityType == 1) {
        messageError = 'error2:identity_existing';
        options = ['identityNo', 'custGroupID'];
        values = {
          "Object": "Web_users",
          "Values": {
            "CustGroupID": personalDocumentDataRequest.custGroupID,
            "IdentityNumber": personalDocumentDataRequest.identityNo,
            "IdentityImage": personalDocumentDataRequest.identityImage
          },
          "Filters": `where GUID = '${getData("secretKey")}'`
        };
        // ----------------------------------------------------
        jsonRequest = {
          "Name": "SiteNewIdentityExisting",
          "Values": {
            "identity": `${personalDocumentDataRequest.identityNo}`,
            "guid": `${getData("secretKey")}`
          }
        };
      } else if (identityType == 2) {
        messageError = 'error2:cr_existing';
        options = ['cn', 'custGroupID'];
        values = {
          "Object": "Web_users",
          "Values": {
            "CustGroupID": personalDocumentDataRequest.custGroupID,
            "TradeNo": personalDocumentDataRequest.cn,
            "TradeNoImage": personalDocumentDataRequest.cnImage
          },
          "Filters": `where GUID = '${getData("secretKey")}'`
        };
        jsonRequest = {
          "Name": "SiteNewCRExisting",
          "Values": {
            "identity": `${personalDocumentDataRequest.cn}`,
            "guid": `${getData("secretKey")}`
          }
        };
      }
      const newErrors = removeUnspecific2Error(result, options);
      setErrorDocument(newErrors);
      const _isFound = await isFound(jsonRequest);
      if (!_isFound) {
        if (Object.keys(newErrors).length === 0) {
          axios.put(
            config.baseURL + 'update',
            values
          ).then(response => {
            setIsLoading(false);
            toast.success(trans("common:update-successful"));
            const currentValue = JSON.parse(getData(User_Data_Key, true));
            currentValue.tradeNoImage = personalDocumentDataRequest.cnImage;
            currentValue.tradeNo = personalDocumentDataRequest.cn;
            currentValue.identity = personalDocumentDataRequest.identityNo;
            currentValue.identityImage = personalDocumentDataRequest.identityImage;
            currentValue.custGroupID = personalDocumentDataRequest.custGroupID;
            saveData(User_Data_Key, currentValue, true);
            window.location = '/user/profile';
          }).catch(err => {
            console.log(err);
            setIsLoading(false);
          })
        }
      } else {
        toast.error(trans(messageError));
      }
    }
  };
  // to remove the unneeded props
  const removeUnspecific2Error = (errorList, values = []) => {
    let errorResult = {};
    if (values?.length > 0) {
      const lowerValues = values.map(item => {
        return item.toLowerCase()
      });
      Object.assign(errorResult, errorList);
      if (errorList) {
        for (let index = 0; index < Object.keys(errorList).length; index++) {
          const key = Object.keys(errorList)[index];
          if (includes(lowerValues, key.toLowerCase())) {
            continue;
          } else {
            delete errorResult[key];
          }
        }
      }
    }
    return errorResult;
  };

  const getCorrectIdentitySection = () => {
    let result = <></>;
    // for identity card
    if (identityType == 1) {
      result = <>
        <div className="nice-col-md-4">
          <InputField
            disabled={freazeType}
            value={personalDocumentDataRequest.identityNo}
            errorLabel={errorDocument?.identityNo}
            placeholder={trans("hints:identity_hint")}
            name='identityNo'
            length={10}
            onChangeValue={(e) => {
              const val = { ...personalDocumentDataRequest };
              val.identityNo = e.target.value;
              setPersonalDocumentDataRequest(val);
            }}
            title={trans("form:identity-no")}
          />
        </div>
        <div className="nice-col-md-4">
          <FileInput
            title={trans("info:identity_image")}
            mimeType="image/*"
            onPicker={async (file) => {
              await handleChangeIdentityImage(file);
            }}
          />
          {
            personalDocumentDataRequest?.identityImage && <> <div className="text-inline-block text-primary text-14 text-bold nice-pointer mb-2" onClick={() => {
              setShowIdentityImage(!showIdentityImage);
            }}>
              {trans("user:show")}
            </div>
              {showIdentityImage && <img src={"data:image/png;base64," + personalDocumentDataRequest?.identityImage} className="text-14 text-block text-primary mb-3" />}
            </>
          }
        </div>
      </>
      // for commercial no
    } else if (identityType == 2) {
      result = <>
        <div className="nice-col-md-4">
          <InputField
            disabled={freazeType}
            value={personalDocumentDataRequest.cn}
            errorLabel={errorDocument?.cn}
            placeholder={trans("hints:cr_hint")}
            name='cr'
            length={10}
            onChangeValue={(e) => {
              const val = { ...personalDocumentDataRequest };
              val.cn = e.target.value;
              setPersonalDocumentDataRequest(val);
            }}
            title={trans("user:cn")}
          />
        </div>
        <div className="nice-col-md-4">
          <FileInput
            title={trans("user:cn_image")}
            mimeType="image/*"
            onPicker={async (file) => {
              await handleChangeCRImage(file);
            }}
          />
          {
            personalDocumentDataRequest?.cnImage && <> <div className="text-inline-block text-primary text-14 text-bold nice-pointer mb-2" onClick={() => {
              setShowIdentityImage(!showIdentityImage);
            }}>
              {trans("user:show")}
            </div>
              {showIdentityImage && <img src={"data:image/png;base64," + personalDocumentDataRequest?.cnImage} className="text-14 text-block text-primary mb-3" />}
            </>
          }
        </div>
      </>
    }
    return result;
  };

  // to check if the identity value is existing here includes [cr - identity type]
  const isFound = async (jsonRequest) => {
    let isExisting = false;
    try {
      const response = await axios.post(
        config.baseURL + 'func',
        jsonRequest
      );
      if (response.status === 200) {
        isExisting = response.data;
      }
    } catch (err) {
      console.log(err);
    }
    return isExisting;
  };


  return (
    <div className={'profile-box  mb-3 ' + languageDir}>
      <div className="profile-box-sections">
        <Tabs selectedTabClassName='tab-active' defaultIndex={0} onSelect={(position) => {
        }}>
          <TabList className='private-vehicle-tabs-container bg-white-shadow p-4'>
            <Tab className='text-size-16r'>
              {trans("user:profile")}
            </Tab>
            <span className='vertical-separater'></span>
            <Tab className='text-size-16r'>
              {trans("user:contact_info")}
            </Tab>
            <span className='vertical-separater'></span>
            <Tab className='text-size-16r'>
              {trans("user:secret_info")}
            </Tab>
          </TabList>
          <TabPanel className='nice-container-fluid'>
            <div className='profile-main-content mt-5'>
              <div className='profile-main-content-image' style={{ backgroundImage: `url('${imageProfile}')` }}>
                <div className='profile-main-content-image-cover'>
                  <span className='profile-upload-btn' onClick={handlePicker}>
                    <img src='/assets/icons/upload.svg' width='24' height='24' />
                    <input type='file' ref={fileInputRef} accept="image/*" style={{ display: 'none' }}
                      onChange={(e) => handleChangeImage(e)}
                    />
                  </span>
                </div>
              </div>
              <div className='text-14 my-3 text-gray text-left'>{selectedGender?.text ?? ''}</div>
              <div className='text-14 my-4 text-gray text-left'>{personalDataRequest?.email ?? ''}</div>
              <div className='text-14 text-gray text-left'>{personalDataRequest?.phone ?? ''}</div>
            </div>
            <SectionHeading
              defaultStatus={editableStatus}
              editable={true}
              onEdit={(status) => {
                if (status == 0) {
                  intialUserData(genderList);
                  setReadOnly(true);
                  setEditableStatus(1);
                } else {
                  setEditableStatus(0);
                  setReadOnly(false);
                }

              }}
              title={trans("user:private_info")}
              infoText={trans("user:private_info_note")}
              hasRuler={true}
            >
              <div className="nice-row my-5">
                <div className="nice-col-md-4 nice-col-sm-4">
                  <InputField
                    disabled={readOnly}
                    errorLabel={error?.arFirstName}
                    length={50}
                    value={personalDataRequest.arFirstName}
                    name='arFirstName'
                    onChangeValue={(e) => {
                      const val = { ...personalDataRequest };
                      val.arFirstName = e.target.value;
                      setPersonalDataRequest(val);
                    }}
                    title={trans("form:first-name") + ' (' + trans('user:ar') + ') '}
                  />
                </div>
                <div className="nice-col-md-4 nice-col-sm-4">
                  <InputField
                    disabled={readOnly}
                    errorLabel={error?.arMiddleName}
                    name='arMiddleName'
                    length={50}
                    value={personalDataRequest.arMiddleName}
                    onChangeValue={(e) => {
                      const val = { ...personalDataRequest };
                      val.arMiddleName = e.target.value;
                      setPersonalDataRequest(val);
                    }}
                    title={trans("info:middle-name") + ' (' + trans('user:ar') + ') '}
                  />
                </div>
                <div className="nice-col-md-4 nice-col-sm-4">
                  <InputField
                    disabled={readOnly}
                    errorLabel={error?.arLastName}
                    name='arLastName'
                    length={50}
                    value={personalDataRequest.arLastName}
                    onChangeValue={(e) => {
                      const val = { ...personalDataRequest };
                      val.arLastName = e.target.value;
                      setPersonalDataRequest(val);
                    }}
                    title={trans("info:surname") + ' (' + trans('user:ar') + ') '}
                  />
                </div>
              </div>
              <div className="nice-row my-5">
                <div className="nice-col-md-4 nice-col-sm-4">
                  <InputField
                    disabled={readOnly}
                    errorLabel={error?.enFirstName}
                    length={50}
                    value={personalDataRequest.enFirstName}
                    name='enFirstName'
                    onChangeValue={(e) => {
                      const val = { ...personalDataRequest };
                      val.enFirstName = e.target.value;
                      setPersonalDataRequest(val);
                    }}
                    title={trans("form:first-name") + ' (' + trans('user:en') + ') '}
                  />
                </div>
                <div className="nice-col-md-4 nice-col-sm-4">
                  <InputField
                    disabled={readOnly}
                    errorLabel={error?.enMiddleName}
                    name='enMiddleName'
                    length={50}
                    value={personalDataRequest.enMiddleName}
                    onChangeValue={(e) => {
                      const val = { ...personalDataRequest };
                      val.enMiddleName = e.target.value;
                      setPersonalDataRequest(val);
                    }}
                    title={trans("info:middle-name") + ' (' + trans('user:en') + ') '}
                  />
                </div>
                <div className="nice-col-md-4 nice-col-sm-4">
                  <InputField
                    disabled={readOnly}
                    errorLabel={error?.enLastName}
                    name='enLastName'
                    length={50}
                    value={personalDataRequest.enLastName}
                    onChangeValue={(e) => {
                      const val = { ...personalDataRequest };
                      val.enLastName = e.target.value;
                      setPersonalDataRequest(val);
                    }}
                    title={trans("info:surname") + ' (' + trans('user:en') + ') '}
                  />
                </div>
              </div>
              <div className="nice-row my-5">
                <div className="nice-col-md-4 nice-col-sm-4">
                  <DropDownInput
                    defaultOption={selectedGender ?? genderList[0]}
                    onSelect={(option) => {
                      const val = { ...personalDataRequest };
                      val.genderID = String(option);
                      setPersonalDataRequest(val);
                    }}
                    selectedValue={personalDataRequest.genderID}
                    options={[...genderList]}
                    title={trans("form:gender")}
                  />
                </div>
                <div className="nice-col-md-4 nice-col-sm-4">

                  <DropDownInput
                    defaultOption={defaultGeoLocation?.country?.value}
                    errorLabel={error?.countryID}
                    title={trans("extra:country")}
                    onSelect={(option) => {
                      const val = { ...personalDataRequest };
                      val.countryID = String(option);
                      setPersonalDataRequest(val);
                    }}
                    selectedValue={personalDataRequest.countryID}
                    options={[...settings?.countries?.map(item => {
                      return { text: item?.description, value: item?.id }
                    })]}
                    onfilter={(value) => {
                      dropDownResetter(() => {
                        setFilteredCities([]);
                        setDefaultGeoLocation(null);
                      });
                      if (value.value === '') {
                        dropDownResetter(() => {
                          setFilteredCities([]);
                          setDefaultGeoLocation(null);
                        });
                        return;
                      }
                      setFilteredCities(settings?.cities?.filter(item => {
                        return item.code == value?.value
                      }));
                    }}
                  />
                </div>

                <div className="nice-col-md-4 nice-col-sm-4">

                  <DropDownInput
                    defaultOption={defaultGeoLocation?.city?.value}
                    errorLabel={error?.cityID}
                    selectedValue={personalDataRequest.cityID}
                    onSelect={(option) => {
                      const val = { ...personalDataRequest };
                      val.cityID = String(option);
                      setPersonalDataRequest(val);
                    }}
                    options={[...filteredCities?.map(item => {
                      return { text: item?.description, value: item?.id, optional: item?.code }
                    })]}
                    title={trans("extra:city")}
                  />
                </div>
                <div className="nice-col-md-4 nice-col-sm-4">
                  <InputField
                    length={1000}
                    disabled={readOnly}
                    value={personalDataRequest.address}
                    title={trans("extra:street")}
                    onChangeValue={(e) => {
                      const val = { ...personalDataRequest };
                      val.address = e.target.value;
                      setPersonalDataRequest(val);
                    }}
                  />
                </div>

              </div>
              <div className="nice-row my-5">
                <div className="nice-col-sm-2">
                  <FormButton name={trans("info:save_changes")} onClick={handleChangePersonalData} isLoading={isLoading} />
                </div>
              </div>
            </SectionHeading>
            <SectionHeading
              hasRuler={false}
              title={trans('user:identity_data')}
              infoText={trans('user:identity_card_note')}
            >
              <div className="nice-row my-5">
                <div className="nice-col-md-4">
                  <DropDownInput
                    force={true}
                    errorLabel={errorDocument?.custGroupID}
                    defaultOption={defaultIdentityType}
                    disabled={freazeType}
                    title={trans("info:account_type")}
                    options={[
                      ...accountTypes.map(item => {
                        if (lang === 'ar') {
                          return { text: item.DescriptionAr, value: item.ID, isClient: item.NeedIdentity }
                        } else {
                          return { text: item.DescriptionEn, value: item.ID, isClient: item.NeedIdentity }
                        }
                      })]}
                    selectedValue={personalDocumentDataRequest.custGroupID}
                    onfilter={(option) => {
                      if (option.value == '' || option.value == '0') {
                        setIdentityType(null);
                        return;
                      } else {
                        const { isClient, value } = { ...option };
                        setIdentityType(isClient ? 1 : 2);
                        const val = { ...personalDocumentDataRequest };
                        val.custGroupID = value;
                        setPersonalDocumentDataRequest(val);
                      }
                    }}
                  />
                </div>
                {getCorrectIdentitySection()}
              </div>
              <div className="nice-row my-5">
                <div className="nice-col-sm-2">
                  <FormButton name={trans("form:save")} onClick={handleChangeIdentityType} isLoading={isLoading} />
                </div>
              </div>
            </SectionHeading>
          </TabPanel>
          <TabPanel className='nice-container-fluid'>
            <SectionHeading
              hasRuler={false}
              title={trans('user:contact_info')}
            >
              <div className="nice-row my-5">
                <div className="nice-col-md-6 flex flex-col nice-gap-2rem">
                  <InputField
                    extraClasses='text-left'
                    disabled={true}
                    readOnly={true}
                    type="currentPhone"
                    value={personalContactDataRequest.phoneNumber}
                    name='currentPhone'
                    onChangeValue={(e) => {
                    }}
                    title={trans("user:current_phone")}
                  />
                  <div className="text-inline-block text-gray text-14 text-bold nice-pointer mb-2" onClick={() => {
                    setChangePhoneNumber(!changePhoneNumber);
                  }}>
                    {trans("user:update_phone_note")}
                  </div>
                  {changePhoneNumber && <> <PhoneDropDown
                    title={trans("user:new_phone_number")}
                    errorLabel={phoneError?.phoneNumber}
                    onChangeValue={(countryCode, phoneNumber) => {
                      setPersonalPhoneNumber({
                        phoneNumber: `+${countryCode}${phoneNumber}`
                      });
                    }}
                  />
                    <div className="nice-row mb-3">
                      <div className="nice-col-sm-3">
                        <FormButton name={trans("form:save")} onClick={handleChangePhoneNumber} isLoading={isLoading} />
                      </div>
                    </div>
                  </>}
                </div>
                <div className="nice-col-md-6 flex flex-col nice-gap-2rem">
                  <InputField
                    readOnly={true}
                    disabled={true}
                    type="email"
                    value={personalContactDataRequest.email}
                    name='email'
                    onChangeValue={(e) => {
                    }}
                    title={trans("form:email")}
                  />
                  <div className="text-inline-block text-gray text-14 text-bold nice-pointer mb-2" onClick={() => {
                    setChangeEmail(!changeEmail);
                  }}>
                    {trans("user:update_email_note")}
                  </div>
                  {changeEmail && <>
                    <InputField
                      errorLabel={emailError?.email}
                      placeholder={trans("hints:email_hint")}
                      type="email"
                      value={personalEmailDataRequest.email}
                      name='email'
                      onChangeValue={(e) => {
                        const val = { ...personalEmailDataRequest };
                        val.email = e.target.value;
                        setPersonalEmailDataRequest(val);
                      }}
                      title={trans("form:email") + ' (' + trans("info:new") + ')'}
                    />
                    <div className="nice-row mb-3">
                      <div className="nice-col-sm-3">
                        <FormButton name={trans("form:save")} onClick={handleChangeEmail} isLoading={isLoading} />
                      </div>
                    </div>
                  </>}
                </div>
              </div>
            </SectionHeading>
          </TabPanel>
          <TabPanel className='nice-container-fluid'>
            <SectionHeading
              hasRuler={false}
              title={trans("user:change_password_note")}
            >
              <div className="nice-row my-5">
                <div className="nice-col-md-6 flex flex-col nice-gap-2rem" >
                  <InputField
                    value={requestChangePassword?.oldPassword}
                    type='password'
                    name='oldPassword'
                    errorLabel={passwordError?.oldPassword}
                    onChangeValue={(e) => {
                      const val = { ...requestChangePassword };
                      val.oldPassword = e.target.value.trim();
                      setRequestChangePassword(val);
                    }}
                    title={trans("form:user-current-password")}
                  />
                  <CheckInput
                    oncheng={
                      (value) => {
                        setUserHasNoPassword(value.checked);
                      }
                    }
                  >
                    <span className='text-14 nice-pointer text-inline-block'>{trans("info:user_has_password")}</span>
                  </CheckInput>
                </div>
                <div className="nice-col-md-6 my-5">

                </div>
                <div className="nice-col-md-6 my-5">
                  <InputField
                    value={requestChangePassword?.newPassword}
                    name='newPassword'
                    passwordStrength={true}
                    type='password'
                    onAcceptPassword={(status) => {
                      setAcceptPasswordStatus(status);
                    }}
                    errorLabel={passwordError?.newPassword}
                    onChangeValue={(e) => {
                      const val = { ...requestChangePassword };
                      val.newPassword = e.target.value;
                      setRequestChangePassword(val);
                    }}
                    title={trans("form:user-new-password")}
                  />
                </div>
                <div className="nice-col-md-6 my-5">

                </div>
                <div className="nice-col-md-6 my-5">
                  <InputField
                    value={requestChangePassword?.confirmPassword}
                    name='confrimPassword'
                    type='password'
                    onChangeValue={(e) => {
                      const val = { ...requestChangePassword };
                      val.confirmPassword = e.target.value;
                      setRequestChangePassword(val);
                    }}
                    title={trans("form:confirm-password")}
                  />
                </div>
              </div>
              <div className="nice-row my-5">
                <div className="nice-col-sm-2">
                  <FormButton name={trans("info:save_changes")} isLoading={isLoading2} onClick={handleChangePassword} />
                </div>
              </div>
            </SectionHeading>
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
};
