import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function SectionHeading({ title, hasRuler = true, onEdit, editable = false, infoText = null,className, ...props }) {
    const { t: trans,i18n } = useTranslation("info");
    const [status,SetStatus] = useState(0);
    let lang =(i18n.language || 'ar');
    let languageDir = "language-" + lang;
    return (
        <div className={languageDir +" " +className}>
            <div className="nice-flex nice-flex-space ">
                <p className={`text-16 text-bold my-4 px-3 border-section-${lang == 'en' ? 'left' : 'right'}`}>{title}</p>
                { editable && <div className="nice-edit-btn  text-16 text-bold  p-3 my-3" onClick={()=>{
                const newStatus = status == 0 ? 1:0;
                SetStatus(newStatus);
                if(onEdit){
                    onEdit(newStatus);
                }
            }}>
                    {trans(`info:${status == 0 ? 'edit' : 'back'}`)}
                </div>
                }
            </div>

            {
                infoText && <div className="mx-2 mb-5 ">
                    <img src='/assets/icons/info.svg' className="mx-3 text-inline-block nice-max-icon" />
                    <p className="text-12 text-bold text-gray text-inline-block">{infoText}</p>
                </div>
            }
            {
                props.children
            }
            {hasRuler && <div className="ruler-2 mb-3"></div>}
        </div>
    )
}