import ErrorLabel from '../../components/error';
import { useState } from "react";
import { useTranslation } from 'react-i18next';

export function InputField({
    name,
    id = null,
    readOnly = false,
    disabled = false,
    type = 'text',
    onChangeValue,
    onKeyDown,
    onLeave,
    onKeyPress,
    title,
    errorLabel,
    placeholder,
    secondary = false,
    value,
    required = false,
    length = 50,
    extraClasses = '',
    passwordStrength = false,
    onAcceptPassword,
    searchIcon = false,
    onClicksearchIcon,
    moduleLabel,
    ...props
}) {

    const { t: trans, i18n } = useTranslation("info");
    let lang = (i18n.language || 'ar');
    const languageDir = "language-" + lang;
    const [validationMessage, setValidationMessage] = useState('');
    const [strength, setStrength] = useState(
        {
            weak: false,
            middle: false,
            powerful: false
        }
    );
    // to handle the password strength 
    const handlePasswordStrength = (event) => {
        const _value = event.target.value;
        const _numericPattern = /[0-9]{3,}/g;
        const _alphaCapitalPattern = /[A-Z]{1,}/g
        const _alphaSamllPattern = /[a-z]{4,}/g;
        const _simplePattern = /[!@#\$%\^\&*\)\(+=._-]{1,}/g;
        try {
            const val = { ...strength };
            let strengthCount = 0;
            let _message = trans("info:password_accept_note");

            if (!_numericPattern.test(_value)) {
                _message += ' ' + trans("info:password_accept_num");
            } else if (!_alphaCapitalPattern.test(_value)) {
                _message += ' ' + trans("info:password_accept_capital_alph");
            } else if (!_alphaSamllPattern.test(_value)) {
                _message += ' ' + trans("info:password_accept_small_alph");
            } else if (!_simplePattern.test(_value)) {
                _message += ' ' + trans("info:password_accept_simple");
            } else {
                _message = '';
            }
            if (_value?.search(_numericPattern) != -1) {
                strengthCount += 1;
            }
            if (_value?.search(_alphaCapitalPattern) != -1) {
                strengthCount += 1;
            }
            if (_value?.search(_alphaSamllPattern) != -1) {
                strengthCount += 1;
            } if (_value?.search(_simplePattern) != -1) {
                strengthCount += 1;
            }
            switch (strengthCount) {
                case 1:
                    val.weak = true;
                    val.middle = false;
                    val.powerful = false;
                    break;
                case 2:
                    val.weak = true;
                    val.middle = true;
                    val.powerful = false;
                    break;
                case 3:
                    val.weak = true;
                    val.middle = true;
                    val.powerful = false;
                    break;
                case 4:
                    val.weak = true;
                    val.middle = true;
                    val.powerful = true;
                    break;
                default:
                    val.weak = false;
                    val.middle = false;
                    val.powerful = false;
            }
            setStrength(val);
            setValidationMessage(_message);
            if (onAcceptPassword) {
                if (val.weak && val.middle) {
                    onAcceptPassword(true);
                } else {
                    onAcceptPassword(false);
                }
            }
        } catch (error) {
            console.log(error);
        }
    };
    return (
        <div className={""}>

            <div title={placeholder} className={"input " + languageDir + ' ' + (secondary && "input-secondary")}>
                {
                    title &&
                    <label className={"input-label"} htmlFor={name}>{title}</label>
                }


                <input disabled={disabled}
                    {
                    ...props
                    }
                    onKeyDown={(event) => {
                        if (onKeyDown)
                            onKeyDown(event)

                        if (event.key === "ArrowUp" || event.key === "ArrowDown") {
                            event.preventDefault(); // Prevent increment/decrement on arrow keys
                          }
                    }}
                    onKeyPress={(event) => {
                        if (onKeyPress)
                            onKeyPress(event)
                    }}
                    onBlur={(event) => {
                        if (onLeave)
                            onLeave(event)
                    }}
                    id={id}
                    name={name}
                    maxLength={length}
                    readOnly={readOnly}
                    onChange={(e) => {
                        if (onChangeValue)
                            onChangeValue(e);
                        if (passwordStrength) {
                            handlePasswordStrength(e);
                        }
                    }}
                    // onLeave={(e) => {
                    //     if (onLeave)
                    //         onLeave(e)
                    // }}
                    className={"input-field " + extraClasses + " " + (disabled && " bg-gray-light ")}
                    placeholder={placeholder}
                    required={required}
                    type={type}
                    value={value} />

                {
                    moduleLabel &&
                    <label for="input-field" className={'Input-module__label ' + (placeholder ? " input-has-placeholder " : "")}>{moduleLabel}</label>
                }


                {(searchIcon) &&

                    <div className='input-field-search-Icon'>
                        <button class={" border-radius " + (value ? "bg-primary" : "bg-gray-light") + " flex justify-center items-center"}

                            onClick={() => {

                                if (onClicksearchIcon)
                                    onClicksearchIcon(value);
                            }}

                        >

                            <img src="/assets/icons/search.svg" alt="search"></img>
                        </button>

                    </div>
                }

                {(type == 'password' && passwordStrength) && <div className='nice-flex nice-flex-column'>
                    <div className='nice-flex nice-flex-row my-2'>
                        <div className={`box-strength ${strength.weak && 'bg-danger'}`}></div>
                        <div className={`box-strength  ${strength.middle && 'bg-warning'}`}></div>
                        <div className={`box-strength  ${strength.powerful && 'bg-primary'}`}></div>
                    </div>
                    <p className='text-14 text-gray'>{validationMessage}</p>
                </div>
                }
            </div>
            <ErrorLabel errorTitle={errorLabel} />
        </div >
    );
}
