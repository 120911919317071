import { Fragment, useEffect, useState } from "react";
import SwiperCore, { Pagination, Navigation, Autoplay, EffectFade } from "swiper";
import { useTranslation } from "react-i18next";
import Banner from "../../components/banner";
import { WhyHJSection } from "../../sections/whyHJSection";
import { TestimonialSection } from "../../sections/testimonialSection";
import Breadcrumb from "../../components/__simple/breadcrumb";
import axios from "axios";
import AwardsSection from "./components/about-awards";
import VisionsSection from "./components/about-visions";
import CounterSection from "./components/about-counter";
import { Helmet } from "react-helmet";
import config from '../../configuration/config.json';

SwiperCore.use([Pagination, Navigation, Autoplay, EffectFade]);

export function AboutPage() {
    const { t: trans ,i18n} = useTranslation(["common", "about"]);

    const [banner, setBanner] = useState({});
    const [awards, setAwards] = useState([]);
    const [visions, setVisions] = useState([]);
    const [about, setAbout] = useState({});

    let lang = (i18n.language || 'ar');
    const languageDir = 'language-' + lang;
    useEffect(async () => {
        try {

            const response = await axios.request({
                baseURL: config.controllURL,
                url: lang + "/api/About/index",
                method: "get",
            });

            setBanner(response.data.banner)
            setVisions(response.data.visions)
            setAwards(response.data.awards)
            setAbout(response.data.about)

        } catch (error) {

        }
    }, [])


    const [seoPageInfo, setSeoPageInfo] = useState({});
    useEffect(async () => {

        var page = "about us"


        const response = await axios.post(
            config.baseURL + 'func',
            {
                "Name": "SiteFetchSeoPages",
                "Values": {
                    "page": page
                }
            }
        );
        if (response.status == 200) {

            var resdata = response.data[0];

            setSeoPageInfo(resdata);


        }
    }, []);

    return (
        <Fragment>

            {
                seoPageInfo &&

                <Helmet>

                    <meta charSet="utf-8" />
                    {
                        seoPageInfo?.title &&
                        <title>{seoPageInfo?.title}</title>
                    }
                    {
                        seoPageInfo?.link &&
                        <link rel="canonical" href={seoPageInfo?.link} />

                    }
                    {
                        seoPageInfo?.MetaDescription &&
                        <meta name="description" content={seoPageInfo?.MetaDescription} />
                    }
                    {
                        seoPageInfo?.MetaGroups &&
                        <meta name="keywords" content={seoPageInfo?.MetaGroups} />
                    }

                </Helmet>


            }
            <Banner
                subtitle={banner?.subtitle}
                title={banner?.title}
                desc={banner?.excerpt}
                background={banner?.imagePath}
                backOpasity={banner?.backOpasity}
            />
            <div className={languageDir}>

                <div className="container">
                    <Breadcrumb className={"mt-16"}
                        links={[
                            {
                                name: trans("common:about-us")
                            }
                        ]}
                    />
                </div>



                <WhyHJSection />



                <CounterSection about={about} />

                {/*<div className="container mb-32 grid-3-cols">*/}
                {/*    <ServiceBox small={false}/>*/}
                {/*    <ServiceBox small={false}/>*/}
                {/*    <ServiceBox small={false}/>*/}
                {/*    <ServiceBox small={false}/>*/}
                {/*    <ServiceBox small={false}/>*/}
                {/*    <ServiceBox small={false}/>*/}
                {/*</div>*/}


                <VisionsSection visions={visions} />

                {
                    about?.bannerPath ?
                        <div className={"container mb-36"}>
                            <img className={"border-radius object-full"} src={config.FilePathURL + about?.bannerPath} alt="hassan jameel" />
                        </div>
                        : null
                }

                <AwardsSection awards={awards} />

                <TestimonialSection />

                <div className="container mb-32" id='workPricipleElement'>

                    <div className="about-w-1/2 flex flex-col justify-center items-center  w-1/2 space-y-10 mx-auto">
                        <h2 className={"text-30 font-bold"}>{about?.lastTitle}</h2>
                        <div className="text-20 text-center">
                            {
                                about?.lastDesc
                            }
                        </div>

                        <a className={"btn btn-primary text-16 py-6 px-8"} href="/contact">{trans("common:contact-us")}</a>
                    </div>

                </div>

            </div>
        </Fragment>
    );
}