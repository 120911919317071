import { Fragment, useEffect, useRef, useState, useContext } from "react";
import { DropDownContext } from "../../pages/eparts/components/partSearch";
import ErrorLabel from '../../components/error';
import { InputField } from "../_input/_input"
import { useTranslation } from "react-i18next";
export function DropDownInput1({
    prps,
    styles,
    title,
    placeholder,
    disabled = false,
    className,
    isSecondary,
    inputName,
    options = [{ value: "0", text: (prps?.lang == 'en' ? 'Choose value' : 'إختيار قيمة') }],
    onSelect,
    defaultOption = null,
    onfilter,
    isepart,
    errorLabel,
    force = false
}) {
    const { t: trans, i18n } = useTranslation([]);
    let lang = (i18n.language || 'ar');
    let languageDir = "language-" + lang;

    const optionsRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(defaultOption != null || defaultOption != undefined ? defaultOption : { value: "0", text: (lang == 'en' ? 'Choose value' : 'إختيار قيمة') });
    const [filterOptions, setfilterOptions] = useState(options);
    useEffect(() => {

        /**
         * if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (optionsRef.current && !optionsRef.current.contains(event.target) && isOpen === true) {
                setIsOpen(false);
            }
        }
        // if(placeholder != null || placeholder != undefined){
        //     alert(placeholder);
        //     let resultValue = options.find((e)=>e.value === placeholder);
        //     selectOption(resultValue); 
        //     }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [optionsRef, isOpen]);

    useEffect(() => {
        setfilterOptions(options);

    }, [options]);

    function selectOption(option) {
        if (onSelect) {
            const val = option.value !== '0' ? option.value : '';
            onSelect(val);
        }
        setIsOpen(false);
        setSelectedOption(option);
        if (onfilter)
            if (defaultOption !== option.text)
                onfilter(option);
        // if (isepart)
        //onfilter(option);

    }

    return (

        <Fragment>

            {
                inputName ? (
                    <input type="hidden" value={selectOption.value} />
                ) : null
            }

            <div className={"dropdown " + languageDir + ' ' + className}>

                {
                    title &&
                    <div className={"dropdown-label " + (isSecondary && "secondary")}>{title}
                    </div>

                }


                <div ref={optionsRef} className={"dropdown-input"}>
                    <div className={"dropdown-field " + (isSecondary && "secondary")} style={styles ? styles : null} onClick={() => {
                        setIsOpen(!isOpen);
                    }}>
                        <div className="dropdown-field-text">
                            {

                                selectedOption.text

                            }
                        </div>
                        <div className={"dropdown-field-arrow " + (isOpen && "invert")}>
                            <img src="/assets/icons/arrow.svg" alt="arrow" />
                        </div>

                    </div>
                    {
                        !disabled && <div className={"dropdown-options " + (isOpen || "hidden")}>

                            {
                                  filterOptions && options && options.length > 10 &&
                                < InputField
                                    extraClasses={""}
                                    placeholder={trans("info:search_now")}
                                    onChangeValue={(value) => {
                                        if (value.target.value) {
                                            const filterData = options?.filter(a => a.text.includes(value.target.value));
                                            setfilterOptions(filterData);
                                        } else {
                                            setfilterOptions(options);
                                        }

                                    }} />

                            }

                            <ul>
                                {

                                    filterOptions?.map((option, index) => {

                                        return (
                                            <li key={option.value} onClick={() => {
                                                selectOption(option);
                                                //  const val = option.value !== '0'? option.value : '';
                                                //  onSelect(val);
                                            }} className={option.value + (((index % 2) == 0) ? "  bg-gray-lighter " : "") + ((option?.value == selectedOption?.value) ? " bg-gray-light " : "")}>
                                                <span>{option.text}</span>
                                            </li>
                                        );

                                    }
                                    )
                                }

                            </ul>
                        </div>
                    }


                </div>
                <ErrorLabel errorTitle={errorLabel} />
            </div>

        </Fragment>
    );
}
