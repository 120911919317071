import React, { Fragment, useCallback, useEffect, useRef, useState, useMemo } from "react";
import Banner from "../../components/banner";
import { Part } from "./components/part";
import SwiperCore, { Autoplay, EffectFade, Navigation, Pagination } from "swiper";
import PartSearch from "./components/partSearch";
import Breadcrumb from "../../components/__simple/breadcrumb";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from "react-router";
import { CheckInput } from "../../components/_input/_checkInput";
import config from "../../configuration/config.json";
import { getData, User_Data_Key } from "../../utils/localStorageManager";
import TestEparts from "./testEparts";
import PaginationComponent from "./paginationComponent";
import CustomaAlert from "./customaAlert";
import { Link } from "react-router-dom";
import { InputField } from "../../components/_input/_input";
import { ServiceBox } from "../../components/serviceBox";
import { Swiper, SwiperSlide } from "swiper/react";
import { debounce } from 'lodash';
SwiperCore.use([Pagination, Navigation, Autoplay, EffectFade]);

let cancelTokenSource;

export function ResulteSearch() {
    const { t: trans } = useTranslation(["common", "eparts", 'inputs', "info", "model"]);
    const SECTION_KEY = "Sections_Key";
    //const { value : fav } = useContext(UserFavoriteContext);
    const history = useHistory();

    const [brand, setBrand] = useState(null);
    const [CatMain, setCatMain] = useState(null);
    const [allCategories, setAllCategories] = useState(false);
    const [Product, setPro] = useState(null);

    const [cat, setCat] = useState(null);
    const [subCat, setSubCat] = useState(null);
    const [subSubCat, setSubSubCat] = useState(null);


    const GetUrl = config.baseURL + 'details';
    const [lang, setlang] = useState(localStorage.getItem('i18nextLng'));
    const languageDir = 'language-' + lang;

    const [countRow, setCountRow] = useState(0);
    const [isStock, setIsStock] = useState(false);

    let query = new URLSearchParams(useLocation().search);

    const [engineGroupsObject, setEngineGroupsObject] = useState({
        EngineGroups: [],
        EngineGroupsSup: [],
        EngineGroupsSup2: []
    });
    useEffect(() => {
        if (query) {
            setBrand(query.has('brand') ? query.get("brand") : undefined);
            setCatMain(query.get("CatMain"));
            setPro(query.has('Product') ? query.get("Product") : undefined);
            setAllCategories(query.has('allCategories'));
            setCat(query.has('CatMain') ? query.get("CatMain") : undefined);
            setSubCat(query.has('SuPCat') ? query.get("SuPCat") : undefined);
            setSubSubCat(query.has('SSuPCat') ? query.get("SSuPCat") : undefined);
        }
    }, [query]);

    useEffect(() => {

        if (query.size == 0 || (query.size == 1 && query.has("allCategories"))) {
            setTimeout(async () => {
                await funcGetAllSpareParts({ _isStock: isStock ?? false });
            }, 200)
        }
    }, []);


    const [Brands, setBrands] = useState([]);
    const [engineGroupsSup2, setEngineGroupsSup2] = useState([]);
    const [sections, setSections] = useState([]);

    const [dataRing, setDataRing] = useState({
        "main": 1,
        "max": 10000,
        "minDistanceProp": 100,
        "defaultValueProp": [1, 10000]
    });

    const [valueRing, setValueRing] = useState({
        "start": 1,
        "end": 10000
    });
    // const [valueRing, setValueRing] = useState();
    const [filterMain, setFilterMain] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [activePage, setActivePage] = useState(1);
    const [itemsCountPer, setItemsCountPer] = useState(8);
    const [clearFilter, setClearFilter] = useState(false);

    const [nameCat, setNameCat] = useState({
        "catAr": "",
        "catEn": "",
        "subCatAr": "",
        "subCatEn": "",
        "subSubCatAr": "",
        "subSubCatEn": ""
    });


    const [queryProduct, setQueryProduct] = useState(query.get("Product") ?? null);

    //query select ProductID todo: >>
    function getResultBrandChek(data) {

    }

    function getResultCat(Groups, GroupsSup, GroupsSup2) {

        // const Body = {
        //     "name": "Site_FeatchPartByCategory",
        //     "values": {
        //         "CategorySPID": parseInt(Groups),
        //         "SupCategorySPID": parseInt(GroupsSup),
        //         "ItemsSupCategorySPID": parseInt(GroupsSup2)
        //     }
        // }

        var Body = {
            "name": "Site_FeatchPartByTypeVehicle",
            "values": {
                "brand": brand == null ? null : brand,
                "group": null,
                "type": null,
                "year": null,
                "pageNumber": 1,
                "freeText": "",
                "productNo": "",
                "cat": parseInt(Groups),
                "subCat": parseInt(GroupsSup),
                "subSubCat": parseInt(GroupsSup2)
            }
        };

        const requestProductsWithDetails = axios.request({
            url: config.baseURL + 'func',
            data: Body, method: "POST", responseType: "json"
        })

        axios.all([requestProductsWithDetails])
            .then(axios.spread(function (ProductsWithDetails) {
                setTimeout(setItems(ProductsWithDetails.data), 500);

                if (ProductsWithDetails?.data?.length > 0)
                    setCountRow(ProductsWithDetails?.data[0]?.CountRow);

            })).catch(function (error) {
                setItems([])
                //console.log(error);
            });
    }

    function getResultVehicle(Brand, type, Group, year) {
        const Body = {
            // "name": "Site_FeatchPartByVehicle",
            "name": "Site_FeatchPartByTypeVehicle",
            "values": {
                "brand": Brand,
                "group": Group,
                "type": type,
                "year": year == null || year == undefined ? null : year.toString(),
                "pageNumber": 1,
                "freeText": "",
                "productNo": "",
                "cat": null,
                "subCat": null,
                "subSubCat": null
            }
        }

        const request = axios.request({
            url: config.baseURL + 'func',
            data: Body, method: "POST", responseType: "json"
        })
        axios.all([request])
            .then(axios.spread(function (ProductsWithDetails) {
                setTimeout(() => {
                    setItems(ProductsWithDetails.data)
                }, 100);

                if (ProductsWithDetails?.data?.length > 0)
                    setCountRow(ProductsWithDetails?.data[0]?.CountRow);

            }))
            .catch(function (error) {
                setItems([]);
                //console.log(error);
            });
    }

    useEffect(() => {
        if (Product) {
            getResultProductID(Product);
        }
    }, [Product]);

    function getResultProductID(ProductNo) {
        setCountRow(0);
        setIsLoading(true);

        //console.log("activePage: ", activePage)
        //console.log("activePage:")

        setActivePage(1);
        //console.log("ProdectNo: ", ProductNo)
        setPro(ProductNo);


        // const Body = {
        //     "name": "Site_FeatchPartByProductNo",
        //     "values": {
        //         "ProductNo": ProductNo
        //     }
        // }
        const Body = {
            "name": "Site_FeatchPartByTypeVehicle",
            // "values": {
            //     "brand": parseInt(filterMain.brand),
            //     "group": (filterMain.group == '' || filterMain.group == undefined) ? null : filterMain.group,
            //     "type": (filterMain.type == '' || filterMain.type == undefined) ? null : filterMain.type,
            //     "year": filterMain.year || filterMain.year != undefined ? filterMain.year?.toString() : null,
            //     "pageNumber": 1,
            //     "freeText": (filterMain.freeText || filterMain.freeText == undefined) ? null : filterMain.freeText,
            //     "productNo": ProductNo,
            //     "cat": null,
            //     "subCat": null,
            //     "subSubCat": null
            // }

            "values": {
                "brand": null,
                "group": null,
                "type": null,
                "year": null,
                "pageNumber": 1,
                "freeText": ProductNo ? ProductNo : "", // Product
                "productNo": "",
                "cat": null,
                "subCat": null,
                "subSubCat": null,
                "isStock": isStock
            }
        };


        const request = axios.request({
            url: config.baseURL + 'func',
            data: Body, method: "POST", responseType: "json"
        })
        axios.all([request])
            .then(axios.spread(function (ProductsWithDetails) {
                setTimeout(setItems(ProductsWithDetails.data), 500);



                if (ProductsWithDetails.data.length > 0)
                    setCountRow(ProductsWithDetails?.data[0].CountRow);

            })).catch(function (error) {
                setItems([])
                setCountRow(0)
                //console.log(error);
            });
    }


    const getResultAll = async (res, { pageNumber = activePage, isPagination = false, isTextNull = false }) => {


        setCountRow(0);
        setIsLoading(true);

        // //console.log("isTextNull: ", isTextNull);
        //console.log(" filterMain.group: ", filterMain.group ?? null);
        //console.log(" filterMain.type: ", filterMain.type ?? null);


        if (res != null || res != undefined)
            setActivePage(1);
        // const Body = {
        //     "name": "Site_FeatchPartByAll",
        //     "values": { 
        //         "search": res.target.value
        //     }
        // }

        let body;
        if (isPagination) {
            body = {
                "name": "Site_FeatchPartByTypeVehicle",
                "values": {
                    "brand": parseInt(filterMain.brand),
                    "group": filterMain.group == '' ? null : filterMain.group,
                    "type": filterMain.type == '' ? null : filterMain.type,
                    "year": filterMain.year ? filterMain.year?.toString() : null,
                    "pageNumber": res == null || res == undefined ? pageNumber : 1,
                    "freeText": res == null ? (
                        (filterMain.freeText || filterMain.freeText == undefined)
                            ? Product ? Product : ""
                            : filterMain.freeText)
                        : res.target.value,
                    // "freeText": filterMain.freeText ,
                    // "productNo": Product ?? "",
                    "productNo": "",
                    "cat": cat ? cat : null,
                    "subCat": subCat ? Number(subCat) : null,
                    "subSubCat": subSubCat ? Number(subSubCat) : null,

                    // "cat": null,
                    // "subCat": null,
                    // "subSubCat": null,
                    "isStock": isStock
                }
            };

        } else {
            body = {
                "name": "Site_FeatchPartByTypeVehicle",
                "values": {
                    "brand": parseInt(filterMain.brand),
                    // "group": filterMain.group == '' ? null : filterMain.group,
                    // "type": filterMain.type == '' ? null : filterMain.type,
                    "group": filterMain.group ?? null,
                    "type": filterMain.type ?? null,
                    "year": filterMain.year ? filterMain.year?.toString() : null,
                    "pageNumber": res == null || res == undefined ? pageNumber : 1,
                    "freeText": res == null ? (
                        (filterMain.freeText || filterMain.freeText == undefined)
                            ? ""
                            : filterMain.freeText)
                        : res.target.value,
                    // "freeText": filterMain.freeText ,
                    // "productNo": Product ?? "",
                    "productNo": "",
                    // "cat": cat ? cat : null,
                    // "subCat": subCat ? Number(subCat) : null,
                    // "subSubCat": subSubCat ? Number(subSubCat) : null

                    "cat": res.target.value == '' && isTextNull == true
                        ? cat ? cat : null
                        : null,

                    "subCat": res.target.value.length == 0 && isTextNull == true && res.target.value == ''
                        ? subCat ? Number(subCat) : null
                        : null,

                    "subSubCat": res.target.value.length == 0 && isTextNull == true && res.target.value == ''
                        ? subSubCat ? Number(subSubCat) : null
                        : null,

                    "isStock": isStock
                }
            };
        }

        try {
            setFilterMain({ ...filterMain, "freeText": res == null ? null : res.target.value });


            const request = await axios.request({
                url: config.baseURL + 'func',
                data: body, method: "POST", responseType: "json"
            })
            await axios.all([request])
                .then(axios.spread(function (ProductsWithDetails) {
                    setTimeout(setItems(ProductsWithDetails.data), 1000);

                    console.info("ProductsWithDetails?.data 1", ProductsWithDetails?.data[0])

                    if (ProductsWithDetails.data.length > 0) {
                        setCountRow(ProductsWithDetails?.data[0].CountRow);
                    }

                })).catch(function (error) {
                    setItems([])
                    setCountRow(0)

                });
        } catch (ex) {
            setItems([]);
            setCountRow(0);

        }
    }

    function SendRequset() {
 
        const BodyEngineGroups = {
            "object": "Site_categorySP",
            "Option": "Column",
            "Fields": "GroupIDMain,DescriptionAr,DescriptionEn,GUID",
            "ObjectSettings": {
                "MetaData": false
            }
        };
        const requestEngineGroups = axios.request({
            url: GetUrl,
            data: BodyEngineGroups, method: "POST", responseType: "json"
        });

        const BodyEngineGroupsSUP = {
            "object": "Site_SupCategory",
            "ObjectSettings": {
                "MetaData": false
            }
        };
        const requestEngineGroupsSUP = axios.request({
            url: GetUrl,
            data: BodyEngineGroupsSUP, method: "POST", responseType: "json"
        });

        const BodyEngineGroupsSUP2 = {
            "object": "Site_ItemsSupCategory",
            "ObjectSettings": {
                "MetaData": false
            }
        };
        const requestEngineGroupsSUP2 = axios.request({
            url: GetUrl,
            data: BodyEngineGroupsSUP2, method: "POST", responseType: "json"
        });


        axios.all([ requestEngineGroups, requestEngineGroupsSUP, requestEngineGroupsSUP2])
            .then(
                axios.spread(
                    function (
                        EngineGroups,
                        EngineGroupsSup,
                        EngineGroupsSup2
                    ) {
                        handleBuildCategoriesSections(EngineGroups?.data?.ApiObjectData);
                        setTimeout(() => {
                            setEngineGroupsSup2(engineGroupsSup2?.data?.ApiObjectData)
                        }, 1500);
                    }))
            .catch(function (error) {
                //console.log(error);
            });

    }

    // to build the categories dynamically and store data into the storage
    //----------------------------------------------------
    const handleBuildCategoriesSections = (data) => {
        let sectionsList = [];
        if (data && data?.length > 0) {
            sectionsList = data.map((item) => {
                return {
                    id: item.GroupIDMain,
                    descriptionAr: item.DescriptionAr,
                    descriptionEn: item.DescriptionEn,
                    activ: true
                }
            });
        }
        localStorage.setItem(SECTION_KEY, JSON.stringify(sectionsList));
        setSections(sectionsList);
    };
    //----------------------------------------------------
    const [SearchItems, setItems] = useState([]);
    const [searchItemsPagination, setSearchItemsPagination] = useState([]);
    const [CheckedBrand, setCheckedBrand] = useState([]);

    function OnCheckBrand(Check) {
        if (Check.checked) {

            setCheckedBrand(CheckedBrand => [...CheckedBrand, Check.name])


        } else {

            setCheckedBrand(CheckedBrand.filter(a => a !== Check.name));
        }
        if (CheckedBrand.length > 0) {
            const values = "(" + Check.name + "," + CheckedBrand.map(ch => {
                return ch;
            }) + ")";
            ////console.log(values);
            getResultBrandChek(values)
        } else {
            const values = "(" + Check.name + ")";
            //   //console.log(values);
            getResultBrandChek(values)
        }

    }
    const [checkedCateegores, setCheckedCateegores] = useState([]);
    const OnCheckCate = (event) => {
        const { id, checked } = event;
        var currentlistvalues = (prevCheckedBrands) => {
            if (checked) {
                // Add the brand ID to the array if checked
                return [...prevCheckedBrands, id];
            } else {
                // Remove the brand ID from the array if unchecked
                return prevCheckedBrands.filter((brandId) => brandId !== id);
            }
        }

        const listValues = currentlistvalues(checkedCateegores);

        setCheckedCateegores(listValues);
        //-----------------

        getEPartsWithStock(isStock, listValues)
        const listValuesString = listValues?.join(',');
    };



    //array fun 
    const [AllFun, setAllFun] = useState(
        {
            FunCat: getResultCat,
            FunPro: getResultProductID,
            FunVech: getResultVehicle
        }
    );

    useEffect(() => {

        if (CatMain) {
            //   getResultCat(CatMain, SuPCat, SSuPCat);
        }
        if (Product) {
            // getResultProductID(Product);
        }

        if (query.get("brand")) {

            // getResultVehicle(query.get("brand"),query.get("type") , query.get("group",query.get("year")));
        }

        SendRequset();
        // 
        RequsetBanner();
        ////console.log("setPWD", PWD);
    }, []);
    const [banner, setbanner] = useState([]);

    function RequsetBanner() {

        axios.request({
            baseURL: config.controllURL,
            url: lang + "/api/Maintenance/BannerSP",
            method: "get",
        })

            .then(function (response) {

                //console.log("response >>>>>>>>>>>>>>>>>>>>>>>>>:", response.data.banner);
                setTimeout(setbanner(response?.data?.banner), 1000);

            })
            .catch(function (error) {
                //console.log(error);
            });


    }

    // -------------------------------------------------
    const [favoriteProducts, setFavoriteProducts] = useState([]);
    // to fetch the user favorites
    const fetchUserFavorites = async () => {
        let _favorites = [];
        try {
            const isLogin = getData('isAuthenticated');
            if (isLogin) {
                const { id } = JSON.parse(getData(User_Data_Key, true));
                const userID = Number(id.toString().split('_')[1]);
                const response = await axios.post(
                    config.baseURL + 'func',
                    {
                        "Name": "SiteFetchUserFavorites",
                        "values": {
                            "userID": userID
                        }
                    }
                );
                if (response.status == 200) {
                    const favorites = response.data;
                    _favorites = _favorites.concat(favorites);
                }
            }
        } catch (err) {
            //console.log(err);
        }
        return _favorites;
    };
    useEffect(async () => {
        const results = await fetchUserFavorites();
        setFavoriteProducts(results);
    }, []);


    useEffect(() => {
        setIsLoading(false);

        setSearchItemsPagination([]);
        if (SearchItems && SearchItems.length > 0) {
            var index = 0;
            var endIndex = 0;

            endIndex = (activePage * itemsCountPer) - 1;

            index = (endIndex - itemsCountPer) + 1;

            //console.log(`index:  ${index}`);
            //console.log(`endIndex:  ${endIndex}`);

            var pagValue = SearchItems.slice(index, (endIndex + 1)).map((val) => {
                return val;
            })

            //console.log(`pagValue:`, pagValue);

            setSearchItemsPagination(pagValue);
        }

    }, [SearchItems]);


    const clearFilters = useRef(null);
    const clearFiltersFunction = async ({ _isStock = isStock }) => {
        setIsLoading(true);
        setFilterMain({ ...filterMain, "freeText": `` });

        if (clearFilters.current) {
            console.info("Call the function defined in the >>>>>>>>>>");
            clearFilters.current.clearFiltersFunc();

            await funcGetAllSpareParts({ _isStock: _isStock });

        }
    };

    const funcGetAllSpareParts = async ({ _isStock = isStock }) => {

        setCountRow(0);
        setIsLoading(true);

        var Body = {
            "name": "Site_FeatchPartByTypeVehicle",
            "values": {
                "brand": brand == null ? null : brand,
                "group": null,
                "type": null,
                "year": null,
                "pageNumber": 1,
                "freeText": "",
                "productNo": "",
                "cat": null,
                "subCat": null,
                "subSubCat": null,
                "isStock": _isStock
            }
        };

        const requestProductsWithDetails = axios.request({
            url: config.baseURL + 'func',
            data: Body, method: "POST", responseType: "json"
        })

        axios.all([requestProductsWithDetails])
            .then(axios.spread(function (ProductsWithDetails) {
                setTimeout(setItems(ProductsWithDetails.data), 500);

                if (ProductsWithDetails?.data?.length > 0)
                    setCountRow(ProductsWithDetails?.data[0]?.CountRow);

            })).catch(function (error) {
                setItems([])
                // //console.log(error);

            });
    }

    const getResultVehicleByCat = async () => {
        setCountRow(0);
        setIsLoading(true);
        setActivePage(1);

        const Body = {
            "name": "Site_FeatchPartByTypeVehicle",
            "values": {
                "brand": null,
                "group": null,
                "type": null,
                "year": null,
                "pageNumber": 1,
                "freeText": "",
                "productNo": "",
                "cat": cat,
                "subCat": Number(subCat),
                "subSubCat": Number(subSubCat),
                "isStock": isStock
            }
        }

        try {


            const request = await axios.request({
                url: config.baseURL + 'func',
                data: Body, method: "POST", responseType: "json"
            });

            await axios
                .all([request])
                .then(axios.spread(function (ProductsWithDetails) {
                    // setCountRow(0);
                    // setIsLoading(true);

                    setTimeout(() => {
                        // setIsLoading(false);
                        setItems(ProductsWithDetails.data);
                    }, 800);

                    if (ProductsWithDetails?.data?.length > 0)
                        setCountRow(ProductsWithDetails?.data[0]?.CountRow);

                }))
                .catch(function (error) {
                    setIsLoading(false);
                    setItems([]);

                    //console.log("getResultVehicleByCat:catch", error);
                });
        } catch (ex) {
            setIsLoading(false);
            setItems([]);
            //console.log("getResultVehicleByCat:catch:Ex", ex);

        }
    };

    const getNameCat = async ({ reqCat, reqSubCat, reqSubSubCat }) => {
        try {
            const body = {
                "name": "func_GetNameCatsByIdEPart",
                "values": {
                    "cat": Number(reqCat),
                    "subCat": Number(reqSubCat),
                    "subSubCat": Number(reqSubSubCat)
                }
            };

            await axios.request({
                url: config.baseURL + 'func',
                data: body, method: "POST", responseType: "json"
            })
                .then(function (response) {
                    if (response.data && response.data.length > 0) {
                        console.info("func_GetNameCatsByIdEPart:data", response.data[0]);
                        setNameCat(response?.data[0]);
                    }
                })
                .catch(function (error) {
                    // console.warn("func_GetNameCatsByIdEPart:catch", error);
                    throw error;
                });
        } catch (ex) {
            console.warn("func_GetNameCatsByIdEPart:catch", ex);
        }
    };

    const getEPartsWithStock = async ({ _isStock = isStock }, catlistvalues = checkedCateegores) => {

        try {
            if (cat)
                catlistvalues = [...catlistvalues, cat];
        } catch (error) {

        }
        setCountRow(0);
        setIsLoading(true);

        // alert(filterMain.freeText +"\n"+ Product +"\n" + cat + "   " + subCat + "  " + subSubCat);
        const body = {
            "name": "Site_FeatchPartByTypeVehicle",
            "values": {
                "brand": brand == null ? null : brand,
                "group": null,
                "type": null,
                "year": null,
                "pageNumber": 1,
                // "freeText": "", //todo: {{
                "freeText": !Product ? '' : Product,// WithStock: filterMain.freeText,
                "productNo": "",
                "cat": catlistvalues ? catlistvalues?.join(',') : null,
                "subCat": subCat ? Number(subCat) : null,
                "subSubCat": subSubCat ? Number(subSubCat) : null,

                "isStock": _isStock
            }
        };




        await axios.request({
            url: config.baseURL + "func",
            data: body, method: "POST", responseType: "json"
        })
            .then((response) => {


                if (response.data && response.data.length > 0) {
                    setTimeout(() => {
                        setItems(response.data)
                    }, 1000);

                    if (response.data.length > 0)
                        setCountRow(response?.data[0].CountRow);
                }
                // setIsLoading(false);

            })
            .catch((error) => {
                setItems([]);
                setCountRow(0);

            });


    };

    useEffect(() => {

        console.info("subSubCat : ", subSubCat)
        if (cat || subCat || subSubCat || allCategories) {
            getResultVehicleByCat();
            getNameCat({ reqCat: cat, reqSubCat: subCat, reqSubSubCat: subSubCat });

        }

    }, [cat, subCat, subSubCat, allCategories]);


    useEffect(() => {

        if (allCategories) {
            setCat(undefined);
            setSubCat(undefined);
            setSubSubCat(undefined);
            getResultVehicleByCat();
            setNameCat({});

        }

    }, [allCategories]);

    // useEffect(() => {
    //     // if (isStock){
    //     // getEPartsWithStock();
    //     // }
    // }, [isStock]);
    const resultNameCat = ({ objCat }) => {

        if (objCat.catAr && objCat.catEn) {
            if ((objCat.catAr && objCat.catEn) && (objCat.subCatAr && objCat.subCatEn)) {
                if ((objCat.catAr && objCat.catEn) && (objCat.subCatAr && objCat.subCatEn) && (objCat.subSubCatAr && objCat.subSubCatEn)) {
                    return <>
                        <label className="text-gray-700 flex flex-row">
                            <span
                                className="text-2xl sm:text-3xl md:text-3xl lg:text-2xl font-bold">{lang.toLowerCase() == "ar" ? objCat.catAr : objCat.catEn}</span>
                            <span className="text-primary text-4xl mx-3 border border-green-500 opacity-25 "></span>
                            <span
                                className="text-2xl sm:text-3xl md:text-3xl lg:text-2xl font-bold">{lang.toLowerCase() == "ar" ? objCat.subCatAr : objCat.subCatEn}</span>
                            <span className="text-primary text-4xl  mx-3 border border-green-500 opacity-25"></span>
                            <span
                                className="text-2xl sm:text-3xl md:text-3xl lg:text-2xl font-bold">{lang.toLowerCase() == "ar" ? objCat.subSubCatAr : objCat.subSubCatEn}</span>
                        </label>
                    </>
                } else {
                    return <>
                        <label className="text-gray-700 h-48">
                            <span
                                className="text-2xl sm:text-3xl md:text-3xl lg:text-2xl font-bold">{lang.toLowerCase() == "ar" ? objCat.catAr : objCat.catEn}</span>
                            <span className="text-primary text-4xl mx-3 border border-green-500 opacity-25"></span>
                            <span
                                className="text-2xl sm:text-3xl md:text-3xl lg:text-2xl font-bold">{lang.toLowerCase() == "ar" ? objCat.subCatAr : objCat.subCatEn}</span>
                        </label>
                    </>
                }
            } else {
                return (
                    <label className="text-gray-700">
                        <span className="text-2xl sm:text-3xl md:text-3xl lg:text-2xl font-bold">
                            {lang.toLowerCase() == "ar" ? objCat.catAr : objCat.catEn}
                        </span>
                        {/*<span className="text-primary"></span>*/}
                    </label>
                )
            }
        } else {
            return <></>
        }
    }


    const searchTextInput = (val) => {
        //console.log("val.target.value: ", val.target.value, "\t", val.target.value.length);

        setCountRow(0);
        setIsLoading(true);
        setActivePage(1);


        if (cancelTokenSource) {
            cancelTokenSource.cancel('Operation canceled due to a new request.');
        }

        cancelTokenSource = axios.CancelToken.source();

        // //console.log("cat : ", cat, "\t >>> \t");

        if (val.target.value.length == 0) {
            //console.log("target.value == 0 : ", val.target.value, "\t val.target.value.length \t", val.target.value.length);
        }

        const body = {
            "name": "Site_FeatchPartByTypeVehicle",
            "values": {
                "brand": parseInt(filterMain.brand),
                "group": filterMain.group ?? null,
                "type": filterMain.type ?? null,
                "year": filterMain.year ? filterMain.year?.toString() : null,

                "pageNumber": 1,

                "freeText": val.target.value == ''
                    ? Product ?? ""
                    : val.target.value,

                "productNo": "",

                "cat": val.target.value.length == 0
                    ? cat ? cat : null
                    : null,

                "subCat": val.target.value.length == 0
                    ? subCat ? Number(subCat) : null
                    : null,

                "subSubCat": val.target.value.length == 0
                    ? subSubCat ? Number(subSubCat) : null
                    : null,

                "isStock": isStock
            }
        };

        try {
            setFilterMain({ ...filterMain, "freeText": val == null ? null : val.target.value });


            const request = axios.request({
                url: config.baseURL + 'func',
                data: body, method: "POST", responseType: "json", cancelToken: cancelTokenSource.token
            });

            axios.all([request])
                .then(axios.spread(function (ProductsWithDetails) {

                    setTimeout(setItems(ProductsWithDetails.data), 1000);

                    console.info("ProductsWithDetails?.data 1", ProductsWithDetails?.data[0])

                    if (ProductsWithDetails.data.length > 0) {
                        setCountRow(ProductsWithDetails?.data[0].CountRow);
                    }


                })).catch(function (error) {


                    if (axios.isCancel(error)) {

                    } else {

                    }

                    setItems([])
                    setCountRow(0)


                });
        } catch (ex) {
            setItems([]);
            setCountRow(0);

        }
    };

    const debouncedGetResultAll = useCallback(debounce(searchTextInput, 1500), [cat, subCat, subSubCat, isStock, allCategories]);

    // const debouncedGetResultAll = useMemo(() => debounce(searchTextInput, 1000), [filterMain]);


    return (
        <Fragment>
            {/* <Banner
                subtitle={banner?.subtitle}
                title={banner?.title}
                desc={banner?.excerpt}
                background={banner?.imagePath}
                backOpasity={banner?.backOpasity}
            /> */}

            <div className="container pt-5 pb-5 ">

                {
                    // (nameCat.catAr && nameCat.catEn) && (nameCat.subCatAr && nameCat.subCatEn) && (nameCat.subSubCatAr && nameCat.subSubCatEn)
                    //     ? <Breadcrumb className={"mt-16 "}
                    //                   links={[
                    //                       {
                    //                           name: trans("eparts:EpartTitel"),
                    //                           to: "/eparts"
                    //                       },
                    //                       {
                    //                           name: lang.toLowerCase() == 'ar' ? nameCat?.catAr : nameCat?.catEn
                    //                       },
                    //                       {
                    //                           name: lang.toLowerCase() == 'ar' ? nameCat?.subCatAr : nameCat.subCatEn
                    //                       },
                    //                       {
                    //                           name: lang.toLowerCase() == 'ar' ? nameCat.subSubCatAr : nameCat.subSubCatEn
                    //                       }
                    //                   ]}
                    //     />
                    //     : ((nameCat.catAr && nameCat.catEn) && (nameCat.subCatAr && nameCat.subCatEn))
                    //         ? <Breadcrumb className={"mt-16 "}
                    //                       links={[
                    //                           {
                    //                               name: trans("eparts:EpartTitel"),
                    //                               to: "/eparts"
                    //                           },
                    //                           {
                    //                               name: lang.toLowerCase() == 'ar' ? nameCat?.catAr : nameCat?.catEn
                    //                           },
                    //                           {
                    //                               name: lang.toLowerCase() == 'ar' ? nameCat?.subCatAr : nameCat.subCatEn
                    //                           }
                    //                       ]}
                    //         />
                    //         : (nameCat?.catAr && nameCat?.catEn)
                    //             ? <Breadcrumb className={"mt-16 "}
                    //                           links={[
                    //                               {
                    //                                   name: trans("eparts:EpartTitel"),
                    //                                   to: "/eparts"
                    //                               },
                    //                               {
                    //                                   name: lang.toLowerCase() == 'ar' ? nameCat?.catAr : nameCat?.catEn
                    //                               }
                    //                           ]}
                    //             />
                    //             : <Breadcrumb className={"mt-16 "}
                    //                           links={[
                    //                               {
                    //                                   name: trans("eparts:EpartTitel"),
                    //                                   to: "/eparts"
                    //                               },
                    //                               // {
                    //                               //     name: trans("info:DenamoEpart")
                    //                               // }
                    //                           ]}
                    //             />
                }

                {
                    <div
                        className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-8 lg:grid-cols-8 xl:grid-cols-8 gap-1
                         justify-items-auto ">
                        <div className="col-span-1 flex gap-3 order-2-sm ">

                            <div className="col-span-5 flex justify-center items-center">
                                <a

                                    href={"/eparts/sereach?" + "allCategories=true"}
                                    className="text-4xl sm:text-3xl md:text-4xl lg:text-3xl xl:text-3xl hover:text-green-500  "
                                >
                                    {lang.toLowerCase() == "ar" ? "جميع الفئات" : "Categories"}
                                </a>
                            </div>
                            <div className="col-span-5 flex justify-center items-center">
                                {/* <a

                                    href={"/eparts/sereach?" + "allCategories=true"}
                                    className="text-4xl sm:text-3xl md:text-4xl lg:text-3xl xl:text-3xl hover:text-green-500  "
                                >
                                    {lang.toLowerCase() == "ar" ? " العروض" : " Categories"}
                                </a> */}
                            </div>
                        </div>

                        {/*<div>2</div>*/}
                        <div className="flex justify-center items-center px-3 col-span-5">
                            <div
                                className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4 gap-0 justify-items-auto w-full ">
                                <div className=" sm:col-span-1 md:col-span-3 lg:col-span-3 xl:col-span-3">
                                    {
                                        <div className={"flex justify-center items-center "}>
                                            <div className="filter-search-grid filter-search-1 h-90">

                                                <InputField
                                                    searchIcon={true}
                                                    extraClasses="part-input-search text-20 "
                                                    // placeholder={trans("new:product_no")}
                                                    placeholder={trans("info:name-number-search")}
                                                    onChangeValue={(e) => {
                                                        setQueryProduct(null);
                                                        // SetProduct(e);
                                                        console.warn(e.target.value);
                                                        setQueryProduct(e.target.value);
                                                    }
                                                    }
                                                    onClicksearchIcon={(value) => {

                                                        if (queryProduct)
                                                            window.location.href = "/eparts/sereach?Product=" + queryProduct;
                                                        // history.push("/eparts/sereach?Product=" + queryProduct);
                                                    }}
                                                    value={queryProduct}
                                                />
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }


                {/*<Breadcrumb className={"mt-16 "}*/}
                {/*            links={[*/}
                {/*                {*/}
                {/*                    name: trans("eparts:EpartTitel"),*/}
                {/*                    to: "/eparts"*/}
                {/*                },*/}
                {/*                // {*/}
                {/*                //     name: trans("info:DenamoEpart")*/}
                {/*                // }*/}
                {/*            ]}*/}
                {/*/>*/}


            </div>


            <PartSearch
                search={AllFun}
                isIndex={false}
                onChangValuesProp={(data) => {
                    setFilterMain(data);
                }}
                isLoadingFuncProp={(val) => {
                    setCountRow(val == true ? 0 : countRow);
                    setIsLoading(val);
                }}
                ref={clearFilters}
                passEngineGroups={(EngineGroupsObject) => {
                    setEngineGroupsObject(EngineGroupsObject);
                }}
                activePage={activePage ?? 1}
            />

            <div className="container pt-5 pb-5 ">
                {
                    (allCategories == true)
                        ?
                        <>

                            < Breadcrumb className={"mt-16 "}
                                links={[
                                    {
                                        name: trans("eparts:EpartTitel"),
                                        to: "/eparts"
                                    },
                                    {
                                        className: "text-primary",
                                        name: lang.toLowerCase() == 'ar' ? "الفئات" : "Categories",
                                        to: `/eparts/sereach?allCategories`
                                    }
                                ]}
                            />
                            <div className="text-40" >{lang.toLowerCase() == 'ar' ? "الفئات" : "Categories"}</div>

                            {
                                engineGroupsObject.EngineGroups?.length > 0 &&
                                <>
                                    {<Swiper
                                        spaceBetween={0}
                                        slidesPerView={2}
                                        breakpoints={{
                                            576: {
                                                slidesPerView: 4,
                                            },
                                        }}
                                        pagination={{ clickable: true }}
                                        className={"mb-16k py-10"}
                                        resizeObserver={"true"}
                                        autoplay={false}
                                        loop={false}
                                        observer={true}
                                    >

                                        {engineGroupsObject.EngineGroups?.map((proValue, prodIndex) => (
                                            <SwiperSlide className={"px-3"} key={proValue.GroupIDMain}>
                                                <ServiceBox
                                                    titlebtn={null}
                                                    // link={"/eparts/group/" + proValue.GUID} 
                                                    link={`/eparts/sereach?CatMain=${proValue?.GroupIDMain}&SuPCat=null&SSuPCat=null`}
                                                    imgUrl={proValue.ImageUrl}
                                                    titleEn={proValue.DescriptionEn}
                                                    titleAr={proValue.DescriptionAr} />
                                            </SwiperSlide>
                                        ))}

                                    </Swiper>}
                                </>

                            }
                        </>



                        : (
                            (nameCat?.catAr && nameCat?.catEn)
                                ? (


                                    (() => {

                                        var linkListObject = [
                                            {
                                                name: trans("eparts:EpartTitel"),
                                                to: "/eparts"
                                            },
                                            {
                                                className: (!subCat || subCat == "null") ? "text-primary" : "",
                                                name: lang.toLowerCase() == 'ar' ? nameCat?.catAr : nameCat?.catEn,
                                                to: `/eparts/sereach?CatMain=${cat}&SuPCat=${null}&SSuPCat=${null}`
                                            }

                                        ];

                                        if (subCat && subCat != "null")
                                            linkListObject.push({
                                                className: (!subSubCat || subSubCat == "null") ? "text-primary" : "",
                                                name: lang.toLowerCase() == 'ar' ? nameCat?.subCatAr : nameCat?.subCatEn,
                                                to: `/eparts/sereach?CatMain=${cat}&SuPCat=${subCat}&SSuPCat=${null}`
                                            });

                                        if (subSubCat && subSubCat != "null")
                                            linkListObject.push({
                                                className: "text-primary",
                                                name: lang.toLowerCase() == 'ar' ? nameCat?.subSubCatAr : nameCat?.subSubCatEn,
                                                to: `/eparts/sereach?CatMain=${cat}&SuPCat=${subCat}&SSuPCat=${subSubCat}`
                                            });

                                        var thisEngineGroupsSup = [];
                                        var ServiceBoxSlid = [];
                                        if ((!subCat || subCat == "null") && cat) {
                                            if (engineGroupsObject.EngineGroupsSup?.length > 0) {
                                                thisEngineGroupsSup = engineGroupsObject.EngineGroupsSup?.filter(g => g.GroupIDMain == cat)

                                                ServiceBoxSlid = thisEngineGroupsSup?.map((proValue, prodIndex) => (
                                                    <SwiperSlide className={"px-3"} key={proValue.GroupIDMainSup1}>
                                                        <ServiceBox
                                                            titlebtn={null}
                                                            // link={"/eparts/group/" + proValue.GUID} 
                                                            link={`/eparts/sereach?CatMain=${cat}&SuPCat=${proValue?.GroupIDMainSup1}&SSuPCat=null`}
                                                            imgUrl={proValue.ImageUrl}
                                                            titleEn={proValue.DescriptionEn}
                                                            titleAr={proValue.DescriptionAr} />
                                                    </SwiperSlide>
                                                ))
                                            }
                                        } else if ((!subSubCat || subSubCat == "null") && subCat) {


                                            if (engineGroupsObject.EngineGroupsSup2?.length > 0) {
                                                thisEngineGroupsSup = engineGroupsObject.EngineGroupsSup2?.filter(g => g.GroupIDMainSup1 == subCat)
                                                ServiceBoxSlid = thisEngineGroupsSup?.map((proValue, prodIndex) => (
                                                    <SwiperSlide className={"px-3"} key={proValue.GroupIDMainSup2}>
                                                        <ServiceBox
                                                            titlebtn={null}
                                                            // link={"/eparts/group/" + proValue.GUID} 
                                                            link={`/eparts/sereach?CatMain=${cat}&SuPCat=${proValue?.GroupIDMainSup1}&SSuPCat=${proValue?.GroupIDMainSup2}`}
                                                            imgUrl={proValue.ImageUrl}
                                                            titleEn={proValue.DescriptionEn}
                                                            titleAr={proValue.DescriptionAr} />
                                                    </SwiperSlide>
                                                ))
                                            }

                                        }

                                        return (

                                            <>
                                                <Breadcrumb className={"mt-16 "}
                                                    links={linkListObject}
                                                />
                                                <div className="text-40" >{linkListObject[linkListObject.length - 1]?.name}</div>


                                                {
                                                    thisEngineGroupsSup?.length > 0 &&


                                                    <>
                                                        {<Swiper
                                                            spaceBetween={0}
                                                            slidesPerView={2}
                                                            breakpoints={{
                                                                576: {
                                                                    slidesPerView: 4,
                                                                },
                                                            }}
                                                            pagination={{ clickable: true }}
                                                            className={"mb-16k py-10"}
                                                            resizeObserver={"true"}
                                                            autoplay={false}
                                                            loop={false}
                                                            observer={true}
                                                        >

                                                            {ServiceBoxSlid}

                                                        </Swiper>}
                                                    </>

                                                }
                                            </>

                                        );
                                    })()



                                ) :
                                <>
                                    <Breadcrumb className={"mt-16 "}
                                        links={[
                                            {
                                                name: trans("eparts:EpartTitel"),
                                                to: "/eparts",
                                            }
                                        ]}
                                    />
                                    {/* <span className="text-primary " >{"/"+lang.toLowerCase() == 'ar' ? "عرض النتائج لـــ " : "Showing results for"}</span> */}
                                    {
                                        Product &&
                                        <div className="text-40" >{lang.toLowerCase() == 'ar' ? "عرض النتائج لـــ " : "Showing results for"} <span className="text-40 text-primary" >{Product}</span></div>
                                    }

                                </>
                        )}
            </div>
            <div className={"mt-20 mb-36 pt-20 borderTop-sm " + languageDir}>
                <div className="container">
                    <div className="nice-row">

                        <div className="nice-col-lg-3 nice-col-md-4">
                            <div className="helper">
                                {/* <PageHeading
                                    title={trans("info:DenamoEpart")}
                                /> */}
                                <div>
                                    {/* <div className='helper-section-heading mb-10'>

                                        <InputField
                                            placeholder={trans("info:name-number-search")}
                                            value={filterMain.freeText}

                                            onChangeValue={(val) => {
                                                setFilterMain({ ...filterMain, "freeText": `${val.target.value}` });
                                                // var caretRequstIndex = requestIndex + 1;
                                                //
                                                // setRequestIndex(caretRequstIndex);

                                                debouncedGetResultAll(val);
                                            }}
                                        />
                                    </div> */}

                                    {/*{"البحث بالرنج"}*/}
                                    <div className='mb-10 w-full'>
                                        <span className="helper-section-heading">{trans('info:price-sar')}</span>
                                        <TestEparts
                                            className='lg:mx-8 md:mx-5 sm:mx-2'
                                            minProp={dataRing.main} maxProp={dataRing.max}
                                            minDistanceProp={
                                                (dataRing.max <= 5000 && dataRing.max >= 1)
                                                    ? (dataRing.max * .1) - dataRing.main
                                                    : (dataRing.max <= 10000 && dataRing.max > 5000)
                                                        ? (dataRing.max * .09) - dataRing.main
                                                        : (dataRing.max * .1) - dataRing.main
                                            }
                                            // defaultValueProp={[1, 10000]}
                                            defaultValueProp={dataRing.defaultValueProp}
                                            returnProp={(value) => {
                                                console.info("console.info:  ", value);
                                                setValueRing(value);
                                            }}
                                        />
                                    </div>

                                    <div className='helper-section-heading mb-10'>
                                        {trans("info:category")}
                                        {sections.map(B => {
                                            if (lang === "en") {

                                                return (
                                                    <CheckInput id={B.id} className=' cursor-pointer' labelClassName=' nice-flex-row-reverse nice-flex-space-between ' oncheng={OnCheckCate}
                                                        Name={"CH_SEC" + B.id}>
                                                        <label
                                                            htmlFor={B.id} className='helper-section-label'>{B.descriptionEn}</label>
                                                    </CheckInput>);

                                            } else {
                                                return (
                                                    <CheckInput id={B.id} className=' cursor-pointer' labelClassName=' nice-flex-row-reverse  nice-flex-space-between ' oncheng={OnCheckCate} Name={"CH_SEC" + B.id}>
                                                        <label
                                                            htmlFor={B.id} className='helper-section-label'>{B.descriptionAr}</label>
                                                    </CheckInput>);
                                            }
                                        })}
                                    </div>
                                    {
                                        // <hr className='mt-10'/>
                                        // العلامة التجارية
                                        // <div className='helper-section-heading my-5 '>
                                        //     {trans("info:company")}

                                        //     {Brands.map(B => {
                                        //         if (lang.toLowerCase() == "en") {
                                        //             if (brand === B.BrandID) {
                                        //                 return (
                                        //                     <CheckInput className="text-primary" oncheng={OnCheckBrand}
                                        //                                 Name={B.BrandID}>
                                        //                         <label
                                        //                             className='helper-section-label'>{B.DescriptionEn}</label>
                                        //                     </CheckInput>);
                                        //             } else {
                                        //                 return (
                                        //                     <CheckInput className="text-primary" oncheng={OnCheckBrand}
                                        //                                 Name={B.BrandID}>
                                        //                         <label
                                        //                             className='helper-section-label'>{B.DescriptionEn}</label>
                                        //                     </CheckInput>);
                                        //             }
                                        //         } else {
                                        //             return (
                                        //                 <CheckInput className="text-primary" oncheng={OnCheckBrand}
                                        //                             Name={B.BrandID}>
                                        //                     <label
                                        //                         className='helper-section-label'>{B.DescriptionAr}</label>
                                        //                 </CheckInput>);
                                        //         }
                                        //     })}
                                        // </div>
                                    }
                                    {
                                        <>
                                            <hr className='mt-10' />
                                            <div className='helper-section-heading my-5'>
                                                <CheckInput
                                                   id={"in-stock"}
                                                    className="text-primary cursor-pointer"
                                                    labelClassName=' nice-flex-row-reverse  nice-flex-space-between '
                                                    checked={isStock}

                                                    oncheng={async (event) => {
                                                        //console.log(" هل في المخزون", event.checked)

                                                        setIsStock(event.checked);
                                                        await getEPartsWithStock({ _isStock: event.checked })
                                                    }}
                                                    Name={"23-44"}
                                                >
                                                    <label
                                                     htmlFor={"in-stock"}
                                                        className='text-2xl sm:text-2xl md:text-2xl'>{trans("eparts:in-stock")}</label>
                                                </CheckInput>
                                            </div>
                                        </>
                                    }

                                    <hr className='mt-10' />
                                    <div className='helper-section-heading my-5 pb-8 border-b'>
                                        <a
                                            href={"/eparts/sereach/"}
                                            className="font-bold text-3xl nice-line-underline sm:text-3xl md:text-2xl cursor-pointer hover:text-[#3bd461]"
                                            onClick={async (e) => {

                                                setNameCat({ catAr: null, catEn: null });
                                                //console.log("e.target.innerHTML: ", e.target.innerHTML);
                                                setClearFilter(true);


                                                setIsStock(false);
                                                await clearFiltersFunction({ _isStock: false });
                                            }}
                                        >
                                            {trans("info:clear-all-filters")}
                                        </a>
                                    </div>


                                </div>
                            </div>
                        </div>

                        <div className="nice-col-lg-9 nice-col-md-8">
                            <div
                                className="h-16 grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 justify-items-auto mb-5">

                                <div
                                    className="col-span-1 sm:col-span-1 md:col-span-2 lg:col-span-2 xl:col-span-2 justify-center items-center">
                                    <div className="flex">
                                        <div className="flex-auto text-start px-4 py-2 m-2 ">
                                            <span
                                                className="text-gray-700 text-2xl sm:text-3xl md:text-3xl lg:text-2xl">
                                                {resultNameCat({ objCat: nameCat })}
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className="col-span-1 sm:col-span-1 md:col-span-1 lg:col-span-1 xl:col-span-1 justify-center items-center">
                                    <div className="flex ">
                                        <div className="flex-auto text-start px-4 py-2 m-2 ">
                                            <span
                                                className="text-gray-700 text-2xl sm:text-3xl md:text-3xl lg:text-2xl">
                                                {trans("eparts:price-includes-VAT")}
                                            </span>
                                        </div>
                                        <div className="border border-gray-500 rounded-lg py-4"></div>
                                        <div
                                            className="flex-auto text-end px-2 py-2 m-2 text-end-important">
                                            <div className="flex">
                                                <div className="flex-auto ">
                                                    <span
                                                        className="mx-3 text-3xl sm:text-3xl md:text-3xl lg:text-2xl font-bold ">{countRow.toString()}</span>
                                                </div>
                                                <div className="flex-auto ">
                                                    <span className="mx-3 text-3xl sm:text-3xl md:text-3xl lg:text-2xl">
                                                        {trans("eparts:results")}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                // sections?.map((item, index) => {
                                // SetCheckCate(item.id.toString());
                                // return 
                                // <>
                                // {
                                // SearchItems?.filter(item1 => {
                                //     return item1.CategorySPID === item.id.toString()
                                // }).length > 0 && 
                                // <h1 id={"T_SEC_CH_SEC" + item.id.toString()} className="text-size-18r text-bold mb-5">
                                //     {lang == 'en' ? item.descriptionEn : item.descriptionAr}
                                // </h1>
                                // }
                                //     {
                                //         <div id={"SEC_CH_SEC" + item.id.toString()} className="nice-row">
                                <>


                                    {
                                        // (
                                        //      <>
                                        //          <div className="bg-yellow-600 w-1/2 h-1/2 text-5xl text-center">
                                        //              {"is Loading: =>" + isLoading}
                                        //          </div>  
                                        //      </>
                                        //  )
                                    }

                                    {
                                        //  !isLoading && (
                                        //     <div className="container my-8">
                                        //         <CustomaAlert
                                        //             titleProp={trans("info:title-alert")}
                                        //             textProp={trans("info:text-alert")}
                                        //         />
                                        //     </div>
                                        // )
                                    }

                                    {
                                        isLoading
                                            ?
                                            <>
                                                <div id="SEC_CH_SEC"
                                                    className="flex justify-center items-center h-full">
                                                    <span className='mx-2 loading-btn'>
                                                        <img alt={'loading-dark.svg'}
                                                            src='/assets/icons/loading-dark.svg' color="#121212"
                                                            width='24' height='24' />
                                                    </span>
                                                </div>
                                            </>
                                            : SearchItems.length === 0
                                                ?
                                                <div className="container my-8">
                                                    <CustomaAlert
                                                        titleProp={trans("info:title-alert")}
                                                        textProp={trans("info:text-alert")}
                                                    />
                                                </div>
                                                :
                                                <>
                                                    <div id="SEC_CH_SEC" className="nice-row" key={'0000'}>
                                                        {
                                                            SearchItems?.map(P => {
                                                                // searchItemsPagination?.map(P => {
                                                                // if (P.CategorySPID === item.id.toString())

                                                                if (valueRing && (valueRing.start !== 0 && valueRing.end !== 0) &&
                                                                    (
                                                                        (valueRing.start >= P.SalesPrice || valueRing.end >= P.SalesPrice) &&
                                                                        (valueRing.start <= P.SalesPrice || valueRing.end <= P.SalesPrice)
                                                                    )
                                                                )
                                                                    if (P.SalesPriceDiscount < 0)
                                                                        return (
                                                                            <div
                                                                                className="nice-col-lg-3 nice-col-md-4 nice-col-sm-6 nice-col-xs-6 my-10">
                                                                                <Part Engine_Groups={P.CategiorAr}
                                                                                    SalesPriceBefor={P.SalesPrice}
                                                                                    GUID={P.GUID}
                                                                                    Weight={P.Weight} height={P.height}
                                                                                    width={P.width} length={P.length}
                                                                                    ProductImage={P.ProductImage}
                                                                                    DescriptionEn={P.DescriptionEn}
                                                                                    DescriptionAr={P.DescriptionAr}
                                                                                    NetSalePrice={P.SalesPriceDiscount}
                                                                                    ProductID={P.ProductID}
                                                                                    ProductNo={P.ProductNo} />
                                                                            </div>
                                                                        )
                                                                    // } 
                                                                    else
                                                                        return (
                                                                            <div
                                                                                className="nice-col-lg-3 nice-col-md-4 nice-col-sm-6 nice-col-xs-6 my-10">
                                                                                <Part Engine_Groups={P.CategiorAr}
                                                                                    SalesPriceBefor={P.SalesPriceDiscount}
                                                                                    GUID={P.GUID}
                                                                                    favorites={favoriteProducts}
                                                                                    Weight={P.Weight} height={P.height}
                                                                                    width={P.width}
                                                                                    length={P.length}
                                                                                    ProductImage={P.ProductImage}
                                                                                    DescriptionEn={P.DescriptionEn}
                                                                                    DescriptionAr={P.DescriptionAr}
                                                                                    NetSalePrice={P.SalesPrice}
                                                                                    ProductID={P.ProductID}
                                                                                    ProductNo={P.ProductNo} />
                                                                            </div>
                                                                        )
                                                            })
                                                        }
                                                    </div>

                                                    {SearchItems.length > 0 && countRow > 8 && (
                                                        <div className="mt-10 mb-6">
                                                            <PaginationComponent
                                                                lang={lang}
                                                                activePageProp={activePage}
                                                                itemsCountPerPageProp={itemsCountPer}  // * totalItemsCountProp/10
                                                                totalItemsCountProp={countRow}
                                                                pageRangeDisplayedProp={10} عدد الصصفحات الضاهرو
                                                                pageReturnProp={(val) => {
                                                                    setActivePage(val);
                                                                    getResultAll(null, {
                                                                        pageNumber: val,
                                                                        isPagination: true,
                                                                        isTextNull: false
                                                                    })

                                                                    // searchWithPagination(val);

                                                                    //console.log(`active page is ${val}`);
                                                                    //console.log(`SearchItems.length ${SearchItems.length}`);
                                                                }
                                                                }
                                                            />
                                                        </div>
                                                    )
                                                    }
                                                </>
                                    }
                                </>
                                // }
                                // {/*</>*/}
                                // })
                            }
                        </div>

                    </div>
                </div>
            </div>
        </Fragment >
    )
        ;
}