import { Fragment, useEffect, useState, useCallback } from "react";
import SwiperCore, { Pagination, Navigation, Autoplay, EffectFade } from "swiper";
import { useTranslation } from "react-i18next";
import Banner from "../../components/banner";
import { InputField } from "../../components/_input/_input";
import { TextArea } from "../../components/_input/_textArea";
import { DateInput2 as DateInput } from "../../components/_input/_dateInput_2";
import { RadioInput } from "../../components/_input/_radioInput";
import { UploadControl } from "../../components/_input/_uploadControl";
import { DropDownInput } from "../../components/_input/_dropdown";
import { FormButton } from "../../components/_input/_button";
import Breadcrumb from "../../components/__simple/breadcrumb";
import { toast } from 'react-toastify';
import axios from "axios";
import Joi from "joi";
import { GetMessages, formValidate, } from '../../utils/validation';
import config from '../../configuration/config.json';
import { getCleanSlug } from '../../utils/slugManager';
import PhoneDropDown from "../../components/_input/_phoneDropDown";
import { reverse } from "lodash";
import Skeleton from 'react-loading-skeleton'



SwiperCore.use([Pagination, Navigation, Autoplay, EffectFade]);

export function EmploymentDetailPage() {
    const { t: trans, i18n } = useTranslation(["common", "employment", "heading", "inputs", "model", "new"]);
    const [requestForm, setRequestForm] = useState({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        selectedGender: '',
        selectedNationality: 'SAU',
        chooseDateOfBirth: '',
        selectedStatus: '',
        selectedLanguageLevel: '',
        selectedComputerLevel: '',
        selectedDegree: '',
        chooseGraduation: '',
        file: '',
        note: ''
    });
    const [skillsList, setSkillsList] = useState([{}]);
    const [certificationList, setCertificationList] = useState([{}]);
    const [statusList, setStatusList] = useState({});
    const schema = Joi.object({
        firstName: Joi.string().required().messages(GetMessages()),
        lastName: Joi.string().required().messages(GetMessages()),
        email: Joi.string().email({ minDomainSegments: 2, tlds: { allow: false } }).required().messages(GetMessages()),
        phoneNumber: Joi.string().min(12).required().messages(GetMessages()),
        chooseDateOfBirth: Joi.string().required().messages(GetMessages()),
        chooseGraduation: Joi.string().required().messages(GetMessages()),
        selectedGender: Joi.string().required().messages(GetMessages()),
        selectedNationality: Joi.string().required().messages(GetMessages()),
        selectedLanguageLevel: Joi.string().required().messages(GetMessages()),
        selectedComputerLevel: Joi.string().required().messages(GetMessages()),
        selectedDegree: Joi.string().required().messages(GetMessages()),
        selectedStatus: Joi.string().required().messages(GetMessages()),
        file: Joi.string().required().messages(GetMessages()),
    });
    const [error, setError] = useState({});
    const [nationalitiesList, setNationalities] = useState([{}]);
    const [gender, setGender] = useState({});
    const [employment, setEmployment] = useState({ title: "" });
    const [loadingEmployment, setLoadingEmployment] = useState(true);
    const lang = (i18n.language || 'ar');
    const languageDir = 'language-' + lang;
    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
    const getYears = (start, end) => {
        let result = [];
        for (let index = start; index <= end; index++) {
            result.push({
                value: String(index), text: String(index)
            });
        }
        return reverse(result);
    };

    const [isLoadingBtnSend, setIsLoadingBtnSend] = useState(false);
    const submitRequest = async (e) => {
        e.preventDefault();
        const result = formValidate(schema, requestForm) ?? {};
        setError(result);
        try {
            if (Object.keys(result).length === 0 && isLoadingBtnSend == false) {
                setIsLoadingBtnSend(true);
                if (!(await isDataSentBefore(requestForm.email, requestForm.phoneNumber))) {

                    const response = await axios.post(
                        config.baseURL + 'add',
                        {
                            "object": "siteEmployment_applyments",
                            "values": {
                                "employmentId": parseInt(employment.id),
                                "firstName": requestForm.firstName,
                                "lastName": requestForm.lastName,
                                "phoneNumber": requestForm.phoneNumber,
                                "email": requestForm.email,
                                "nationality": requestForm.selectedNationality,
                                "brithDate": requestForm.chooseDateOfBirth,
                                "gender": requestForm.selectedGender,
                                "maritalStatus": requestForm.selectedStatus,
                                "englishLevel": requestForm.selectedLanguageLevel,
                                "computerLevel": requestForm.selectedComputerLevel,
                                "degree": requestForm.selectedDegree,
                                "graduationYear": parseInt(requestForm.chooseGraduation),
                                "notes": requestForm.note,
                                "cvFile": requestForm.file,
                                "PositionID": employment.positionId ? parseInt(employment.positionId) : null
                                // "ApplyDate": moment().format('YYYY-MM-DD HH:mm:ss.SSS')
                            }
                        }
                    );
                    if (response.status === 200 || response.status === 201) {
                        setIsLoadingBtnSend(false);
                        const defaultValue = { ...requestForm };
                        setRequestForm({
                            firstName: '',
                            lastName: '',
                            phoneNumber: '',
                            email: '',
                            selectedGender: defaultValue.selectedGender,
                            selectedNationality: defaultValue.selectedNationality,
                            chooseDateOfBirth: '',
                            selectedStatus: defaultValue.selectedStatus,
                            selectedLanguageLevel: defaultValue.selectedLanguageLevel,
                            selectedComputerLevel: defaultValue.selectedComputerLevel,
                            selectedDegree: defaultValue.selectedDegree,
                            chooseGraduation: defaultValue.chooseGraduation,
                            file: defaultValue.file,
                            note: ''
                        });
                        setError({});
                        toast.success(trans("employment:success_employment_success"));
                        //alert(trans("employment:success_employment_success"));
                    } else {
                        setIsLoadingBtnSend(false);
                    }
                } else {
                    setIsLoadingBtnSend(false);
                    toast.error(trans("employment:data-sent-before"));
                }
            }
        } catch (e) {
            setIsLoadingBtnSend(false);
            console.log(e);
        }

    };
    const isDataSentBefore = async (email, phone) => {
        let isExisted = false;
        try {
            const response = await axios.post(
                config.baseURL + "details",
                {
                    "Object": "siteEmployment_applyments",
                    "ObjectSettings": {
                        "MetaData": false
                    },
                    "option": "column",
                    "fields": "GUID",
                    // "Filters": `Where employmentId = ${parseInt(employment.id)} and (email = '${email}' or phoneNumber = '${phone}') and PositionID =${parseInt(employment.positionId)} `
                    "Filters": `Where  (email = '${email}' or phoneNumber = '${phone}') `
                }
            );
            if (response.status == 200) {
                isExisted = response.data.ApiObjectData.length > 0;
            }
        } catch (err) {
            console.log(err);
        }
        return isExisted;
    };
    useEffect(() => {
        callback();
    }, []);

    const callback = useCallback(async () => {
        try {
            setLoadingEmployment(true);
            const response = await axios.request({
                baseURL: config.controllURL,
                url: lang + "/api/employment/detail/" + getCleanSlug(),
                method: "get",
            });

            console.log('res', response);

            if (response == null || response.data == null || response.data == '') {
                setLoadingEmployment(false);
                return;
            }

            console.log('setEmployment', response.data);
            setEmployment(response.data);
            setLoadingEmployment(false);


            const nationalitiesResponse = await axios.post(
                config.baseURL + 'details',
                {
                    "object": "Sys_Nationalities",
                    "objectSettings": {
                        "MetaData": false
                    },
                    "option": "column",
                    "fields": "ID,DescriptionAr,DescriptionEn"
                }
            );





            if (nationalitiesResponse?.data?.ApiObjectData[0])
                setNationalities(nationalitiesResponse.data.ApiObjectData.map(e => ({ value: String(e['ID']), text: e[`${trans("model:description")}`] })));
            //************************************************************************************************************************
            const genderResponse = await axios.post(
                config.baseURL + 'details',
                {
                    "object": "SYS_LookUpDetails",
                    "objectSettings": {
                        "MetaData": false
                    },
                    "option": "column",
                    "fields": "ID,DescriptionAr,DescriptionEn",
                    "Filters": "where lookupID = 'Gender' "
                }
            );
            //*************************************************************************************************************************
            if (genderResponse?.data?.ApiObjectData[0])
                setGender({ value1: genderResponse.data.ApiObjectData[0].ID, value2: genderResponse.data.ApiObjectData[1].ID });

            const skillsResponse = await axios.post(
                config.baseURL + 'details',
                {
                    "object": "SiteSkillsLevel",
                    "objectSettings": {
                        "MetaData": false
                    },
                    "option": "column",
                    "fields": "ID,DescriptionAr,DescriptionEn"
                }
            );
            console.log('skills', skillsResponse);
            //***************************************************************************************************************************
            if (skillsResponse?.data?.ApiObjectData)
                setSkillsList(skillsResponse.data.ApiObjectData.map(e => ({ value: String(e['ID']), text: e[`${trans("model:description")}`] })));
            //*************************************************************************************************************************
            const certificationResponse = await axios.post(
                config.baseURL + 'details',
                {
                    "object": "SiteCertificateLevel",
                    "objectSettings": {
                        "MetaData": false
                    },
                    "option": "column",
                    "fields": "ID,DescriptionAr,DescriptionEn"
                }
            );
            setCertificationList(certificationResponse.data.ApiObjectData.map(e => ({ value: String(e['ID']), text: e[`${trans("model:description")}`] })));
            //*************************************************************************************************************************
            const statusResponse = await axios.post(
                config.baseURL + 'details',
                {
                    "object": "SYS_LookUpDetails",
                    "objectSettings": {
                        "MetaData": false
                    },
                    "option": "column",
                    "fields": "ID,DescriptionAr,DescriptionEn",
                    "Filters": "where lookupID = 'Marital Status'"
                }
            );
            setStatusList({ value1: statusResponse.data.ApiObjectData[0].ID, value2: statusResponse.data.ApiObjectData[1].ID });
        } catch (error) {
            setLoadingEmployment(false);
            console.log(error);
        }
    }, [lang]);

    return (
        <Fragment>



            {

                loadingEmployment == true ?
                    (
                        <div className="container mb-32 mt-32">

                            <Skeleton count={1} borderRadius={"1rem"} width={"100%"} height={"30rem"}
                                containerClassName={"flex flex-col"} className={"  my-10"}
                                inline={true} />

                            <Skeleton count={5} borderRadius={"1rem"} width={"100%"} height={"10rem"}
                                containerClassName={"flex flex-col"} className={"  my-10"}
                                inline={true} />

                        </div>

                    )


                    :
                    employment.id != null ?
                        <>
                            <Banner
                                subtitle={employment?.department}
                                title={employment?.position}
                                desc={employment?.desc}
                                background={employment?.imagePath}
                                backOpasity={employment?.backOpasity}
                            />
                            <div className="container">
                                <Breadcrumb className={"mt-16 "}
                                    links={[
                                        {
                                            name: trans("common:employment"),
                                            to: "/recruitment"
                                        },
                                        {
                                            name: employment.position
                                        }
                                    ]}
                                />
                            </div>

                            <div className={"container mb-32 grid-2-cols " + languageDir} style={{ gap: "3rem" }}>

                                <div className={"flex-grow-1"}>
                                    <div className={"w-full relative"}>

                                        <div className={"mb-20"}>
                                            <div className="job-heading">
                                                {trans("employment:roles")}
                                            </div>
                                            {

                                                employment?.role &&
                                                <ul className={"list-bullet"}
                                                    dangerouslySetInnerHTML={{
                                                        __html:
                                                            "<li>" + employment.role?.replaceAll("\n", "</li><li>") + "</li>"
                                                    }}>

                                                </ul>
                                            }


                                        </div>
                                        <div className={"mb-20"}>
                                            <div className="job-heading">
                                                {trans("employment:skills")}
                                            </div>

                                            {
                                                employment?.skill &&
                                                <ul className={"list-bullet"}
                                                    dangerouslySetInnerHTML={{
                                                        __html:
                                                            "<li>" + employment.skill?.replaceAll("\n\n", "\n").replaceAll("\n", "</li><li>") + "</li>"
                                                    }}>

                                                </ul>
                                            }



                                        </div>

                                        <div>
                                            <div className="job-heading">
                                                {trans("employment:additional")}
                                            </div>

                                            {
                                                employment?.additional &&
                                                <ul className={"list-bullet"}
                                                    dangerouslySetInnerHTML={{
                                                        __html:
                                                            "<li>" + employment.additional?.replaceAll("\n\n", "\n").replaceAll("\n", "</li><li>") + "</li>"
                                                    }}>

                                                </ul>
                                            }


                                        </div>
                                    </div>
                                </div>
                                <div className={"inputs-group flex-grow-1"}>
                                    <form className="flex flex-col nice-gap-3rem" onSubmit={(e) => {
                                        submitRequest(e);
                                    }}>
                                        <InputField
                                            placeholder={trans("employment:firstname")}
                                            value={requestForm.firstName}
                                            errorLabel={error.firstName}
                                            onChangeValue={(e) => {
                                                const val = { ...requestForm };
                                                val.firstName = e.target.value;
                                                setRequestForm(val);
                                            }}
                                            name='firstName'
                                        />
                                        <InputField
                                            placeholder={trans("employment:lastname")}
                                            value={requestForm.lastName}
                                            onChangeValue={(e) => {
                                                const val = { ...requestForm };
                                                val.lastName = e.target.value;
                                                setRequestForm(val);
                                            }}
                                            errorLabel={error.lastName}
                                            name='lastName'
                                        />
                                        <PhoneDropDown
                                            mandotory={true}
                                            name='phoneNumber'
                                            errorLabel={error?.phoneNumber}
                                            onChangeValue={(countryCode, phoneNumber) => {
                                                const val = { ...requestForm };
                                                val.phoneNumber = `+${countryCode}${phoneNumber}`;
                                                setRequestForm(val);
                                            }}

                                        />

                                        <InputField
                                            placeholder={trans("employment:email")}
                                            value={requestForm.email}
                                            type='email'
                                            onChangeValue={(e) => {
                                                const val = { ...requestForm };
                                                val.email = e.target.value;
                                                setRequestForm(val);
                                            }}
                                            errorLabel={error.email}
                                            name='email'
                                        />
                                        <div className="flex flex-row grid-2-cols nice-gap-2rem ">
                                            <RadioInput
                                                onChangeValue={(e) => {
                                                    const val = { ...requestForm };
                                                    val.selectedGender = String(e);
                                                    setRequestForm(val);
                                                    console.log(e);
                                                }}
                                                name="selectedGender"
                                                groupName="Gender"
                                                title={trans("employment:type")}
                                                value1={gender.value1}
                                                text1={trans("employment:man")}
                                                text2={trans("employment:female")}
                                                value2={gender.value2}
                                                errorLabel={error.selectedGender}
                                            />
                                            <RadioInput
                                                onChangeValue={(e) => {
                                                    const val = { ...requestForm };
                                                    val.selectedStatus = String(e);
                                                    setRequestForm(val);
                                                    console.log(e);
                                                }
                                                }
                                                name="selectedStatus"
                                                groupName="SocialStatus"
                                                title={trans("employment:state")}
                                                text1={trans("employment:marred")}
                                                value1={statusList.value1}
                                                text2={trans("employment:single")}
                                                value2={statusList.value2}
                                                errorLabel={error.selectedStatus}
                                            />
                                        </div>
                                        <DropDownInput
                                            onSelect={(e) => {
                                                const val = { ...requestForm };
                                                val.selectedNationality = String(e);
                                                console.log(e);
                                                setRequestForm(val);
                                            }}
                                            name='selectedNationality'
                                            selectedValue={requestForm.selectedNationality}
                                            className={"flex-grow-1"}
                                            placeholder={trans("employment:nationalte")}
                                            options={[...nationalitiesList]}
                                            errorLabel={error.selectedNationality}
                                            defaultOption={requestForm.selectedNationality ? null : { value: "SAU", text: lang == "ar" ? "سعودي  / سعودية" : "Kingdom of Saudi Arabia" }}


                                        />
                                        <DateInput
                                            value={requestForm.chooseDateOfBirth}
                                            onSelect={
                                                (e) => {
                                                    const val = { ...requestForm };
                                                    val.chooseDateOfBirth = e.target.value;
                                                    setRequestForm(val);
                                                }
                                            }
                                            name='chooseDateOfBirth'
                                            placeholder={trans("employment:birthday")}

                                            errorLabel={error.chooseDateOfBirth}
                                        />


                                        <DropDownInput
                                            defaultOption={{
                                                text: trans("employment:levelEn"),
                                                value: '0'
                                            }}
                                            onSelect={(e) => {
                                                const val = { ...requestForm };
                                                val.selectedLanguageLevel = String(e);
                                                setRequestForm(val);
                                            }}
                                            selectedValue={requestForm?.selectedLanguageLevel}
                                            name='selectedLanguageLevel'
                                            className={"flex-grow-1"}
                                            options={[...skillsList]}
                                            errorLabel={error.selectedLanguageLevel}
                                        />

                                        <DropDownInput
                                            defaultOption={{
                                                text: trans("employment:skilsComputer"),
                                                value: '0'
                                            }}
                                            selectedValue={requestForm?.selectedComputerLevel}
                                            onSelect={(e) => {
                                                const val = { ...requestForm };
                                                val.selectedComputerLevel = String(e);
                                                setRequestForm(val);
                                            }}
                                            name='selectedComputerLevel'
                                            className={"flex-grow-1 "}
                                            options={[...skillsList]}
                                            errorLabel={error.selectedComputerLevel}
                                        />
                                        <DropDownInput
                                            defaultOption={{
                                                text: trans("employment:dagree"),
                                                value: '0'
                                            }}
                                            selectedValue={requestForm?.selectedDegree}
                                            onSelect={(e) => {
                                                const val = { ...requestForm };
                                                val.selectedDegree = String(e);
                                                setRequestForm(val);
                                            }}
                                            name='selectedDegree'
                                            className={"flex-grow-1"}
                                            options={[...certificationList]}
                                            errorLabel={error.selectedDegree}
                                        />
                                        <DropDownInput

                                            defaultOption={{
                                                text: trans("employment:yearOuter"),
                                                value: '0'
                                            }}
                                            selectedValue={requestForm?.chooseGraduation}
                                            onSelect={(e) => {
                                                const val = { ...requestForm };
                                                val.chooseGraduation = String(e);
                                                setRequestForm(val);
                                            }}
                                            className={"flex-grow-1"}
                                            errorLabel={error.chooseGraduation}
                                            name='chooseGraduation'
                                            options={[...getYears(1960, new Date().getFullYear())]}
                                        />
                                        {/* <FileInput
                   errorLabel={error.file}
                   onPicker={
                       async (e) => {
                           if (e.target.value.length > 0) {
                               let file = e.target.files[0];
                               const size = file.size;
                               const finalCheck = Math.round((size / 1024));
                               if (finalCheck >= 4096) {
                                   toast.error(trans('new:file_size_error'));
                                   return;
                               }
                               const val = { ...requestForm };
                               const fileBase64 = await toBase64(file);
                               const finalFile = fileBase64.substr(fileBase64.indexOf(',') + 1, fileBase64.length);
                               val.file = finalFile;
                               setRequestForm(val);
                           }
                       }
                   }
                   name='file'
                   title={trans("employment:fileCV")}
               /> */}


                                        <div className="grow input  ">

                                            {/* <ErrorLabel errorTitle={error?.file} /> */}
                                            <UploadControl
                                                placeholder={trans("employment:fileCV")}
                                                errorLabel={error?.identificationImage}
                                                extraClasses={"border-none border-radius-sm  text-24 "}
                                                value={requestForm?.fileName}
                                                onPicker={async (e) => {
                                                    const { target } = e;
                                                    const { files } = target;
                                                    const loadedImage = files[0];

                                                    // loadedImage will be a image file.
                                                    if (target.value && loadedImage?.name) {
                                                        const size = loadedImage.size;
                                                        const finalCheck = Math.round((size / 1024));
                                                        if (finalCheck >= 4096) {
                                                            toast.error(trans('new:file_size_error'));
                                                            return;
                                                        }

                                                        const val = { ...requestForm };
                                                        val.fileName = loadedImage?.name;
                                                        const fileBase64 = await toBase64(loadedImage);
                                                        const finalFile = fileBase64.substr(fileBase64.indexOf(',') + 1, fileBase64.length);
                                                        val.file = finalFile;
                                                        setRequestForm(val);
                                                    } else {
                                                        const val = { ...requestForm };
                                                        val.file = "";
                                                        val.fileName = "";
                                                        setRequestForm(val);
                                                    }
                                                }} accept="*" />
                                        </div>

                                        <TextArea
                                            value={requestForm.note}
                                            onChangeValue={
                                                (e) => {
                                                    const val = { ...requestForm };
                                                    val.note = e;
                                                    setRequestForm(val);
                                                }
                                            }
                                            name='note'
                                            placeholder={trans("employment:note")}
                                        />
                                        <FormButton
                                            isLoading={isLoadingBtnSend}
                                            name={trans("employment:send")}
                                        />
                                    </form>
                                </div>

                            </div>

                        </>
                        :
                        <div className={"container error-page my-32"}>
                            <div className="error-text">

                                <div className="error-heading">
                                    {trans("messages:page_not_found")}
                                </div>
                                <h1 className={"error-code"}>
                                    404 {employment.id}
                                </h1>
                                <p className="error-message">
                                    {trans("messages:page_not_found_message")}
                                </p>

                            </div>

                            <div className={'error-image'}>
                                <img src="/assets/svg_background/error.svg" alt="" />
                            </div>
                        </div>
            }

        </Fragment>
    );
}
