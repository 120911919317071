import React, { useState } from "react";

function CustomItemServices({ moreLink, img, title, borderCSS = "", key, children }) {

    return (
        <>
            <div key={key} className={'border-box-periodic-maintenance nice-apply-theme bg-theme-up text-center bg-white ' + borderCSS.toString()}>

                {
                    children &&
                    children
                }
                <h3 className="text-20 font-bold font-family"> {title} </h3>
            </div>
        </>
    );
}

export default CustomItemServices;
