import { includes, sortedUniq } from "lodash";
import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Switch from "react-switch";
import SectionHeading from '../../../components/sectionHeading';
import { DropDownInput } from '../../../components/_input/_dropdown';
import config from "../../../configuration/config.json"
export const SearchHeader = ({ resultNumber = 0, onSearch, onSearchClick, speedTyping = 80, delayTyping = 5000, hintsTyping = null, onSupportFilter, hasFilters = false }) => {
    const { t: trans,i18n } = useTranslation(["navbar", "model", "global"]);
    let lang = (i18n.language || 'ar');
    const dir = lang == 'en' ? 'left' : 'right';
    const [textTyping, setTextTyping] = useState('');
    const [textPostion, setTextPosition] = useState(0);
    const [textSectionInArray, setTextSectionInArray] = useState(0);
    const [showPlasceHolder, setShowPlaceHolder] = useState(true);
    const [hints, setHints] = useState(hintsTyping ?? [
        {
            ar: 'ابحث عن افخم السيارات المقدمة من حسن جميل',
            en: 'Look for the most luxurious cars offered by Hassan Jameel',
        },
        {
            ar: 'عبر موقع حسن جميل تستطيع الوصول الى قطع غيار سيارتك',
            en: 'Through the Hassan Jameel website, you can find spare parts for your car',
        },
        {
            ar: 'استغل العروض المقدمة من حسن جميل وكن اول المستفيدين منها',
            en: 'Take advantage of the offers offered by Hassan Jameel and be the first to benefit from them',
        },
        {
            ar: 'تابع جديد الاخبار المتعلقة بمعرض حسن جديد',
            en: 'Follow new news related to the new Hassan exhibition',
        },
        {
            ar: 'ابحث في حملات قطع الغيار المقدمة من حسن جميل',
            en: 'Search for spare parts campaigns offered by Hassan Jameel',
        }
    ]);
    let timer = null, delayTimer = null;
    const searchRef = useRef();
    const [filterOptions, setFilterOptions] = useState({
        sort: 'desc',
        showOptions: []
    });
    const [showOptions, setShowOptions] = useState({
        cars: false,
        maintenance: false,
        offers: false,
        campaign: false,
        news: false,
        jobs: false,
        eparts: false
    });
    useEffect(() => {
        if (searchRef.current !== document.activeElement && (searchRef.current.value !== '' || searchRef.current.value != null)) {
            startTyping(speedTyping, delayTyping);
        }
    }, [textTyping, textSectionInArray]);

    // start the typiny as hints
    function startTyping(speed, delay) {
        try {
            const hintValue = hints[textSectionInArray][`${lang}`];
            timer = setTimeout(() => {

                if (textSectionInArray < hints.length) {

                    if (textPostion < hintValue.length) {
                        let liter = hintValue.charAt(textPostion);
                        let newValue = textTyping + liter;
                        setTextPosition(textPostion + 1);
                        setTextTyping(newValue);
                    }
                }
            }, speed);
            if (textTyping.length == hintValue.length) {
                delayTimer = setTimeout(() => {
                    setTextPosition(0);
                    setTextTyping('');
                    if (textSectionInArray < hints.length - 1) {
                        setTextSectionInArray(textSectionInArray + 1);
                    } else if (textSectionInArray == hints.length - 1) {
                        resetTyping();
                    }
                }, delay);
            }
        } catch (error) {
            clearTimeout(timer);
        }
    };
    // fire once the focus on the text input
    const handleFocus = () => {
        setShowPlaceHolder(false);
        resetTyping();
    };
    // fire once the blur event occurs
    const handleBlur = () => {
        if (searchRef.current.value == '' || searchRef.current.value == null) {
            setShowPlaceHolder(true);
            resetTyping();
            startTyping(100);
        }
    };
    // reset the text input 
    const resetTyping = () => {
        setTextTyping('');
        setTextPosition(0);
        setTextSectionInArray(0);
        clearTimeout(timer);
        clearTimeout(delayTimer);
    };
    // fire to change the value
    const handleOnChange = (event) => {
        if (onSearch)
            onSearch(event.target.value);
    };
    // to add the value to array
    const handleDragOption = (state, option) => {
        let options = sortedUniq([...filterOptions.showOptions]);
        if (state) {
            options.push(option);
        } else {
            options = options.filter(item => {
                return item !== option
            });
        }
        const val = { ...filterOptions };
        val.showOptions = sortedUniq(options);
        setFilterOptions(val);
    }
    return (
        <div className="search">
            <div className="search-header">
                <div className="search-header-overlay"></div>
                <div className="search-header-content nice-row">
                    <div className={`search-header-content-box search-header-content-box-${lang == 'en' ? 'l' : 'r'} nice-col-9 mb-4`} onClick={handleFocus}>
                        <input type='text' onChange={(event) => { handleOnChange(event) }} className="p-3" ref={searchRef} onFocus={handleFocus} onBlur={handleBlur} />
                        {showPlasceHolder && <label className={`auto-type mx-2 auto-type-${lang == 'en' ? 'l' : 'r'}`}>
                            <span className="writeHints text-16">{textTyping}</span>
                            <span className="rulerBlock text-16">|</span>
                        </label>
                        }
                    </div>
                    <div className="nice-col-3" onClick={() => {
                        if (onSearchClick) {
                            onSearchClick(filterOptions);
                        }
                    }}>
                        <div className="search-btn bg-primary p-2">
                            <span className="text-inline-block text-16 text-white mx-2">{trans("info:search_now")}</span>
                            <img src="/assets/icons/search.svg" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="search-filters p-4">
                <div className="nice-flex nice-flex-row nice-flex-space-between">
                    <div className="search-filters-heading nice-flex nice-flex-row nice-flex-gap-1rem nice-align-item-center ">
                        <img src='/assets/icons/filter.svg' className="filter-primary" />
                        <h3 className="text-14 text-bold">{trans("global:filters_options")}</h3>
                    </div>
                    <Switch
                        checked={hasFilters}
                        onChange={(state) => {
                            if (onSupportFilter) {
                                onSupportFilter(state);
                            }
                            if (!state) {
                                setFilterOptions(
                                    {
                                        sort: 'desc',
                                        showOptions: []
                                    }
                                );
                                setShowOptions({
                                    cars: false,
                                    maintenance: false,
                                    offers: false,
                                    campaign: false,
                                    news: false,
                                    jobs: false,
                                    eparts: false
                                });
                            }
                        }}
                        onColor="#86d3ff"
                        onHandleColor="#2693e6"
                        handleDiameter={30}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={20}
                        width={48}
                        className="react-switch"
                        id="material-switch"
                    />
                </div>

                <div className="ruler-2 my-2"></div>
                {

                    hasFilters && (
                        <div className="nice-row">
                            <div className="nice-col-sm-6 nice-col-offset-6">
                                <SectionHeading
                                    hasRuler={false}
                                    title={trans("global:show_options")}
                                >
                                    <div className="nice-flex nice-flex-columns nice-flex-gap-1rem nice-flex-wrap-content">
                                        <label className="nice-flex nice-flex-row nice-align-item-center m-2">
                                            <span className="text-14 text-grey">{trans("global:vehicle")}</span>
                                            <Switch
                                                checked={showOptions.cars}
                                                onChange={(state) => {
                                                    const val = { ...showOptions };
                                                    val.cars = state;
                                                    setShowOptions(val);
                                                    handleDragOption(state, 1);
                                                }}
                                                onColor="#86d3ff"
                                                onHandleColor="#2693e6"
                                                handleDiameter={30}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                height={20}
                                                width={48}
                                                className="react-switch"
                                                id="material-switch"
                                            />
                                        </label>
                                        <label className="nice-flex nice-flex-row nice-align-item-center m-2">
                                            <span className="text-14 text-grey">{trans("global:spare_parts")}</span>
                                            <Switch
                                                checked={showOptions.eparts}
                                                onChange={(state) => {
                                                    const val = { ...showOptions };
                                                    val.eparts = state;
                                                    setShowOptions(val);
                                                    handleDragOption(state, 2);
                                                }}
                                                onColor="#86d3ff"
                                                onHandleColor="#2693e6"
                                                handleDiameter={30}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                height={20}
                                                width={48}
                                                className="react-switch"
                                                id="material-switch"
                                            />
                                        </label>
                                        <label className="nice-flex nice-flex-row nice-align-item-center m-2">
                                            <span className="text-14 text-grey">{trans("global:news")}</span>
                                            <Switch
                                                checked={showOptions.news}
                                                onChange={(state) => {
                                                    const val = { ...showOptions };
                                                    val.news = state;
                                                    setShowOptions(val);
                                                    handleDragOption(state, 3);
                                                }}
                                                onColor="#86d3ff"
                                                onHandleColor="#2693e6"
                                                handleDiameter={30}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                height={20}
                                                width={48}
                                                className="react-switch"
                                                id="material-switch"
                                            />
                                        </label>
                                        <label className="nice-flex nice-flex-row nice-align-item-center m-2">
                                            <span className="text-14 text-grey">{trans("global:jobs")}</span>
                                            <Switch
                                                checked={showOptions.jobs}
                                                onChange={(state) => {
                                                    const val = { ...showOptions };
                                                    val.jobs = state;
                                                    setShowOptions(val);
                                                    handleDragOption(state, 4);
                                                }}
                                                onColor="#86d3ff"
                                                onHandleColor="#2693e6"
                                                handleDiameter={30}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                height={20}
                                                width={48}
                                                className="react-switch"
                                                id="material-switch"
                                            />
                                        </label>
                                        <label className="nice-flex nice-flex-row nice-align-item-center m-2">
                                            <span className="text-14 text-grey">{trans("global:maintenance_services")}</span>
                                            <Switch
                                                checked={showOptions.maintenance}
                                                onChange={(state) => {
                                                    const val = { ...showOptions };
                                                    val.maintenance = state;
                                                    setShowOptions(val);
                                                    handleDragOption(state, 5);
                                                }}
                                                onColor="#86d3ff"
                                                onHandleColor="#2693e6"
                                                handleDiameter={30}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                height={20}
                                                width={48}
                                                className="react-switch"
                                                id="material-switch"
                                            />
                                        </label>

                                        <label className="nice-flex nice-flex-row nice-align-item-center m-2">
                                            <span className="text-14 text-grey">{trans("global:offers")}</span>
                                            <Switch
                                                checked={showOptions.offers}
                                                onChange={(state) => {
                                                    const val = { ...showOptions };
                                                    val.offers = state;
                                                    setShowOptions(val);
                                                    handleDragOption(state, 6);
                                                }}
                                                onColor="#86d3ff"
                                                onHandleColor="#2693e6"
                                                handleDiameter={30}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                height={20}
                                                width={48}
                                                className="react-switch"
                                                id="material-switch"
                                            />
                                        </label>
                                        <label className="nice-flex nice-flex-row nice-align-item-center m-2">
                                            <span className="text-14 text-grey">{trans("global:campaign")}</span>
                                            <Switch
                                                checked={showOptions.campaign}
                                                onChange={(state) => {
                                                    const val = { ...showOptions };
                                                    val.campaign = state;
                                                    setShowOptions(val);
                                                    handleDragOption(state, 7);
                                                }}
                                                onColor="#86d3ff"
                                                onHandleColor="#2693e6"
                                                handleDiameter={30}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                height={20}
                                                width={48}
                                                className="react-switch"
                                                id="material-switch"
                                            />
                                        </label>


                                    </div>


                                </SectionHeading>
                                <SectionHeading
                                    hasRuler={false}
                                    title={trans("global:sort_result")}
                                >
                                    <div className="nice-width-size-40">
                                        <DropDownInput
                                            options={[
                                                {
                                                    text: trans("global:desc"), value: "desc"
                                                },
                                                {
                                                    text: trans("global:asc"), value: "asc"
                                                },
                                            ]}
                                            onSelect={(value) => {
                                                const val = { ...filterOptions };
                                                val.sort = value;
                                                setFilterOptions(val);
                                            }}
                                        />
                                    </div>
                                </SectionHeading>
                            </div>

                        </div>
                    )

                }
            </div>
            <div className="text-16 text-bold text-gray text-inline-block nice-col-12 mt-4">
                {trans("info:search_result") + ': (' + resultNumber + ')'}
            </div>
        </div>
    );
}
export const SearchResultItem = ({ title, description, image, type = 0, key, target = '', extra }) => {
    const { i18n } = useTranslation();
    let lang = (i18n.language || 'ar');
    const DEFAULT_PATH = '/assets/icons/search/';
    const [icon, setIcon] = useState('');
    const dir = lang == 'en' ? 'left' : 'right';
    const [redirect, setRedirect] = useState('');
    useEffect(() => {
        let iconName = '';
        let redirectPath = '';
        switch (Number(type)) {
            case 1:
                iconName = 'car.svg';
                redirectPath = '/vehicles/' + extra + "/" + target;
                break;
            case 2:
                iconName = 'maintenance.svg';
                redirectPath = '/eparts/detail/' + target;
                break;
            case 3:
                iconName = 'news.svg';
                redirectPath = '/news/detail/' + extra;
                break;
            case 4:
                iconName = 'speackers.svg';
                redirectPath = '/recruitment/detail/' + extra;
                break;
            case 5:
                iconName = 'speackers.svg';
                redirectPath = '/eparts/compaigndetails/' + target;
                break;
            case 6:
                iconName = 'maintenance.svg';
                redirectPath = extra + target;
                break;
        }

        setIcon(DEFAULT_PATH + iconName)
        setRedirect(redirectPath);
    }, []);
    return (
        <Link to={redirect} key={key} className={`search-result-item mb-4 p-3 search-result-item-${dir}`}>

            <div className="search-result-item-content">
                <div className="text-16 text-bold m-4 nice-title">
                    {title}
                </div>
                <div className="text-14 text-gray mx-5 nice-title">
                    {description}
                </div>
            </div>
            {
                image ? <div className="search-result-item-img-box search-result-item-img-box-contianer">
                    <img src={config.FilePathURL + image} />
                </div>
                    : <div className="search-result-item-img-box">
                        <img src={icon} className="filter-primary" />
                    </div>
            }

        </Link>
    )
}