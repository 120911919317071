import { Map } from "./map";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from 'react';
import axios from "axios";
import ContactList from "./contactList";
import config from "../../../configuration/config.json";
export function MapBanner({ }) {
    const { t: trans, i18n } = useTranslation(["common", "inputs", "offers", "home"]);
    const lang = (i18n.language || 'ar');

    const [branches, setBranches] = useState([]);
    const [contacts, setContacts] = useState([]);

    useEffect(async () => {

        try {

            const response = await axios.request({
                 baseURL: config.controllURL,
                url: lang + "/api/contact/contact/",
                method: "get",
            });

            setBranches(response.data.branches);
            setContacts(response.data.contacts);

        } catch (error) {
            console.log(error);
        }

        return () => {

        }
    }, []
    );
    const [geoLocation, setGeoLocation] = useState({
        location: {
            name: '',
            content: '',
            latitude: '',
            longitude: ''
        }
    });

    function handleChangeLocation(geo) {
        console.log("handleChangeLocation:", geo)
        const { latitude, longitude, content, name } = geo;
        const newLocation = { ...geoLocation };
        newLocation.location.latitude = latitude;
        newLocation.location.longitude = longitude;
        newLocation.location.content = content;
        newLocation.location.name = name;
        setGeoLocation(newLocation);
    }

    useEffect(() => {
        try {
            console.log('length', branches.length);
            if (branches.length > 0)
                handleChangeLocation(branches[0]);
        } catch (err) {
        }
    }, [branches]);




    return (
        <div className=" mx-10 mb-8 banner-container-map border-radius box-shadow-large">
            <Map longitude={geoLocation.location.longitude} latitude={geoLocation.location.latitude} content={geoLocation.location.content} name={geoLocation.location.name} />
            <div className={"border-radius box-shadow banner-container-map-list bg-white p-8 hide-xs"}>
                {
                    branches.map((branch, index) => {
                        return (
                            <ContactList
                                key={index}
                                onGeo={(e) => handleChangeLocation(e)}
                                branch={branch}
                                contacts={contacts.filter((contact) => contact.branchId == branch.name)}
                            />
                        );
                    })
                }
            </div>
        </div>
    )

}

