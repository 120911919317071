import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Banner from "../../components/banner";
import { InputField } from "../../components/_input/_input";
import { PhoneInput } from "../../components/_input/_phoneInput";
import { TextArea } from "../../components/_input/_textArea";
import { DropDownInput } from "../../components/_input/_dropdown";
import { FormButton } from "../../components/_input/_button";
import Breadcrumb from "../../components/__simple/breadcrumb";
import { CarColorPicker } from "../vehicles/components/colorPicker";
import { useHistory, useLocation } from "react-router";
import config from '../../configuration/config.json';
import axios from "axios";
import Joi from "joi";
import { GetMessages, formValidate, validateProperty } from '../../utils/validation';
import { toast } from "react-toastify";
import _ from 'lodash';
export function OrderFinancePage() {
    const history = useHistory();

    const languageDir = "language-" + lang;
    const { t: trans,i18n } = useTranslation(["error", "heading", "model", "vehicales", "form", "finance", "common"]);
    const [selectedColor, setSelectedColor] = useState(null);
    let lang =  (i18n.language || 'ar');
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const [offerId, setOfferId] = useState();
    const [terms, setTerms] = useState(null);
    const [vehicleDetails, setVehicleDetails] = useState({});
    const [supportedColors, setSupportedColors] = useState([]);
    const [error, setError] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [banner, setbanner] = useState([]);
    const [userData, setUserData] = useState({
        name: '',
        address: '',
        email: '',
        phoneNumber: '',
        quantity: 1,
        message: '',
        note: ''

    });

    const schema = Joi.object({
        name: Joi.string().required().messages(GetMessages()),
        address: Joi.string().required().messages(GetMessages()),
        email: Joi.string().email({ minDomainSegments: 2, tlds: { allow: false} }).required().messages(GetMessages()),
        phoneNumber: Joi.string().length(13).required().messages(GetMessages()),
        quantity: Joi.number().min(1).required().messages(GetMessages())
    });

    useEffect(() => {
        try {
            const id = query.get("id").split("_")[1];
            setOfferId(id);
            initialBanner();
            initialVehicle(id);
            initialColors();
            initialTerms();
        } catch (error) {
            console.log(error);
        }
    }, []);

    const initialTerms = () => {
        axios.post(
            config.baseURL + "details",
            {
                "Object": "Financil_Bank_Condations",
                "ObjectSettings": {
                    "MetaData": false
                },
                "Filters": `where ShowInWebsite = 1 and bankId = '${query.get("b")}'`
            }
        ).then(response => {
            const result = response.data.ApiObjectData;
            if (result.length > 0) {
                setTerms(result.map(e => {
                    return { description: e[`${trans("model:description")}`] }
                })[0]);
            }
        }).catch(error => {
            console.log(error);
        });
    };
    // initial colors
    const initialColors = () => {
        axios.post(
            config.baseURL + "details",
            {
                "Object": "SiteColorView",
                "ObjectSettings": {
                    "MetaData": false
                },
            }
        ).then(response => {
            const result = response.data.ApiObjectData;
            if (result.length > 0) {
                const colors = result.filter(ee => {
                    return (ee.CarGroupID == query.get("group") && (query.get("model") >= ee.StartModel && query.get("model") <= ee.EndModel))
                })
                setSupportedColors(colors);
            };
        }).catch(error => {
            console.log(error);
        });
    };
    // initilal vehicle values
    const initialVehicle = (offerId) => {
        axios.post(
            config.baseURL + "details",
            {
                "Object": "SiteCarsFinanceDataView",
                "ObjectSettings": {
                    "MetaData": false
                },
                "Option": "column",
                "Fields": "HorsePower,Cylinder,Liter,Petrol,Seats,GroupImage",
                "Filters": `where EndFinance = 0 and BankTermId = ${offerId}`
            }
        ).then(respone => {
            const result = respone.data.ApiObjectData;
            if (result.length > 0) {
                const info = result[0];
                setVehicleDetails({
                    hoursePower: info.HorsePower,
                    cylinder: info.Cylinder,
                    liter: info.Liter,
                    petrol: info.Petrol,
                    seats: info.Seats,
                    image: info.GroupImage
                });
            }
        }).catch(error => {
            console.log(error);
        });
    };
    // send request
    const handleRequestOrder = async () => {
        const result = formValidate(schema, userData) ?? {};
        setError(result);
        if (Object.keys(result).length === 0) {
            setIsLoading(true);
            if (supportedColors?.length > 0 && selectedColor == null) {
                toast.error(trans("finance:color_not_select"));
                setIsLoading(false);
                return;
            }
            const data = await getNewUserID();
            alert(JSON.stringify(data));
            if (data.hasAlready) {
                const statusOrder = await createOrder(data.id);
                alert(JSON.stringify(statusOrder));
                if (statusOrder) {
                    toast.success(trans("common:order-request-success"));
                    setError({});
                    history.push("/finance")
                } else {
                    toast.error(trans("finance:error_send_data"));
                }
            } else {
                const status = await createFinancialUser(data.id);
                if (status) {
                    const statusOrder = await createOrder(data.id);
                    if (statusOrder) {
                        toast.success(trans("common:order-request-success"));
                        setError({});
                        history.push("/finance")
                    } else {
                        toast.error(trans("finance:error_send_data"));
                    }
                } else {
                    toast.error(trans("finance:error_send_data"));
                }
            }
        }
        setIsLoading(false);
    };
    // initilaize the new id for the financial user
    const getNewUserID = async () => {
        let userInfo = {
            hasAlready: false,
            id: 1
        };
        try {
            const response = await axios.post(
                config.baseURL + "details",
                {
                    "Object": "CustTable_Finance",
                    "ObjectSettings": {
                        "MetaData": false
                    },
                    "Option": "Column",
                    "Fields": "FinancialUserID,Email"
                }
            );
            if (response.status == 200) {
                const result = response.data.ApiObjectData;
                if (result.length > 0) {
                    const userExisting = result.find(e => e.Email.toString().toLowerCase() == userData.email.toLowerCase());
                    if (userExisting) {
                        userInfo.hasAlready = true;
                        userInfo.id = userExisting.FinancialUserID;
                    } else {
                        const arr = result.map(e => Number(e.FinancialUserID));
                        const maxNumber = _.max(arr) + 1;
                        userInfo.id = maxNumber;
                    }
                }
            }
        } catch (err) {
            console.log(err);
        }
        return userInfo;
    };
    // get the main essetional info about user [web_user_id & cust_id]
    const getMainUserData = async () => {
        let data = null;
        try {
            if (localStorage.getItem("isAuthenticated")) {
                data = {
                    userId: localStorage.getItem("userId"),
                    custId: localStorage.getItem("custId") != 'null'?localStorage.getItem("custId"):null
                };
            } else {
                const response = await axios.post(
                    config.baseURL + "details",
                    {
                        "Object": "web_users",
                        "Option": "Column",
                        "ObjectSettings": {
                            "MetaData": false,
                            "Limit": {
                                "Value": 1,
                                "Optional": "Top"
                            }
                        },
                        "Fields": "Web_UserId,CustID",
                        "Filters": `where lower(email) = lower('${userData.email}')`
                    }
                );
                if (response.status == 200) {
                    const result = response.data.ApiObjectData;
                    if (result.length > 0) {
                        const finalResult = result[0];
                        data = {
                            userId: finalResult.Web_UserId,
                            custId: finalResult.CustID
                        };
                    }
                }
            }
        } catch (err) {
            console.log(err);
        }
        return data;
    };
    // create new financial user
    const createFinancialUser = async (id) => {
        let success = false;
        try {
            const basicData = await getMainUserData();
            const response = await axios.post(
                config.baseURL + "add",
                {
                    "Object": "CustTable_Finance",
                    "Values": {
                        "FinancialUserID": id,
                        "Web_UserId": basicData ? Number(basicData.userId) : null,
                        "CustID": basicData ? basicData.custId ? String(basicData.custId) : null : null,
                        "Name": userData.name.trim(),
                        "Phone": userData.phoneNumber,
                        "Email": userData.email,
                        "Adderss": userData.address
                    }
                }
            );
            if (response.status == 200) {
                success = true;
            }
        } catch (err) {
            console.log(err);
        }
        return success;
    };
    // create new financial order
    const createOrder = async (id) => {
        let success = false;
        try {
            const response = await axios.post(
                config.baseURL + "add",
                {
                    "Object": "FinanceOrdersTable",
                    "Values": {
                        "FinanceUserID": Number(id),
                        "BankOfferID": Number(offerId),
                        "ColorID": selectedColor ? String(selectedColor.id) : null,
                        "Quantity": Number(userData.quantity),
                        "Note": userData.note,
                        "Message": userData.message
                    }
                }
            );
            alert(JSON.stringify(response));
            if (response.status == 200 || response.status ==201) {
                success = true;
            }
        } catch (err) {
            console.log(err);
        }
        return success;
    };
    //It's used to intialize the banner
    const initialBanner = () => {
        axios.post(
            config.baseURL + "details",
            {
                "object": "sitePageBanners",
                "ObjectSettings": {
                    "MetaData": false
                },
                "filters": "where bannerId=7"
            }
        ).then(response => {
            setTimeout(setbanner(response.data.ApiObjectData), 500);
        }).catch(err => {
            console.log(err);
        });
    };

    return (
        <Fragment>

            {banner.map(BN => {

                if (lang === "en") {
                    return (<Banner
                        background={"data:image/png;base64," + BN.image}
                        title={BN.titleEn}
                        subtitle={BN.subTitleEn}
                        desc={BN.excerptEn}
                        backOpasity={BN?.backOpasity}
                    />);
                } else {
                    return (<Banner
                        background={"data:image/png;base64," + BN.image}
                        title={BN.titleAr}
                        subtitle={BN.subTitleAr}
                        desc={BN.excerptAr}
                        backOpasity={BN?.backOpasity}
                    />);
                }
            })}
            <div className="container">
                <Breadcrumb className={"mt-16 "}
                    links={[
                        {
                            to: '/finance',
                            name: trans("finance:finance"),
                        },
                    ]}
                />
            </div>

            <div className={"container mb-32 " + languageDir}>

                <div className={"grid-2-cols  gap-10 mb-10"}>
                    <div className={"inputs-group flex-grow-1"}>
                        <div>
                            <div className="job-heading">
                                {trans("finance:finance_terms")}
                            </div>
                            {<ul className={"list-bullet"}
                                dangerouslySetInnerHTML={{
                                    __html:
                                        "<li>" + terms?.description.replaceAll("\n", "</li><li>") + "</li>"
                                }}>

                            </ul>}
                        </div>
                    </div>
                    <div className={"inputs-group flex-grow-1"}>
                        <h2 className="text-20 font-bold mb-6">{trans("finance:personal_info")}</h2>
                        <InputField
                            value={userData?.name}
                            errorLabel={error?.name}
                            onChangeValue={(e) => {
                                const val = { ...userData };
                                val.name = e.target.value;
                                setUserData(val);
                            }}
                            title={trans("form:name")}
                            name='name'
                        />
                        <InputField
                            value={userData?.address}
                            errorLabel={error?.address}
                            onChangeValue={(e) => {
                                const val = { ...userData };
                                val.address = e.target.value;
                                setUserData(val);
                            }}
                            title={trans("form:address")}
                            name='address'
                        />
                        <PhoneInput
                            title={trans("form:phone")}
                            name='phoneNumber'
                            errorLabel={error?.phoneNumber}
                            onChangeValue={
                                (e) => {
                                    const val = { ...userData };
                                    val.phoneNumber = e;
                                    setUserData(val);
                                }
                            }
                        />
                        <InputField
                            value={userData.email}
                            type='email'
                            onChangeValue={(e) => {
                                const val = { ...userData };
                                val.email = e.target.value;
                                setUserData(val);
                            }}
                            errorLabel={error?.email}
                            title={trans("form:email")}
                            name='email'
                        />
                        <TextArea
                            value={userData?.message}
                            onChangeValue={
                                (e) => {
                                    const val = { ...userData };
                                    val.message = e;
                                    setUserData(val);
                                }
                            }
                            name='message'
                            title={trans("form:message")}
                        />
                        <div className="text-20 font-bold my-6">{trans("finance:vehicle_info")}</div>

                        <InputField
                            value={userData?.quantity}
                            type='number'
                            onChangeValue={(e) => {
                                const val = { ...userData };
                                val.quantity = e.target.value;
                                setUserData(val);
                            }}
                            errorLabel={error?.quantity}
                            title={trans("finance:quantity")}
                            name='quantity'
                        />
                        <TextArea
                            value={userData?.note}
                            onChangeValue={
                                (e) => {
                                    const val = { ...userData };
                                    val.note = e;
                                    setUserData(val);
                                }
                            }
                            name='note'
                            title={trans("finance:note")}
                        />
                        <div className={"flex items-center gap-1"}>
                            {
                                supportedColors && supportedColors?.map(color => {
                                    return <CarColorPicker color={`${color.HexCode}`} onClick={() => setSelectedColor({
                                        id: color.ColorID,
                                        active: color.HexCode
                                    })}
                                        active={selectedColor?.active} />
                                })
                            }
                        </div>

                        <div className="order-car-container">

                            <img className={"order-car-img"} src={vehicleDetails?.image ? "data:image/png;base64," + vehicleDetails?.image : "/assets/images/car-finance.png"} alt="car" />

                            <div className="order-specs">
                                <div>
                                    <span className={"text-24 flex items-center flex-col"}>
                                        <span className={"text-24"}>
                                            {
                                                vehicleDetails.petrol ?? "0"
                                            }
                                        </span>
                                        <span className={"text-20"}>{trans("vehicales:veh-petrol")}</span>
                                    </span>
                                    <div className="order-spec-img">
                                        <img className={"dark-filter-gray"} src="/assets/icons/car/petrol.svg"
                                            alt="petrol" />
                                    </div>
                                </div>
                                <div>
                                    <span className={"text-24 flex items-center flex-col"}>
                                        <span className={"text-24"}>
                                            {
                                                vehicleDetails.seats ?? "0"
                                            }
                                        </span>
                                        <span className={"text-20"}>{trans("vehicales:veh-seat")}</span>
                                    </span>
                                    <div className="order-spec-img">
                                        <img className={"dark-filter-gray"} src="/assets/icons/car/seat.svg"
                                            alt="seats" />
                                    </div>
                                </div>
                                <div>
                                    <span className={"text-24 flex items-center flex-col"}>
                                        <span className={"text-24"}>
                                            {
                                                vehicleDetails.cylinder ?? "0"
                                            }
                                        </span>
                                        <span className={"text-18"}>{trans("vehicales:veh-cle")}</span>
                                    </span>
                                    <div className="order-spec-img">
                                        <img className={"dark-filter-gray"} src="/assets/icons/car/cylinders.svg"
                                            alt="cylinders" />
                                    </div>
                                </div>
                                <div>

                                    <span className={"text-24 flex items-center flex-col"}>
                                        <span className={"text-24"}>
                                            {
                                                vehicleDetails.hoursePower ?? "0"
                                            }
                                        </span>
                                        <span className={"text-20"}>{trans("vehicales:veh-eng")}</span>

                                    </span>
                                    <div className="order-spec-img">
                                        <img className={"dark-filter-gray"} src="/assets/icons/car/engine.svg"
                                            alt="engine" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FormButton
                    isLoading={isLoading}
                    onClick={() => handleRequestOrder()}
                    name={trans("form:send-order")}
                />
            </div>
        </Fragment>
    );
}