import { Fragment, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import axios from "axios";
import { slugSplitter } from "../../../utils/slugManager";
import { FeatureCard } from "../components/featureCard";
import config from "../../../configuration/config.json";
import { useTranslation } from "react-i18next";
export function VehicleFeaturePage() {
    const { i18n } = useTranslation();
    const { slug,group,year, brand } = useParams();
    const [features, setFeatures] = useState([]);

    let lang = (i18n.language || 'ar');

    useEffect(() => {
        setFeatures([]);
        callback();
    }, [group, year]);
    const callback = useCallback(async () => {
        try {
            setFeatures([]);
            const response = await axios.request({
                baseURL: config.controllURL,
                url: lang + "/api/VehicleFeature/" + slugSplitter(brand) + "/" + group + "/"+ year +"/feature",
                method: "get",
            });

            setFeatures(response.data);

        } catch (error) {
            console.log(error);
        }
    }, [group,year, brand]);
    return (
        <Fragment>
            <div className="container mb-32">
                <div className="nice-row">
                    {
                        features?.map((feature, index) => {
                            return (
                                <FeatureCard
                                    key={index}
                                    title={feature.title}
                                    description={feature.desc}
                                    image={feature.image}
                                />
                            );
                        })
                    }
                </div>
            </div>
        </Fragment>
    );
}