import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import ErrorLabel from '../error';
export function DateInput3({ name, title, placeholder = '', errorLabel, onSelect, type = "date", extraClasses = "", value, ...props }) {
    const { t: trans, i18n } = useTranslation(["info"]);
    let lang = (i18n.language || 'ar');
    let languageDir = "language-" + lang;
    const ref = useRef();
    return (

        <div className={"input " + languageDir}>
            {/* <label className={"input-label"} htmlFor={name}>{title}</label> */}
            <label htmlFor="container-button-file  " className={"bg-theme-up m-0 w-100 custom-input-file input-label  " + (errorLabel && 'border-danger')}>


                <div
                    {
                    ...props
                    }

                    className={" custom-input-file-content "}

                    style={{ boxShadow: "none", cursor: "pointer" ,paddingInlineStart:`${value ? '2rem' : ''}`}}
                >
                    <input
                        style={{ boxShadow: "none", cursor: "pointer" }}
                        className={"bg-theme-up  input-field object-full  border-none  box-shadow-0"}
                        readOnly={true}
                        placeholder={placeholder}
                        value={value}
                        type={"text"}

                    />
                    <img
                        src={"/assets/icons/calender_icon.svg"}
                        onClick={(e) => {
                            // e.target.type = type;
                            // ref.current.type = type
                            // e.target.readOnly = false;

                            // setTimeout(() => {


                            // ref?.current?.showPicker();


                            // }, [100])
                        }}

                    />
                </div>
            </label>
            <ErrorLabel errorTitle={errorLabel} />
        </div>
    );
}
