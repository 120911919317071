import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

function NewsBanner({background, title, desc, link}) {
    const {t: trans,i18n} = useTranslation("common");
    let lang = (i18n.language || 'ar');
    const languageDir = 'language-'+lang;
    return (
        <div className={" mx-10 mb-8 banner-container banner-news  border-radius box-shadow-large " + languageDir}>
            <Link to={link}>
                <div className="banner-bg">
                    <img src={background} alt={title}/>
                </div>
            </Link>
            <div className="banner-news-info ">
                <div className="banner-news-info-flex">
                    <div className="banner-news-info-flex-title">
                        <h1 className={"text-30 mb-20 text-line-clamp text-line-clamp-1"}>
                            {title}
                        </h1>
                    </div>
                    <div className="banner-news-info-flex-desc text-line-clamp text-line-clamp-4k">

                        <p className={"text-16"}>
                            {desc}
                        </p>
                    </div>
                    <div className="banner-news-info-flex-link">

                        <Link to={link} className={"text-16"}>
                            {trans('read-more')}
                        </Link>
                    </div>
                </div>


            </div>
        </div>
    );


}

export default NewsBanner;