import ErrorLabel from '../../components/error';
import { useTranslation } from "react-i18next";
export function DateInput({name,title , errorLabel , onSelect, type = "date" , value}) {
    const { t: trans,i18n } = useTranslation(["info"]);
    let lang =(i18n.language || 'ar');
    let languageDir = "language-" + lang;
    return (
        <div className={"input " + languageDir}>
            <label className={"input-label"} htmlFor={name}>{title}</label>
           
            <input onChange={(e)=>onSelect(e)} value={value} name={name} className={"input-field bg-white"} type={type}  lang="en"/>
            <ErrorLabel errorTitle={errorLabel}/>
        </div>
    );
}
