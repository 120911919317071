import { useTranslation } from "react-i18next";
export function UploadControl({ name, errorLabel, title, value, imgValue, onPicker, onRemove, accept = '*', placeholder, extraClasses, modelClasses }) {
    const { i18n } = useTranslation();
    let lang = (i18n.language || 'ar');
    const languageDir = "language-" + lang;
    return (
        <label htmlFor="contained-button-file " className={"bg-theme-up m-0 w-100 custom-input-file border-none  border-radius-sm   nice-shadow-2 " + (modelClasses + " ") + (errorLabel && ' border-colors-error ')}>
            <input
                accept={accept}
                className={"input-field bg-theme-up "}
                id="contained-button-file"
                type="file"
                name={name}
              
                onChange={(e) => {
                    onPicker(e)
                }
                }
                
            // onChange={disabled ? () => { } : onChange}
            />

            <div className={" custom-input-file-content "}>
                <input className={"input-field bg-theme-up " + extraClasses}
                    readOnly={true}
                    placeholder={placeholder}
                    value={value}
                    type={"text"}
                />
                <img src={"/assets/icons/add-file-icon.svg"} />
            </div>

            {
                <div className={"custom-input-file-imgView"} style={{ display: "none" }}>

                    {
                        value ?
                            <>
                                {/* <img className="image-container" src={"data:image/*;base64," + imgValue} onError={e => e.target.style.display = 'none'} alt={value} /> */}

                                <div className={"fallback-text"}
                                // style={{ display: "none" }}
                                >
                                    <span className="text-center text-24">{value}</span>
                                    <img src={"/assets/icons/add-file-icon.svg"} />
                                </div>

                                <div className={"custom-input-file-imgView-remove"}
                                    onClick={() => {
                                        if (onRemove)
                                            onRemove(null)
                                    }}
                                >
                                    <img src={"/assets/icons/removeIcon.svg"} />
                                </div>
                            </>

                            :
                            <img className="image-add" src={"/assets/icons/upload-file.svg"} />
                    }


                    <span className={"Input-file-module__label text-gray text-center text-24 "+(value ? " input-has-data ":"")}>{placeholder}</span>
                </div>
            }

        </label>
    );
}
