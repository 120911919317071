import PageHeading from "../components/__simple/_pageheading";

import { AboutBox } from "../components/__simple/_aboutBox";
import { SwiperSlide, Swiper } from 'swiper/react';
import { useEffect, useState } from "react";
import axios from "axios";
import config from "../configuration/config.json";
import { useTranslation } from "react-i18next";


export function AboutHJSection() {
    const { t: trans,i18n } = useTranslation("eparts");

    let lang = (i18n.language || 'ar');
    const languageDir = 'language-' + lang;
    const [about, setAbout] = useState([]);

    useEffect(async () => {
        try {
            const response = await axios.request({
                baseURL: config.controllURL,
                url: lang + "/api/sections/aboutHj",
                method: "get",
            });
            setAbout(response.data)
        } catch (error) {

        }

    }, []);

    return (

        <div className="container mb-32">

            {

                about?.length > 0 &&
                <PageHeading
                    title={trans("eparts:aboutHJ")}
                />
            }
            <Swiper
                spaceBetween={0}
                slidesPerView={2}
                breakpoints={{
                    576: {
                        slidesPerView: 3,
                    },
                }}
                pagination
                className={"mb-16"}
                resizeObserver={"true"}
                autoplay={false}
                loop={false}
                observer={true}
            >
                {

                    about?.length > 0 &&
                    <div className={" grid-3-cols about-hj-section " + languageDir}>
                        {

                            about?.map((item, index) => {
                                return (
                                    <SwiperSlide className={"px-3"} key={index}>
                                        <AboutBox

                                            key={index}
                                            icon={"data:image/image;base64," + item.imagePath}
                                            title={item.name}
                                            subtitle={item.content}

                                        />
                                    </SwiperSlide>
                                );
                            })
                        }

                    </div>

                }
            </Swiper>
        </div>
    );
}