import { useTranslation } from "react-i18next";
export default function VisionsSection({ visions }) {
    const {i18n } = useTranslation();
    if (visions == null)
        return null;
    let lang =  (i18n.language || 'ar');
    const languageDir = 'language-' + lang;
    return (
        <div className="container mb-32 mt-40 space-y-32">

            {
                visions.map((vision) => {
                    return (
                        <div className="about-box">
                            <h2 className={"about-box-title "}>{vision.name}</h2>
                            <p className={"about-box-desc"}>{vision.content}</p>
                        </div>
                    );
                })
            }
        </div>
    );
}