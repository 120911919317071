import { useEffect, useRef, useState } from "react";
import ErrorLabel from '../../components/error';
import { useTranslation } from "react-i18next";

export function RadioInput({ name, title, groupName, selectValue,value1, text1, value2, text2, errorLabel, onChangeValue,  disabled = false }) {
    const {i18n } = useTranslation();
    let lang =  (i18n.language || 'ar');
    const languageDir = "language-" + lang;
    const [radioSelectValue, setRadioSelectValue] = useState(selectValue);
    useEffect(() => {
        setRadioSelectValue(selectValue);
    }, [selectValue]);
    return (
        <div className={"input " + languageDir}>
            <label className={"input-label"} htmlFor={name}>{title}</label>
            <ErrorLabel errorTitle={errorLabel} />
            <div>
                {
                    value1 != null ?
                    <label className={"input-radio  "}>
                        <input  disabled={disabled} radioGroup={groupName}   checked={radioSelectValue == value1}  onChange={() =>{ onChangeValue(value1) ;  setRadioSelectValue(value1);}} value={value1} name={name} className={""} type="radio" />
                        {text1}
                    </label>
                    :null
                }

                {
                    value2 != null  ?
                    <label  disabled={disabled} className={"input-radio"}>
                        <input radioGroup={groupName} checked={radioSelectValue == value2}  onChange={() =>{ onChangeValue(value2) ;   setRadioSelectValue(value2);}} value={value2} name={name} className={""} type="radio" />
                        {text2}

                    </label>
                    :null
                }

            </div>
        </div>
    );
}
