import {useTranslation} from "react-i18next";
import config from "../../../configuration/config.json";
export function CarFeature({title, desc, image}) {

    return (
        <div className={"feature"}>
            <div className="feature-img">
                <img className={"object-full object-cover"} src={config.FilePathURL+ image} alt={title}/>
            </div>
            <div className="feature-text text-center px-8">
                <h3 className={"text-20 font-bold my-8"}>{title}</h3>
                <p className={"text-16 mb-8"}>
                    {desc}
                </p>
            </div>
        </div>
    );
}

