import { useState, useContext, useEffect } from "react";
import { OwnOrderHistory } from "./components/ownOrders/ownHistory";
import { useTranslation } from "react-i18next";
import OwnOrderContext from './components/ownOrders/ownOrderContext';
import Popup from './popup';
import { formValidate, GetMessages} from "../utils/validation";
import {TextArea} from '../components/_input/_textArea';
import {PartRating} from "../pages/eparts/components/rate";
import Joi from 'joi';
import _ from 'lodash';
import {
    OwnOrderDetails,
    OwnPGTOrders,
    OwnSDCOrders, OwnServiceOrders,
    OwnSpareOrders,
    OwnVehicleOrders
} from "./components/ownOrders/ownOrderDetails";
import { FormButton } from "../components/_input/_button";
import axios from "axios";
import config from "../configuration/config.json";
import {getData, User_Data_Key} from "../utils/localStorageManager";
import { toast } from "react-toastify";
import moment, { isMoment } from "moment";
const UserOrdersPage = () => {
    const { t: trans,i18n } = useTranslation(["ownOrder","info","error","form","global"]);
    const lang = (i18n.language || 'ar');
    const languageDir = 'language-' + lang;
    //------------------------------------------------
    const OwnContext = useContext(OwnOrderContext);
    const [parentBox, setParnetBox] = useState({
        itemActive: false,
        showHistoryBox: true,
        showDetailsBox: false
    });
    const [Carts, setCarts] = useState([]);
    const [fCarts,setFCarts] = useState([]);
    const [userID,setUserID] = useState(null);
    const [CartsTrans, setCartTrans] = useState([]);
    const [CartsTrac, setCartTrac] = useState([]);
    const [CartStatus, setCartStatus] = useState([]);
    const [evaluatePopupShow,setEvaluatePopupShow] = useState(false);
    const [CartInfo,setcartInfo]=useState({status:'',ID:0,Total:0,Description:0,CreatedAt:'',shippingPrice:0,deliveryLocation:'' ,deliveryMethod : ''});
    const [sparePartProductID,setSparePartProductID] = useState(null);
    const [evaluation,SetEvaluation] = useState({
      stars:0,
      comment:''
    });
    const schema = Joi.object({
        comment: Joi.string().required().messages(GetMessages())
    });
    const [error,setError] = useState({});
    //------------------------------------------------
    const handleChangeParentState = (data) => {
        

        console.log("handleChangeParentState:",data);
        const currentValue=CartInfo;
        currentValue.ID=data.CID;
        currentValue.CreatedAt=data.CreatedAt;
        currentValue.status=data.TStatus;
        currentValue.Total=data.Total;
        currentValue.Description=data.Description;
        currentValue.shippingPrice=data.shippingPrice;
        currentValue.deliveryLocation=data.Delivery;
        currentValue.deliveryMethod  = data.deliveryMethod;
         setcartInfo(currentValue);
        fetchMycartTrans(data);
        fetchMycartTracing(data);
        
        let currentState = { ...parentBox, ...data };
        setParnetBox(currentState);
    };
    const handleWindowResize = () => {
        const currentValue = { ...parentBox };
        if (window.innerWidth < 577) {
            currentValue.showHistoryBox = true;
            currentValue.showDetailsBox = false;
        } else {
            if (parentBox.itemActive) {
                currentValue.showDetailsBox = true;
            } else {
                currentValue.showDetailsBox = false;
            }
        }
        handleChangeParentState(currentValue);
    };
    const handleEvaluatePopup =(data,state)=>{
        setError({});
        SetEvaluation({
            stars:0,
            comment:''
        });
        setEvaluatePopupShow(state);
        setSparePartProductID(data);
    };
    //-----------------------------------------------
    function  fetchMycart(ID){
        const  Body=  {
            "name":"Site_FeatchMyRequests",
            "values":{
                "userID":parseInt(ID)
            }}
       if(ID)
        axios.request({
            url: config.baseURL + 'func',
            data: Body, method:"POST",responseType:"json"
        })
            .then(function (response) {
                // console.log("setFCarts:",response.data);
                setTimeout(setCarts(response.data),1000);
                setTimeout(setFCarts(response.data),1000);
            })
            .catch(function (error) {
                console.log(error);
            });



    }
    function  fetchMycartTrans({cartID}){
        const  Body=  {
            "name":"site_featchTransCarts",
            "values":{
                "CartID":Number(cartID)
            }}
        if(cartID)
            axios.request({
                url: config.baseURL + 'func',
                data: Body, method:"POST",responseType:"json"
            })
                .then(function (response) {
                    // console.log(response.data.ApiObjectData);
                    setTimeout(setCartTrans(response.data),1000);

                })
                .catch(function (error) {
                    console.log(error);
                });



    }
    function  fetchMycartTracing({cartID}){
        const  Body=  {
            "name":"Site_GetTrackingOFCart",
            "values":{
                "CartID":Number(cartID)
            }}
        if(cartID)
            axios.request({
                url: config.baseURL + 'func',
                data: Body, method:"POST",responseType:"json"
            })
                .then(function (response) {
                    // console.log(response.data.ApiObjectData);
                    setTimeout(setCartTrac(response.data),1000);

                })
                .catch(function (error) {
                    console.log(error);
                });



    } 
    function  fetchCartStatus(){
        const  Body=  {
            "name":"site_fetchStatusCart"
        }
     
            axios.request({
                url: config.baseURL + 'func',
                data: Body, method:"POST",responseType:"json"
            })
                .then(function (response) {
                    // console.log(response.data.ApiObjectData);
                    setTimeout(setCartStatus(response.data),1000);

                })
                .catch(function (error) {
                    console.log(error);
                });



    }
    // to set the evaulation details on the spare part product
    const handleSendEvaluation = ()=>{
     try{
        const result = formValidate(schema,evaluation);
        setError(result);
        if(sparePartProductID){
            if(Object.keys(result).length === 0){
                const {stars , comment} = evaluation;
                 axios.post(
                     config.baseURL + 'add',
                     {
                         "object":"SparePartsEvalutions",
                         "values":{
                             "ProductID":sparePartProductID,
                             "WebUserID":Number(userID),
                             "StarsCount":stars,
                             "Comment":_.trim(comment)
                         }
                     }
                 ).then(response=>{
                   if(response.status == 201){
                    handleEvaluatePopup(null,false);
                    toast.success(trans("global:success_operation"));
                   }
                 }).catch(err=>{
                    toast.error(trans("error:unknown_failure"));
                 });
            }
        }
     }catch(err){
         console.log(err);
     }   
    };
    // to filter the history item
    const handleFilterHistoryItems = (value)=>{
        try{
            const {filterStatusOption,filterDate} = value;
            let _fValues = Carts;
            if(filterStatusOption){
               _fValues =  Carts.filter(item=>{
                 return item.statusId === Number(filterStatusOption)
             });
             setFCarts(_fValues);
            }else{
             setFCarts(Carts); 
            }
            if(filterDate){
                _fValues =  Carts.filter(item=>{
                    return new moment(item.CreatedAt).format('yyyy-MM-DD') === new moment().format('yyyy-MM-DD') 
                });
                setFCarts(_fValues);
            }
            console.log(_fValues);
        }catch(error){
            console.log(error);
        }
    };
    useEffect(() => {
        try{
            const cleanData = JSON.parse(getData(User_Data_Key,true));
            const user_id = cleanData.id.toString().split('_')[1];
            window.addEventListener('resize', handleWindowResize);
            fetchMycart(user_id);
            setUserID(user_id);
            fetchCartStatus();
            return () => window.removeEventListener('resize', handleWindowResize);
        }catch(err){
            console.log(err);
        }
    }, []);
    return (
        <OwnOrderContext.Provider value={{ parentBox, handleChangeParentState }}>
            <div className={"own-order " + languageDir}>
                <OwnOrderHistory
                    historyTitle={trans("ownOrder:history")}
                    historySubTitle={trans("ownOrder:history_note")}
                    statusOptions={CartStatus}
                    values={fCarts}
                    onClick = {handleFilterHistoryItems}
                />
                <section style={{ display: parentBox.showDetailsBox ? 'flex' : 'none' }} className="own-order-details  nice-flex-column">
                    <OwnOrderDetails
                        orderStatusText={CartInfo.status}
                        orderDate={CartInfo.CreatedAt}
                        deliveryLocation={CartInfo.deliveryLocation}
                        deliveryMethod={CartInfo.deliveryMethod}
                        amount={CartInfo.amount}
                        shippingPrice={CartInfo.shippingPrice}
                        finalTotal={CartInfo.Total}
                        orderNumber={CartInfo.ID}
                        orderHistory={CartsTrac}
                    />
                    <OwnVehicleOrders vehicles={CartsTrans.filter(a=>a.CartItemsTypeID===1)} />
                    <OwnSpareOrders values={CartsTrans.filter(a=>a.CartItemsTypeID===2)} onEvaluate={handleEvaluatePopup}/>
                    <OwnServiceOrders values={CartsTrans.filter(a=>a.CartItemsTypeID===3)}/>
                    <OwnSDCOrders values={CartsTrans.filter(a=>a.CartItemsTypeID===4)} />
                    <OwnPGTOrders values={CartsTrans.filter(a=>a.CartItemsTypeID===7)} />
                </section>
                <Popup show={evaluatePopupShow}>
                    <div className="evaluate">
                        <div className="evaluate-box">
                            <div className="evaluate-box-header nice-flex nice-flex-center nice-relative">
                             <h2 className="text-white text-14">{trans("global:what_elavoution")}</h2>
                             <span className='popup-close' onClick={()=>{
                                 handleEvaluatePopup(null,false);
                             }}>&times;</span>
                            </div>
                            <div className="nice-flex nice-flex-column nice-flex-center mt-12">
                             <PartRating
                             intialValue={evaluation.stars}
                             onChange={(stars)=>{
                               const val = {...evaluation};
                               val.stars = stars;
                               SetEvaluation(val);
                             }}
                             />
                            </div>
                            <div className="m-5">
                            <TextArea 
                            value={evaluation.comment}
                            errorLabel={error?.comment}
                            title={trans("global:elavoution")} 
                            onChangeValue = {(value)=>{
                                const val = {...evaluation};
                                val.comment = value;
                                SetEvaluation(val);
                            }} 
                            row={5} 
                            style={{maxHeight:'12rem'}}/>
                            <FormButton name={trans('form:save')} onClick={handleSendEvaluation}/>
                            </div>
                        </div>
                    </div>
                </Popup>
            </div>
        </OwnOrderContext.Provider>

    );
};
export default UserOrdersPage;