import ErrorLabel from '../../components/error';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
export function RadioInputDynamic({ name, title, selectValue, groupName, ListValues, errorLabel, onChangeValue, extraClasses, disabled = false }) {
    const { i18n } = useTranslation();
    let lang = (i18n.language || 'ar');
    const languageDir = "language-" + lang;
    const [radioSelectValue, setRadioSelectValue] = useState(selectValue);
    useEffect(() => {
        setRadioSelectValue(selectValue);
    }, [selectValue]);
    return (
        <div className={"input " + " " + extraClasses + " " + languageDir}>
            <label className={"input-label"} htmlFor={name}>{title}</label>
            <ErrorLabel errorTitle={errorLabel} />
            <div className='radio-list-container'>
                {ListValues?.map((item, index) => (
                    item && (
                        <label key={index} className={"input-radio"}>
                            <input
                                disabled={disabled}
                                type="radio"
                                checked={radioSelectValue != null && radioSelectValue != undefined && radioSelectValue == item?.value}
                                radioGroup={groupName}
                                onChange={() => { onChangeValue(item?.value); setRadioSelectValue(item?.value); }}
                                value={item?.value}
                                name={name}
                                className={""}
                            />

                            <span>{item?.text}</span>
                        </label>
                    )
                ))}
            </div>
        </div>
    );
}
