import { FormButton } from "../../../components/_input/_button";
import { InputField } from "../../../components/_input/_input";
import axios from "axios";
import { useEffect, useState, useRef, useCallback, createRef } from "react";
import config from '../../../configuration/config.json'
import { formValidate, } from '../../../utils/validation';
import { toast } from "react-toastify";
import Joi from 'joi';
import { useTranslation } from "react-i18next"
import { Link } from 'react-router-dom';
function SMSCheck({ mobile, onSucess, setSubFunctionRef, goBack,SignUpData }) {

    const { t: trans, i18n } = useTranslation(["new"]);

    const bodyRef = useRef(null);
    // const { hash, otp, message, timeoutError, stopListener, startListener } = useOtpVerify({numberOfDigits: 4});
    const [error, setError] = useState({});
    const [isSending, setIsSending] = useState(false);
    const [correctValidate, setCorrectValidate] = useState(null);

    // Function defined in SubComponent
    const subFunction = () => {

        // clearTimer(getDeadTime());
        generateSMS();
    };

    useEffect(() => {
        window.addEventListener('paste', (event) => {
            let paste = (event.clipboardData || window.clipboardData).getData('text');
            try {
                //console.log("paste:", paste);

                var codeNumber = paste.trim().replaceAll('"', "");
                codeNumber = codeNumber.match(/(\d+)/);
                codeNumber = codeNumber[0];
                //console.log("codeNumber 11:", codeNumber);
                codeNumber = codeNumber.split('');
                //console.log("codeNumber 22:", codeNumber);

                if (codeNumber.length == 4) {
                    const val = { ...requestForm };
                    val.code1 = (codeNumber[0] && !isNaN(codeNumber[0])) ? codeNumber[0] : ''
                    val.code2 = (codeNumber[1] && !isNaN(codeNumber[1])) ? codeNumber[1] : ''
                    val.code3 = (codeNumber[2] && !isNaN(codeNumber[2])) ? codeNumber[2] : ''
                    val.code4 = (codeNumber[3] && !isNaN(codeNumber[3])) ? codeNumber[3] : ''
                    setRequestForm(val);
                }

            } catch (error) {
                //console.log("error codeNumber:", error);
            }


        });





        // todo: هذه الفحص خاص بالنسخ sms
        if ('OTPCredential' in window) {
            // toast.error("OTPCredential");
            window.addEventListener('DOMContentLoaded', e => {
                // toast.error("DOMContentLoaded");
                const ac = new AbortController();
                navigator.credentials.get({
                    otp: { transport: ['sms'] },
                    signal: ac.signal
                }).then(otp => {
                    navigator.clipboard.writeText(otp);

                    ac.abort();
                }).catch(err => {
                    //console.log(err);
                });
            });
        }


    }, []);

    useEffect(() => {
        document.getElementById("code1").focus();
    }, []);


    const [requestForm, setRequestForm] = useState({
        code1: '',
        code2: '',
        code3: '',
        code4: '',
    });

    const schema = Joi.object({
        code1: Joi.string().max(1).required(),
        code2: Joi.string().max(1).required(),
        code3: Joi.string().max(1).required(),
        code4: Joi.string().max(1).required(),

    });
    const [smsCode, setSmsCode] = useState(null);
    const submitRequest = async (btn) => {

        const _codeOtp = requestForm.code1 + requestForm.code2 + requestForm.code3 + requestForm.code4;


        if (btn) {
            const result = formValidate(schema, requestForm) ?? {};
            setError(result);
        }

        if (timer == "00:00:00") {
            //console.log("timer:", timer);
            if (_codeOtp) {
                toast.error(trans("new:waiting_period_has_expired"));
            }
            else {
                if (btn) {
                    toast.error(trans("new:waiting_period_has_expired"));
                }
            }


            return "";
        }

        let validCode = await validationOTP({
            // numberPhone: " +966547221619", //todo: نرجع رقم الاصل, // رقم ضيا +966547221619
            numberPhone: mobile, 
            codeOTP: _codeOtp
        });
        if (validCode == 1 || validCode == '1') {
                toast.success(trans("new:verification_completed_successfully"));

                if (onSucess)
                    onSucess(_codeOtp);

        } else {

            toast.error(trans("new:please_enter_the_correct_code"));
        }



    }


    const handelFocusNext = (currentIndex) => {
        try {
            document.getElementById("code" + (1 + currentIndex)).focus();
        } catch (error) {
            //console.log("Catch error handelFocusNext: ", error.toString());
        }
    }

    const handelFocusBack = (currentIndex) => {
        try {
            document.getElementById("code" + (currentIndex - 1)).focus();
        } catch (error) {
            //console.log("Catch error handelFocusBack: ", error.toString());
        }
    }

    const generateSMS = async () => {
        //console.log(">>>>>>>>>>> SendSmsCodeFormAppOTP >>>>");
        //console.log("SendSmsCodeFormAppOTP:", mobile);

        if (timer == "00:00:00") {
            try {
                let Body = {
                    number: mobile
                }

                await axios.request({
                    baseURL: config.paymentURL,
                    // 
                    url: `/api/Payment/SendSmsCodeFormAppOtp`,
                    // headers: myHeaders,
                    data: Body, method: "POST", responseType: "json"
                }).then(
                    async response => {
                        //console.log('SendSmsCodeFormAppOTP response:', response);
                        if (response.status >= 200 && response.status <= 299) {
                            //console.log("SendSmsCodeFormAppOTP response.data: ", response.data);
                            if (response.data.status == 200) {
                                //console.log("SendSmsCodeFormAppOTP response.data: ", response.data.code);
                                clearTimer(getDeadTime());
                            } else {
                                // toast.error(trans("new:please_enter_the_correct_code"));
                                toast.error(trans("error:error_verification_code"));

                                //console.log("SendSmsCodeFormAppOTP response.data: ", response.data.status);
                            }
                        }
                    }).catch(async err => {
                        toast.error(trans("error:error_verification_code"));
                        console.info("Error catch SendSmsCodeFormAppOtp", err);
                    })

            } catch (ex) {
                toast.error(trans("error:error_verification_code"));
                //console.log(ex, "Ex catch:", ex);
            }
        }
    }

    // * ValditionOTP
    const validationOTP = async ({ numberPhone, codeOTP }) => {
        let correct = 0;
        try {
            let Body = {
                number: numberPhone, 
                code: codeOTP
            }
            await axios.request({
                baseURL: config.paymentURL,
                // 
                url: `/api/Payment/ValditionOTP`,
                // headers: myHeaders,
                data: Body, method: "POST", responseType: "json"
            }).then(
                async response => {
                    //console.log('ValditionOTP response:', response);
                    if (response.status >= 200 && response.status <= 299) {
                        //console.log("ValditionOTP response.data: ", response.data);
                        if (response.data.Correct == 1) {
                            setCorrectValidate(true);
                            // return correct; // 1 true
                            correct = response.data.Correct;
                        }
                        else {
                            setCorrectValidate(false);
                            toast.warn(trans("error:error_otp"));
                        }
                    }
                }).catch(async err => {
                    toast.error("error:verification_code");
                    console.info("Error catch SendSmsCodeFormAppOtp", err);
                })

        } catch (ex) {
            toast.error("error:verification_code");
        }

        return correct;
    }




    // We need ref in this, because we are dealing
    // with JS setInterval to keep track of it and
    // stop it when needed
    const Ref = useRef(null);

    // The state for our timer
    const [timer, setTimer] = useState('00:00:00');


    const getTimeRemaining = (e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / 1000 / 60 / 60) % 24);
        return {
            total, hours, minutes, seconds
        };
    }


    const startTimer = (e) => {
        let { total, hours, minutes, seconds }
            = getTimeRemaining(e);
        if (total >= 0) {
            // update the timer
            // check if less than 10 then we need to 
            // add '0' at the beginning of the variable
            setTimer(
                (hours > 9 ? hours : '0' + hours) + ':' +
                (minutes > 9 ? minutes : '0' + minutes) + ':'
                + (seconds > 9 ? seconds : '0' + seconds)
            )
        }
    }


    const clearTimer = (e) => {
        // If you adjust it you should also need to
        // adjust the Endtime formula we are about
        // to code next    
        setTimer('00:02:00');

        // If you try to remove this line the 
        // updating of timer Variable will be
        // after 1000ms or 1sec
        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(e);
        }, 1000)
        Ref.current = id;
    }

    const getDeadTime = () => {
        let deadline = new Date();

        // This is where you need to adjust if 
        // you entend to add more time
        deadline.setSeconds(deadline.getSeconds() + 120);
        return deadline;
    }

    const onClickReset = () => {
        clearTimer(getDeadTime());
    }
    // Pass the function reference to the main component on mount
    useEffect(() => {
        setSubFunctionRef(() => subFunction());
    }, [setSubFunctionRef]);

    //----------------------------------------------------------------
    const PHONE_KEY = 'PhoneNumber';
    //-------------------------------------------------------------
    const IsUserExisted = async () => {
        try {

                        if (mobile) {
                            axios.post(
                                config.controllURL + "api/Administration/" + "IsUserExistedAsync",
                                {
                                    "AccessType": PHONE_KEY,
                                    "Access":mobile
                                }
                            ).then(response => {
                                if (response.data && response.data == true) {
 
                                }else{
                                    handleSignUp();
                                }
                            }).catch(error => {
                                if (error.response && error.response.status === 400) {
                                    toast.error(error.response.data[`${trans("model:description3")}`]);
                                }
                            });
                        } else {
                            toast.error(trans("common:not-agree"));
                        }
        } catch (err) {
            console.log(err);
        }
    };
   const handleSignUp = async () => {
        try {

                        if (mobile) {
                            axios.post(
                                config.controllURL + "api/Administration/" + "SignUp",
                                {
                                    "ArFirstName": SignUpData.arFirstName ?? null,
                                    "ArMiddlename": SignUpData.arMiddleName ?? null,
                                    "ArLastname": SignUpData.arLastName ?? null,
                                    "EnFirstName": SignUpData.enFirstName ?? null,
                                    "EnMiddlename": SignUpData.enMiddleName ?? null,
                                    "EnLastname": SignUpData.enLastName ?? null,
                                    "Phone": SignUpData.phoneNumber,
                                    "Identity": SignUpData.identity,
                                    "IdentityImage": SignUpData.identityImage,
                                    "TradeNo": SignUpData.commercialIdentity,
                                    "TradeNoImage": SignUpData.commercialIdentityImage,
                                    "CustGroupID": SignUpData.accountType,
                                    "IsClient":  SignUpData.isClientAccount
                                }
                            ).then(response => {
                                if (response.data) {
                                   
                                }
                            }).catch(error => {
                                if (error.response && error.response.status === 400) {
                                    toast.error(error.response.data[`${trans("model:description3")}`]);
                                }
                            });
                        } else {
                            toast.error(trans("common:not-agree"));
                        }
        } catch (err) {
            console.log(err);
        }
    };

    const handleSendVerificationCode = () => {
  
        var codeValid = false;
        if (mobile) {

            setIsSending(true);
            axios.post(
                config.controllURL + "api/Administration/" + "SendSms",
                {
                    "AccessType": PHONE_KEY,
                    "Access": mobile
                }
            ).then(response => {
                setIsSending(false);
                console.log("SendSms response.data ", response);

                if (response?.data == false) {

                    toast.error(trans("common:failed-access"));
                } else {

                    if (response.data) {

                        codeValid = true;
                        clearTimer(getDeadTime());
                    } else {
                        toast.error(trans("error2:no_phone_number_error"));
                    }
                }

                if (codeValid) {

                    console.log("after validate phoneNumber error");
                }

            }).catch(err => {
                setIsSending(false);
                toast.error(trans("common:failed-access"));
                console.log(err);
            });
            console.log("after validate phoneNumber ");

        }
    };
    const handleLogin = () => {
        console.log("handleLogin");
        if (mobile) {
            const _codeOtp = requestForm.code1 + requestForm.code2 + requestForm.code3 + requestForm.code4;
            setIsSending(true);
            axios.post(
                config.controllURL + "api/Administration/" + "MatchedKeysNoValidationKey",
                {
                    "AccessType": PHONE_KEY,
                    "Access": mobile,
                    "ValidationKey": _codeOtp
                }
            ).then(response => {
                setIsSending(false);
                // if (response.data) {
                if (response.status === 200) {
                    var cookies = null;

                    setCorrectValidate(true);
                

                    let userrespons = response?.data;
                    userrespons["OTP"] =_codeOtp;
                    // userrespons["identityImage"] = "";
                    // saveData(User_Data_Key, userrespons, true);
                    // saveData('isAuthenticated', true, false);
                    // saveData('secretKey', response.data.id.toString().split('_')[0], false);
      
                    // init();


                    if (onSucess)
                        onSucess(userrespons);  
                }
                else {

                    toast.error(trans("error2:validation_error"));
                }


            }).catch(err => {
                setIsSending(false);
                toast.error(trans("error2:validation_error"));
            })
        }
    };
    return (
        <div ref={bodyRef} className={`sms-check-page  nice-apply-theme `}>


            <div className="otp-wrapper  ">

                <div className="otp-icon" >
                </div>
                <h2 className="otp-title" >التحقق من رقم الهاتف</h2>
                <label className="otp-label" >{trans("new:ادخل رمز التحقق لتقديم الطلب")}</label>
                {/* <strong className="otp-phoneNumber" >{mobile && (mobile?.substr(0, 3) + "****" + mobile?.substr(7, 8))}</strong> */}
                <strong className="otp-phoneNumber language-en" >{mobile && mobile}</strong>
                <button className="otp-editButton" type="button"
                    onClick={() => {

                        if (goBack) {
                            goBack();
                        }
                    }}>
                    تعديل رقم الجوال
                </button>

                <div className=" Otp-module flex flex-nowrap nice-gap-5rem flex-wrap language-en">

                    {Array.from({ length: 4 }).map((_, index) => (
                        <div className="grow" key={index}>
                            <InputField
                                autoComplete="one-time-code"
                                placeholder={"0"}
                                id={"code" + (index + 1)}
                                language={"language-en"}
                                // refValue={index == 0 ? refCode1 : index == 1 ? refCode2 : index == 2 ? refCode3 : index == 3 ? refCode4 : null}
                                value={requestForm["code" + (index + 1)]}
                                type='text'
                                inputMode="numeric"
                                pattern="[0-9]*"
                                length={1}
                                // style={{ height: "48px", width:"51px",fontSize: "24px", textAlign: "center" }}
                                extraClasses={" no-cursor"}

                                // errorLabel={error?.code1}
                                onKeyDown={(e) => {

                                    if (e.key === 'Backspace') {
                                        const val = { ...requestForm };
                                        val["code" + (index + 1)] = "";
                                        setRequestForm(val);
                                    } else if (/^[0-9]$/.test(e.key) && e.key.length == 1) {
                                        const val = { ...requestForm };
                                        val["code" + (index + 1)] = e.key;
                                        setRequestForm(val);
                                        handelFocusNext((index + 1));
                                    }
                                    if (e.key === 'Backspace' && (e.target.value == null || e.target.value == "")) {
                                        handelFocusBack((index + 1));

                                    }
                                }}
                                onChangeValue={(e) => {
                                    // const val = { ...requestForm };
                                    // val.code1 = e.target.value;
                                    // setRequestForm(val);

                                    // if (e.target.value && e.target.value != "")
                                    //     handelFocusNext(1);
                                    // else {
                                    //     // if(e.target.value.length == 0 && e.target.value == ""){
                                    //     //console.log("e.value = 0? >>>>", e.target.value);
                                    //     // handelFocusBack(1);
                                    // }
                                }}
                                name={'code' + (index + 1)}
                            />
                        </div>
                    ))}
                </div>




                <FormButton name={trans("new:تحقق من الرمز")}
                    //  isLoading={isLoading} 
                    classes={"text-40 otp-submitButton " + ((requestForm.code1 && requestForm.code2 && requestForm.code3 && requestForm.code4) ? "" : " notActive ")}
                    type="button"
                    onClick={() => submitRequest(true)}

                />
                {
                    <h3 className="text-40 my-10  otp-timer text-gray text-center"> {timer}  </h3>
                }
                <div className="sms-check-footer my-10 flex justify-between">

                    <Link to={"#"} className="text-30  otp-notSend text-gray text-center"

                        onClick={(e) => {
                            e.preventDefault();

                        }}
                    > {trans("new:لم يصلني الرمز.")}  </Link>

                    <Link to={"#"} className="text-30 otp-resendButton  text-info text-center"
                        onClick={(e) => {
                            e.preventDefault();
                            if (timer == "00:00:00") {
                                generateSMS();
                            }

                        }}

                    > {trans("new:إعادة إرسال")}  </Link>

                </div>

            </div >
        </div >
    );
}

export default SMSCheck;
