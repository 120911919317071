import {Fragment, useEffect, useState} from "react";
import News from "./components/news";
import SwiperCore, {Pagination, Navigation, Autoplay, EffectFade} from "swiper";
import {useTranslation} from "react-i18next";
import Breadcrumb from "../../components/__simple/breadcrumb";
import axios from "axios";
import {useParams, useHistory} from "react-router";
import Moment from "react-moment";
import {slugSplitter,slugCombine} from '../../utils/slugManager';
import config from "../../configuration/config.json";
SwiperCore.use([Pagination, Navigation, Autoplay, EffectFade]);

// import { useHistory } from 'react-router-dom';

export function NewsDetailPage() {
    const {t: trans,i18n} = useTranslation(["heading","info"]);
    let {slug} = useParams();
    const [detail, setDetail] = useState({title: ""});
    const [news, setNews] = useState([{},{}]);

    let lang = (i18n.language || 'ar');
    const languageDir = 'language-' + lang;

    // // let history = useHistory();
    // // let history = window.history;
    //
    // useEffect(() => {
    //     console.log("history.url.includes(): ", window.location.pathname);
    //     // console.log("history: ", history.location.pathname);
    //
    //     // history.push('/news',['Test'])
    //     if(window.location.pathname.includes("/news/detail/")){
    //         window.history.pushState([slugSplitter(slug)],'/news', '/news1');
    //     }
    // }, [slug]);
    //
    // const handlePopstate = () => {
    //     // يتم تحديث العنوان عند الرجوع في المتصفح
    //     window.history.pushState([slug], '/news1', '/news');
    //     window.location.reload();
    // };
    // // استمع لحدث popstate عند الرجوع في المتصفح
    // window.addEventListener('popstate', handlePopstate);
    
    
    useEffect(async () => {
        try {

            const response = await axios.request({
                 baseURL: config.controllURL,
                url: lang+"/api/news/detail/" + slugSplitter(slug),
                method: "get",
            });
            
            setDetail(response.data.detail)
            setNews(response.data.latestNews)
            
        } catch (error) {

        }
    }, [slugSplitter(slug)])
    
    return (
        <Fragment>

            <div className="news-detail-container container mb-32">
                <Breadcrumb className={"mt-16 hide-xs"}
                            links={[
                                {
                                    name: trans("info:news"),
                                    to: "/news"
                                },
                                {
                                    name: detail.title
                                }
                            ]}
                />
                <article className={"news-detail mb-32"}>
                    <div className="news-detail-img ">
                        <img className={"object-full"} src={config.FilePathURL +detail.imagePath} alt={news.title}/>
                    </div>
                    <Breadcrumb className={"mt-16 show-xs mx-12"}
                                links={[
                                    {
                                        name:trans("info:news"),
                                        to: "/news"
                                    },
                                    {
                                        name: detail.title
                                    }
                                ]}
                    />
                    <div className={"news-detail-text clearfix " + languageDir}>
                        
                        <h1 className={"text-40 font-bold"}>
                            {detail.title}
                        </h1>
                        
                            <Moment className={"text-16 "} 
                                    date={detail.createdDate} 
                                    format={"DD/MM/YYYY"}/>
                        
                        <p className={"text-20 text-height-20 mt-12 new-lines"}>
                            {detail?.content}
                        </p>
                        
                    </div>
                </article>

            </div>
            <div className={"container " + languageDir}>
                <h2 className={"text-20 font-bold mb-16"}>{trans('info:last-news')}</h2>
                <div className="grid-4-cols mb-16 news-gap">
                    {
                        news?.map((data) => {
                            return (
                                <News
                                    title={data.title}
                                    desc={data.excerpt}
                                    date={data.createdDate}
                                    image={config.mainURL +data.imagePath}
                                    link={"/news/detail/" + slugCombine(data.slug,data.otherLink)}
                                    brand={data.brand}
                                />
                            );
                        })
                    }
                    


                </div>

            </div>
        </Fragment>
    );
}