import {useEffect, useRef, useState} from "react";
import ErrorLabel from '../../components/error';
import { useTranslation } from "react-i18next";
export function FileInput({name,errorLabel,title, onPicker,mimeType = '.doc,.docx,application/pdf'}) {
    const {i18n } = useTranslation();
    let lang = (i18n.language || 'ar');
    const languageDir = "language-" + lang;
    return (
        <div className={"input " + languageDir}>
            <label className={"input-label"} htmlFor={name}>{title}</label>
           
            <input accept={mimeType} name= {name} onChange={(e)=> onPicker(e)} className={"input-field bg-white"} type="file"/>
            <ErrorLabel errorTitle={errorLabel}/>
        </div>
    );
}
