import {Fragment, useCallback, useEffect, useState} from "react";
import Banner from "../../components/banner";
import Breadcrumb from "../../components/__simple/breadcrumb";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {useParams} from "react-router";
import Offer from "../../components/offer";
import {slugCombine} from '../../utils/slugManager';
import config from "../../configuration/config.json";

export function OfferByTypePage() {
    let {type} = useParams();

    const {t: trans,i18n} = useTranslation(["common", "heading", "inputs", "employment"]);
    const [banner, setBanner] = useState({});
    const [offers, setOffers] = useState([]);
    let lang = (i18n.language || 'ar');
    useEffect( () => {
    callback();
    }, []);

    const callback = useCallback(async()=>{
        try {
            const response = await axios.request({
                 baseURL: config.controllURL,
                url: lang+"/api/offers/type/" + type,
                method: "get",
            }); 
            setBanner(response.data.banner)
            setOffers(response.data.offers)
        } catch (error) {
         console.log(error);
        }
     },[lang,type]);


    return (
        <Fragment>

            <Banner
                subtitle={banner?.subtitle}
                title={banner?.title}
                desc={banner?.excerpt}
                background={"data:image/png;base64," + banner?.image}
                backOpasity={banner?.backOpasity}
            />
            <div className="container">
                <Breadcrumb className={"mt-16 "}
                            links={[
                                {
                                    name: trans("common:offers"),
                                }
                            ]}
                />
            </div>
            <div className={"container mb-32"}>
                <h2 className={"text-20 font-bold mb-16"}>{type}</h2>
                <div className="grid-4-cols mb-16">
                    {
                        offers.map((offer) => {
                            return (
                                <Offer
                                    name={offer?.title}
                                    img={"data:image/png;base64," + offer?.image}
                                    totalDays={offer?.totalDays}
                                    endDate={offer?.endDate}
                                    startDate={offer?.startDate}
                                    type={offer?.type}
                                    excerpt={offer?.excerpt}
                                    slug={slugCombine(offer?.slug,offer?.otherLink)}
                                />

                            );
                        })
                    }
                </div>
            </div>

        </Fragment>
    );
}

