import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import ErrorLabel from '../../components/error';
export function DateInput2({ name, title, placeholder = '', errorLabel, onSelect, type = "date", extraClasses = "", value, ...props }) {
    const { t: trans, i18n } = useTranslation(["info"]);
    let lang = (i18n.language || 'ar');
    let languageDir = "language-" + lang;
    const ref = useRef();
    return (

        <div className={"input " + languageDir}>

            {
                title &&
                <label className={"input-label"} htmlFor={name}>{title}</label>
            }
         
           
           
            <label htmlFor="container-button-file " className={"bg-theme-up m-0 w-100 custom-input-file input-label  " + (errorLabel && 'border-danger')}>

                <input
                    id="contained-button-file"
                    inputMode='none'
                    lang="en-us"
                    placeholder={placeholder}

                    onClick={(e) => {
                        // e.target.type = type;
                        // ref.current.type = type
                        // e.target.readOnly = false;

                        // setTimeout(() => {
                        try {
                            e.target.showPicker();
                        } catch (error) {

                        }




                        // }, [100])
                    }}
                    ref={ref}
                    onFocus={(e) => {
                        // e.target.type = type;

                        // setTimeout(() => {

                        try {
                            e.target.showPicker();
                        } catch (error) {

                        }

                        // }, [200])


                    }}
                    min={new Date().setHours(5, 59, 0, 0)}
                    // onBlur={() => (ref.current.type = "text")}
                    onChange={(e) => onSelect(e)}
                    value={value}
                    name={name}
                    className={`input-field bg-theme-up ${errorLabel && 'border-danger'} bg-white  object-full  ${extraClasses}`} type={type}

                    {
                    ...props
                    }
                />

                <div className={" custom-input-file-content "}>
                    <input
                        className={"bg-theme-up input-field"}
                        readOnly={true}
                        placeholder={placeholder}

                        value={value}
                        type={"text"}
                    />
                    <img src={"/assets/icons/calender_icon.svg"}
                        onClick={(e) => {
                            // e.target.type = type;
                            // ref.current.type = type
                            // e.target.readOnly = false;

                            // setTimeout(() => {


                            // ref?.current?.showPicker();


                            // }, [100])
                        }}

                    />
                </div>
            </label>
            <ErrorLabel errorTitle={errorLabel} />
        </div>
    );
}
