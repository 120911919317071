import Offer from "../components/offer";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {slugCombine} from '../utils/slugManager';
export function OfferSections({title, offersList, type, showMore = true}) {
    const {t: trans,i18n} = useTranslation(["common"]);
    let lang = (i18n.language || 'ar');
    const languageDir = "language-" + lang;

    if (offersList == null || offersList.length === 0)
        return null;
    return (
        <div className={"container mb-32 " + languageDir}>
            <h2 className={"text-20 font-bold mb-16"}>{title}</h2>
            <div className="grid-4-cols mb-16">
                {
                    offersList?.map((offer,index) => {
                        return (
                            <Offer
                            key={index}
                                name={offer?.title}
                                img={offer?.image ?  offer?.image : '/assets/images/eparts/default.png'}
                                type={offer?.type}
                                excerpt={offer?.excerpt}
                                endDate={offer?.endDate}
                                startDate={offer?.startDate}
                                totalDays={offer?.totalDays}
                                slug={slugCombine(offer?.slug,offer?.otherLink)}
                            />
                        );
                    })
                }
            </div>
            {
                showMore ?
                    offersList?.length === 4 ?
                        <div className="text-left">
                            <Link to={"/offers/type/" + type} className={"link-btn-white"}>
                                {trans("common:show-more")}
                            </Link>
                        </div>
                        : null
                    : null
            }

        </div>
    );
}