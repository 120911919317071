import 'swiper/swiper.min.css'
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/effect-fade/effect-fade.scss';
import 'swiper/components/effect-coverflow/effect-coverflow.scss';
import './css/index.scss';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from './components/navbar/navbar';
import Footer from './components/footer'
import { Home } from "./pages/home";
import { Route, Switch, useLocation, useHistory } from "react-router";
import { OfferPage } from "./pages/offers";
import { OfferServiceReservationByID } from "./pages/offers/offerServiceReservationByID";
import { NewsPage } from "./pages/news";
import { NewsDetailPage } from "./pages/news/detail";
import { EmploymentPage } from "./pages/employment";
import { EmploymentDetailPage } from "./pages/employment/detail";
import { EmploymentApplicationFormPage } from "./pages/employment/ApplicationForm";
import { EmploymentInterviewAttendance } from "./pages/employment/interviewAttendance";
import { VehiclePage } from "./pages/vehicles";
import { VehicleOnlinePage } from "./pages/vehicles/vehicleOnline";
import { VehicleDetailPage } from "./pages/vehicles/details/home";
import { OfferDetailPage } from "./pages/offers/detail";
import { OfferVehiclesPage } from "./pages/offers/vehicles";
import { EpartsPage } from "./pages/eparts";
import { EpartsDetailPage } from "./pages/eparts/detail";
import { CartPage } from "./pages/cart";
import { FinancePage } from "./pages/finance";
import { FinanceDetailPage } from "./pages/finance/detail";
import { AboutPage } from "./pages/about";
import { ContactPage } from "./pages/contact";
import ScrollToTop from "./scrollToTop";
import { Provider } from "react-redux";
import store from "./_redux/store";
import { MaintenancePage } from "./pages/maintenance";
import { MaintenanceServicePage } from "./pages/maintenance/m-service";
import { MaintenanceServiceDetailPage } from "./pages/maintenance/serviceDetail";
import { OrderCarPage } from "./pages/orderCar";
import { OrderFinancePage } from "./pages/finance/order";
import { MaintenanceOrderPage } from "./pages/maintenance/order";
import { HJAdvisesPage } from "./pages/Hj/advises";
import { UserPage } from "./User";
import NotFoundPage_404 from "./pages/_errors/404";
import RedirectPage from "./pages/_errors/redirect";
import { OfferByTypePage } from "./pages/offers/offersByType";
import { ToastContainer } from "react-toastify";
import { EpartsGroupPage } from "./pages/eparts/group";
import { ResulteSearch } from "./pages/eparts/sereach";
import { CampaignOrderPage } from './pages/eparts/campaignOrder';
import { CompaignDetails } from './pages/eparts/campaignDetails';
import { FeatureDetails } from './pages/eparts/featureDetails';
import ProtectedRoute from './components/protectedRoute';
import { ComparePage } from "./pages/compare";
import { OfferOrderPage } from "./pages/offers/order";
import ConfirmContext from './utils/confirmContext';
import MaintenanceContext from "./pages/maintenance/maintenanceContext";
import ServiceOrderPopup  from "./pages/maintenance/serviceOrderPopup";
import PurchaseConfirm from './components/purchaseConfirm';
import Registrationpup from './components/registrationpup';
import { useEffect, useLayoutEffect, useState } from 'react';
import { Search } from './pages/search/index';
import CustomerServices from './components/customerService';
import axios from 'axios';
import config from './configuration/config.json';
import { concat, first, split } from 'lodash';
import { useTranslation } from 'react-i18next';
import PurchaseAlertPage from './pages/Alerts/purchaseAlertPage';
import { PrivacyPage } from './pages/privacy';
import { WebsitePolicyPage } from './pages/websitePolicy';
import { AgreementPage } from './pages/Agreement';
import { ProtectionAndShadingAgreementPage } from './pages/ProtectionAndShadingAgreement';
import { LandengPage } from './pages/landengPage';
import { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { CarOverviewPage } from "./pages/carOverview";
import { CarOverviewPage2 } from "./pages/carOverview/index-2";
import { NewsPageDaynamic } from "./pages/landengPage/news";
function App() {
    const { t: trans, i18n } = useTranslation("model");
    const lang =i18n.language || 'ar';
    const dir = lang == 'en' ? false : true;
    const location = useLocation();
    const pageDir = 'language-' + lang;
    const [confirmObj, setConfirmObj] = useState({
        title: 'عنوان رسالة التاكيد',
        subTitle: null,
        image: null,
        backGround: null,
        displayConfirm: false,
        isHyper: false,
        isNew: false,
        brand: null,
        type: null,
        cartDetails: null,
        defaultColorID: null,
        modelYear: null,
        colors: [{ text: '1', value: '1' }],
        carOrder: null,
        ColorsModel: []
    });
    const [contactUsData, setContactUsData] = useState({});


    // to open the confim

    // u

    // useHistory()
    let history = useHistory();
    // useEffect(() => {
    //     console.log("history.url.includes(): ", history.location.pathname);
    //    
    //     // history.push('/news',['Test'])
    //     if(window.location.pathname.includes('/news/detail/')){
    //     //    
    //     window.history.pushState([3333],'', '/news1');
    //     window.location.reload();
    //        
    //    
    //     }
    //    
    //     // console.log(history);
    // }, []);
    // 

    const [maintenanceObj, setMaintenanceObj] = useState({
        showPopup: false, type: "Notset", main: "", sub1: "", sub2: ""
    });
    const handleServiceOrder = (data) => {

        console.log("handleServiceOrder>>>>>>>>>>>>>>>>>>>>:", data);
        let val = { ...maintenanceObj, ...data };
        setMaintenanceObj(val);
    };

    const onShow = (data) => {
        let val = { ...confirmObj, ...data };
        setConfirmObj(val);
    };

    // to close the confirm
    const onClose = () => {
        setConfirmObj({
            title: 'عنوان رسالة التاكيد',
            subTitle: null,
            image: null,
            backGround: null,
            displayConfirm: false,
            isHyper: false,
            isNew: false,
            brand: null,
            type: null,
            cartDetails: null,
            defaultColorID: null,
            modelYear: null,
            colors: [{ text: '1', value: '1' }],
            carOrder: null,
            ColorsModel: []
        })
    };

    useLayoutEffect(() => {
        // initialSeoMetaData();
    }, []);
    useEffect(() => {
        initialCustomerServicesData();
    }, []);


    // to fetch the contact us info
    const initialCustomerServicesData = () => {
        axios.post(
            config.baseURL + 'func',
            {
                "Name": "SiteFetchActiveContactUs"
            }).then(response => {
                const responseData = response.data.map(item => {
                    return {
                        name: item[`${trans("model:description")}`],
                        whatsApp: item.WhatsAppPhoneNumber,
                        phoneNumber: item.CallPhoneNumber,
                        start: item.StartActiveTime,
                        end: item.EndActiveTime,
                        isOnline: item?.IsOnline,
                        image: item?.image,
                        welcomeTitle: item?.welcomeTitle
                    }

                });
                const result = responseData[0];
                setContactUsData(result);
            }).catch(error => {
                console.log(error);
            });
    };

    const [visible, setVisible] = useState(false)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(true)
        }
        else if (scrolled <= 300) {
            setVisible(false)
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
        });
    };

    window.addEventListener('scroll', toggleVisible);

    const [showRegistrationPup, setShowRegistrationPup] = useState(false)
    const onShowRegistrationPup = (val) => {

        setShowRegistrationPup(val);
    };
    const paths = [
        "maintenance/servicereservation",
        "maintenance/protectionandshading",
        "offers/offerservicereservation",
        "/r/"
    ];
    return (
        <SkeletonTheme
            baseColor={""}
            highlightColor={"#c9c9c9"}
        >
            <Provider store={store}>
                <ConfirmContext.Provider value={{ onShow, onClose, onShowRegistrationPup }}>
                    <MaintenanceContext.Provider value={{ maintenanceObj, handleServiceOrder }}>

                        <div className={`App  ${pageDir}`}>
                            {
                                location?.pathname?.toLowerCase().includes("landengpage") || paths.some(path => location?.pathname?.toLowerCase().includes(path)) ?
                                    null
                                    :
                                    <Navbar />
                            }

                            <div className='flex-auto'>
                                <Switch>


                                    <Route exact path="/" component={Home} />
                                    <Route exact path="/news/:page?" component={NewsPage} />
                                    <ProtectedRoute component={UserPage} path='/user' />
                                    <Route exact path="/offers" component={OfferPage} />
                                    <Route exact path="/offers/order/:slug" component={OfferOrderPage} />
                                    <Route exact path="/offers/detail/:slug" component={OfferDetailPage} />
                                    <Route exact path="/offers/vehicles/:slug" component={OfferVehiclesPage} />
                                    <Route exact path="/offers/type/:type" component={OfferByTypePage} />
                                    <Route exact path="/offers/offerServiceReservation/:slug" component={OfferServiceReservationByID} />
                                    <Route exact path="/news/detail/:slug" component={NewsDetailPage} />
                                    {/*<Route exact path="/news1" component={NewsDetailPage} />*/}
                                    <Route exact path={'/carOverview/:sn/:slug'} component={CarOverviewPage} />
                                    <Route exact path={'/car/:page/:sn/:slug'} component={CarOverviewPage2} />
                                    <Route exact path="/recruitment/detail/:slug" component={EmploymentDetailPage} />
                                    <Route exact path="/recruitment/:page?" component={EmploymentPage} />
                                    <Route exact path="/recruitment/applicationForm/:id" component={EmploymentApplicationFormPage} />
                                    <Route exact path="/recruitment/interviewAttendance/:id" component={EmploymentInterviewAttendance} />
                                    <Route exact path="/order-car" component={OrderCarPage} />
                                    <Route exact path="/hj/advises" component={HJAdvisesPage} />
                                    <Route exact path="/about" component={AboutPage} />
                                    <Route path="/maintenance" component={MaintenancePage} />
                                    <Route exact path="/maintenance/service/:CATid" component={MaintenanceServicePage} />
                                    <Route exact path="/maintenance/service/detail/:ServicId" component={MaintenanceServiceDetailPage} />
                                    <Route exact path="/maintenance/order-service/:service" component={MaintenanceOrderPage} />
                                    <Route exact path="/contact" component={ContactPage} />
                                    <Route exact path="/finance" component={FinancePage} />
                                    <Route exact path="/finance/detail" component={FinanceDetailPage} />
                                    <Route exact path="/finance/order" component={OrderFinancePage} />
                                    <Route exact path="/cart" component={CartPage} />
                                    <Route path="/purchaseMsg/:id" component={PurchaseAlertPage} />
                                    <Route exact path="/eparts" component={EpartsPage} />
                                    <Route exact path="/eparts/detail" component={EpartsDetailPage} />
                                    <Route exact path="/eparts/group/:GroupId" component={EpartsGroupPage} />
                                    <Route exact path="/eparts/sereach" component={ResulteSearch} />
                                    <Route exact path='/eparts/compaigndetails/:compaignId' component={CompaignDetails} />
                                    <Route exact path='/eparts/featuredetails/:compaignId' component={FeatureDetails} />
                                    <Route exact path="/eparts/detail/:ProductId" component={EpartsDetailPage} />
                                    <Route exact path="/eparts/campaignorder" component={CampaignOrderPage} />
                                    <Route exact path="/vehicles/:brand" component={VehiclePage} />
                                    <Route exact path="/VehicleOnline/" component={VehicleOnlinePage} />
                                    <Route exact path="/compare" component={ComparePage} />
                                    <Route exact path="/vehicles/:brand/:group/:year" component={VehicleDetailPage} />
                                    {/*<Route exact path="/vehicles/:brand/:slug" component={VehicleDetailPage} />*/}
                                    <Route exact path="/vehicles/:brand/:group/:year/:section/" component={VehicleDetailPage} />
                                    <Route exact path="/vehicles/:brand/:group/:year/:section/:modelslug" component={VehicleDetailPage} />
                                    <Route exact path='/search' component={Search} />
                                    <Route exact path='/privacy' component={PrivacyPage} />
                                    <Route exact path='/websitePolicy' component={WebsitePolicyPage} />
                                    <Route exact path='/agreement' component={AgreementPage} />
                                    <Route exact path='/protectionAndShadingAgreement' component={ProtectionAndShadingAgreementPage} />

                                    <Route exact path="/landengPage" component={LandengPage} />
                                    <Route exact path="/landengPage/:pageID" component={LandengPage} />

                                    <Route exact path="/NewsPage/:page?" component={NewsPageDaynamic} />

                                    <Route exact path="/r/:page?" component={RedirectPage} />
                                    <NotFoundPage_404 />


                                </Switch>
                                {/* <SocialContact/> */}

                                {
                                    location.pathname.includes("landengPage") ?
                                        null
                                        :
                                        <CustomerServices
                                            fullName={contactUsData?.name ?? ''}
                                            whatsupNumber={contactUsData.whatsApp ?? '+966920018996'}
                                            phonCallNumber={contactUsData.phoneNumber ?? '+966920018996'}
                                            isOnline={contactUsData.isOnline}
                                            image={contactUsData.image}
                                            welcomeTitle={contactUsData.welcomeTitle}
                                        />
                                }



                            </div>
                            <ScrollToTop />

                            <div
                                className={"ScrollToTopButton " + (visible ? " active " : "")}
                                onClick={scrollToTop}
                            >
                                <img className={"w-11 h-11 rotate-180"}
                                    src={"/assets/icons/arrow-light.svg"} alt={"show"} />
                            </div>

                            {
                                paths.some(path => location?.pathname?.toLowerCase().includes(path)) ?
                                    null
                                    :
                                    <>
                                        <Footer />

                                    </>
                            }

                            <ToastContainer
                                position="bottom-left"
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={dir}

                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                            />
                            <PurchaseConfirm
                                {
                                ...confirmObj
                                }
                            />

                            <ServiceOrderPopup maintenanceObjData ={maintenanceObj} />
                            <Registrationpup
                                displaypup={showRegistrationPup}
                                handlePopupStatusChange={(val) => setShowRegistrationPup(val)}
                            />
                        </div>
                    </MaintenanceContext.Provider >
                </ConfirmContext.Provider>
            </Provider>
        </SkeletonTheme>
    );
}
export default App;
