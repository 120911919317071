import { Fragment, useEffect, useState, useRef } from "react";
import SwiperCore, { Pagination, Navigation, Autoplay, EffectFade } from "swiper";
import { useTranslation } from "react-i18next";
import Banner from "../../components/banner";
import { InputField } from "../../components/_input/_input";
import { FormButton } from "../../components/_input/_button";
import { PhoneInput } from "../../components/_input/_phoneInput";
import PhoneDropDown from "../../components/_input/_phoneDropDown";
import Breadcrumb from "../../components/__simple/breadcrumb";
import { toast } from 'react-toastify';
import { useParams, useLocation, useHistory } from "react-router";
import axios from "axios";
import Joi from "joi";
import { GetMessages, formValidate} from '../../utils/validation';
import config from '../../configuration/config.json';
export function CampaignOrderPage() {
    const { t: trans , i18n} = useTranslation(["common", "employment", "form", "heading", "inputs", "model", "eparts", "error"]);
    const languageDir = 'language-' + (i18n.language || 'ar'); 
    const [requestForm, setRequestForm] = useState({
        arName: '',
        enName: '',
        phoneNumber: '',
        email: '',
        identityNo: ''
    });


    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const history = useHistory();
    //*****************************************
    const [employment, setEmployment] = useState({});


    const [compaignDetail, setCompaignDetails] = useState({
        compaignNo: '',
        featureNo: '',
        userNo: ''
    });

    const [controlDisabled, setControlDisabled] = useState(false);
    //**********************************************
    const [orderType, setOrderType] = useState(0);

    const schema = Joi.object({
        arName: Joi.string().required().messages(GetMessages()),
        enName: Joi.string().required().messages(GetMessages()),
        email: Joi.string().email({ minDomainSegments: 2, tlds: { allow: false } }).required().messages(GetMessages()),
        phoneNumber: Joi.string().min(12).required().messages(GetMessages()),
        identityNo: Joi.string().required().messages(GetMessages())
    });

    //************************************
    const [error, setError] = useState({});
    //************************************
    const [compaign, setCompaign] = useState({
        description: '',
        descriptionTitle: '',
        photo: ''
    });
    //************************************
    const [compaignFeatures, setCompaignFeatures] = useState([{}]);
    //************************************

    const submitRequest = async (e) => {
        e.preventDefault();
        const result = formValidate(schema, requestForm) ?? {};
        setError(result);
        try {

            if (Object.keys(result).length === 0) {

                if (compaignDetail.userNo != '') {

                    if (await sendOrderRequest(compaignDetail.userNo) === 201) {
                        toast.success(trans('eparts:success-sent'));
                        resetControls();
                    }
                } else {
                    if (!(await existedUser(requestForm.phoneNumber, requestForm.email, requestForm.identityNo))) {
                        if ((await sendCreateUserAccount()) == 201) {

                            const val = { ...compaignDetail };
                            const response = await getUserDetails(requestForm.phoneNumber);
                            if (response.length > 0) {
                                val.userNo = response[0]["Web_UserID"];
                                setCompaignDetails(val);
                                if ((await sendOrderRequest(val.userNo)) === 201) {
                                    toast.success(trans('eparts:success-sent'));
                                }
                            } else {
                                throw new Error();
                            }
                            resetControls();
                        }
                    } else {
                        toast.error(trans('common:existed_info'));
                    }
                }
            }

        } catch (e) {
            toast.error('error:unknown_failure');
        }

    }

    function resetControls() {
        setControlDisabled(false);
        setRequestForm({
            arName: '',
            enName: '',
            phoneNumber: '',
            email: '',
            identityNo: ''
        });
        setCompaignDetails({});
        setError({});
        history.goBack();
    }

    async function sendOrderRequest(no) {
        const response = await axios.post(
            config.baseURL + 'add',
            {
                "object": "WebSalesDiscountCustomersCon",
                "values": {
                    "Web_UserID": parseInt(no),
                    "packageId": compaignDetail.featureNo != '' ? parseInt(compaignDetail.featureNo) : null,
                    "DiscountID": compaignDetail.compaignNo
                }
            }
        );

        return response.status;
    }

    async function sendCreateUserAccount() {
        const response = await axios.post(
            config.baseURL + 'add',
            {
                "object": "Web_Users",
                "values": {
                    "DescriptionAr": requestForm.arName,
                    "DescriptionEn": requestForm.enName,
                    "Email": requestForm.email,
                    "Phone": requestForm.phoneNumber,
                    "IdentityNumber": requestForm.identityNo,
                }
            }
        );
        return response.status;
    }

    async function getUserDetails(phoneNumber) {
        const response = await axios.post(
            config.baseURL + 'details',
            {
                "object": "Web_Users",
                "ObjectSettings": {
                    "MetaData": false
                },
                "Filters": `where phone = '${phoneNumber}'`
            });
        return response.data.ApiObjectData;
    }

    async function existedUser(phoneNumber, email, identityNo) {
        const response = await axios.post(
            config.baseURL + 'details',
            {
                "object": "Web_Users",
                "ObjectSettings": {
                    "MetaData": false
                },
                "Filters": `where phone = '${phoneNumber}' or lower(email) = lower('${email}') or IdentityNumber = '${identityNo}'`
            });
        return response.data.ApiObjectData.length > 0;
    }
    function compaignFearuresObject(compaignID) {
        return {
            "Object": "WebSparePackagesDatails",
            "ObjectSettings": {
                "MetaData": false
            },
            "Filters": `where offerID = '${compaignID}'`
        };
    }

    const GetUrl = config.baseURL + 'details';
    const [PWD, setPWD] = useState([]);

    let { compaignId } = useParams();
    function RequsetProductsWithDetails(ID) {
        const Body = {
            "object": "WebSpareSupportedCars",
            "ObjectSettings": {
                "MetaData": false
            },
            "Filters": "where packageId='" + ID + "'"
        };
        axios.request({
            url: GetUrl,
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {
                // console.log(response.data.ApiObjectData);
                setCompaignFeatures(response.data.ApiObjectData.map((e) => {
                    return {
                        name: e[trans("model:description")]
                    }
                }))

            })
            .catch(function (error) {
                console.log(error);
            });


    }
    const [EngineGroups, setEngineGroups] = useState([]);
    function RequsetsetEngineGroups(packageID) {
        const compaignInfo = { ...compaign };
        const BodyEngineGroups = {
            "object": "WebSparePackagesDatails",
            "ObjectSettings": {
                "MetaData": false

            },
            "Filters": "where packageId='" + packageID + "'"
        };
        axios.request({
            url: GetUrl,
            data: BodyEngineGroups, method: "POST", responseType: "json"
        })
            .then(function (response) {
                // console.log(response.data.ApiObjectData);
                setTimeout(setEngineGroups(response.data.ApiObjectData), 1000);
                RequsetProductsWithDetails(response.data.ApiObjectData[0].packageId)
                compaignInfo.descriptionTitle = response.data.ApiObjectData[0][trans("model:name")] ?? '';
                compaignInfo.description = response.data.ApiObjectData[0][trans("model:desc")] ?? '';
                compaignInfo.photo = response.data.ApiObjectData[0].image;
                setCompaign(compaignInfo);

            })
            .catch(function (error) {
                console.log(error);
            });


    }
    //finsh
    useEffect(() => {
        try {
            /*Here we set some terms to initialize the values and set values to controls*/
            const val = { ...compaignDetail };
            val.compaignNo = query.get('compaign');
            if (query.has('ur_gid')) {
                const userGid = query.get('ur_gid');
                const userNo = userGid.split('_')[1];
                val.userNo = userNo;
                //***************************************
                const userInfo = { ...requestForm };
                userInfo.arName = query.get('ur_ar') ?? '';
                userInfo.enName = query.get('ur_en') ?? '';
                userInfo.email = query.get('ur_email') ?? '';
                userInfo.phoneNumber = '\+' + query.get('ur_phone').trim() ?? '';
                userInfo.identityNo = query.get('ur_iden') ?? '';
                setRequestForm(userInfo);
                //*****************************************
                setControlDisabled(true);
            }
            if (query.has('fetr')) {
                val.featureNo = query.get('fetr');
            }
            setCompaignDetails(val);
        } catch (err) {

        }
        console.log('info', requestForm);
    }, [])

    useEffect(() => {
        const compaignID = query.get('compaign');
        const packageID = query.get('fetr');
        const compaignInfo = { ...compaign };
        if (packageID) {
            RequsetsetEngineGroups(packageID);
            setOrderType(1);
        } else {
            setOrderType(0);
            axios.post(
                config.baseURL + "details",
                {
                    "Object": "SalesDiscountCustomers_sp",
                    "ObjectSettings": {
                        "MetaData": false
                    },
                    "Option": "Column",
                    "Fields": "DescriptionEnTitle,DescriptionArTitle,Photo,DescriptionAr,DescriptionEn",
                    "Filters": `where DiscountID = '${compaignID}'`
                }
            ).then((response) => {
                compaignInfo.descriptionTitle = response.data.ApiObjectData[0][trans("model:descriptionTitle")] ?? '';
                compaignInfo.description = response.data.ApiObjectData[0][trans("model:description")] ?? '';
                compaignInfo.photo = response.data.ApiObjectData[0].Photo;
                setCompaign(compaignInfo);
                //********************************************
                //populate the compaign features list
            }).catch((error) => {
                console.log(error);
            });

            axios.post(
                config.baseURL + "details",
                compaignFearuresObject(compaignID)
            ).then((response) => {
                const result = response.data.ApiObjectData;
                if (result.length > 0) {
                    let features = result.map(e => {
                        return {
                            name: e[trans("model:name")]
                        }
                    });
                    setCompaignFeatures(features);
                }
            }).catch((error) => {
                console.log(error);
            });
        }
    }, []);

    return (
        <Fragment>

            <Banner
                subtitle={compaign.description}
                title={compaign.descriptionTitle}
                desc={''}
                background={
                    compaign.photo != '' ?
                        "data:image/png;base64," + compaign.photo : '/assets/images/eparts/defulte.png'
                }
                backOpasity={compaign?.backOpasity}
            />
            <div className="container">
                <Breadcrumb className={"mt-16 "}
                    links={[
                        {
                            name: trans("common:e-parts-&-services"),
                            to: "/eparts"
                        },
                        {
                            name: orderType == 0 ? trans("common:compaign-order") : trans("common:feature-order")
                        }
                    ]}
                />
            </div>

            <div className={"container mb-32 grid-2-cols " + languageDir} style={{ gap: "3rem" }}>

                <div className={"flex-grow-1"}>
                    <div className={"w-full relative"}>

                        <div className={"mb-20"}>
                            <div className="job-heading">
                                {
                                    orderType == 0 ? trans("common:features") : trans("common:feature-goods")

                                }
                            </div>
                            <ul className={"list-bullet"}>
                                {
                                    compaignFeatures && compaignFeatures.map(
                                        (e, i) => {
                                            return <li key={i}>
                                                {
                                                    e.name
                                                }
                                            </li>
                                        }
                                    )
                                }
                            </ul>

                        </div>

                    </div>
                </div>
                <div className={"inputs-group flex-grow-1"}>
                    <form onSubmit={(e) => {
                        submitRequest(e);
                    }}>
                        <InputField
                            enabled={controlDisabled}
                            value={requestForm.arName}
                            errorLabel={error.arName}
                            onChangeValue={(e) => {
                                const val = { ...requestForm };
                                val.arName = e.target.value;
                                setRequestForm(val);
                            }}
                            title={trans("form:ar_name")}
                            name='arName'
                        />
                        <InputField
                            enabled={controlDisabled}
                            value={requestForm.enName}
                            onChangeValue={(e) => {
                                const val = { ...requestForm };
                                val.enName = e.target.value;
                                setRequestForm(val);
                            }}
                            errorLabel={error.enName}
                            title={trans("form:en_name")}
                            name='enName'
                        />
                        <PhoneInput
                            defaultValue={requestForm.phoneNumber}
                            enabled={controlDisabled}
                            title={trans("form:phone")}
                            name='phone'
                            errorLabel={error.phoneNumber}
                            onChangeValue={
                                (e) => {
                                    const val = { ...requestForm };
                                    val.phoneNumber = e;
                                    setRequestForm(val);
                                }
                            }
                        />
                        <InputField
                            enabled={controlDisabled}
                            value={requestForm.email}
                            type='email'
                            onChangeValue={(e) => {
                                const val = { ...requestForm };
                                val.email = e.target.value;
                                setRequestForm(val);
                            }}
                            errorLabel={error.email}
                            title={trans("form:email")}
                            name='email'
                        />
                        <InputField
                            enabled={controlDisabled}
                            value={requestForm.identityNo}
                            onChangeValue={(e) => {
                                const val = { ...requestForm };
                                val.identityNo = e.target.value;
                                setRequestForm(val);
                            }}
                            errorLabel={error.identityNo}
                            title={trans("form:identity-no")}
                            name='identityNo'
                        />
                        <FormButton
                            name={trans("form:send")}
                        />
                    </form>
                </div>

            </div>
        </Fragment>
    );
}