import { useTranslation } from "react-i18next";
export default function PopupHeader({welcomeTitle , subTitle , closePopUp}){
    const {i18n } = useTranslation();
    let lang = (i18n.language || 'ar');
    return(
        <div className='popup-header'>
        <span className='popup-close' onClick={()=>closePopUp()}>&times;</span>
        <div className='popup-header-profile'>
            {
                lang=="ar" ?
                <img src="/assets/logo/logo_AR.png"alt="hassan jameel" style={{width:'80%'}}/>   
                :
                <img src="/assets/logo/logo_EN.png"alt="hassan jameel" style={{width:'80%'}}/>   

            }
       
        <div className='ruler mt-10'></div>
               <h1 className='text-white mt-10'>{welcomeTitle}</h1>
               <p className='text-white mt-5 text-size-16'>{subTitle}</p>
        </div> 
       </div>
    );
}