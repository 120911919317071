import { useTranslation } from "react-i18next";
import config from '../../../configuration/config.json';
export const Compare = ({ headerItems = [], bodyItems = [], onAdd, onDelete, maxHeaderItems = 5 }) => {
    const {i18n } = useTranslation();
    const lang =  (i18n.language || 'ar');
    const languageDir = 'language-' + lang;
    return (
        <div className={"mb-20 vehicle-compare " + languageDir}>
            <CompareHeaderRow headerItems={headerItems} onAdd={onAdd} onDelete={onDelete} maxItems={maxHeaderItems} />
            <CompareBody bodyItems={bodyItems} />
        </div>
    );
};
export const CompareHeaderRow = ({ headerItems = [], onAdd, onDelete, maxItems }) => {
    const { t: trans } = useTranslation("compare","common");
    return (
        <div className="vehicle-compare-header">
            <div className="vehicle-compare-header-item-primary">
                <div className="text-16 text-bold text-center">
                    {trans("common:compare")}
                </div>
            </div>
            {
                headerItems.map((item, index) => {
                    return <CompareHeaderItem
                        vehicleImage={item.Image}
                        postion={index}
                        id={item.ID}
                        onAdd={
                            (value) => {
                                if (onAdd) {
                                    onAdd(value);
                                }
                            }
                        }
                        onDelete={(value, id) => {
                            if (onDelete) {
                                onDelete(value, id);
                            }
                        }}
                    />
                })
            }
            {headerItems?.length < maxItems && <CompareHeaderItem
                postion={headerItems?.length}
                onAdd={
                    (value) => {
                        if (onAdd) {
                            onAdd(value);
                        }
                    }
                }
                onDelete={(value, id) => {
                    if (onDelete) {
                        onDelete(value, id);
                    }
                }}
            />}
        </div>
    )
};
export const CompareHeaderItem = ({ vehicleImage, id, onDelete, onAdd, postion }) => {
    return (
        <div className="vehicle-compare-header-item p-12">
            { id && <div className="nice-pointer nice-trash-btn" onClick={() => {
                if (onDelete) {
                    onDelete(postion, id);
                }
            }}>
                <img src="/assets/icons/trash.svg" />
            </div>
            }
            <div className="vehicle-compare-header-item-img-container nice-flex nice-flex-center">
                {
                    (vehicleImage != null) ? <img src={config.FilePathURL +vehicleImage} />
                        : (
                            <div onClick={() => {
                                if (onAdd) {
                                    onAdd(postion);
                                }
                            }} className="nice-pointer" title="Click here to add new one">
                                <img src="/assets/icons/plus.svg" />
                            </div>)
                }
            </div>
        </div>
    )
};
export const CompareBody = ({ bodyItems = [] }) => {
    const { t: trans } = useTranslation("model");
    return (
        <div className="vehicle-compare-body">
            {
                bodyItems.map((item, index) => {
                    return <CompareBodyRow
                        properityName={item[`${trans('model:description')}`]}
                        properityValues={item.Values}
                    />
                })
            }
        </div>
    )
};
export const CompareBodyRow = ({ properityName, properityValues = [] }) => {
    const { t: trans } = useTranslation("model");
    return (
        <div className="vehicle-compare-body-row">
            <div className="text-center px-4 vehicle-compare-body-row-item vehicle-compare-body-row-item-properity nice-flex nice-flex-center text-16 text-bold ">
                {properityName}
            </div>
            {
                properityValues.map((item, index) => {
                    return <div key={index} className=" text-center vehicle-compare-body-row-item nice-flex nice-flex-center text-16 ">
                        {item !== null ? item[`${trans('model:description')}`] : '-'}
                    </div>
                })
            }
        </div>
    )
};
