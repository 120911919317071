import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { getTrackBackground, Range } from "react-range";
import _ from 'lodash'; // Only if using lodash for debounce
export function FinanceRange({ placeHolder, min, max, step, suffix, firstValue, onChangeValue }) {
    const { i18n } = useTranslation();
    let lang = (i18n.language || 'ar');
    const [values, setValues] = useState([firstValue]);
    const [valueInput, setValueInput] = useState(firstValue);
    const [Lastvalues, setLastValues] = useState([firstValue]);

    useEffect(() => {
        setValues([firstValue]);
        setLastValues([firstValue]);
        setValueInput(firstValue);
    }, [firstValue]);
    const RTL = lang == "ar" ? true : false;

    // Debounce the function to set the value
    const debouncedSave = useCallback(
        _.debounce((value) => {
     
            setFinalChangeValue(value);
          
            // Here you would normally save the value to a server or similar
        }, 500), // 1000 ms debounce time
        []
    );

    const setFinalChangeValue = (value) => {
        if (value <= max && value >= min) {

            setLastValues([value]);
            setValues([value]);
            if (onChangeValue) {
                onChangeValue([value]);
            }
        } else {
            if(Lastvalues){
                setValues(Lastvalues);
                setValueInput(Lastvalues);
            }
      
        
        }
    }
    const handleChangeValue = (value) => {

        setValueInput(value);
        // debouncedSave(value);
    };
    const handleChangeValueRange = (value) => {
        if (value[0] <= max) {
            setValues(value);
            if (onChangeValue) {
                onChangeValue(value);
            }
        } else {
        }
    };
    return (
        <div className={"range grid-3-cols"}>
            <div className="text-16">
                {placeHolder}
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    margin: "0 2rem",
                    flexGrow: "1"
                }}

                className="range-control"
            >
                <Range
                    values={values}
                    step={step}
                    min={min}
                    max={max}
                    rtl={RTL}

                    onChange={(values) => handleChangeValueRange(values)}
                    renderTrack={({ props, children }) => (
                        <div
                            onMouseDown={props.onMouseDown}
                            onTouchStart={props.onTouchStart}
                            style={{
                                ...props.style,
                                height: "3.6rem",
                                display: "flex",
                                width: "100%"
                            }}
                        >
                            <div
                                ref={props.ref}
                                style={{
                                    height: ".5rem",
                                    width: "100%",
                                    borderRadius: ".4rem",
                                    background: getTrackBackground({
                                        values: values,
                                        colors: ["var(--primary)", "var(--gray-light)"],
                                        min: min,
                                        max: max,
                                        rtl: RTL
                                    }),
                                    alignSelf: "center"
                                }}
                            >
                                {children}
                            </div>
                        </div>
                    )}
                    renderThumb={({ props, isDragged }) => (
                        <div
                            {...props}
                            style={{
                                ...props.style,
                                height: "3rem",
                                width: "3rem",
                                borderRadius: "40rem",
                                backgroundColor: "var(--primary)",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <div
                                style={{
                                    height: "1rem",
                                    width: "1rem",
                                    borderRadius: "40rem",
                                    backgroundColor: "var(--white)"
                                }}
                            />
                        </div>
                    )}
                />
            </div>
            <div className={"range-suffix"}>
                <div className={"input input-secondary "}>
                    <input className={"input-field "}
                        pattern="[0-9]+"
                        inputMode="numeric"
                        onChange={(e) => handleChangeValue(e.target.value)} onBlur={(e)=>setFinalChangeValue(e.target.value)} type="text" value={valueInput}
                    />
                </div>
                <div className="text-16 text-gray ">
                    {suffix}
                </div>

            </div>
        </div>
    );
}

