import ErrorLabel from '../../components/error';
import { useTranslation } from "react-i18next";
export function TextArea({ title, placeholder, row = 10, onChangeValue, secondary = false, required = false, errorLabel, value, disabled = false, style = null }) {
    const { t: trans, i18n } = useTranslation(["info"]);
    let lang = (i18n.language || 'ar');
    let languageDir = "language-" + lang;
    return (
        <div className={"input " + languageDir + ' ' + (secondary && "input-secondary")}>
            {
                title &&
                <label className={"input-label"} htmlFor="name">{title}</label>
            }

            <ErrorLabel errorTitle={errorLabel} />
            <textarea placeholder={placeholder} style={style} disabled={disabled} className={"input-field"} onChange={(e) => onChangeValue(e.target.value)} rows={row} required={required} value={value}></textarea>
        </div>
    );
}
