import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { InputField } from "../components/_input/_input";
import { FormButton } from "../components/_input/_button";
import PhoneDropDown from '../components/_input/_phoneDropDown';
import { useState, useRef } from "react";
import crypto from 'crypto-js';
import Joi from "joi";
import { toast } from "react-toastify";
import { formValidate, GetMessages } from "../utils/validation";
import axios from "axios";
import { GoogleLogin } from 'react-google-login';
import config from '../configuration/config.json';
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { saveData, User_Data_Key, getData } from "../utils/localStorageManager";
import FacebookLogin from 'react-facebook-login';
import { useUserFavorite } from "./hooks/useUserFavorite";
export default function SignIn({ onTabChange, onLogin }) {
    const { init } = useUserFavorite();
    const bodyRef = useRef(null);
    const { t: trans, i18n } = useTranslation(["common", "form", "model", "error2"]);
    let lang = (i18n.language || 'ar');
    const languageDir = 'language-' + lang;
    const tabsList = [{
        tab: 1, title: 'sign-in'
    }, {
        tab: 2, title: 'sign-up'
    }, {
        tab: 3, title: 'reset-password'
    }, {
        tab: 4, title: 'login-validation'
    }
    ];
    const [isSending, setIsSending] = useState(false);
    const EMAIL_KEY = 'Email', PHONE_KEY = 'PhoneNumber', IDENTITY_KEY = 'IdentityNo';
    const history = useHistory();
    const [requestForm, setRequestForm] = useState({
        email: null,
        phoneNumber: null,
        identityNo: null,
        password: null,
        confirm: null
    });
    const [enterType, setEnterType] = useState(PHONE_KEY);
    const [confirmPhoneNumber, setConfirmPhoneNumber] = useState(false);

    let schema = Joi.object().keys({
        email: Joi.string().email({ minDomainSegments: 2, tlds: { allow: false } }).messages(GetMessages()).optional(),
        phoneNumber: Joi.string().min(13).messages(GetMessages()).optional(),
        // identityNo: Joi.string().min(8).messages(GetMessages()).optional(),
        password: Joi.string().required().messages(GetMessages()),
        confirm: Joi.string().required().messages(GetMessages())
    }).or('email', 'phoneNumber'
        , 'identityNo'
    ).required();

    let schemaphoneNumber = Joi.object().keys({
        phoneNumber: Joi.string().min(13).messages(GetMessages())
    })

    let schemaidentityNo = Joi.object().keys({
        identityNo: Joi.string().min(8).messages(GetMessages())
    })
    let schemaConfirm = Joi.object().keys({
        phoneNumber: Joi.string().min(13).messages(GetMessages()),
        confirm: Joi.string().min(8).messages(GetMessages()),
    });

    const [error, setError] = useState({});
    const handleChangeEnteringType = (position) => {
        let type;
        switch (position) {
            case 0:
                type = PHONE_KEY;
                break;
            case 1:
                type = EMAIL_KEY;

                break;
            case 2:
                type = IDENTITY_KEY;
                break;
        }
        setConfirmPhoneNumber(false);
        setEnterType(type);
        setRequestForm({
            email: null,
            phoneNumber: null,
            identityNo: null,
            password: null,
            confirm: null
        });
    };

    // We need ref in this, because we are dealing
    // with JS setInterval to keep track of it and
    // stop it when needed
    const Ref = useRef(null);
    const [timer, setTimer] = useState('00:00:00');
    const getTimeRemaining = (e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / 1000 / 60 / 60) % 24);
        return {
            total, hours, minutes, seconds
        };
    }
    const startTimer = (e) => {
        let { total, hours, minutes, seconds }
            = getTimeRemaining(e);
        if (total >= 0) {

            // update the timer
            // check if less than 10 then we need to 
            // add '0' at the beginning of the variable
            setTimer(
                (hours > 9 ? hours : '0' + hours) + ':' +
                (minutes > 9 ? minutes : '0' + minutes) + ':'
                + (seconds > 9 ? seconds : '0' + seconds)
            )
        }
    }


    const clearTimer = (e) => {

        // If you adjust it you should also need to
        // adjust the Endtime formula we are about
        // to code next    
        setTimer('00:02:00');

        // If you try to remove this line the 
        // updating of timer Variable will be
        // after 1000ms or 1sec
        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(e);
        }, 1000)
        Ref.current = id;
    }
    const getDeadTime = () => {
        let deadline = new Date();

        // This is where you need to adjust if 
        // you entend to add more time
        deadline.setSeconds(deadline.getSeconds() + 120);
        return deadline;
    }
    const handleRequest = () => {
        console.log("handleRequest");
        const result = formValidate(schema, requestForm) ?? {};
        const errorResult = removeUnspecificError(result);
        setError(errorResult);
        try {
            if (Object.keys(errorResult).length === 0) {
                setIsSending(true);
                axios.post(
                    config.controllURL + "api/Administration/" + "SignInValidate1",
                    {
                        "AccessType": `${enterType}`,
                        "Access": (enterType == EMAIL_KEY ? requestForm.email.trim().toLowerCase() : enterType == PHONE_KEY ? requestForm.phoneNumber : requestForm.identityNo.trim().toLowerCase()),
                        "Password": crypto.MD5(requestForm.password).toString(),
                    }
                ).then((response) => {
                    setIsSending(false);
                    if (response.status == 200) {
                        // const encodedData = crypto.AES.encrypt(JSON.stringify(response.data),config.cryptoKey).toString();
                        saveData(User_Data_Key, response.data, true);
                        saveData('isAuthenticated', true, false);
                        saveData('secretKey', response.data.id.toString().split('_')[0], false);
                        // history.push('/');
                        // window.location.reload();
                        if (onLogin)
                            onLogin();
                        init();
                    }
                }).catch((error) => {
                    setIsSending(false);
                    if (error.response && error.response.status === 400) {
                        toast.error(trans("common:failed-access"));
                    }
                });
            }
        } catch (err) {
            console.log(err);
        }
    };

    const removeUnspecificError = (errorList) => {
        let errorResult = {};
        Object.assign(errorResult, errorList);
        if (errorList) {
            for (let index = 0; index < Object.keys(errorList).length; index++) {
                const key = Object.keys(errorList)[index];
                if (enterType.toLowerCase() == key.toLowerCase()) {
                    continue;
                } else {
                    if (key.toLowerCase() == 'password')
                        continue;
                    delete errorResult[key];
                }
            }
        }
        return errorResult;
    };
    const removeUnspecific2Error = (errorList) => {
        let errorResult = {};
        Object.assign(errorResult, errorList);
        if (errorList) {
            for (let index = 0; index < Object.keys(errorList).length; index++) {
                const key = Object.keys(errorList)[index];
                if (enterType.toLowerCase() == key.toLowerCase()) {
                    continue;
                } else {
                    delete errorResult[key];
                }
            }
        }
        return errorResult;
    };
    const handleSendVerificationCode = () => {
        console.log("handleSendVerificationCode");

        var sch = schemaidentityNo;
        if (enterType == PHONE_KEY) {
            sch = schemaphoneNumber;
        }
        const result = formValidate(sch, requestForm) ?? {};
        const errorResult = removeUnspecific2Error(result);

        console.log("errorResult:", errorResult);
        console.log("requestForm:", requestForm);
        setError(errorResult);
        var codeValid = false;
        if (Object.keys(errorResult).length === 0) {

            setIsSending(true);
            axios.post(
                config.controllURL + "api/Administration/" + "SendSms",
                {
                    "AccessType": enterType,
                    "Access": enterType == PHONE_KEY ? requestForm.phoneNumber : requestForm.identityNo
                }
            ).then(response => {
                setIsSending(false);
                console.log("SendSms response.data ", response);

                if (response?.data == false) {

                    toast.error(trans("common:failed-access"));
                } else {

                    if (response.data) {

                        codeValid = true;
                        clearTimer(getDeadTime());
                        if (enterType == IDENTITY_KEY) {

                            const val = { ...requestForm };
                            val.phoneNumber = response.data;
                            setRequestForm(val);
                        }


                    } else {
                        toast.error(trans("error2:no_phone_number_error"));
                    }
                }

                if (codeValid) {
                    setConfirmPhoneNumber(true);

                    console.log("after validate phoneNumber error");
                }

            }).catch(err => {
                setIsSending(false);
                toast.error(trans("common:failed-access"));
                console.log(err);
            });
            console.log("after validate phoneNumber ");

        }
    };
    const handleLogin = () => {
        console.log("handleLogin");

        const result = formValidate(schemaConfirm, requestForm) ?? {};
        const errorResult = removeUnspecific2Error(result);

        console.log("errorResult>>>>>>>>>>>>>>>:", errorResult);
        console.log("requestForm:", requestForm);
        setError(errorResult);
        if (Object.keys(errorResult).length == 0) {

            setIsSending(true);
            axios.post(
                config.controllURL + "api/Administration/" + "MatchedKeysNoValidationKey",
                {
                    "AccessType": PHONE_KEY,
                    "Access": requestForm.phoneNumber,
                    "ValidationKey": requestForm.confirm
                }
            ).then(response => {
                setIsSending(false);
                // if (response.data) {
                if (response.status === 200) {
                    var cookies = null;


                    let userrespons = response?.data;
                    userrespons["identityImage"] = "";
                    saveData(User_Data_Key, userrespons, true);
                    saveData('isAuthenticated', true, false);
                    saveData('secretKey', response.data.id.toString().split('_')[0], false);
                    // history.push('/');
                    // window.location.reload();
                    if (onLogin)
                        onLogin();
                    init();

                }
                else {

                    toast.error(trans("error2:validation_error"));
                }


            }).catch(err => {
                setIsSending(false);
                toast.error(trans("error2:validation_error"));
                console.log(err);
            })
        }
    };
    return (

        <div ref={bodyRef} className="flex flex-col w-100">
            <h1 className="text-block text-30 text-center text-bold mb-10  ">{trans("info:select-sign-in")}</h1>
            <div className={'popup-content ' + languageDir}>
                <Tabs selectedTabClassName=' border-b  text-bold  border-black' defaultIndex={0} onSelect={(position) => {
                    handleChangeEnteringType(position);
                }}>
                    <div className="sign-in-tabs p-5">

                        <TabList className='private-vehicle-tabs-container gap-3'>
                            <Tab className='text-20'>
                                {trans("form:phone")}
                            </Tab>
                            {/* <span className='vertical-separater'></span> */}
                            <Tab className='text-20'>


                                {trans("form:email")}
                            </Tab>
                            {/* <span className='vertical-separater'></span> */}
                            <Tab className='text-20'>
                                {trans("form:identity-no")}
                            </Tab>
                        </TabList>
                    </div>
                    <div className="mt-4">
                        <TabPanel>
                            {!confirmPhoneNumber &&
                                (
                                    <PhoneDropDown
                                        isLoginProp={true}
                                        errorLabel={error?.phoneNumber}
                                        placeholderProp={'5xxxxxxxx'}
                                        onChangeValue={(countryCode, phoneNumber) => {
                                            const val = { ...requestForm };
                                            val.phoneNumber = `+${countryCode}${phoneNumber}`;
                                            setRequestForm(val);
                                        }}
                                    />
                                )
                            }
                        </TabPanel>
                        <TabPanel>
                            <InputField
                                type="email"
                                errorLabel={error?.email}
                                value={requestForm.email}
                                onChangeValue={(e) => {
                                    const val = { ...requestForm };
                                    val.email = e.target.value;
                                    setRequestForm(val);
                                }}
                                placeholder={trans("form:email")}
                            />
                        </TabPanel>

                        <TabPanel>
                            {

                                !confirmPhoneNumber &&
                                <InputField
                                    length={10}
                                    errorLabel={error?.identityNo}
                                    value={requestForm.identityNo}
                                    onChangeValue={(e) => {
                                        const val = { ...requestForm };
                                        val.identityNo = e.target.value;
                                        setRequestForm(val);
                                    }}
                                    placeholder={trans("form:identity-no")}
                                />
                            }

                        </TabPanel>
                    </div>

                </Tabs>
                {enterType == EMAIL_KEY ?

                    <div className={"forget-password-content my-5 "+ languageDir}>

                        <InputField
                            type='password'
                            errorLabel={error?.password}
                            value={requestForm.password}
                            onChangeValue={(e) => {
                                const val = { ...requestForm };
                                val.password = e.target.value;
                                setRequestForm(val);
                            }}
                            placeholder={trans("common:password")}
                        />


                        <Link className='forget-password-link text-size-14 text-center text-block text-grey border-black'
                            onClick={() => onTabChange(tabsList[2])}>
                            {trans("common:forget-password")}
                        </Link>
                    </div>
                    : (confirmPhoneNumber) && <InputField
                        errorLabel={error?.confirm}
                        value={requestForm.confirm}
                        onChangeValue={(e) => {
                            const val = { ...requestForm };
                            val.confirm = e.target.value;
                            setRequestForm(val);
                        }}
                        placeholder={trans("form:validation-key")}
                    />
                }

                <div className="flex flex-row items-center justify-center nice-flex-space-between nice-gap-1rem mt-10">


                    <FormButton name={
                        (enterType == EMAIL_KEY) ?
                            trans("form:sign-in") : ((enterType == PHONE_KEY || enterType == IDENTITY_KEY) && confirmPhoneNumber == false)
                                ? trans("form:sign-in") : (timer == "00:00:00" ? (lang == "ar" ? "اعادة ارسال" : "Resend") : trans("form:sign-in"))
                    }

                        icon={true}
                        onClick={() => {

                            if (isSending == false) {

                                setTimeout(() => {
                                    if (enterType == EMAIL_KEY) {
                                        handleRequest();
                                    } else if (enterType == PHONE_KEY || enterType == IDENTITY_KEY) {
                                        if (confirmPhoneNumber == false) {
                                            handleSendVerificationCode();

                                        } else {
                                            if (timer == "00:00:00") {
                                                handleSendVerificationCode();
                                            } else {
                                                handleLogin();
                                            }

                                        }
                                    }

                                }, 500)

                            }

                        }}
                        isLoading={isSending}
                        classes={" px-10 py-5 text-20 border-sm"}

                    />

                    {
                        (confirmPhoneNumber) &&
                        <h3 className="text-20 my-5 text-block  text-center"> {timer}  </h3>

                    }



                    <Link className='text-size-14  text-center text-block text-black  border-b border-black'
                        onClick={() => onTabChange(tabsList[1])}>
                        {trans("common:no-account")}
                    </Link>
                </div>


                {/* <div className="flex flex-row items-center justify-center nice-gap-1rem">
                <div className="nice-width-size-50 p-3 ">
                    <GoogleLogin
                        clientId={`${config.googleClientID}`}
                        render={renderProps => (
                            <Link className='nice-btn nice-btn-white text-12 font-bold text-center nice-gap-1rem p-5 flex flex-row'
                                disabled={renderProps.disabled} onClick={renderProps.onClick}>
                                <span className=' nice-btn-white text-12 font-bold text-center' >
                                    {trans("form:sign-in-google")}</span>

                                <img src="/assets/icons/google.png" className='--absolute absolute-left' alt="google logo" />
                            </Link>
                        )}
                        onSuccess={(e) => onSuccess('Google', e)}
                        onFailure={(e) => onFailure('Google', e)}
                        cookiePolicy={window.location.origin}
                    />
                </div>
                <div className="nice-width-size-50 p-3 ">

                    <FacebookLogin
                        appId={`${config.facebookClientID}`}
                        callback={(e) => onSuccess("Facebook", e)}
                        fields="name,email"
                        cssClass='nice-btn nice-btn-primary p-5 flex flex-row'
                        textButton={<div className="flex flex-row items-center nice-gap-1rem font-bold text-12 text-white">
                            {trans("form:sign-in-facebook")}
                            <img src="/assets/icons/facebook.svg" className='--absolute absolute-left' alt="facebook logo" /></div>}
                        render={renderProps => (
                            <button onClick={renderProps.onClick}>
                            </button>)}
                    />
                </div>
            </div> */}
            </div>
        </div>
    );
}