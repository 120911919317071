import { Fragment, useCallback, useEffect, useState, useRef } from "react";
import SwiperCore, { Pagination, Navigation, Autoplay, EffectFade } from "swiper";
import PageHeading from "../../components/__simple/_pageheading";
import { useTranslation } from "react-i18next";
import Car from "../../components/car";
import { FilterButton } from "../../components/__simple/_filterButton";
import { OfferSections } from "../../sections/offersSection";
import Breadcrumb from "../../components/__simple/breadcrumb";
import InfoCircle from "../../components/__simple/_infoCirclejsx";
import Info from "../../components/_info";
import { useDispatch } from "react-redux";
import { changeIcons } from "../../_redux/slices/navSlice";
import axios from "axios";
import { slugCombine } from "../../utils/slugManager";
import config from "../../configuration/config.json";
import ImageGallery from "react-image-gallery";
import { groupBy, mapValues, omit } from "lodash";
import { Helmet } from "react-helmet";
import { useSelector } from 'react-redux';
SwiperCore.use([Pagination, Navigation, Autoplay, EffectFade]);

export function VehicleOnlinePage() {
    const reduxBrands = useSelector((state) => state.apiData.data.reduxBrands);
    const { t: trans,i18n } = useTranslation(["inputs", "heading", "vehicales", "info"]);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const popRef = useRef(null);
    const [gallery, setGallery] = useState([]);
    const [brandInfo, setBrandInfo] = useState({});
    const [vehicles, setVehicles] = useState([]);
    const [filteredVehicles, setFilteredVehicles] = useState(vehicles);
    const [Featured, setFeatured] = useState([]);
    const GetUrl = config.baseURL + 'details';
    const [offerVehicle, setOfferVehicle] = useState([]);
    const [brands, setBrands] = useState([]);
    const dispatch = useDispatch();
    const [carFilter, setCarFilter] = useState(null);
    let lang = (i18n.language || 'ar');
    const languageDir = 'language-' + lang;
    function RequsetFeatured() {
        const Body = {
            "object": "SiteVehicleSpecial",
            "ObjectSettings": { "MetaData": false }
        };

        axios.request({
            url: GetUrl,
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {
                setFeatured(response.data.ApiObjectData);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(async () => {
        //console.log("reduxBrands>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>:");
        //console.log("reduxBrands>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>:",reduxBrands);
    }, [reduxBrands]);
    const [cachSNImages, setCachSNImages] = useState({});
    async function FetchCarSNImages(SN) {

        try {

            //console.log("cachSNImages:", cachSNImages);
            if (cachSNImages[SN]) {

                const filterG = cachSNImages[SN];
                setGallery(filterG);

                if (popRef && filterG && filterG?.length > 0) {
                    popRef.current.toggleFullScreen();
                }

            } else {



                const response = await axios.post(
                    config.baseURL + 'func',
                    {
                        "Name": "siteFetchCarSNImages",
                        "Values": {
                            "SN": SN
                        }
                    }
                );

                if (response.status == 200) {


                    const filterG = [];

                    response.data?.map((item, index) => {
                        item["original"] = config.mainURL + item.Image;
                        item["thumbnail"] = config.mainURL + item.Image;
                        item["originalHeight"] = "50";
                        item["originalWidth"] = "50";
                        item["sizes"] = "50";
                        item["fullscreen"] = config.mainURL + item.Image;
                        filterG.push(item);


                    });

                    setGallery(filterG);

                    if (popRef && filterG && filterG?.length > 0) {
                        popRef.current.toggleFullScreen();
                    }

                    var cuCachSNImages = { ...cachSNImages };
                    cuCachSNImages[SN] = filterG;

                    setCachSNImages(cuCachSNImages);


                } else {

                    var cuCachSNImages = { ...cachSNImages };
                    cuCachSNImages[SN] = [];
                    setCachSNImages(cuCachSNImages);
                }

            }

        } catch (error) {
            console.log(error);

            var cuCachSNImages = { ...cachSNImages };
            cuCachSNImages[SN] = [];
            setCachSNImages(cuCachSNImages);

        }
    }


    useEffect(async () => {

        callback();

        const response = await axios.post(
            config.baseURL + 'func',
            {
                "Name": "siteFetchCarSNImages",
                "Values": {
                    "SN": null
                }
            }
        );

        if (response.status == 200) {


            const filterG = [];

            response.data?.map((item, index) => {
                item["original"] = config.mainURL + item.Image;
                item["thumbnail"] = config.mainURL + item.Image;
                item["originalHeight"] = "50";
                item["originalWidth"] = "50";
                item["sizes"] = "50";
                item["fullscreen"] = config.mainURL + item.Image;
                filterG.push(item);


            });


            //console.log("filterG:", filterG);
            const groupResult = mapValues(groupBy(filterG, 'SN'), fList => fList.map(items => omit(items, 'SN')));


            //console.log("groupResult:", groupResult);

            setCachSNImages(groupResult);


        } else {

            var cuCachSNImages = { ...cachSNImages };
            setCachSNImages(cuCachSNImages);
        }

    }, []);


    const callback = useCallback(async () => {
        try {
            setFilteredVehicles([]);
            setVehicles([]);
            setOfferVehicle([]);
            setBrands([]);
            setBrandInfo({});
            var res = null;

            try {
                if ("caches" in window) {
                    var curl = "OnlineVehicles-" + lang;

                    // Opening that particular cache
                    const cacheStorage = await caches.open("HJWeb");

                    // Fetching that particular cache data
                    const cachedResponse = await cacheStorage.match(
                        curl
                    );
                    var dataVehicles = await cachedResponse?.json();

                    if (dataVehicles?.vehicles)
                        setVehicles(dataVehicles.vehicles);
                    if (dataVehicles?.brands)
                        setBrands(dataVehicles.brands);

                }
            } catch (error) {

            }

            try {
                if ("caches" in window) {
                    var curl22 = "HomeExtra-" + lang;

                    // Opening that particular cache
                    const cacheStorage22 = await caches.open("HJWeb");

                    // Fetching that particular cache data
                    const cachedResponse22 = await cacheStorage22?.match(
                        curl22
                    );
                    let homeExtraRescach = await cachedResponse22?.json();

                    setOfferVehicle(homeExtraRescach?.offerVehicle);

                }

            } catch (error) {

            }
            res = await axios.request({
                baseURL: config.controllURL,
                url: lang + "/api/vehicles/OnlineVehicles",
                method: "get",
            });


            setVehicles(res.data.vehicles);
            setBrands(res.data.brands);
            const resdataVehicles = new Response(JSON.stringify(res.data));

            if ("caches" in window) {
                try {
                    // Opening given cache and putting our data into it
                    caches.open("HJWeb").then((cache) => {
                        cache.put("OnlineVehicles-" + lang, resdataVehicles);

                    });
                } catch (error) {

                }
            }


            const homeExtraRes = await axios.request({
                baseURL: config.controllURL,
                url: lang + "/api/home/HomeExtra",
                method: "get",
            });


            setOfferVehicle(homeExtraRes.data.offerVehicle);
            const data2 = new Response(JSON.stringify(homeExtraRes.data));

            if ("caches" in window) {
                try {
                    // Opening given cache and putting our data into it
                    caches.open("HJWeb").then((cache) => {
                        cache.put("HomeExtra-" + lang, data2);

                    });
                } catch (error) {

                }
            }


        } catch (error) {
            console.log(error);
        }
        RequsetFeatured();
    }, []);

    function filterCars(brand) {
        setCarFilter(brand);
        let filterData = [];
        if (brand != null)
            filterData = vehicles.filter((car) => {
                return car.brand === brand;
            });
        else {
            filterData = vehicles;
        }
        setFilteredVehicles(filterData);
    };
    // to change the icons color on the navbar
    useEffect(() => {
        dispatch(
            changeIcons({ iconsBlack: true })
        )

        return () => {
            dispatch(
                changeIcons({ iconsBlack: false })
            )
        }
    }, []);

    useEffect(() => {

        if (vehicles && vehicles.length > 0)
            setFilteredVehicles(vehicles);
    }, [vehicles]);

    const [seoPageInfo, setSeoPageInfo] = useState({});
    useEffect(async () => {

        var page = "Online Cars Store"


        const response = await axios.post(
            config.baseURL + 'func',
            {
                "Name": "SiteFetchSeoPages",
                "Values": {
                    "page": page
                }
            }
        );
        if (response.status == 200) {

            var resdata = response.data[0];

            setSeoPageInfo(resdata);


        }
    }, []);

    return (

        <>

            <Fragment>

                {
                    seoPageInfo &&

                    <Helmet>

                        <meta charSet="utf-8" />
                        {
                            seoPageInfo?.title &&
                            <title>{seoPageInfo?.title}</title>
                        }
                        {
                            seoPageInfo?.link &&
                            <link rel="canonical" href={seoPageInfo?.link} />

                        }
                        {
                            seoPageInfo?.MetaDescription &&
                            <meta name="description" content={seoPageInfo?.MetaDescription} />
                        }
                        {
                            seoPageInfo?.MetaGroups &&
                            <meta name="keywords" content={seoPageInfo?.MetaGroups} />
                        }

                    </Helmet>


                }

                <div className="container mt-xs flex nice-align-item-center" >
                    <Breadcrumb className={"mt-16 "}
                        links={[
                            {
                                name: trans("info:Online_Cars_Store")
                            }
                        ]}
                    />

                    <div className="bg-primary px-10 py-2 border-radius-full mx-5">
                        <span className="text-white text-20">{lang == "ar" ? "اصدار تجريبي" : "BETA"}</span>
                    </div>
                </div>
                <div className="container mb-32 ">
                    <PageHeading
                        fixed={true}
                        subtitle={brandInfo?.name && trans("vehicales:showroom").replace("{brand}", brandInfo?.name)}
                        title={brandInfo?.name && trans("vehicales:showroom_desc").replace("{brand}", brandInfo?.name)}
                    />



                    <div className={" mb-32 mt-32 " + languageDir}>
                        <div className={"vehicle-filter-group start mb-24 mx-auto"}>

                            <div className="filter-btns">

                                <FilterButton name={trans("inputs:all")} isActive={carFilter == null}
                                    onclick={() => filterCars(null)} />
                                {
                                    brands.map((brand, index) => {
                                        return (
                                            <FilterButton key={index} name={brand.name} isActive={carFilter == brand.name} onclick={() => filterCars(brand.name)} />
                                        );
                                    })
                                }


                            </div>

                        </div>
                        <div className="nice-row mb-16 vehicle-gap">
                            {
                                filteredVehicles?.map((vehicle, index) => {
                                    // if (parseFloat(vehicle.minPrice) != '0')
                                    return (
                                        <div key={vehicle.productTarget} className="nice-col-lg-3 nice-col-md-4 nice-col-sm-6 my-20">
                                            <Car
                                                group={vehicle.group}
                                                slug={vehicle.productTarget}
                                                year={vehicle.year}
                                                colorID={vehicle.colorID}
                                                gifts={vehicle.countGifts}
                                                groupType={vehicle.carGroupID}
                                                modelType={vehicle.type}
                                                hybridImage={vehicle.hybridImage}
                                                brandImage={vehicle.BrandImage}
                                                discountPrice={vehicle.discount}
                                                ProductId={vehicle.productID}
                                                img={vehicle.image}
                                                name={vehicle.descriptionEn + " "+vehicle.year}
                                                brand={vehicle.brand}
                                                brandID={vehicle?.brandID}
                                                category={vehicle.categor}
                                                minPrice={vehicle.minPrice}
                                                miniDownPayment={vehicle?.miniDownPayment}
                                                Tax={vehicle.Tax}
                                                RateCareDiscount={vehicle.RateCareDiscount}
                                                newCar={vehicle.newCar}
                                                hybird={vehicle.hybird}
                                                desc={vehicle.desc}
                                                modelTypes={vehicle?.modelDescription?.replace(vehicle?.brand, "")}
                                                // link={"/vehicles/" + slugCombine(vehicle.brand, vehicle.otherBrand) + "/" + vehicle.productTarget}
                                                link={"/car/Overview/" + vehicle?.sn + "/" + vehicle.productTarget}
                                                brandIcon={vehicle.brandIcon}
                                                type={vehicle.type}
                                                showPric={vehicle.showPric}
                                                buyOnline={vehicle?.buyOnline}
                                                goBuyDerict={true}
                                                colorName={vehicle?.colorName ? vehicle?.colorName : null}
                                                carData={vehicle}
                                                onClickShowSN={(SN) => FetchCarSNImages(vehicle?.sn)}
                                                btnShowSN={(cachSNImages && cachSNImages[vehicle?.sn]) ? true : false}
                                            />
                                        </div>
                                    )
                                })
                            }
                        </div>

                    </div>

                </div>

                {
                    offerVehicle &&
                    <OfferSections title={trans("info:vehicle_offers")} offersList={offerVehicle} />
                }
                <div className={"container mb-32 " + languageDir}>
                    <div className="mx-20 flex flex-row justify-center flex-wrap items-center nice-gap-1rem">

                        {Featured?.map(a => (

                            <InfoCircle
                                icon={"data:image/image;base64," + a.icon}
                                titleAr={a.titleAr}
                                titleEn={a.titleEn}
                            />
                        ))}


                    </div>
                </div>

                <div className="container mb-32">
                    <Info />
                </div>

                {/* <div className="beta-tag mb-32">
                <span className="text-white text-30">{"اصدار تجريبي"}</span>
            </div> */}




            </Fragment>

            <div className={""} style={{ opacity: (isFullScreen == true ? 1 : 0), position: (isFullScreen == true ? "relative" : "fixed"), zIndex: (isFullScreen == true ? 999999 : -1) }}>
                <ImageGallery ref={popRef} items={gallery} onScreenChange={(e) => {

                    setIsFullScreen(e);
                }} />
            </div>
        </>
    );
}