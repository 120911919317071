import axios from "axios";
import { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link,useHistory } from "react-router-dom";
import { Price } from "../../../components/__simple/_price";
import config from '../../../configuration/config.json';
import { union } from "lodash";
import ConfirmContext from "../../../utils/confirmContext";
export function CarModel({ group, type, image, name, spec, price, backupImage,
    offerIndex, extraSpecsTo = "#", productID, colorID, productTarget, brand, hybird, newCar,
    modelYear, showPric, brandID, buyOnline }) {
    const { t: trans,i18n } = useTranslation(["common", "offers", "model", "info"]);
    const [features, setFeatures] = useState([]);
    let langaugeDir = 'language-' + (i18n.language || 'ar');
    const context = useContext(ConfirmContext);
    let history = useHistory();
    const isToyota = (brand == "تويوتا" || brand.toLowerCase() == "toyota");
    useEffect(() => {
        formatFeatures();
        console.log("CarModel brand:", brand)
    }, []);
    // to format the features
    const formatFeatures = () => {
        try {
            const splittedValues = spec?.split('\n');
            setFeatures(splittedValues);


        } catch (error) {
            console.log(`Error in spliting values ${error}`);
        }
    };
    function sendRequest() {
        try {
            context.onShow(
                {
                    displayConfirm: true,
                    title: name,
                    image: image,
                    brand: brand,
                    type: type,
                    brandID: brandID,
                    isHyper: hybird,
                    isNew: newCar,
                    defaultColorID: colorID,
                    modelYear: modelYear,
                    cartDetails: {
                        id: productID,
                        name: name,
                        brand: brand,
                        image: image,
                        Type: "car",
                    },
                    carOrder: {
                        vehicle: productID,
                        group: group
                    }
                }
            );
        } catch (err) {
            console.log(err);
        }
    }

    function gotoextraSpecs() {
        const url = extraSpecsTo ? extraSpecsTo : "#";
        history.push(url);
    }
    return (
        <div className={"car-model-card box-shadow border-radius " + langaugeDir + " flex flex-col"}>

            <div className={"car-model-img py-5  " + (buyOnline == true ? " bg-primary" : "")}>

                <img onClick={() => {
                    gotoextraSpecs();
                }} className={""} src={image !== "null" ? config.mainURL + image : backupImage} alt={""} />

                {/* <div className="car-model-offers">
                    {trans("offers:offer") + ' ' + offerIndex}
                </div> */}
                <div className={"car-card-type-online car-tags"}>
                    {(buyOnline == true) && <span
                        className={"bg-white tag text-primary"}> {trans("info:Available_for_purchase_online")}</span>}
                </div>
                <div className="car-img-bg">
                    <img className={"object-full"}
                        src={`/assets/icons/brands/${(isToyota ? "toyota-black" : "lexus-black")}.png`}
                        onError={e => e.target.style.display = 'none'} />

                </div>
                <div className="car-model-name">
                    <h3 className={"text-20 font-bold  text-line-clamp text-line-clamp-2 language-en " + (buyOnline == true ? " text-white " : "")}>{name}</h3>


                    <div className="options-price ">


                        <div className="flex flex-row nice-flex-space-between language-en">


                            <h4 className={"text-16 font-bold inline-block text-primary " + (buyOnline == true ? " text-white " : "")}>
                                {showPric && <Price price={price} />}
                            </h4>
                            <Link to={"#"} className={"text-primary  " + (buyOnline == true ? " text-white " : "")}
                                onClick={() => {
                                    sendRequest();
                                }}>

                                <div className={"text-primary nice-flex nice-flex-row  "}>
                                    <span
                                        className={" text-20   " + (buyOnline == true ? " text-white filter-white " : " text-primary filter-primary  ")}>{trans("info:buy")}</span>
                                    <img src='/assets/icons/cart.svg'
                                        className={" size-40 " + (buyOnline == true ? " filter-white  " : "  filter-primary  ")}
                                        alt="hassan jameel carts " />
                                </div>

                            </Link>

                        </div>
                    </div>
                </div>
            </div>

            <div className="car-model-card-info border-radius ">

                <div className="car-model-options">
                    {
                        (features?.length > 0) && (
                            <ul className={"list-bullet list-gray list-limit-10"}>
                                {
                                    features?.map((item, index) => {
                                        if (++index <= 5)
                                            return <li key={index}>
                                                {item}
                                            </li>
                                    })
                                }
                                {/*<li className="list-none last:list-disc ">*/}
                                {/*    */}
                                {/*</li>*/}
                            </ul>)
                    }


                    {
                        // features?.length > 6 &&
                        // <div className={"text-left mt-3"}>
                        //     <Link to={extraSpecsTo ? extraSpecsTo : "#"} className={"text-size-14r  text-primary"}>
                        //         {trans("common:show-more")}
                        //     </Link>
                        // </div>
                    }
                </div>
            </div>

            <div className={"text-end my-4 sm:my-3 md:my-2 lg:my-2 mx-8 text-end-important"}>
                {/*<div className="grid grid-cols-2 justify-items-auto">*/}
                {/*    <div className="justify-center items-center border border-pink-700">00</div>*/}
                {/*    <div className="justify-end items-end content-end text-end-important">*/}
                {/*        */}
                {/*    </div>*/}
                {/*</div>*/}

                <Link to={extraSpecsTo ? extraSpecsTo : "#"} className={"text-size-14r  text-primary text-end"}>
                    {trans("common:show-more")}
                </Link>
            </div>

        </div>
    );
}

