import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { Link, NavLink, useHistory } from "react-router-dom";
import { Fragment, useEffect, useRef, useState, } from "react";
import { useDispatch, useSelector } from "react-redux";
import { list, total } from "cart-localstorage";
import Popup from '../../../../User/popup';
import axios from "axios";
import { changeCount } from "../../../../_redux/slices/cartSlice";
import PopupHeader from "../../../../User/components/popupHeader";
import SignIn from "../../../../User/signIn";
import SignUp from "../../../../User/signUp";
import ResetPassword from "../../../../User/resetPassword";
import LoginValidation from "../../../../User/loginValidation";
import AdvancedSearch from "../../../../components/_input/advancedSearch";
import CallCenter from "../../../../components/__simple/callCenter";
import config from "../../../../configuration/config.json";
function Navbar({ popupShow, pageID }) {
   
    const { t: trans,i18n } = useTranslation(["common", "form", "language", "info"]);
    let lang =  (i18n.language || 'ar');
    const navRef = useRef(null);
    const [navTop, setNavTop] = useState(-16);
    const [navFlex, setNavFlex] = useState(false);
    const [lastScrollY, setLastScrollY] = useState(0);
    const languageDir = "language-" + lang;
    const [onhamburger, setOnhamburger] = useState(false);

    const [showNav, setShowNav] = useState(null);
    const history = useHistory();
    const headerFixed = useSelector(state => state.entities.nav.isFixed);
    const headerIcons = useSelector(state => state.entities.nav.iconsBlack);
    const cartCount = useSelector(state => state.entities.cart.count);

    const [brands, setBrands] = useState([]);
    const [searchPopup, setSearchPopup] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [activePopUpModel, setActivePopUpModel] = useState({
        tab: 1,
        title: 'sign-in'
    });

    const handleTabChange = (tab) => {
        console.log('New tab is', tab);
        setActivePopUpModel(tab);
    };

    const handlePopupStatus = (status) => {
        setShowPopup(status);
    };

    useEffect(() => {
    }, [activePopUpModel])


    useEffect(() => {
        if (window.innerWidth < 577) {
            setNavTop(-1);
        }
    }, [window.innerWidth]);

    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(
            changeCount({ count: list().length })
        )
        return () => {
            dispatch(
                changeCount({ count: list().length })
            )
        };
    }, [total()]);

    const [dynamicMenu, setDynamicMenu] = useState([]);
    useEffect(async () => {


        const Body = {
            "name": "GetDynamicMenu",
            "values": {


            }
        };
        await axios.request({
            url: config.baseURL + 'func',
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {
                const result = response.data;
                setDynamicMenu(result);

            })
            .catch(function (error) {
                console.log(error);
            });

        setNavFlex(true)

        setNavTop(-1);

        // window.addEventListener("scroll", handleScroll);
        // return () => window.removeEventListener("scroll", handleScroll);

    }, []);

    const handleScroll = () => {
        if (headerFixed) {
            const currentScrollY = window.scrollY;
            if (currentScrollY - 0 > lastScrollY) {
                setNavFlex(true)

                setNavTop(-1);
            } else {
                if (window.innerWidth < 577) {
                    setNavTop(-1);
                } else {
                    setNavTop(-18);
                }
                if (currentScrollY <= lastScrollY + 200) {
                    setNavFlex(false);
                }
            }
            setLastScrollY(currentScrollY);
        } else {
            setNavFlex(false)
        }
    };

    function show(section) {
        setShowNav(null)
        setShowNav(section);
    }

    function onMouseOut() {
        setShowNav(null)

    }

    return (
        <Fragment>
            <header className={"   " + languageDir + ' ' + (headerFixed ? "  " : " header-absolute")}>
                {/* <CallCenter /> */}
                <div className={"hide-xs nav-height hidden  " + (navFlex ? navFlex : "hidden")}>
                    <span style={{ opacity: "0" }}>navbar holder</span>
                </div>
                <nav ref={navRef}
                    onMouseLeave={() => onMouseOut()}
                    className={"p-2 border-radius nav nav-height flex justify-between items-center mt-4 nav-default nav-fixed w-100" + (headerFixed ? (navFlex ? " nav-fixed " : " ") : " ") + " " + (onhamburger ? " nav-active" : " ")}
                    style={{ top: navTop + "rem" }}>

                    <div className="nav-first flex justify-center items-center ">
                        <div className="nav-icon ">
                            {
                                lang == "ar" ?

                                    <NavLink
                                        exact={true}
                                        to="/">
                                        <img src="/assets/logo/logo_AR.png"
                                            alt="hassan jameel" className="hidden d-md-inline" />
                                        <img src="/assets/logo/logo_AR.png"
                                            alt="hassan jameel" className="d-inline d-md-hidden" />
                                    </NavLink>


                                    :

                                    <NavLink
                                        exact={true}
                                        to="/">
                                        <img src="/assets/logo/logo_EN.png"
                                            alt="hassan jameel" className="hidden d-md-inline" />
                                        <img src="/assets/logo/logo_EN.png"
                                            alt="hassan jameel" className="d-inline d-md-hidden" />
                                    </NavLink>
                            }
                        </div>
                        <div
                            onClick={() => setOnhamburger(false)}
                            className={"nav-links text-white flex  space-x-4  space-x-reverse mr-20 " + languageDir + (onhamburger ? ' active' : ' ')}>




                            {
                                dynamicMenu.map((item, index) => {
                                    return (
                                        <div key={index} className="mx-3">
                                            <a
                                                //  onMouseEnter={() => show(null)}
                                                // onClick={() => show(null)}
                                                className={"text-20 capitalize " + (item?.pageID == pageID ? " active " : "")}
                                                href={item?.isTransferlink ? item?.transferlink : "/landengPage/" + item?.pageID}>
                                                {lang == "ar" ? item?.nameAr : item?.nameEn}
                                            </a>
                                        </div>
                                    )
                                })
                            }


                            <div className={"nav-show-sm"}>
                                <Link to={""} onClick={(e) => {
                                    e.preventDefault();
                                    if (!localStorage.getItem("isAuthenticated")) {
                                        handlePopupStatus(true);
                                    } else {
                                        history.push(localStorage.getItem("isAuthenticated") ? "/user" : "/");
                                    }
                                }}
                                >{!localStorage.getItem("isAuthenticated") ? trans("info:login") : trans("info:profile")}<img src={localStorage.getItem("isAuthenticated") ? "/assets/icons/user.svg" : "/assets/icons/login.svg"} alt="user profile" /></Link>
                            </div>
                            <div className={"nav-show-sm"}>

                                {/* <a href="..">مواقعنا<img src="/assets/icons/location.svg" alt="location"/></a> */}
                            </div>
                            <div className={"nav-show-sm"}>

                                <Link to={'/search'} onClick={() => {
                                    //  setSearchPopup(true);
                                }}>{trans("info:search")}<img src="/assets/icons/search.svg" alt="search" /></Link>
                            </div>
                            <div className={"nav-show-sm"}>
                                <Link to={""} onClick={() => {


                                    if (window.location.href.includes("/ar") || window.location.href.includes("/en")) {

                                        if (window.location.href.includes("/en"))
                                            window.location = window.location.href.replace("/en", "/ar");
                                        if (window.location.href.includes("/ar"))
                                            window.location = window.location.href.replace("/ar", "/en");

                                    } else {

                                        window.location = window.location.origin + "/en" + window.location.pathname;
                                    }


                                    // window.location = window.location.href.replace(/en|ar/, trans("changeLangCode"));
                                }}>{trans("common:language") + ':' + trans("info:lang")}<img src="/assets/icons/globle.svg" alt="change language" /></Link>
                            </div>

                        </div>
                    </div>

                    <div className="flex h-full space-x-8  space-x-reverse">
                        <div className="nav-btns flex flex-row justify-center items-center space-x-4  space-x-reverse ">


                            {/* <div className={"relative"}>
                                <div
                                    className={" bg-danger border-radius-full text-white w-8 h-8 absolute -top-2 -right-1 flex item-center justify-center"}
                                    style={{ lineHeight: 1.8, zIndex: 1 }}>{cartCount}</div>
                                <Link
                                    className={(!navFlex && onhamburger) ? "open" : ""}
                                    to="/cart"><img
                                        src="/assets/icons/cart.svg"
                                        className="max-icon"
                                        alt="cart" /></Link>
                            </div> */}

                            <Link to={""} onClick={(e) => {
                                e.preventDefault();
                                if (!localStorage.getItem("isAuthenticated")) {
                                    handlePopupStatus(true);
                                } else {
                                    history.push(localStorage.getItem("isAuthenticated") ? "/user" : "/");
                                }
                            }}
                            ><img src={localStorage.getItem("isAuthenticated") ? "/assets/icons/user.svg" : "/assets/icons/login.svg"} alt="user profile" /></Link>

                            {/* <a href=".."><img src="/assets/icons/location.svg" alt="location"/></a> */}

                            <Link to='/search' ><img src="/assets/icons/search.svg" alt="search" /></Link>
                            <div
                                className={"hamburger " + (onhamburger ? "open" : "")}
                                onClick={() => setOnhamburger(!onhamburger)}>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>

                        <button
                            onClick={() => {


                                if (window.location.href.includes("/ar") || window.location.href.includes("/en")) {

                                    if (window.location.href.includes("/en"))
                                        window.location = window.location.href.replace("/en", "/ar");
                                    if (window.location.href.includes("/ar"))
                                        window.location = window.location.href.replace("/ar", "/en");

                                } else {

                                    window.location = window.location.origin + "/en" + window.location.pathname;
                                }

                                // window.location = window.location.href.replace(/en|ar/,  trans("changeLangCode"));
                            }}
                            className="nav-lang h-full w-44 border-radius bg-primary flex justify-center items-center">

                            <span className='text-white text-16 font-bold pl-2 capitalize'>{trans('changeLang')}</span>
                            <img src="/assets/icons/globle.svg" alt="change language" />
                        </button>
                    </div>


                </nav>
            </header>
            <Popup show={showPopup}>
                <div className='popup-model'>
                    <PopupHeader welcomeTitle={trans("common:say-hello")} subTitle={trans("common:" + activePopUpModel.title)} closePopUp={() => handlePopupStatus(false)} />
                    {
                        activePopUpModel.tab == 1 ?
                            <SignIn onTabChange={(tab) => { handleTabChange(tab) }} onLogin={() => handlePopupStatus(false)} />
                            : activePopUpModel.tab == 2 ?
                                <SignUp onTabChange={(tab) => { handleTabChange(tab) }} onLogin={() => handlePopupStatus(false)} />
                                : activePopUpModel.tab == 3 ?
                                    <ResetPassword onTabChange={(tab) => { handleTabChange(tab) }} />
                                    : <LoginValidation onTabChange={(tab) => { handleTabChange(tab) }} onLogin={() => handlePopupStatus(false)} />

                    }
                </div>
            </Popup>
            <AdvancedSearch show={searchPopup} onSearchClick={(value) => { setSearchPopup(value) }} />
        </Fragment>
    );
}


export default Navbar;






