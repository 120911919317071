import { createSlice } from '@reduxjs/toolkit';

const dataSlice = createSlice({
    name: 'data',
    initialState: {
        reduxVehicles: [],
        reduxBrands: [],
        reduxHomeExtra:null, // Store vehicles data
    },
    reducers: {
        setReduxVehicles: (state, action) => {
            if (!(state.reduxVehicles?.length > 0))
                state.reduxVehicles = action.payload; // Set the vehicles in the Redux state
        },
        setReduxBrands: (state, action) => {

            if (!(state.reduxBrands?.length > 0))
                state.reduxBrands = action.payload; // Set the vehicles in the Redux state
        },
        setReduxHomeExtra: (state, action) => {

            if (!(state.reduxHomeExtra))
                state.reduxHomeExtra = action.payload; // Set the vehicles in the Redux state
        },
    },
});

export const { setReduxVehicles, setReduxBrands,setReduxHomeExtra } = dataSlice.actions; // Export the action
export default dataSlice.reducer;
