import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import SwiperCore, { Autoplay, EffectFade, Navigation, Pagination } from "swiper";
import PageHeading from "../components/__simple/_pageheading";
import VehicleDetails from "./components/vehicleDetails";
import axios from "axios";
import { useParams } from "react-router";
import config from '../configuration/config.json';
import { Part } from "../pages/eparts/components/part";
import { DropDownInput } from "../components/_input/_dropdown";
import TrackItem from "./components/trackItem";
import moment from "moment";
import { groupBy, mapValues, omit } from "lodash";
import { slugCombine } from "../utils/slugManager";
import { getData, User_Data_Key } from "../utils/localStorageManager";
import Offer from "../components/offer";
import dropDownResetter from "../utils/resetDropDown";
import { Link } from "react-router-dom";
import "./MaintenanceTwo.scss"
import { Price } from "../components/__simple/_price";
SwiperCore.use([Pagination, Navigation, Autoplay, EffectFade]);
export default function UserVehiclesDetailsPage() {
    const { t: trans, i18n } = useTranslation(["eparts", "common", "form", "vehicales", "model", "info", "inputs", "global"]);
    const lang = i18n.language;
    const { car, fetchType } = useParams();
    const languageDir = 'language-' + lang;
    //--------------------------------------------------
    const [vehicleDetails, setVehicleDetails] = useState(null);
    const [primaryCategory, setPrimaryCategory] = useState([]);
    const [subPrimaryCategory, setSubPrimaryCategory] = useState([]);
    const [lastSubPrimaryCategory, setLastSubPrimaryCategory] = useState([]);
    const [fSubPrimaryCategory, setFSubPrimaryCategory] = useState([]);
    const [flastSubPrimaryCategory, setFLastSubPrimaryCategory] = useState([]);
    const [allParts, setAllParts] = useState([]);
    const [fAllParts, setFAllParts] = useState([]);
    const [selectedPrimaryCategory, setSelectedPrimaryCategory] = useState('');
    const [selectedSubPrimaryCategory, setSubSelectedPrimaryCategory] = useState('');
    const [selectedSubLastPrimaryCategory, setSubLastSelectedPrimaryCategory] = useState('');
    const [offers, setOffers] = useState([]);

    const getIsExpireDate = async (date) => {

        try {

            const date1 = new Date(date);
            const date2 = new Date();
            const diffTime = date1 - date2;
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

            if (diffDays <= 0 && diffTime <= 0) {
                return true;

            } else {

                return false;
            }

        } catch (error) {
            console.log("error:", error);
            return true

        }

    }
    useEffect(async () => {
        getVehicleDetails();
        await initalCategories();
    }, []);

    useEffect(async () => {
        console.log("useEffect ReservationFetch>>>>>>>>>>:", vehicleDetails)
        if (vehicleDetails && vehicleDetails?.vinNo) {
            ReservationFetch();
            PreviousMaintenance();
        }

    }, [vehicleDetails]);

    const [Reservation, setReservation] = useState(null);
    const [isLoadingReservation, setLoadingReservation] = useState(false);
    const [isFetchedReservation, setFetchedReservation] = useState(false);
    const ReservationFetch = async () => {


        console.log("ReservationFetch>>>>>>>>>>:", vehicleDetails)
        console.log("userId>>>>>>>>>>:", vehicleDetails)

        const userId = JSON.parse(getData(User_Data_Key, true))?.custID?.toString() ?? 0;
        setLoadingReservation(true);
        const Body3 = {
            "name": "App_ReservationFetchWithVIN",
            "values": {
                "UserID": userId.toString(),
                "VIN": vehicleDetails?.vinNo
            }

        }

        await axios.request({
            url: config.baseURL + 'func',
            data: Body3, method: "POST", responseType: "json"
        })
            .then(function (response) {
                console.log("FetchTypeCart:", response.data);
                setReservation(response.data);
                setFetchedReservation(true);
                setLoadingReservation(false);
            })
            .catch(function (error) {
                setLoadingReservation(false);
                console.log(error);
            });


    }
    const [isLoadingContent, setLoadingContent] = useState(false);
    const [ReservationFilterData, setReservationFilterData] = useState([]);
    const [isActive, setIsActive] = useState(1);
    const filterReservation = async (type) => {

        if (!isLoadingContent) {
            setLoadingContent(true);
            setIsActive(type);

            if (type == 1) {

                let newData = [];

                await Reservation?.map(async a => {
                    var ex = await getIsExpireDate(a.Orderdate)
                    if (!ex)
                        newData = [...newData, a];

                })

                setReservationFilterData(newData);
            } else {
                let newData = [];
                await Reservation?.map(async a => {
                    var ex = await getIsExpireDate(a.Orderdate)
                    if (ex)
                        newData = [...newData, a];

                })
                setReservationFilterData(newData);

            }

            setLoadingContent(false);
        }

    }
    const [Maintenance, setMaintenance] = useState([]);
    const [MaintenanceDetails, setMaintenanceDetails] = useState({});
    const [isFetchedMaintenance, setFetchedMaintenance] = useState(false);
    const [isLoadingMaintenance, setLoadingMaintenance] = useState(false);
    const PreviousMaintenance = async () => {

        setLoadingMaintenance(true);

        const userId = JSON.parse(getData(User_Data_Key, true))?.custID?.toString() ?? 0;

        const Body3 = {
            "name": "App_PreviousMaintenanceWithVIN",
            "values": {
                "UserID": userId.toString(),
                "VIN": vehicleDetails?.vinNo
            }

        }

        await axios.request({
            url: config.baseURL + 'func',
            data: Body3, method: "POST", responseType: "json"
        })
            .then(function (response) {
                console.log("App_PreviousMaintenance :", response.data);
                setMaintenance(response.data);
                setFetchedMaintenance(true)
                setLoadingMaintenance(false);
            })
            .catch(function (error) {
                setLoadingMaintenance(false);
                console.log(error);
            });


    }

    useEffect(() => {
        console.log("useEffect filterReservation:", isActive);

        filterReservation(isActive);
    }, [isActive, Reservation]);
    //---------------------------------------------------
    // to fetch the vehicle details by passing the vehicleID
    const getVehicleDetails = () => {

        const funSiteGetVehicleDetails = fetchType == "user" ? "SiteGetVehicleDetails" : "SiteGetVehiclePurchase";
        if (car) {
            axios.post(
                config.baseURL + 'func',
                {
                    "name": funSiteGetVehicleDetails,
                    "values": {
                        "vehicleID": car
                    }
                }
            ).then(response => {
                const responseData = response.data[0];
                const modelTrans = trans("model:description");
                const _vehicle = { ...vehicleDetails };
                _vehicle.groupName = responseData[`Group${modelTrans}`];
                _vehicle.modelName = responseData[`Model${modelTrans}`];
                _vehicle.colorImage = responseData.ColorImage;
                _vehicle.plateNo = responseData[`Board${modelTrans}`];
                _vehicle.vinNo = responseData.VIN;
                _vehicle.carImage = responseData.Image;
                _vehicle.industryYear = responseData.Model ?? '';
                _vehicle.inputDate = responseData.InputDate;
                _vehicle.brand = responseData[`Brand${modelTrans}`];
                _vehicle.userCarName = responseData.UserCarName ?? ''
                intialAllPartsByGroup(responseData.BrandID, responseData.ProductGroupID, responseData.Model);
                setVehicleDetails(_vehicle);
                initialOffers(responseData.ProductGroupID);
            }).catch(error => {
                console.log('error in fetching the vehicle details,', error);
            });
        }
    };
    //----------------------------------------------------
    //to fetch all the categories
    const initalCategories = async () => {
        const primaryCategoriesBody = {
            "object": "Site_categorySP",
            "objectSettings": {
                "metaData": false
            },
            "option": "column",
            "fields": "GroupIDMain,DescriptionAr,DescriptionEn"
        };
        const subPrimaryCategoriesBody = {
            "object": "Site_SupCategory",
            "objectSettings": {
                "metaData": false
            },
            "option": "column",
            "fields": "GroupIDMainSup1,DescriptionAr,DescriptionEn,GroupIDMain"
        };
        const lastSubPrimaryCategoriesBody = {
            "object": "Site_ItemsSupCategory",
            "objectSettings": {
                "metaData": false
            },
            "option": "column",
            "fields": "GroupIDMainSup2,DescriptionAr,DescriptionEn,GroupIDMainSup1"
        };

        try {
            const primaryCategoriesRequest = axios.request(
                {
                    url: config.baseURL + 'details',
                    data: primaryCategoriesBody, method: "POST", responseType: "json"
                }
            );

            const subPrimaryCategoriesRequest = axios.request(
                {
                    url: config.baseURL + 'details',
                    data: subPrimaryCategoriesBody, method: "POST", responseType: "json"
                }
            );


            const lastSubPrimaryCategoriesRequest = axios.request(
                {
                    url: config.baseURL + 'details',
                    data: lastSubPrimaryCategoriesBody, method: "POST", responseType: "json"
                }
            );

            await axios.all([primaryCategoriesRequest, subPrimaryCategoriesRequest, lastSubPrimaryCategoriesRequest])
                .then(axios.spread((category1, category2, category3) => {
                    setPrimaryCategory(category1.data.ApiObjectData);
                    setSubPrimaryCategory(category2.data.ApiObjectData);
                    setLastSubPrimaryCategory(category3.data.ApiObjectData);
                })).catch(err => {
                    console.log(err);
                });
        } catch (error) {
            console.log(error);
        }
    };
    // filter the sub categories
    const handlFilterSubCategories = (option) => {
        try {
            if (option?.value == '' || option?.value == '0' || option?.value == null) {
                dropDownResetter(() => {
                    setFSubPrimaryCategory([]);
                    setFLastSubPrimaryCategory([]);
                    setSelectedPrimaryCategory('');
                });
                return;
            }
            setSelectedPrimaryCategory(option.value);
            setFSubPrimaryCategory(subPrimaryCategory.filter(item => {
                return item.GroupIDMain == option.value
            }));

        } catch (err) {
            console.log(err);
        }
    };
    // filter the last sub categories
    const handlFilterLastSubCategories = (option) => {
        try {
            if (option?.value == '' || option?.value == '0' || option?.value == null) {
                dropDownResetter(() => {
                    setFLastSubPrimaryCategory([]);
                    setSubSelectedPrimaryCategory('');
                });
                return;
            }
            setSubSelectedPrimaryCategory(option.value);
            setFLastSubPrimaryCategory(lastSubPrimaryCategory.filter(item => {
                return item.GroupIDMainSup1 == option.value
            }));
        } catch (err) {
            console.log(err);
        }
    };
    // to intialize the parts that are base on the group and brand
    const intialAllPartsByGroup = (brandID, groupID, model) => {
        axios.post(
            config.baseURL + 'func',
            {
                "name": "Site_FeatchPartByVehicle",
                "values": {
                    "brand": Number(brandID),
                    "group": groupID,
                    "type": null,
                    "year": String(model)
                }
            }
        ).then(response => {
            setAllParts(response.data);
            setFAllParts(response.data);
        }).catch(error => {
            console.log(error);
        });
    };
    // to search by the dropdowns
    const handleOnSearch = () => {
        try {
            let filterResults = [];
            if ((selectedPrimaryCategory != '' && selectedPrimaryCategory != 0)
                && (selectedSubLastPrimaryCategory == '' || selectedSubLastPrimaryCategory == 0) && (selectedSubLastPrimaryCategory == '')) {
                filterResults = allParts.filter((item => {
                    return item.CategorySPID == selectedPrimaryCategory
                }));
            } else if ((selectedPrimaryCategory != '' && selectedPrimaryCategory != 0) &&
                (selectedSubLastPrimaryCategory != '' && selectedSubLastPrimaryCategory != 0) && (selectedSubLastPrimaryCategory == '')) {
                filterResults = allParts.filter((item => {
                    return item.CategorySPID == selectedPrimaryCategory && item.SupCategorySPID == selectedSubPrimaryCategory
                }));
            } else if ((selectedPrimaryCategory != '' && selectedPrimaryCategory != 0) &&
                (selectedSubLastPrimaryCategory != '' && selectedSubLastPrimaryCategory != 0) && (selectedSubLastPrimaryCategory != '')) {
                filterResults = allParts.filter((item => {
                    return item.CategorySPID == selectedPrimaryCategory && item.SupCategorySPID == selectedSubPrimaryCategory && item.ItemsSupCategorySPID == selectedSubLastPrimaryCategory
                }));
            } else {
                filterResults = allParts;
            }
            setFAllParts(filterResults);
        } catch (err) {
            console.log(err);
        }
    };
    const [favoriteProducts, setFavoriteProducts] = useState([]);
    useEffect(async () => {
        const results = await fetchUserFavorites();
        setFavoriteProducts(results);
    }, []);
    // to fetch the user favorites
    const fetchUserFavorites = async () => {
        let _favorites = [];
        try {
            const isLogin = getData('isAuthenticated');
            if (isLogin) {
                const { id } = JSON.parse(getData(User_Data_Key, true));
                const userID = Number(id.toString().split('_')[1]);
                const response = await axios.post(
                    config.baseURL + 'func',
                    {
                        "Name": "SiteFetchUserFavorites",
                        "values": {
                            "userID": userID
                        }
                    }
                );
                if (response.status == 200) {
                    const favorites = response.data;
                    _favorites = _favorites.concat(favorites);
                }
            }
        } catch (err) {
            console.log(err);
        }
        return _favorites;
    };
    // to fetch all the user offers
    const initialOffers = (groupID) => {
        try {
            axios.post(
                config.baseURL + 'func',
                {
                    "Name": "SiteFetchOffersByGroup",
                    "Values": {
                        "GroupID": String(groupID)
                    }
                }
            ).then(response => {
                const result = response.data;
                const groupResult = mapValues(groupBy(result, 'OfferType'), fList => fList.map(items => omit(items, 'OfferType')));
                let groupValues = {};
                Object.assign(groupValues, groupResult);
                let finalResults = [];
                //----------------------------------------------------------------------------  
                for (let item in groupValues) {
                    const obj = { heading: '', items: [] };
                    let title = '';
                    const results = groupValues[item].map(item => item[`Type${trans("model:description")}`]);
                    if (results?.length > 0) {
                        title = results[0];
                    }
                    obj.heading = title;
                    obj.items = groupValues[item];
                    finalResults.push(obj);
                }
                setOffers(finalResults);
            }).catch(error => {
                console.log(error);
            })
        } catch (error) {
            console.log(error);
        }
    };


    const formatDateTime = (date, time) => {

        try {
            if (date) {
                let dateNow = new Date(date);


                if (time) {

                    let timeNow = new Date("2023-02-26T" + time);

                    dateNow.setHours(timeNow.getHours(), timeNow.getMinutes(), timeNow.getSeconds());
                }



                var formatDate = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', hour12: true, minute: '2-digit' };

                if (lang.language === "en") {
                    return dateNow.toLocaleDateString('en-US', formatDate);

                } else {
                    return dateNow.toLocaleDateString('en-US', formatDate);
                    // return dateNow.toLocaleDateString('ar-US', formatDate);
                }

            } else {
                return date;

            }
        } catch (error) {
            console.log(error);
            return date;
        }
    };
    return (
        <div className={languageDir}>
            <PageHeading title={trans("vehicales:vehicle-details")} />
            <VehicleDetails
                carImage={vehicleDetails?.carImage ? vehicleDetails?.carImage : '/assets/images/eparts/default.png'}
                createdInput={vehicleDetails?.inputDate}
                plateNo={vehicleDetails?.plateNo}
                carModelTitle={vehicleDetails?.modelName}
                carBrand={vehicleDetails?.brand}
                colorImage={vehicleDetails?.colorImage}
                industryYear={vehicleDetails?.industryYear}
                structureNo={vehicleDetails?.vinNo}
                carNameTitle={vehicleDetails?.groupName}
                userCarName={vehicleDetails?.userCarName} />;

            <Tabs selectedTabClassName='tab-active' defaultIndex={0}>
                <div className='private-vehicle-tab-box my-8 px-5'>
                    <TabList className='private-vehicle-tabs-container'>

                        <Tab className='text-size-16r'>
                            {trans("info:offers")}
                        </Tab>
                        {/* <span className='vertical-separater'></span>
                        <Tab className='text-size-16r'>
                            {trans("info:spare-parts")}
                        </Tab> */}
                        {/* <span className='vertical-separater'></span>
                        <Tab className='text-size-16r'>
                            {trans("info:history")}
                        </Tab> */}
                        <span className='vertical-separater'></span>
                        <Tab className='text-size-16r'>
                            {trans("info:my-bookings")}
                        </Tab>
                        <span className='vertical-separater'></span>
                        <Tab className='text-size-16r'>
                            {trans("info:previous-maintenance")}
                        </Tab>
                    </TabList>
                </div>
                <TabPanel>
                    <div className="my-8">
                        {
                            offers.map((parent, index) => {
                                return (
                                    <>
                                        <h2 className="text-30 text-bold my-6">{parent.heading}</h2>
                                        <Swiper
                                            key={index}
                                            spaceBetween={0}
                                            slidesPerView={2}
                                            breakpoints={{
                                                576: {
                                                    slidesPerView: 4,
                                                },
                                            }}
                                            className={"mb-16"}
                                            resizeObserver={true}
                                            watchSlidesVisibility
                                            watchSlidesProgress
                                            autoplay={false}
                                            loop={false}
                                            observer={true}
                                        >
                                            {
                                                parent.items?.map((item, index) => {
                                                    return (
                                                        <SwiperSlide
                                                            className={"px-3"}
                                                            key={index}>
                                                            <Offer
                                                                startDate={item?.StartDate}
                                                                endDate={item?.EndDate}
                                                                totalDays={item?.TotalDays}
                                                                slug={slugCombine(item.slugAr, item.slugEn)}
                                                                img={item.Image}
                                                                excerpt={item[`Sub${trans("model:description")}`]}
                                                                name={item[`Title${trans("model:description")}`]}
                                                                type={item.OfferType} />
                                                        </SwiperSlide>
                                                    )
                                                })
                                            }

                                        </Swiper>
                                    </>

                                )
                            })
                        }
                    </div>
                </TabPanel>
                {/* <TabPanel>
                    <div style={{ minHeight: '45rem' }} className='mb-4 py-8'>
                        <div className={"filter-search-container mb-14"}>
                            <div className={"flex items-center"}>
                                <div className="filter-search-grid filter-search-3">
                                    <DropDownInput
                                        className={"flex-grow-1"}
                                        title={trans("eparts:category")}
                                        isSecondary={true}
                                        options={[...primaryCategory?.map(item => {
                                            if (lang == 'ar') {
                                                return { text: item.DescriptionAr, value: item.GroupIDMain }
                                            } else {
                                                return { text: item.DescriptionEn, value: item.GroupIDMain }
                                            }
                                        })]}
                                        onfilter={handlFilterSubCategories}
                                    />
                                    <DropDownInput
                                        selectedValue={selectedPrimaryCategory}
                                        className={"flex-grow-1"}
                                        title={trans("eparts:Subcategory")}
                                        isSecondary={true}
                                        options={[...fSubPrimaryCategory?.map(item => {
                                            if (lang == 'ar') {
                                                return { text: item.DescriptionAr, value: item.GroupIDMainSup1 }
                                            } else {
                                                return { text: item.DescriptionEn, value: item.GroupIDMainSup1 }
                                            }
                                        })]}
                                        onfilter={handlFilterLastSubCategories}
                                    />

                                    <DropDownInput
                                        defaultOption={`${selectedSubPrimaryCategory}${selectedPrimaryCategory}`}
                                        className={"flex-grow-1"}
                                        isSecondary={true}
                                        title={trans("eparts:Subcategory2")}
                                        options={[...flastSubPrimaryCategory?.map(item => {
                                            if (lang == 'ar') {
                                                return { text: item.DescriptionAr, value: item.GroupIDMainSup2 }
                                            } else {
                                                return { text: item.DescriptionEn, value: item.GroupIDMainSup2 }
                                            }
                                        })]}
                                        onSelect={(value) => {
                                            setSubLastSelectedPrimaryCategory(value)
                                        }}
                                    />
                                </div>
                                <button
                                    className={"btn btn-primary text-16 py-3 px-10 mx-2"}
                                    onClick={handleOnSearch}
                                >{trans("eparts:search")}
                                </button>
                            </div>
                        </div>
                        {
                            fAllParts?.length > 0 ? (<div><Swiper
                                spaceBetween={0}
                                slidesPerView={1}
                                breakpoints={{
                                    576: {
                                        slidesPerView: 4,
                                    },
                                }}
                                resizeObserver={"true"}
                                watchSlidesVisibility
                                watchSlidesProgress
                                autoplay={false}
                                loop={false}
                                observer={true}

                            >
                                {
                                    fAllParts.map((P, index) => {
                                        return (
                                            <SwiperSlide className="px-3">
                                                <Part favorites={favoriteProducts} ProductNo={P.ProductNo} Engine_Groups={P.CategiorAr} Discount={P.DiscountAmount} Tax={P.Percentage} GUID={P.GUID} Weight={P.Weight} height={P.height} width={P.width} length={P.length} ProductImage={P.ProductImage} DescriptionEn={P.DescriptionEn} DescriptionAr={P.DescriptionAr} SalesPriceBefor={0} NetSalePrice={P.SalesPrice} ProductID={P.ProductID} />
                                            </SwiperSlide>
                                        )
                                    })
                                }

                            </Swiper>
                                {
                                    (fAllParts?.length > 10) && (
                                        <div className="text-left mt-4">
                                            <Link className={"link-btn-white"} to='/eparts'>
                                                {trans("common:show-more")}
                                            </Link>
                                        </div>
                                    )
                                }
                            </div>) :

                                <div className="nice-flex mt-12 nice-flex-column nice-flex-center">
                                    <img src='/assets/icons/sad.svg' alt="hassan jameel sad" />
                                    <h1 className="text-14 text-bold my-3">{trans("info:no_parts")}</h1>
                                </div>

                        }
                    </div>
                </TabPanel> */}
                {/* <TabPanel>
                    <div className='private-vehicle-history px-4 nice-border-primary-right'>
                        {
                            [1, 2, 3].map(item => {
                                return (
                                    <TrackItem
                                        title={'إشعار'}
                                        date={new moment().format('yyyy-MM-dd || hh:mm')}
                                        detail={'التفاصيل تنعرض هنا'}
                                    />
                                )
                            })
                        }
                    </div>
                </TabPanel> */}
                <TabPanel>
                    <div   >
                        {
                            ReservationFilterData?.map((res, index) => {
                                return (

                                    <div key={index} className={`${isActive ? "z-details-box-m-white" : 'z-details-box-m'}  my-10`} >
                                        <div className='z-right-side-imageBox'>
                                            <div className='z-boxImage-s'>
                                                <img alt='' className='z-right_image_s' src="/assets/images/more-images/Group 23663.png" />
                                            </div>
                                        </div>

                                        <div className="z-middle-lineM"></div>
                                        <div className='z-left-side-details-m p-10 flex flex-col nice-gap-2rem'>
                                            <p className="z-title text-black text-bold text-40 text-line-clamp text-line-clamp-1">{lang == "ar" ? res?.DescriptionAr : res?.DescriptionEn}</p>

                                            <p className="z-text text-black">{trans("info:order-number")}: <span className="z-gray-text text-gray">{res?.ReceptionID}</span></p>

                                            {
                                                <p className="z-text text-black">{trans("info:booking-date")}:  <span dir={"ltr"} className="z-gray-text text-gray">{formatDateTime(res.Orderdate, res.Ordertime)}</span></p>

                                            }


                                            <p className="z-text text-black">
                                                <span className="green text-primary">
                                                    {res?.price && <Price price={parseFloat(res?.price.toFixed(2))} />}
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </TabPanel>
                <TabPanel>
                    <div   >
                        {
                            Maintenance?.map((res, index) => {
                                return (

                                    <div key={index} className={`${isActive ? "z-details-box-m-white" : 'z-details-box-m'}  my-10`} >
                                        <div className='z-right-side-imageBox'>
                                            <div className='z-boxImage-s'>
                                                <img alt='' className='z-right_image_s' src="/assets/images/more-images/Group 23663.png" />
                                            </div>
                                        </div>

                                        <div className="z-middle-lineM"></div>
                                        <div className='z-left-side-details-m p-10 flex flex-col nice-gap-2rem'>
                                            <p className="z-title text-black text-bold text-40 text-line-clamp text-line-clamp-1">{res?.Description}</p>

                                            <p className="z-text text-black">{trans("info:order-number")}: <span className="z-gray-text text-gray">{res?.OrderID}</span></p>

                                            {
                                                <p className="z-text text-black">{trans("info:maintenance-date")}:  <span dir={"ltr"} className="z-gray-text text-gray  language-en">{formatDateTime(res?.CompleteDate, null)}</span></p>

                                            }


                                            <p className="z-text text-black">
                                                <span className="green text-primary">
                                                    {res?.Price && <Price price={parseFloat(res?.Price.toFixed(2))} />}
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </TabPanel>
            </Tabs>
        </div>
    )
}