import { useTranslation } from "react-i18next";
import config from "../../../configuration/config.json"

export const FeatureCard = ({ image = null, title = '', description = '' }) => {
    const {i18n} = useTranslation();
    const languageDir = "language-" + (i18n.language || 'ar');
    const dir = (i18n.language || 'ar') == 'en' ? 'l' :'r';
    return (
        <div className={"nice-col-md-4 mb-20 " + languageDir}>
            <div className="car-feature feature">
                <div className="car-feature-wallpaper">
                    <img  className={"object-full"} src={config?.mainURL +image} onError = {e => e.target.style.display = 'none'}/>
                </div>
                <div className={` car-feature-details car-feature-details-${dir} px-8`}>
                   <h1 className="text-24 text-bold text-black">
                    {title}
                   </h1>
                   <p className="text-16 my-4 nice-justifiy pb-4">
                       {description}
                   </p>
                </div>
            </div>
        </div>
    )
};