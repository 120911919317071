import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CountDown } from "./countDown";
import { Price } from "../../../components/__simple/_price";
// import {addToCart} from "../../../_functions/cart";
import { useUserCart } from '../../../User/hooks/useUserCart';
import { useEffect } from "react";
import config from "../../../configuration/config.json";
export function Campaign({ TotalAmountDiscount, TotalTime, startDate = new Date(), endDate = new Date(), CampaignType, Photo, Title, Description, DateFrom, DateTo, Type, DiscountAmount, BrandID, DiscountID, GUID, toyota = true, to = '/eparts/campaignorder', onChooseCompaign }) {
    const { t: trans, i18n } = useTranslation(["eparts", "form", "info"]);
    const { removecartItems, changecartItem } = useUserCart();
    const dir = (i18n.language || 'ar') == 'en' ? 'l' : 'r';
    const image = Photo ? Photo : "/assets/images/offers/bg.png";
    return (
        <Link to={to + "/" + GUID} >
            <div className="epart-box">
                <time className={"epart-box-date " + (toyota ? "bg-toyota" : "bg-lexus")}><CountDown Total={TotalTime} start={startDate.getDate()} end={endDate.getDate()} /></time>
                <div className={"epart-campaign"}>
                    <h3 className={"epart-campaign-type " + (toyota ? "bg-toyota" : "bg-lexus")}>{(CampaignType === 1) ? trans("eparts:bauget") : trans("eparts:discount")}</h3>
                    <div className={`epart-campaign-text epart-campaign-text-${dir}`}>
                        <div className="epart-campaign-text-title p-4">
                            <h2>{Title}</h2>
                            <h3 className="mt-2 text-primary text-30"><Price price={TotalAmountDiscount} /></h3>
                        </div>
                        <div className="epart-campaign-text-desc">{Description}</div>
                    </div>
                    <img className={"epart-campaign-image object-full object-cover border-radius"} src={config.FilePathURL + image} alt="" />
                    <div className="epart-overlay border-radius"></div>
                    <div className='nice-flex nice-flex-row my-5 px-4 epart-btns'>
                        <Link className={"flexiable-btn mx-2 text-14 btn-primary"} onClick={
                            () => {
                                if (onChooseCompaign)
                                    onChooseCompaign(true, DiscountID);
                            }
                        }>
                            {trans("eparts:order-now")}
                        </Link>
                        <Link className={"flexiable-btn mx-2 nice-flex nice-flex-center nice-flex-row text-14 px-1 btn-primary"} onClick={
                            () => {

                                var objectCart = 
                                      {
                                         id: DiscountID,
                                         name:Title,
                                         price: TotalAmountDiscount,
                                         image: Photo,
                                         Type:"Campaign",

                                     }

                                changecartItem(DiscountID, null, null, 1,null,null,objectCart);

                                //  addToCart(
                                //      {
                                //          id: DiscountID,
                                //          name:Title,
                                //          price: TotalAmountDiscount,
                                //          image: Photo,
                                //          Type:"Campaign",

                                //      }
                                //  )
                            }
                        }>
                            {trans("info:add_to_cart")}
                            <img src='/assets/icons/cart.svg' className="filter-white" alt="hassan jameel carts " />
                        </Link>
                    </div>
                </div>
            </div>

        </Link>
    );
}