import { NavCarCard } from "./components/navCarCard";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Skeleton from 'react-loading-skeleton'
import { slugCombine } from '../../../utils/slugManager';
import { useTranslation } from "react-i18next";
import config from "../../../configuration/config.json";
import { useDispatch, useSelector } from 'react-redux';
import { setReduxVehicles, setReduxBrands } from '../../../_redux/slices/dataSlice';
export function NavCar({ ...prop }) {
    const { t: trans, i18n } = useTranslation(["inputs", "heading", "vehicales", "common"]);
    const dispatch = useDispatch();
    const reduxBrands = useSelector((state) => state.apiData.data.reduxBrands); // Access Redux state
    const [brands, setBrands] = useState([]);
    const [vehicles, setVehicles] = useState([]);
    const [filteredVehicles, setFilteredVehicles] = useState(vehicles);

    const [filters, setFilters] = useState([""]);
    const [CarCategories, setCarCategories] = useState([]);
    const [brandFilter, setBrandFilter] = useState(null);
    const [typeFilter, setTypeFilter] = useState(trans("inputs:all"));
    const [otherBrand, setOtherBrand] = useState(null);

    function _setFilters(filteredBrand) {
        const fif = brands?.filter((brand) => {
            return (
                brand.brand === filteredBrand
            )
        });

        //سيدان - كوبيه - دفع رباعي - عائلية - تجارية
        const filterHasVehicals = fif[0]?.filters?.filter(f => (vehicles?.filter(c => (c?.brand?.toLowerCase() == filteredBrand?.toLowerCase() && c?.category == f?.category)))?.length > 0);


        const brandCategories = [
            { brand: filteredBrand, category: trans("inputs:all") }, ...filterHasVehicals
        ]
        setFilters(brandCategories);
        setTypeFilter(trans("inputs:all"))
    }


    useEffect(() => {
        if (vehicles?.length > 0)
            _filterVehicles(trans("common:toyota"), trans("inputs:all"));

    }, [vehicles])

    function _filterVehicles(brand, type) {
        let filterData;
        if (brand != null) {
            filterData = vehicles?.filter((car) => {
                return car.brand.toLowerCase() === brand.toLowerCase() && (car.category === type || type === trans("inputs:all"));
            });
        }
        else {
            filterData = vehicles;
        }
        setFilteredVehicles(filterData);
    }

    function fBrandFilter(brand, otherBrand) {


        setBrandFilter(brand);
        _setFilters(brand);
        setOtherBrand(otherBrand);
        _filterVehicles(brand, trans("inputs:all"))
    }

    function fTypeFilter(type) {


        setTypeFilter(type);
        _filterVehicles(brandFilter, type)
    }

    let lang = (i18n.language || 'ar');

    useEffect(async () => {
        try {



            ////////////////////////////////////////////////////////
            if (!(reduxBrands?.length > 0)) {
                try {

                    if ("caches" in window) {
                        var curl2 = "brands-" + lang;

                        // Opening that particular cache
                        const cacheStorage = await caches.open("HJWeb");
                        // Fetching that particular cache data
                        const cachedResponse = await cacheStorage.match(
                            curl2
                        );
                        let data = await cachedResponse?.json();

                        if (data && data?.brands && data?.brands?.length > 0)
                            setBrands(data.brands);

                    }

                } catch (error) {
                }

                const res = await axios.request({
                    baseURL: config.controllURL,
                    url: lang + "/api/brands/brands",
                    method: "get",

                });
                setBrands(res.data.brands);
                const data22 = new Response(JSON.stringify(res.data));

                if ("caches" in window) {
                    // Opening given cache and putting our data into it
                    caches.open("HJWeb").then((cache) => {
                        cache.put("brands-" + lang, data22);

                    });
                }
            }

            try {

                try {

                    if ("caches" in window) {
                        var curl = "site_FetchCarCategories";

                        // Opening that particular cache
                        const cacheStorage = await caches.open("HJWeb");
                        // Fetching that particular cache data
                        const cachedResponse = await cacheStorage.match(
                            curl
                        );
                        let data = await cachedResponse?.json();
                        setCarCategories(data)

                    }

                } catch (error) {
                }


                const response = await axios.post(
                    config.baseURL + 'func',
                    {
                        "Name": "site_FetchCarCategories"
                    }
                );
                if (response.status == 200) {

                    setCarCategories(response.data)
                    const data44 = new Response(JSON.stringify(response.data));

                    if ("caches" in window) {
                        // Opening given cache and putting our data into it
                        caches.open("HJWeb").then((cache) => {
                            cache.put("site_FetchCarCategories", data44);

                        });
                    }


                }
            } catch (error) {
                console.log('error in fetching the countries of SAU', error);
            }


            try {

                if ("caches" in window) {
                    var curl = "vehiclesHomeMenu-" + lang;

                    // Opening that particular cache
                    const cacheStorage = await caches.open("HJWeb");
                    // Fetching that particular cache data
                    const cachedResponse = await cacheStorage.match(
                        curl
                    );
                    let data = await cachedResponse?.json();
                    if (data && data?.vehicles && data?.vehicles?.length > 0)
                        setVehicles(data?.vehicles)

                }

            } catch (error) {
            }
            console.log(lang);
            const response = await axios.request({
                baseURL: config.controllURL,
                url: lang + "/api/home/vehiclesHomeMenu",
                method: "get",
            });

            response?.data?.vehicles?.map((item, index) => {
                item["id"] = index


            })

            setVehicles(response.data.vehicles)



            const data = new Response(JSON.stringify(response.data));

            if ("caches" in window) {
                // Opening given cache and putting our data into it
                caches.open("HJWeb").then((cache) => {
                    cache.put("vehiclesHomeMenu-" + lang, data);

                });
            }

        } catch (error) {
            console.log(error);
        }
    }, [])

    useEffect(() => {
        if (brands?.length > 0 && CarCategories?.length > 0 && vehicles?.length > 0) {
            _setFilters(brands[0].brand);
            setBrandFilter(brands[0].brand);
            setOtherBrand(brands[0].otherBrand);
        }


    }, [brands, CarCategories, vehicles])


    useEffect(async () => {


        if (reduxBrands?.length > 0 && brands?.length <= 0) {

            console.log("reduxBrands>>>>>>>NavCar>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>:");
            console.log("reduxBrands>>>>>>>>>>>NavCar>>>>>>>>>>>>>>>>>>>>>>>>>>:", reduxBrands);
            setBrands(reduxBrands);
        }
        if (brands?.length > 0 && reduxBrands?.length <= 0) {
            console.log("brands>>>>>>>NavCar>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>:");
            console.log("brands>>>>>>>>>>>NavCar>>>>>>>>>>>>>>>>>>>>>>>>>>:", brands);
            dispatch(setReduxBrands(brands));
        }
    }, [reduxBrands, brands]);
    return (
        <div className={"nav-mega-menu bg-white box-shadow border-radius  " + (prop.className ? prop.className : " ")}>
            <div className="my-4 text-20 border-radius ">
                <div className="nav-brand-filter box-shadow">
                    {
                        brands ?
                            brands?.map((brand, index) => {

                                return (
                                    <div
                                        key={index}
                                        onClick={() => fBrandFilter(brand.brand, brand.otherBrand)}
                                        className={"cursor-pointer item " + (brandFilter === brand.brand && "active")}>
                                        <img
                                            src={`/assets/icons/brands/${((brand?.brand === "تويوتا" || brand?.brand?.toLowerCase() === "toyota") ? "toyota" : "lexus-black")}.png`}
                                            alt={brand.brand} onError={e => e.target.style.display = 'none'} />
                                        <span className={"text-20"}>{brand.brand}</span>
                                    </div>
                                );
                            })
                            :

                            <>
                                <Skeleton count={1} borderRadius={"1rem"} width={"100%"} height={"5rem"}
                                    containerClassName={" "} className={" cursor-pointer item"}
                                    inline={true} />
                                <Skeleton count={1} borderRadius={"1rem"} width={"100%"} height={"5rem"}
                                    containerClassName={" "} className={" cursor-pointer item"}
                                    inline={true} />

                            </>
                    }

                </div>

                {
                    filteredVehicles && filteredVehicles.length > 0 ?


                        <div className="p-8">

                            <div className="nav-car-filter box-shadow">

                                <div
                                    key={-1}
                                    onClick={() => fTypeFilter(trans("inputs:all"))}
                                    className={(typeFilter == trans("inputs:all") ? "active" : "")}>
                                    {trans("inputs:all")}
                                </div>

                                {


                                    CarCategories?.map((item, index) => {

                                        if (item && item[lang == "ar" ? "NameAr" : "NameEn"] && filters.filter(a => a.category == item[lang == "ar" ? "NameAr" : "NameEn"])?.length > 0) {
                                            return (
                                                <div
                                                    key={index}
                                                    onClick={() => fTypeFilter(item[lang == "ar" ? "NameAr" : "NameEn"])}
                                                    className={(typeFilter === item[lang == "ar" ? "NameAr" : "NameEn"] ? "active" : "")}>
                                                    {item[lang == "ar" ? "NameAr" : "NameEn"]}
                                                </div>);
                                        } else { return null }
                                    })


                                }
                            </div>
                            <div className={"nav-car-list grid-6-cols py-8"}>
                                {
                                    filteredVehicles?.map((vehicle, index) => {

                                        return (
                                            <NavCarCard
                                                key={vehicle.id}
                                                onClick={prop.onClick}
                                                img={config.mainURL + vehicle.image}
                                                name={vehicle.name}
                                                Year={vehicle.Year}
                                                // link={"/vehicles/" + slugCombine(vehicle.brand, vehicle.otherBrand) + "/" + vehicle.slug}
                                                link={"/vehicles/" + slugCombine(vehicle.brand, vehicle.otherBrand) + "/" + vehicle.group + "/" + vehicle.year}
                                            />
                                        );
                                    })
                                }

                            </div>

                            <div className="text-left box-shadow p-5 breakout">
                                <div className=" inline-block">
                                    <Link to={"/vehicles/" + slugCombine(brandFilter, otherBrand)} onClick={prop.onClick}
                                        className="btn btn-primary text-16 py-6 px-10">
                                        {trans("vehicales:show_more")}
                                    </Link>
                                </div>
                            </div>

                        </div>
                        :
                        <>
                            <Skeleton count={1} borderRadius={"1rem"} width={"100%"} height={"60rem"}
                                containerClassName={""} className={""}
                                inline={true} />

                        </>

                }

            </div>
        </div>
    );
}