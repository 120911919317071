import axios from "axios";
import { useEffect, useState } from "react";
import _ from 'lodash';
import { useTranslation } from "react-i18next";
import config from '../../../../configuration/config.json';
import Skeleton from 'react-loading-skeleton'
export function NavWorkHours({ department = 12 }) {
    const { t: trans,i18n } = useTranslation(["navbar", "model"]);
    let lang = (i18n.language || 'ar');
    const languageDir = 'language-' + lang;
    //-------------------------------------------------
    const [workingHours, setWorkingHours] = useState(null);
    const [activeDay, setActiveDay] = useState({});

    const [holidaysList, setHolidaysList] = useState(null);
    const [weekDatesList, setWeekDatesList] = useState([]);
    //--------------------------------------------------
    const days = [
        { day: 6, orginal: 'Saturday', name: trans('navbar:sat') },
        { day: 0, orginal: 'Sunday', name: trans('navbar:sun') },
        { day: 1, orginal: 'Monday', name: trans('navbar:mon') },
        { day: 2, orginal: 'Tuesday', name: trans('navbar:tues') },
        { day: 3, orginal: 'Wednesday', name: trans('navbar:wed') },
        { day: 4, orginal: 'Thursday', name: trans('navbar:thur') },
        { day: 5, orginal: 'Friday', name: trans('navbar:fri') }
    ];
    // -----------------------------------------------

    const getHolidays = async () => {
        const body = {
            name: "fun_GetHolidays",
            values: {
                lang: lang
            }
        };

        console.log("fun_GetHolidays:body", body);

        try {
            await axios.request({
                url: config.baseURL + 'func',
                data: body, method: "POST", responseType: "json"
            })
                .then(function (response) {
                    console.info(response.data);
                    if (response.data.length > 0) {
                        setHolidaysList(response.data);
                    }
                })
                .catch(function (error) {
               
                    setHolidaysList([]);
                    throw error;
                });

        } catch (ex) {
         
            setHolidaysList([]);
        }
    };

    // const getWeekDates = () => {
    //     const now = new Date();
    //     const dayOfWeek = now.getDay(); // getDay() returns the day of the week (0-6) with Sunday as 0
    //     const startOfWeek = new Date(now);
    //
    //     startOfWeek.setDate(now.getDate() - dayOfWeek); // set to Sunday (start of the week)
    //
    //     const weekDates = [];
    //     for (let i = 0; i < 7; i++) {
    //         const date = new Date(startOfWeek);
    //         date.setDate(startOfWeek.getDate() + i);
    //         weekDates.push({
    //             dayNameAr: date.toLocaleDateString('ar-SA', {weekday: 'long'}),
    //             dayNameEn: date.toLocaleDateString('en-US', {weekday: 'long'}),
    //             date: date.toLocaleDateString('en-US'),
    //             dya: date.getDate(),
    //             month: date.getMonth(),
    //
    //         });
    //     }
    //     console.info("weekDates: list: ", weekDates);
    //    
    //     if(weekDates.length > 0){
    //         setWeekDatesList(weekDates);
    //     }
    //     // return weekDates;
    // };

    const getWeekDates = () => {
        const now = new Date();
        const dayOfWeek = now.getDay(); // getDay() returns the day of the week (0-6) with Sunday as 0
        // Calculate the difference from Saturday
        const startOfWeek = new Date(now);
        const daysSinceSaturday = (dayOfWeek + 1) % 7;
        startOfWeek.setDate(now.getDate() - daysSinceSaturday); // set to Saturday (start of the week)

        const weekDates = [];
        for (let i = 0; i < 7; i++) {
            const date = new Date(startOfWeek);
            date.setDate(startOfWeek.getDate() + i);
            weekDates.push({
                dayNameAr: date.toLocaleDateString('ar-SA', { weekday: 'long' }),
                dayNameEn: date.toLocaleDateString('en-US', { weekday: 'long' }),
                date: date.toLocaleDateString('en-US'),
                dya: date.getDate(),
                get_month: date.getMonth() + 1,
            });
        }
     

        if (weekDates.length > 0) {
            setWeekDatesList(weekDates);
        }
        // return weekDates;
    };

    //-------------------------------------------------
    // useEffect(() => {
    //     intialOperationalTimeOfDuty(department);
    // }, []);
    // to intialize operational department time
    useEffect(() => {
        getWeekDates();


        setTimeout(async () => {
            await getHolidays();
        }, 20);
    }, []);

    useEffect(() => {

        let _holidaysList;



        if (holidaysList != null) {

            intialOperationalTimeOfDuty(department);
        }

        const now = new Date();
        const dayNumber = now.getDate();
        const dayName = now.toLocaleDateString('en-US', { weekday: 'long' });
        const currentDate = now.toLocaleDateString('en-US');


    }, [holidaysList]);

    // to intialize operational department time
    const intialOperationalTimeOfDuty = (id) => {
        let calenders = [];
        axios.post(
            config.baseURL + 'func',
            {
                "name": "SiteGetCalenderDetails",
                "values": {
                    "CalenderType": id
                }
            }
        ).then(response => {
            const now = new Date();
            // var dayAfterFind;

            const responseData = response.data;

            const firstPeroid = responseData.find((item) => {
                return String(item.Type).toLowerCase() == 'morning'
            });
            const { StartTime: startOnetPeriodTime, EndTime: endOnePeriodTime } = firstPeroid;

            //-------------------------------------------------------------------------------
            const secondPeriod = responseData.find((item) => {
                return String(item.Type).toLowerCase() == 'afternoon'
            }) ?? {};

            const { StartTime: startPeriodTime, EndTime: endPeriodTime } = secondPeriod;

            delete firstPeroid.StartTime;
            delete firstPeroid.EndTime;
            delete firstPeroid.Type;

            const items = Object.keys(firstPeroid);

            for (let item of items) {

                const _day = days.find(day => {
                    return day.orginal == item
                });

                if (firstPeroid[`${item}`]) {

           

                    var dayAfterFind =
                        weekDatesList.find(itemDay => (itemDay.dayNameEn.toLowerCase() == _day.orginal.toLowerCase()));

     

                    var hasHoliday =
                        holidaysList.find(itemHoliday => itemHoliday.Day == dayAfterFind.dya && itemHoliday.Month == dayAfterFind.get_month);


                    if (hasHoliday != null) {

                        calenders.push({
                            dayOfWeek: _day.name,
                            formatValue: hasHoliday.Description == null ? trans("navbar:no_work") : hasHoliday.Description,
                            day: _day.day
                        });


                    } else {
                        calenders.push({
                            dayOfWeek: _day.name,
                            formatValue: getTimeFormat(startOnetPeriodTime) + ' - ' +
                                getTimeFormat(endOnePeriodTime) + (startPeriodTime ? ' & ' +
                                    getTimeFormat(startPeriodTime + ' - ' + getTimeFormat(endPeriodTime)) : ''),
                            day: _day.day
                        });
                    }

                } else {

                    calenders.push({
                        dayOfWeek: _day.name,
                        formatValue: trans("navbar:no_work"),
                        day: _day.day
                    });
                }
            }
            setWorkingHours(calenders);
        }).catch(error => {
            console.log(error);
        });
    };
    const getTimeFormat = (value) => {
        if (value) {
            if (lang == 'ar') {
                return String(value).includes('AM') ? String(value).replace('AM', 'صباحاً') : String(value).includes('PM') ?
                    String(value).replace('PM', 'مساءً') : value;
            }
        }
        return value ?? '';
    };
    return (



        workingHours != null ?

            <ul className={"work-hours-flex " + languageDir}>
                {
                    workingHours?.map((ele, index) => {
                        return (
                            <li key={index} className={(new Date().getDay() == ele.day) ? 'active' : ""}>
                                <div className={"day"}>{ele?.dayOfWeek}</div>
                                <div className={"hours mx-5"}>{ele?.formatValue}</div>
                            </li>
                        )
                    })
                }
            </ul>

            :
            <>
                <Skeleton count={1} borderRadius={"1rem"} width={"100%"} height={"100%"}
                    containerClassName={"nav-maintenance-container "} className={""}
                    inline={true} />

            </>





    );
}