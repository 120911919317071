import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import SimpleButton from "../../../components/_input/SimpleButton";
import { toast } from "react-toastify";
const PaymentPlatforms = ({ title, isFreeze = true, onPaytabsClick, onSadadClick, isToggle = false, onPaymentClick, isTamara = false }) => {

    const { t: trans, i18n } = useTranslation(["new"]);
    const [paymentType, setPaymentType] = useState(null);
    let lang = (i18n.language || 'ar');
    return (
        (isToggle && <div className="cart-available-payments mt-2 ">
            <h3 className="text-gray text-20">{title}</h3>
            {/* <div className="nice-flex nice-flex-row nice-flex-center nice-flex-wrap-content nice-gap-3rem">
                <Link
                    className="cart-available-payments-link"
                    onClick={() => {
                        if (onPaymentClick) {
                            !isFreeze && onPaymentClick("CashByBranch");
                        }
                    }}
                >
                    <img src='/assets/icons/sadad.png' title="Sadad" />
                </Link>

                {


                    isTamara == true &&
                    <Link className="cart-available-payments-link"
                        onClick={() => {
                            if (onPaymentClick) {
                                !isFreeze && onPaymentClick("Tamara");
                            }
                        }}
                    >
                        <img

                            src={lang == 'ar'
                                ? "/assets/icons/AR-tamara.svg"
                                : "/assets/icons/En-tamara.svg"}

                            title="Tamara" />
                    </Link>
                }


                <Link className="cart-available-payments-link"
                    onClick={() => {
                        if (onPaymentClick) {
                            !isFreeze && onPaymentClick("Online");
                        }
                    }}
                >
                    <img src='/assets/icons/paytabs.png'

                        title="Paytabs" />
                </Link>
            </div> */}


            <div className="my-10 ">
                <div className="flex flex-col nice-gap-2rem">

                    <div className="breakout!! flex flex-col nice-gap-2rem ">


                        {

                            <div className={"flex flex-col nice-gap-2rem   " + (paymentType == "Online" ? " bg-gray-lighter " : "")}>
                                <label className={" text-20 font-bold flex flex-row nice-gap-1rem "}>
                                    <input type='radio' checked={paymentType == "Online" ? true : false} name='paymentType'
                                        onClick={() => {
                                            setPaymentType("Online");
                                        }}

                                        onChange={() => {



                                        }}
                                        id="primary"
                                    />
                                    {(isTamara ? trans("new:online_all")  : trans("new:online_batch") + " " + trans("new:and_the_rest_via_SADAD"))}

                                </label>
                                <div className="flex flex-row items-center  mx-10">
                                    <img className="cart-payment-icon object-contain" src="/assets/icons/companies/master-card-3.png" alt={"master"} />
                                    <img className="cart-payment-icon object-contain" src="/assets/icons/companies/visa-3.png" alt={"visa"} />
                                    <img className="cart-payment-icon object-contain" src="/assets/icons/companies/mada-3.png" alt={"mada"} />

                                    {
                                        !isTamara ?
                                            <img className="cart-payment-icon object-contain" src={"/assets/icons/companies/SADAD-Logo.png"} alt={"SADAD"} />
                                            : null
                                    }



                                </div>

                            </div>


                        }



                        {


                            isTamara ?

                                <div className={"flex flex-col   " + (paymentType == "Tamara" ? " bg-gray-lighter  " : "")}>
                                    <label className={" text-20 font-bold flex flex-row nice-gap-1rem "}>
                                        <input type='radio' checked={paymentType == "Tamara" ? true : false}
                                            name='paymentType'
                                            onClick={() => {

                                                setPaymentType("Tamara");
                                            }}
                                            onChange={() => {
                                                console.log("<< PaymentType >>: ", paymentType);
                                            }}

                                            id="primary"

                                        />

                                        {trans("new:byTamara")}

                                        <div className="flex flex-row  cart-payment-icon">
                                            <img className="cart-payment-icon object-contain"
                                                src={lang == 'ar'
                                                    ? "/assets/icons/AR-tamara.svg"
                                                    : "/assets/icons/En-tamara.svg"}
                                                onError={"/assets/icons/companies/tamara.jpeg"}
                                                alt={""} />
                                        </div>

                                    </label>
                                    <label className={" text-20  flex flex-row  pr-20 pl-20"}>
                                        {trans("new:byTamara3")}
                                    </label>
                                    <label className={" text-20  flex flex-row pr-20 pl-20 "}>
                                        {trans("new:byTamara4")}
                                    </label>
                                </div>

                                : null
                        }

                        {/* <div className={"flex flex-col nice-gap-2rem  " + (paymentType == 2 ? " bg-gray-lighter " : "")}>
                            <label className={" text-20 font-bold flex flex-row nice-gap-1rem "}>
                                <input type='radio' checked={paymentType == 2 ? true : false} name='paymentType'
                                    onClick={() => {

                                        setPaymentType(2);
                                    }}
                                    onChange={() => {

                                    }}

                                    id="primary"
                                />

                                {trans("new:by_finance")}

                            </label>
                        </div> */}

                        <div className={"flex flex-col nice-gap-2rem   " + (paymentType == "CashByBranch" ? " bg-gray-lighter " : "")}>
                            <label className={" text-20 font-bold flex flex-row nice-gap-1rem "}>
                                <input type='radio' checked={paymentType == "CashByBranch" ? true : false} name='paymentType'
                                    onClick={() => {

                                        setPaymentType("CashByBranch");
                                    }}
                                    onChange={() => {

                                    }}
                                    id="primary"
                                />
                                {trans("new:by_branch")}

                            </label>
                        </div>
                    </div>

                    <div className={"nice-flex nice-flex-row "}>

                        <SimpleButton classes=" bg-primary text-white text-20 nice-flex-1 mb-2" title={trans("new:confirm_order")}

                            onClick={() => {
                                if (paymentType) {
                                    if (onPaymentClick) {
                                        onPaymentClick(paymentType);
                                    }
                                } else {

                                    toast.warning(trans("new:please_define_pay_method"));
                                }

                            }}

                        />
                    </div>
                </div>
            </div>

        </div>
        )
    )
};

export default PaymentPlatforms;