import {useEffect, useState } from "react";
import PageHeading from "../components/__simple/_pageheading";
import { Swiper, SwiperSlide } from "swiper/react";
import Offer from "../components/offer";
import SwiperCore, { Autoplay, EffectFade, Navigation, Pagination } from "swiper";
import axios from "axios";
import CarUser from "./components/CarUser";
import config from "../configuration/config.json";
import { groupBy, mapValues, omit } from "lodash";
import { slugCombine } from "../utils/slugManager";
import { useTranslation } from "react-i18next";
import { getData, User_Data_Key } from "../utils/localStorageManager";
import SectionHeading from "../components/sectionHeading";
import { Link } from "react-router-dom";
SwiperCore.use([Pagination, Navigation, Autoplay, EffectFade]);

export default function UserHomePage() {
    const { t: trans, i18n } = useTranslation(["global", "model","common"]);
    const languageDir = 'language-' + (i18n.language || 'ar');
    //-------------------------------------------------
    const [cars, setCars] = useState([]);
    const [offers,setOffers] = useState([]);
    //-------------------------------------------------
    useEffect(() => {
        getUserCars();
        initialUserOffers();
    }, []);
    // to fetch user cars
    const getUserCars = () => {
        try {
            const _userID = JSON.parse(getData(User_Data_Key, true)).id.toString().split('_')[1];
            axios.post(
                config.baseURL + 'func',
                {
                    "name": "SiteGetUserCars",
                    "values": {
                        "userID": Number(_userID)
                    }
                }
            ).then(response => {
                setCars(response.data);
               
            }).catch(error => {
                console.log(error);
            });
        } catch (err) {
            console.log(err);
        }
    };
    // to fetch all the user offers
    const initialUserOffers = () => {
        try {
         const isLogin = getData("isAuthenticated");
         if(isLogin){
          const userID = getData('secretKey');
          axios.post(
              config.baseURL + 'func',
              {
                  "Name": "SiteFetchActiveOffers",
                  "values":{
                      "Id":userID
                  }
              }
           ).then(response=>{ 
             const result = response.data;
             const groupResult = mapValues(groupBy(result, 'OfferType'), fList => fList.map(items => omit(items, 'OfferType')));
             console.log(groupResult);
             let groupValues = {};
             Object.assign(groupValues, groupResult);
             let finalResults = [];
             //----------------------------------------------------------------------------  
             for (let item in groupValues) {

                 const obj = { heading: '', items: [] };
                 let title = '';
                 const  results =  groupValues[item].map(item=> item[`Type${trans("model:description")}`]);
              
                 if(results?.length > 0){
                     title = results[0];
                 }
                 obj.heading = title;
                 obj.items = groupValues[item];
                 finalResults.push(obj);
             } 
           
             setOffers(finalResults);
           }).catch(error=>{
               console.log(error);
           })
         }
        } catch (error) {
            console.log(error);
        }
    };
    return (
        <div className={languageDir}>
            <SectionHeading
                title={trans("global:my_cars")}
                hasRuler = {false}
            >
               <Swiper
                spaceBetween={0}
                slidesPerView={2}
                breakpoints={{
                    576: {
                        slidesPerView: 4,
                    },
                }}
                resizeObserver={"true"}
                watchSlidesVisibility
                watchSlidesProgress
                autoplay={false}
                loop={false}
                observer={true}
            >
                {
                    cars.map((item, index) => {
                        return (
                            <SwiperSlide className={"px-3 my-3"} key={index+1000}>
                                <CarUser
                                 key={index+5000}
                                    inputDate={item.InputDate}
                                    showDetails={false}
                                    id={item.CarID}
                                    img={item.Image ?  item.Image : '/assets/images/eparts/default.png'}
                                    name={item[`Model${trans('model:description')}`] ?? item[`Group${trans('model:description')}`]}
                                    brand={item[`Brand${trans('model:description')}`]}
                                    brandIcon={item.Icon ? "data:image/png;base64," + item.Icon : null}
                                    brandImage={item.IconImage ? "data:image/png;base64," + item.IconImage : null}
                                    link={"/user/vehicles/privateVehicles/" + item.CarID+"/"+item.FetchType}
                                />

                            </SwiperSlide>
                        )
                    })
                }

            </Swiper>
            </SectionHeading>
            <div className="my-8">
         {
            offers.map((parent,index)=>{
              return (
                <div key={index}>
                <PageHeading
                key={index+100}
                title ={parent.heading}
                subtitle = {trans("global:offers")}
                />
                <Swiper
                key={index+3000}
                spaceBetween={0}
                slidesPerView={2}
                breakpoints={{
                    576: {
                        slidesPerView: 4,
                    },
                }}
                className={"mb-16"}
                resizeObserver={true}
                watchSlidesVisibility
                watchSlidesProgress
                autoplay={false}
                loop={false}
                observer={true}
            >
                          {
                              parent.items?.map((item, index2) => {
                                  return (
                                      <SwiperSlide
                                       className={"px-3"}
                                        key={index2+2000}>
                                          <Offer 
                                            key={index2+6000}
                                          showSeconds={false}
                                          endDate={item.EndDate}
                                          startDate={item.StartDate}
                                          totalDays={item.TotalDays}
                                          price={item?.price}
                                          slug={slugCombine(item.slugAr,item.slugEn)}
                                          img={item.Image}
                                          excerpt={item[`Sub${trans("model:description")}`]}
                                          name={item[`Title${trans("model:description")}`]} 
                                          type={item.OfferType} />
                                      </SwiperSlide>
                                  )
                              })
                          }

                </Swiper>
               {
                 (parent.items?.length > 4 ) && (
                    <div className="text-left">
                    <Link className={"link-btn-white"} to={`/offers/type/${parent.heading}`}>
                        {trans("common:show-more")}
                    </Link>
                    </div>
                 )
               }
                </div>
              )
            })
         }
           </div>
        </div>
    );
}