import { useRef, useState, useEffect } from "react";
import { quantity } from 'cart-localstorage'
import { Price } from "../../../components/__simple/_price";
import axios from "axios";
import config from "../../../configuration/config.json";
import { useTranslation } from "react-i18next";
import { LikeButton } from "../../../components/__simple/_likeButton";
import { DateInput2 as DateInput } from "../../../components/_input/_dateInput_2";
import { getData, User_Data_Key } from "../../../utils/localStorageManager";
import { DropDownInput } from "../../../components/_input/_dropdown";
import { slugCombine, slugSplitter } from '../../../utils/slugManager';
import moment from "moment";
export function BouquetCart(
    { Account, img, brand, name, price, year, count, Desc, id, productID, onDelete, Categior, Discount, Tax, Pay, packageId, ErrorDate, Orderdate, refrechDate }
) {
    const { t: trans, i18n } = useTranslation(["cartFile", "new", "form","info", "eparts"]);
    const [counter, setCounter] = useState(count);
    const specRef = useRef(null);
    const [specHeight, setHeight] = useState(0);
    const [editDate, setEditDate] = useState(false);
    const [editCarType, setEditCarType] = useState(false);
    const [models, setModels] = useState([]);
    const [CartInfo, setCartInfo] = useState({ id: "", brand: "", DiscountRate: 0, img: "", Description: "", Titel: 0, ItemsNumer: 0, Price: 0.000 });
    const [ServiceInfo, setServiceInfo] = useState(null);
    const [acticeCar, setActiceCar] = useState(null);
    const [formGroup, setFormGroup] = useState(null);
    const [formModelTypeID, setFormModelTypeID] = useState(null);
    const [formYear, setFormYear] = useState(null);
    const [requestForm, setRequestForm] = useState({
        date: '',
        time: '',
    });
    let lang = (i18n.language || 'ar');
    function countF(num) {
        if (num === -1 && counter > 1)
            setCounter(counter + num);
        else if (num === 1 && counter < 100)
            setCounter(counter + num);
        if (num === -1 && counter === 1) {
            // onDelete();

            return;
        }
        console.log("part id:" + id);
        quantity(id, num);
        Account();
    }

    const [LodingDelate, setLodingDelate] = useState(false);
    const [LodingEditDate, setLodingEditDate] = useState(false);
    const [LodingEditCarType, setLodingEditCarType] = useState(false);
    const [SupportedVehicles, setSupportedVehicles] = useState([]);
    const [years, setYears] = useState([]);
    const [filteredProductModels, setFilteredProductModels] = useState([]);
    async function updateDateService() {
        setLodingEditDate(true);
        var fullDatetime = Orderdate;
        var userID = null;
        try {
            const { id } = JSON.parse(getData(User_Data_Key, true));
            userID = Number(id.toString().split('_')[1]);
        } catch (error) {

        }

        if (userID) {
            try {

                if (requestForm?.date) {

                    var md = new Date(requestForm?.date);
                    try {
                        if (requestForm?.time) {

                            var Hours = requestForm?.time.split(":");

                            md.setHours(Hours[0], Hours[1], 0);
                        }
                    } catch (error) {

                    }
                    var datetime = new moment(md).format('yyyy-MM-DD HH:mm:ss');
                    fullDatetime = datetime;
                }

            } catch (error) {
            }

            await axios.put(
                config.baseURL + 'update',
                {
                    "Object": "WebUserCart",
                    "values": {
                        "Orderdate": fullDatetime
                    },
                    "Filters": `where WebUserID = '${userID}' and ProductID= '${productID}' and packageId=${packageId}  `
                }
            ).then(async response => {

                await refrechDate();
                setEditDate(false);
                setLodingEditDate(false);
                console.log("response update:", response);


            }).catch(function (error) {
                console.log(error);
                setEditDate(false);
                setLodingEditDate(false);
            });
        } else {
            setLodingEditDate(false);
            setEditDate(false);
        }
    }

    async function updateCarTypeService() {
        setLodingEditCarType(true);
        var userID = null;
        try {
            const { id } = JSON.parse(getData(User_Data_Key, true));
            userID = Number(id.toString().split('_')[1]);
        } catch (error) {

        }

        if (userID) {


            await axios.put(
                config.baseURL + 'update',
                {
                    "Object": "WebUserCart",
                    "values": {
                        "modelTypeID": requestForm?.modelTypeID,
                        "year": requestForm?.year
                    },
                    "Filters": `where WebUserID = '${userID}' and ProductID= '${productID}' and packageId=${packageId}  `
                }
            ).then(async response => {

                await refrechDate();
                setEditCarType(false);
                setLodingEditCarType(false);
                console.log("response update:", response);


            }).catch(function (error) {
                console.log(error);
                setEditCarType(false);
                setLodingEditCarType(false);
            });
        } else {
            setLodingEditCarType(false);
            setEditCarType(false);
        }
    }

    function GetCartInfo() {

        const Body = {
            "name": "site_fetchPackageDetails",
            "values": {
                "lang": lang,
                "packageId": packageId ? packageId + "" : null
            }
        }
        axios.request({
            url: config.baseURL + 'func',
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {

                var CuurentValue = response.data[0];
                CuurentValue.offerName = response.data[0].Offer;
                CuurentValue.packageName = response.data[0].Title;
                CuurentValue.Price = response.data[0].Price;
                CuurentValue.img = response.data[0].image;
                setCartInfo(CuurentValue);


            })
            .catch(function (error) {
                console.log(error);
            });
    }



    function GetServiceInfo() {

        const Body = {
            "name": "site_FetchInfoService",
            "values": {
                "ServiceID": productID
            }
        }
        axios.request({
            url: config.baseURL + 'func',
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {

                var CuurentValue = {};

                CuurentValue.ServiceName = lang == "ar" ? response.data[0].ServiceNameAR : response.data[0].ServiceName;

                setServiceInfo(CuurentValue);

            })
            .catch(function (error) {
                console.log(error);
            });
    }


    function showData() {

        setHeight(specHeight <= 0 ? specRef.current.scrollHeight : 0);
    }
    useState(() => {
        GetCartInfo();
        GetServiceInfo();
    })

    useEffect(() => {

        if (CartInfo && CartInfo?.slugAr) {

            FetchOfferSupportedServices(CartInfo?.slugAr);
            FetchOfferSupportedVehicles(CartInfo?.slugAr);
            FetchModels();
        }

    }, [CartInfo])

    useEffect(() => {

        var ly = [];

        try {
            if (acticeCar?.fromYear && acticeCar?.toYear) {

                ly.push({ value: acticeCar.fromYear, text: acticeCar.fromYear });
                var newy = parseInt(acticeCar.fromYear);
                for (var i = 0; i <= 30; i++) {

                    newy = parseInt(newy) + 1;

                    ly.push({ value: newy, text: newy });

                    if (newy >= acticeCar?.toYear) {

                        break;
                    }
                }
                setYears(ly);

            }
        } catch (error) {

        }


    }, [acticeCar]);

    function setDefultDate() {

        var date = null
        var time = null;

        if (Orderdate) {
            console.log("Orderdate>>>>>>>>>>>>>>>>>>>>>>>>:", Orderdate);
            try {
                var all = Orderdate.split(" ");
                console.log("all:", all);
                date = all[0];
                time = all[1];



            } catch (error) {
            }

        }

        const val = { ...requestForm };
        val.date = date;
        val.time = time;
        setRequestForm(val);
    }

    useEffect(() => {

        setDefultDate();

    }, [Orderdate])


    const handleFilteredModels = (option) => {


        const fModels = models?.filter(a => a.productGroupID == option);

        setFilteredProductModels(fModels);

    };

    const FetchModels = async () => {
        try {
            try {
                const responsemodels = await axios.request({
                    baseURL: config.controllURL,
                    url: config.controllURL + lang + "/api/Maintenance/ProductModels",
                    method: "get",
                });
                setModels(responsemodels.data);


            } catch (error) {
                console.log(error);

            }
        } catch (error) {
            console.log(error);
        }

    };

    const FetchOfferSupportedVehicles = async (Slug) => {
        try {
            await axios.post(
                config.baseURL + 'func',
                {
                    "name": "Site_FetchOfferSupportedVehicles",
                    "values": {
                        "slug": Slug

                    }
                }
            ).then(function (response) {

                setSupportedVehicles(response.data);

            }).catch(function (error) {
                console.log(error);

            });
        } catch (error) {
            console.log(error);
        }

    };

    const [SupportedServices, setSupportedServices] = useState([]);
    const [SupportedMainServices, setSupportedMainServices] = useState([]);
    const [SupportedLastsServices, setSupportedLastsServices] = useState([]);
    const [isLoadingSupportedServices, setLoadingSupportedServices] = useState(true);
    const FetchOfferSupportedServices = async (Slug) => {
        try {
            setLoadingSupportedServices(true);

            await axios.post(
                config.baseURL + 'func',
                {
                    "name": "Site_FetchOfferSupportedServices",
                    "values": {
                        "slug": Slug
                    }
                }
            ).then(function (response) {

                var Mainservice = [];
                var Lastservice = [];
                var MainserviceIDS = [];
                response.data?.map((item, index) => {

                    if (item?.packageId == packageId) {



                        if (item?.ServiceID) {
                            if (!Lastservice.includes(item)) {
                                Lastservice.push(item);
                                MainserviceIDS.push(item?.subService2);
                            }
                        } else if (item?.subService2) {

                            if (!Mainservice.includes(item)) {

                                Mainservice.push(item);
                            }
                        }

                    }

                });
                Mainservice = Mainservice.filter(item => !MainserviceIDS.includes(item?.subService2))
                setSupportedMainServices(Mainservice);
                setSupportedLastsServices(Lastservice);

                setSupportedServices(response.data);
                setLoadingSupportedServices(false);

            }).catch(function (error) {
                console.log(error);
                setLoadingSupportedServices(false);
            });
        } catch (error) {
            console.log(error);
            setLoadingSupportedServices(false);
        }

    };
    return (<div className={" " + (ErrorDate ? " cart-card-error " : "")}>

        {

            (ErrorDate == true) &&
            <div className="card-error-lable"><span>{trans("cartFile:service_date_isMandatory")}</span></div>
        }

        <div className={"cart-card p-8 mb-8"}>
            <div className={"bg-secondary border-radius p-10"}>
                {
                    CartInfo?.img &&
                    < img className={"object-full product-cart-image object-contain"} src={config.FilePathURL + CartInfo?.img} />
                }

            </div>
            <div className="cart-detail">
                <div className="nice-flex nice-flex-row">
                    <div className="nice-flex-1 nice-gap-3rem nice-flex nice-flex-column">
                        <div className="cart-detail-container">
                            <h3 className={"text-16"}>{trans("new:package")} : </h3>
                            <p className={"text-16 font-bold"}>{CartInfo?.offerName}</p>
                        </div>
                        <div className="cart-detail-container">
                            <h3 className={"text-16"}>{trans("new:vehicle")}:</h3>
                            <p className={"text-16 font-bold"}>{Desc} </p>

                            {
                                editCarType == false && getData('isAuthenticated') ?

                                    <>

                                        <img className="image-edit"
                                            src="/assets/images/more-images/8fc02cfb0c252e439ae0caf0e61d1228.svg"

                                            onClick={() => {
                                                setEditCarType(true);

                                            }}

                                        />
                                    </>
                                    : null

                            }

                        </div>


                        {

                            SupportedLastsServices && SupportedLastsServices.length > 0 &&
                            <div className="cart-detail-container">
                                <h3 className={"text-16"}>{trans("new:items_count")}:</h3>
                                <p className={"text-16 font-bold"}>{SupportedLastsServices.length}</p>
                            </div>
                        }



                        {
                            SupportedLastsServices && SupportedLastsServices.length > 0 &&
                            <div className="cart-detail-container">
                                <h3 className={"text-16"}>{trans("new:description")} : </h3>
                                <p className={"text-16 mx-5 font-bold list-gray"}>{SupportedLastsServices.map((a, index) => {

                                    return (
                                        <li key={index} className={"text-16"}> {a.ServiceNameAR} </li>
                                    )
                                })}</p>
                            </div>

                        }



                    </div>
                    <div className="nice-flex nice-flex-column nice-gap-2rem">
                        <div className="nice-flex nice-flex-row nice-justifiy-end nice-align-item-center">
                            {

                                LodingDelate ?
                                    <span className='mx-2 loading-btn '>
                                        <img src='/assets/icons/loading-dark.svg' color="#121212" width='24' height='24' />
                                    </span>

                                    : <button onClick={() => {

                                        onDelete();
                                        setLodingDelate(true);

                                        setTimeout(() => {
                                            setLodingDelate(false);

                                        }, 2000)


                                    }} className={`cart-delete cart-delete-${lang == 'en' ? 'right' : 'left'}`}>
                                        <img src={"/assets/icons/trash.svg"} alt={"minus"} />
                                    </button>
                            }

                            <LikeButton long={true} />
                        </div>
                        <div className={"flex gap-2 "} style={{ display: "flex", flexFlow: "column nowrap" }}>
                            <div className={"flex items-center"}>
                                <div className={"text-12 ml-4"}>{trans("new:package_price")}</div>
                                <div className={"text-20 font-bold text-primary"}><Price price={CartInfo?.Price} /></div>
                            </div>

                            {
                                // Orderdate &&
                                <div className={"flex items-center gap-2 "}>
                                    <div className={"text-12 ml-4"}>{trans("form:maintenance-date")}</div>
                                    <div className={"text-16 font-bold text-primary"} dir="ltr">{Orderdate && new moment(Orderdate).format('yyyy/MM/DD hh:mm:ss a')}</div>

                                    {
                                        editDate == false && getData('isAuthenticated') ?

                                            <>

                                                <img className="image-edit"
                                                    src="/assets/images/more-images/8fc02cfb0c252e439ae0caf0e61d1228.svg"

                                                    onClick={() => {
                                                        setEditDate(true);

                                                    }}

                                                />
                                            </>
                                            : null

                                    }


                                </div>



                                // <img className="z2-car-image-m"
                                //     src="/assets/icons/save.svg" />



                            }


                            {false && <div className={"flex items-center"}>
                                <div className={"text-12 ml-4"}> {trans("cartFile:discount_rate")}</div>
                                <div className={"text-20 font-bold text-primary"}>{CartInfo.DiscountRate}%</div>
                            </div>}
                        </div>
                        <div >
                            <div className={"cart-counter"}>
                                <h3 className={"cart-counter-title text-12"}>{trans("cartFile:amount")}</h3>
                                <div className="nice-flex nice-flex-row ">
                                    <button onClick={() => countF(+1)} className="cart-counter-btn">
                                        <img src={"/assets/icons/plus.svg"} alt={"plus"} />
                                    </button>
                                    <div className={"cart-counter-num text-20 font-bold"}>{counter}</div>
                                    <button onClick={() => countF(-1)} className="cart-counter-btn">
                                        <img src={"/assets/icons/minus.svg"} alt={"minus"} />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>
                {
                    editDate == true ?

                        <div className="flex flex-row nice-gap-2rem  ">


                            <div className="flex flex-row grid-2-cols nice-gap-2rem ">
                                <div className="grow">
                                    <div className="basis-2/4 grow">

                                        <DateInput
                                            extraClasses={"border-none  text-30  border-radius-sm "}
                                            type={"date"}
                                            placeholder={trans("form:maintenance-date")}
                                            value={requestForm.date}
                                            length={255}
                                            onSelect={
                                                (e) => {
                                                    //////console.log(">>>>>>>>>>>>>:", e.target.value);
                                                    if (e.target.value >= (new Date().toJSON().slice(0, 10)) || !e.target.value) {
                                                        const val = { ...requestForm };
                                                        val.date = e.target.value;
                                                        setRequestForm(val);
                                                    }
                                                }
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="grow">
                                    <div className="basis-2/4 grow">

                                        <DateInput
                                            extraClasses={"border-none  text-30  border-radius-sm "}
                                            type={"time"}
                                            placeholder={trans("form:maintenance-time")}
                                            value={requestForm.time}
                                            length={255}
                                            onSelect={
                                                (e) => {
                                                    const val = { ...requestForm };
                                                    val.time = e.target.value;
                                                    setRequestForm(val);
                                                }
                                            }
                                        />
                                    </div>
                                </div>
                            </div>



                            <div className="flex flex-row grid-2-cols nice-gap-2rem nice-align-item-center ">
                                {

                                    LodingEditDate ?
                                        <span className='mx-2 loading-btn '>
                                            <img src='/assets/icons/loading-dark.svg' color="#121212" width='24' height='24' />
                                        </span>

                                        :
                                        <>
                                            <img className="image-save"
                                                src="/assets/icons/save.svg"
                                                onClick={() => {

                                                    updateDateService();


                                                }}
                                            />

                                            <img className="image-save"
                                                src="/assets/icons/refresh.svg"
                                                onClick={() => {

                                                    setDefultDate();
                                                    setEditDate(false);
                                                    setLodingEditDate(false);


                                                }}
                                            />
                                        </>

                                }
                            </div>
                        </div>

                        :

                        null


                }


                {
                    editCarType == true ?

                        <div className="flex flex-row nice-gap-2rem  ">


                            <div className="flex flex-row grid-2-cols nice-gap-2rem w-100">
                                <div className="grow w-100">
                                    <div className="basis2/4 grow w-100">

                                        <DropDownInput
                                            // title={trans("info:model")}
                                            // errorLabel={error?.model}

                                            defaultOption={((formGroup == '0' || formGroup == null)) ? {
                                                text: trans("eparts:vehicle"),
                                                value: '0'
                                            } : null}
                                            options={[
                                                ...(SupportedVehicles.filter(a => a.packageId == packageId)).map(B => {
                                                    return { value: B.slug, text: ((lang == "ar" ? B?.brand : B?.brandEn) + " " + (lang == "ar" ? B?.group : B?.groupEn)), carGroupID: B.carGroupID };
                                                })]}
                                            selectedValue={requestForm?.slug}
                                            onSelect={(value) => {

                                                console.log("onSelect:", value);

                                                var car = (SupportedVehicles.filter(a => a.slug == value))[0];
                                                if (car) {
                                                    setActiceCar(car);
                                                    const val = { ...requestForm };
                                                    val.slug = value;
                                                    val.carGroupID = car?.carGroupID;
                                                    val.year = null;
                                                    val.modelTypeID = null;
                                                    setFormGroup(val.carGroupID);
                                                    setFormYear(null);
                                                    setFormModelTypeID(null);
                                                    setRequestForm(val);
                                                    handleFilteredModels(val.carGroupID);
                                                }

                                            }}

                                        />
                                    </div>
                                </div>
                                <div className="grow w-100">
                                    <div className="basis2/4 grow">

                                        <DropDownInput
                                            // title={trans("info:vehicle_year")}
                                            // errorLabel={error?.year}
                                            defaultOption={((formYear == '0' || formYear == null) || (formGroup == '0' || formGroup == null)) ? {
                                                text: trans("info:created_year"),
                                                value: '0'
                                            } : null}
                                            options={[...years]}
                                            selectedValue={formYear}
                                            onSelect={(value) => {
                                                const val = { ...requestForm };
                                                val.year = value + "";
                                                setRequestForm(val);
                                                setFormYear(val.year);
                                                handleFilteredModels(requestForm?.carGroupID);
                                            }}

                                        />
                                    </div>
                                </div>

                                <div className="grow w-100">
                                    <div className="basis2/4 grow">

                                        <DropDownInput
                                            // title={trans("info:vehicle_year")}
                                            // errorLabel={error?.year}      
                                            defaultOption={((formModelTypeID == '0' || formModelTypeID == null) || (formGroup == '0' || formGroup == null)) ? {
                                                text: trans("eparts:model"),
                                                value: '0'
                                            } : null}
                                            options={[
                                                ...filteredProductModels.map(B => {
                                                    return { value: B.productTypeID, text: B.description };
                                                })]}
                                            selectedValue={formModelTypeID}
                                            onSelect={(value) => {
                                                const val = { ...requestForm };
                                                val.modelTypeID = value + "";
                                                setRequestForm(val);
                                                setFormModelTypeID(val.modelTypeID);
                                            }}

                                        />
                                    </div>
                                </div>
                            </div>


                            <div className="flex flex-row grid-2-cols nice-gap-2rem nice-align-item-center ">
                                {
                                    LodingEditCarType ?
                                        <span className='mx-2 loading-btn '>
                                            <img src='/assets/icons/loading-dark.svg' color="#121212" width='24' height='24' />
                                        </span>

                                        :
                                        <>
                                            <img className="image-save"
                                                src="/assets/icons/save.svg"
                                                onClick={() => {

                                                    updateCarTypeService();


                                                }}
                                            />

                                            <img className="image-save"
                                                src="/assets/icons/refresh.svg"
                                                onClick={() => {

                                                    setDefultDate();
                                                    setEditCarType(false);
                                                    setLodingEditCarType(false);


                                                }}
                                            />
                                        </>
                                }
                            </div>



                        </div>

                        :

                        null


                }

            </div>


        </div>
    </div>
    );
}
