import { Fragment, useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useParams,useHistory } from "react-router";
import { InputField } from "../../components/_input/_input";
import { FormButton } from "../../components/_input/_button";
import config from '../../configuration/config.json';
import Popup from "../../User/popup";
import moment from "moment";
import Joi from "joi";
import { GetMessages, formValidate } from '../../utils/validation';
import PhoneDropDown from "../../components/_input/_phoneDropDown";
export function EmploymentInterviewAttendance() {
    const { t: trans, i18n } = useTranslation(["common", "heading", "inputs", "employment"]);
    const { id = 1 } = useParams();
    const [requestForm, setRequestForm] = useState({});
    const [isLoadingBtnSend, setIsLoadingBtnSend] = useState(false);
    const [showFinalPopup, setShowFinalPopup] = useState(false);
    const [interviewData, setInterviewData] = useState({});
    const [showFinalPopupMessage, setShowFinalPopupMessage] = useState("");
    const [interviewApplicantsData, setInterviewApplicantsData] = useState([]);
    const [phoneNumberApplicants, setPhoneNumberApplicants] = useState([]);
    const [error, setError] = useState({});
    const lang = (i18n.language || 'ar');
    const history = useHistory();
    const schema = Joi.object({
        phoneNumber: Joi.string().min(12).required().messages(GetMessages()),
        OTP: Joi.string().required().messages(GetMessages()),

    });
    useEffect(async () => {
        GetinterviewApplicants();
    }, []);

    const GetinterviewApplicants = async () => {
        try {

            axios.post(
                config.baseURL + "func",
                {
                    "name": "SiteGetinterview_applicants",
                    "Values": {
                        "GUID": id,

                    }
                }
            ).then(response => {
                const result = response.data;

                var interviewData = {};
                if (result && result.length > 0) {
                    setInterviewApplicantsData(result);
                    interviewData = result[0];
                    setInterviewData(interviewData);
                    const date = new Date();


                    const stardate = new Date(interviewData.startDate);
                    const diffTime22 = date - stardate;
                    const diffDays22 = Math.ceil(diffTime22 / (1000 * 60 * 60 * 24));

                    if (diffDays22 <= 0 && diffTime22 <= 0) {
                        setShowFinalPopupMessage(trans("employment:The_application_deadline_has_not_yet_begun"));
                        setShowFinalPopup(true);
                        return;

                    }


                    const date1 = new Date(interviewData.endDate);
                    const diffTime = date1 - date;
                    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

                    if (diffDays <= 0 && diffTime <= 0) {
                        setShowFinalPopupMessage(trans("employment:Application_deadline_has_expired"));
                        setShowFinalPopup(true);
                        return;

                    }
                    var phoneNumberApplicants = result.map(i => { return i.phoneNumber });
                    setPhoneNumberApplicants(phoneNumberApplicants);
                } else {

                    setShowFinalPopup(true);
                }

            }).catch(error => {
                setShowFinalPopup(true);
            });


        } catch (err) {
            console.log("isDataSentBefore catch", err);
        }
    };
    const submitRequest = async (e) => {
        e.preventDefault();
        var result = formValidate(schema, requestForm) ?? {};

        if (requestForm.phoneNumber != null && !phoneNumberApplicants.includes(requestForm.phoneNumber)) {
            result.phoneNumber =trans("employment:The_mobile_number_not_on_list");
        }

        setError(result);
        if (Object.keys(result).length === 0) {
            const match = (await isDataOTPMatch(requestForm.phoneNumber))
            if (match == 1) {


                try {
                    var datetime = new moment(new Date()).format('yyyy-MM-DDTHH:mm:ss');

                    const response = await axios.put(

                        config.baseURL + 'update',
                        {
                            "object": "siteEmployment_application",
                            "values": {
                                "attendanceDateTime": datetime
                                // attendanceDateTime

                            },
                            "Filters": `Where interviewID = ${interviewData?.interviewID} and phoneNumber = '${requestForm.phoneNumber}' and OTP = '${requestForm.OTP}'   `
                        }
                    );
                    if (response.status == 200) {
                        setShowFinalPopup(true);
                        setShowFinalPopupMessage(trans("employment:Attendance_has_been_confirmed_successfully"));
                    } else {
                        setIsLoadingBtnSend(false);
                    }
                } catch (err) {
                    console.log("isDataSentBefore catch", err);
                }

                setIsLoadingBtnSend(false);

            } else if (match == 2) {
                setShowFinalPopup(true);
                setShowFinalPopupMessage(trans("employment:Attendance_has_already_been_confirmed"));
            } else {

                setError({ ...result, OTP: trans("employment:The_confirmation_code_incorrect_application_not_exist")});
                setIsLoadingBtnSend(false);
            }
        }

    };
    const [applicationData, setApplicationData] = useState({});
    const isDataOTPMatch = async (phone) => {
        let isExisted = 0;
        try {

            const response = await axios.post(
                config.baseURL + "details",
                {
                    "Object": "siteEmployment_application",
                    "ObjectSettings": {
                        "MetaData": false
                    },
                    "Filters": `Where interviewID = ${interviewData?.interviewID} and phoneNumber = '${phone}' and OTP = '${requestForm.OTP}'  `
                }


            );
            if (response.status == 200) {
                if (response.data.ApiObjectData.length > 0) {
                    if (response.data.ApiObjectData[0].attendanceDateTime) {
                        setApplicationData(response.data.ApiObjectData[0]);
                        isExisted = 2;
                    } else {
                        isExisted = 1;
                    }


                }
            }
        } catch (err) {
            console.log("isDataSentBefore catch", err);
        }
        return isExisted;
    };
    return (
        <Fragment>


            <div className="flex flex-col container employment-application mt-40 mb-32">


                <form className="flex flex-col nice-gap-3rem container my-20 " onSubmit={(e) => {
                    submitRequest(e);
                }}>


                    <h3 className="text-primary text-bold text-24  text-center">{trans("employment:Confirm_attendance")}</h3>

                    <div className="flex flex-row grid-1-cols-importent nice-gap-2rem ">
                        <div className="flex flex-row grid-1-cols-importent nice-gap-2rem ">

                            <PhoneDropDown
                                mandotory={true}
                                name='phoneNumber'
                                defaultValue={requestForm.phoneNumberOnly}
                                errorLabel={error?.phoneNumber}
                                onChangeValue={(countryCode, phoneNumber) => {
                                    const val = { ...requestForm };
                                    val.phoneNumberOnly = phoneNumber;
                                    val.phoneNumber = `+${countryCode}${phoneNumber}`;
                                    setRequestForm(val);
                                }}


                            />
                        </div>
                        <div className="flex flex-row grid-1-cols-importent nice-gap-2rem ">

                            <InputField
                                placeholder={trans("employment:OTP")}
                                type="number"
                                value={requestForm.OTP}
                                errorLabel={error.OTP}
                                onChangeValue={(e) => {
                                    const val = { ...requestForm };
                                    val.OTP = e.target.value;
                                    setRequestForm(val);
                                }}
                                name='OTP'
                            />
                        </div>
                        <FormButton
                            isLoading={isLoadingBtnSend}
                            name={trans("employment:Confirm")}
                        />

                    </div>
                </form>
            </div>


            <Popup show={showFinalPopup}>
                <div className="w-full h-full flex flex-column justify-center items-center" >
                    <div className="cart-finalized" >
                        <div className="cart-finalized-container flex flex-col">

                            <div className="cart-finalized-container-img flex flex-col">
                                <img src="/assets/images/finalized.svg" />
                            </div>
                            {
                                <>

                                    <div className="flex flex-wrap justify-center">
                                        <span className="text-20 font-bold  mx-2"> {showFinalPopupMessage}</span>
                                    </div>



                                </>

                            }
                            {
                                applicationData?.attendanceDateTime &&
                                <div className="flex flex-row justify-center">
                                    <span className="text-20 font-bold text-primary  mx-2">
                                        {moment(applicationData?.attendanceDateTime).format('YYYY-MM-DD hh:mm A')}
        
                                    </span>
                                </div>
                            }


                            <div className="flex flex-center flex-col  mt-5 pt-5 w-80">
                                <div className=" mb-5 w-100">
                                    <div className="nice-sm-btn cart-notification-go  bg-primary text-white text-16" onClick={() => {

                                        setShowFinalPopup(false);
                                        setIsLoadingBtnSend(false);
                                        history.push("/");

                                    }}>
                                        {trans("cartFile:Continue_browsing")}
                                    </div>
                                </div>
                            </div>


                        </div>

                    </div>

                </div>
            </Popup>
        </Fragment>
    );
}