import { useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Route, Switch, useRouteMatch } from "react-router";
import config from '../../configuration/config.json';
import MainSericePage from '../maintenance/subpages/mainServicePage';
import SubMainServicePage from "./subpages/subMainServicePage";
import SubServicePage from "./subpages/subServicePage";
import ServicePage from "./subpages/servicePage";
import { ServiceReservation } from "./serviceReservation";
import { ServiceReservationByID } from "./serviceReservationByID";
import { ProtectionAndShading } from "./protectionAndShading";
import { SalesOrderService } from "./SalesOrderService";
import { Helmet } from "react-helmet";
export function MaintenancePage() {
    const { t: trans, i18n } = useTranslation(["info", "eparts"]);
    const { path } = useRouteMatch();
    let lang = (i18n.language || 'ar');

    const [seoPageInfo, setSeoPageInfo] = useState({});
    useEffect(async () => {

        var page = "maintenance services"


        const response = await axios.post(
            config.baseURL + 'func',
            {
                "Name": "SiteFetchSeoPages",
                "Values": {
                    "page": page
                }
            }
        );
        if (response.status == 200) {

            var resdata = response.data[0];

            setSeoPageInfo(resdata);


        }
    }, []);

    return (

<>
            {
                seoPageInfo &&

                <Helmet>

                    <meta charSet="utf-8" />
                    {
                        seoPageInfo?.title &&
                        <title>{seoPageInfo?.title}</title>
                    }
                    {
                        seoPageInfo?.link &&
                        <link rel="canonical" href={seoPageInfo?.link} />

                    }
                    {
                        seoPageInfo?.MetaDescription &&
                        <meta name="description" content={seoPageInfo?.MetaDescription} />
                    }
                    {
                        seoPageInfo?.MetaGroups &&
                        <meta name="keywords" content={seoPageInfo?.MetaGroups} />
                    }

                </Helmet>


            }
            <Switch>
                <Route exact path={path} component={MainSericePage} />
                <Route exact path={`${path}/serviceReservation/:PVIN/:PServiceID`} component={ServiceReservation} />
                <Route exact path={`${path}/serviceReservation/:PServiceID`} component={ServiceReservationByID} />
                <Route exact path={`${path}/serviceReservation/`} component={ServiceReservationByID} />
                <Route exact path={`${path}/protectionAndShading/:PVIN/:PUser`} component={ProtectionAndShading} />
                <Route exact path={`${path}/protectionAndShading/:PVIN/`} component={ProtectionAndShading} />
                <Route exact path={`${path}/SalesOrderService/:OrderGUID/`} component={SalesOrderService} />
                <Route exact path={`${path}/:PGroupID/:PYear/:PSerciveID`} component={MainSericePage} />
                <Route exact path={`${path}/subMainService/:ID`} component={SubMainServicePage} />
                <Route exact path={`${path}/subMainService/subService/:ID`} component={SubServicePage} />
                <Route exact path={`${path}/subMainService/subService/service/:ID`} component={ServicePage} />
            </Switch>
        
        </>
    );
}