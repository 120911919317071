import { Fragment, useCallback, useEffect, useState } from "react";
import Banner from "../../components/banner";
import { OfferSections } from "../../sections/offersSection";
import Breadcrumb from "../../components/__simple/breadcrumb";
import { useTranslation } from "react-i18next";
import axios from "axios";
import config from "../../configuration/config.json";
import { Helmet } from "react-helmet";
export function OfferPage() {

    const { t: trans,i18n } = useTranslation(["common"]);
    const [banner, setBanner] = useState({});
    const [offers, setOffers] = useState([]);
    let lang =(i18n.language || 'ar');

    useEffect(() => {
        callback();
    }, []);

    const callback = useCallback(async () => {


        try {

            if ("caches" in window) {
                let url = "offers";

                // Opening that particular cache
                const cacheStorage = await caches.open("OfferPage");
                // Fetching that particular cache data
                const cachedResponse = await cacheStorage.match(
                    url
                );
                var data = await cachedResponse?.json();
                if (data) {
                    setBanner(data?.banner);
                    setOffers(data?.offers);

                }
            }

        } catch (error) {
        }
        try {
            const response = await axios.request({
                baseURL: config.controllURL,
                url: lang + "/api/offers/offers",
                method: "get",
            });

            setBanner(response.data.banner);
            setOffers(response.data.offers);

            const Responsedata = new Response(JSON.stringify(response.data));

            if ("caches" in window) {
                // Opening given cache and putting our data into it
                caches.open("OfferPage").then((cache) => {
                    cache.put("offers", Responsedata);

                });
            }

        } catch (error) {
            console.log(error);
        }
    }, [lang]);

    const [seoPageInfo, setSeoPageInfo] = useState({});
    useEffect(async () => {

        var page = "offers"


        const response = await axios.post(
            config.baseURL + 'func',
            {
                "Name": "SiteFetchSeoPages",
                "Values": {
                    "page": page
                }
            }
        );
        if (response.status == 200) {

            var resdata = response.data[0];

            setSeoPageInfo(resdata);


        }
    }, []);

    return (
        <Fragment>
            {
                seoPageInfo &&

                <Helmet>

                    <meta charSet="utf-8" />
                    {
                        seoPageInfo?.title &&
                        <title>{seoPageInfo?.title}</title>
                    }
                    {
                        seoPageInfo?.link &&
                        <link rel="canonical" href={seoPageInfo?.link} />

                    }
                    {
                        seoPageInfo?.MetaDescription &&
                        <meta name="description" content={seoPageInfo?.MetaDescription} />
                    }
                    {
                        seoPageInfo?.MetaGroups &&
                        <meta name="keywords" content={seoPageInfo?.MetaGroups} />
                    }

                </Helmet>


            }
            <Banner
                subtitle={banner?.subtitle}
                title={banner?.title}
                desc={banner?.excerpt}
                background={banner?.imagePath}
                backOpasity={banner?.backOpasity}
            />
            <div className="container">
                <Breadcrumb className={"mt-16 "}
                    links={[
                        {
                            name: trans("common:offers"),
                        }
                    ]}
                />
            </div>
            {
                offers?.map(({ key, value }, index) => {
                    return (
                        <OfferSections
                            key={index}
                            title={key}
                            type={key}
                            offersList={value}
                        />

                    );
                })
            }
        </Fragment>
    );
}

