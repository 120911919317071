import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Popup from "../../User/popup";
import { TextArea } from "../../components/_input/_textArea";
import SimpleButton from "../../components/_input/SimpleButton";
import { useParams } from "react-router";
import PopupHeader from "../../User/components/popupHeader";
import config from '../../configuration/config.json';
import { useHistory, useLocation } from "react-router";
import { GetMessages, formValidate } from '../../utils/validation';
import Joi from "joi";
import { Swiper, SwiperSlide } from "swiper/react";
import Skeleton from 'react-loading-skeleton'
import { EvaluationType } from "./components/EvaluationType";
import { toast } from "react-toastify";
import { Price } from "../../components/__simple/_price";
import { Helmet } from "react-helmet";
import CustomItemServices from "../../components/CustomItemServices";
import { CheckInput } from "../../components/_input/_checkInput";
import { filter } from "lodash";
export function SalesOrderService() {

    let { PGroupID, PYear, PServiceID, OrderGUID } = useParams();
    const { t: trans, i18n } = useTranslation(["info", "employment", "eparts", "common", "global", "offers", "cartFile"]);
    let lang = (i18n.language || 'ar');
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const [paramGUID, setParamGUID] = useState(query.get("Id") ?? null);
    const [pSalesorderid, setSalesorderid] = useState(query.get("Salesorderid") ?? null);
    const [sadadInfo, setSadadInfo] = useState(null);
    const [PVIN, setPVIN] = useState(null);
    const [PUser, setPUser] = useState(null);
    const [showFinalPopup, setShowFinalPopup] = useState(false);
    const [showFinalPopupMessage, setShowFinalPopupMessage] = useState("");
    const popRef = useRef(null);
    const [Agreements, setAgreements] = useState([]);
    const [resOrderID, setResOrderID] = useState(null);
    const history = useHistory();
    const [stepNum, setStepNum] = useState(1);
    const [dataFullNum, setDataFullNum] = useState(0);
    const [VINDetails, setVINDetails] = useState(null);
    const [SalesInvoiceTrans, setSalesInvoiceTrans] = useState([]);
    const [infoPrice, setinfoPrice] = useState([{ Price: 0 }]);
    const [isSendeng, setIsSendeng] = useState(false);
    const [orderReservation, setOrderReservation] = useState(null);
    const [allselectServices, setAllSelectServices] = useState([]);
    const [selectService, setSelectService] = useState({});
    const [acticeService, setActiceService] = useState(null);
    const [acticeMenu, setActiceMenu] = useState("Saving Packages");
    const [selsub0, setselsub0] = useState({ ID: "0", desAr: "اختيار  قيمة", desEn: "chose" });
    const [paymentType, setPaymentType] = useState(null);
    const [isToggleSwitch, setIsToggleSwitch] = useState(true);
    const [showAgreements, setshowAgreements] = useState(false);
    const [indexPanel, setIndexPanel] = useState("1000");
    const [brand, setBrand] = useState(null);
    const [type, setType] = useState(null);
    const [group, setGroup] = useState(null);
    const [vModelCode, setVModelCode] = useState([]);
    const [year, setYear] = useState(null);
    const [evaluations, setEvaluations] = useState({
        evaluation_false_0: 0,
        evaluation_false_1: 0.2,
        evaluation_false_2: 0.2,
        evaluation_false_3: 0.2,
        evaluation_true_0: 0.2,
    });
    const evaluationOptions = [{
        titel: "زجاج أمامي",
        titelEn: "Windshield",
        mandatory: false,
        defultValue: 0
    },
    {
        titel: "زجاج خلفي",
        titelEn: "Rear glass",
        mandatory: true,
        defultValue: 0.2
    },
    {
        titel: "زجاج جانبي  أمامي ",
        titelEn: "Front side glass",
        mandatory: true,
        defultValue: 0.2
    },
    {
        titel: "زجاج جانبي  خلفي",
        titelEn: "Rear side glass",
        mandatory: true,
        defultValue: 0.2
    },];
    const [requestForm, setRequestForm] = useState({
        name: '',
        phone: '',
        email: '',
        subject: '',
        date: '',
        time: '',
        messageContent: '',
        myCarID: '',
        SiteID: null,
        ServiceID: ''
    });
    const [filteredSettings, setFilteredSettings] = useState({
        brands: [],
        groups: [],
        models: [],
        productModels: []
    });


    const [settings, setSettings] = useState({
        brands: [],
        groups: [],
        models: [],
        productModels: []
    });
    const targetRef = useRef(null);
    const targetRefAdditional = useRef(null);// Ref to the target area
    const [arrowPosition, setArrowPosition] = useState({ top: 50, left: 100 });
    const [isArrowVisible, setIsArrowVisible] = useState(false);
    // إخفاء السهم عند أي تفاعل (نقر أو تمرير)
    useEffect(() => {

        // if (VINDetails && brand) {
        //     if (targetRef.current) {
        //         const rect = targetRef.current.getBoundingClientRect();

        //         console.log("targetRef", window.scrollX);
        //         setArrowPosition({
        //             top: rect.top + window.scrollY, // Adjust for scroll
        //             left: (rect.left == 0 ? 50 : rect.left) + window.scrollX, // Adjust for scroll
        //         });
        //     }
        //     setIsArrowVisible(true);
        //     const hideArrow = () => setIsArrowVisible(false);
        //     window.addEventListener("scroll", hideArrow);
        //     window.addEventListener("click", hideArrow);

        //     return () => {
        //         window.removeEventListener("scroll", hideArrow);
        //         window.removeEventListener("click", hideArrow);
        //     };
        // }

    }, [VINDetails, brand]);
    useEffect(() => {
        if (OrderGUID)
            FetchSalesOrderService(OrderGUID);

    }, [OrderGUID]);

    const [salesOrder, setSalesOrder] = useState({});
    const FetchSalesOrderService = async (guid) => {
        try {
            axios.post(config.baseURL + "details",
                {
                    "Object": "SalesOrders_Service",
                    "ObjectSettings": {
                        "MetaData": false,
                        "Limit": {
                            "Value": 1,
                            "Optional": "Top"
                        }
                    },
                    "option": "column",
                    "fields": "*",
                    "Filters": `Where GUID = '${guid}' AND SalesOrderStatusID !='Canceled' and  SalesOrderStatusID !='Closed' `
                }
            ).then(response => {
                const result = response?.data?.ApiObjectData;
                if (result && result.length > 0) {

                    var appData = result[0];
                    setSalesOrder(appData);

                    if (appData.StatusPaymentID == 'FullyPaid') {

                        const paymentMethodCallback = new URL(window.location.href).searchParams.get("PaymentMethod");
                        if (paymentMethodCallback == null ) {
                            if (appData.SalesOrderID)
                                setResOrderID(appData.SalesOrderID);

                            setShowFinalPopupMessage(trans("تم الدفع مسبقا"));
                            setShowFinalPopup(true);
                        }
                      
                    }
                    if (!appData.SN) {
                        setShowFinalPopupMessage(trans("يجب اضافة VIN"));
                        setShowFinalPopup(true);
                    }
                    setPVIN(appData.SN);
                    setPUser(appData.CustID);
                } else {
                    setShowFinalPopupMessage(trans("امر البيع غير موجود"));
                    setShowFinalPopup(true);
                }

            }).catch(error => {

            });


        } catch (err) {
            console.log("", err);
        }
    };
    function handelalertIndicatorArrow(targetRef) {

        if (targetRef.current) {
            const rect = targetRef.current.getBoundingClientRect();

            console.log("targetRef", window.scrollX);
            setArrowPosition({
                top: (rect.top - 30) + window.scrollY, // Adjust for scroll
                left: (rect.left == 0 ? 50 : rect.left) + window.scrollX, // Adjust for scroll
            });

            setIsArrowVisible(true);
            const hideArrow = () => setIsArrowVisible(false);
            window.addEventListener("scroll", hideArrow);
            window.addEventListener("click", hideArrow);

            return () => {
                window.removeEventListener("scroll", hideArrow);
                window.removeEventListener("click", hideArrow);
            };
        }
    }

    const [browser, setBrowser] = useState('');

    useEffect(() => {
        const userAgent = navigator.userAgent;

        if (userAgent.indexOf('Chrome') > -1) {
            setBrowser('Chrome');
        } else if (userAgent.indexOf('Firefox') > -1) {
            setBrowser('Firefox');
        } else if (userAgent.indexOf('Safari') > -1 && userAgent.indexOf('Chrome') === -1) {
            setBrowser('Safari');
        } else if (userAgent.indexOf('MSIE') > -1 || userAgent.indexOf('Trident') > -1) {
            setBrowser('Internet Explorer');
        } else if (userAgent.indexOf('Edge') > -1) {
            setBrowser('Edge');
        } else {
            setBrowser('Other');
        }
    }, []);

    useEffect(async () => {

        try {
            const response = await axios.request({
                baseURL: config.controllURL,
                url: lang + "/api/privacy/ProtectionAndShadingAgreement",
                method: "get",
            });
            setAgreements(response.data?.agreements);
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        const paymentStatus = new URL(window.location.href).searchParams.get("paymentStatus");
        const orderId = new URL(window.location.href).searchParams.get("orderId");

        const paymentMethodCallback = new URL(window.location.href).searchParams.get("PaymentMethod");
        const paymentTypeCallback = new URL(window.location.href).searchParams.get("PaymentType");
        if (paymentMethodCallback != null && paymentTypeCallback != null) {
            if (paymentMethodCallback == 'paytabs') {
                StatusPaymentPayTabsSalesOrdersService();
            } else if (paymentMethodCallback == 'tamara') {
                if (paymentStatus == 'approved') {
                    StatusPaymentTamaraSalesOrdersService(orderId);
                    setTimeout(() => {

                        // StatusPaymentTamaraSalesOrdersService(orderId);

                    }, 2000);

                    setShowFinalPopup(true);
                }

            } else if (paymentMethodCallback == 'sadad') {
                StatusPaymentSadadSalesOrdersService();
                //site_fetchSadadCode_salesOrdersService
            } else {
                setIsSendeng(false);
                toast.error(trans("error:unknown_failure"));
            }

        }

    }, [])

    useEffect(() => {

        if (orderReservation) {
            setResOrderID(orderReservation?.MaintenanceOrderID);
            setShowFinalPopup(true);

        }
        fetchSettings();


        if (PVIN) {
            GetVINDetails(PVIN);
            FetchSalesInvoiceTrans(PVIN);
        }




    }, [PVIN, orderReservation])
    const [firstByLink22, setFirstByLink22] = useState(true);
    useEffect(() => {

        if (VINDetails && VINDetails.length > 0 && settings && settings?.groups?.length > 0 && firstByLink22 == true) {

            setFirstByLink22(false);
            PGroupID = VINDetails[0].CarGroup;
            PYear = VINDetails[0].Model;
            let PBrandID = VINDetails[0].BrandID;
            let ttmodelType = VINDetails[0].Type;


            setRequestForm({
                ...requestForm,
                name: lang == "ar" ? VINDetails[0].CustNameAr : VINDetails[0].CustNameEn,
                phone: VINDetails[0].Mobile,
                email: VINDetails[0].Email,
                myCarID: VINDetails[0].VIN
            });

            setTimeout(() => {
                handleServiceOrder({
                    showPopup: true,
                    type: "sub2",
                    main: "B56D30C7-3AA6-4698-A640-6F392138CB24",
                    sub1: "635F9201-A9F5-404D-9A9A-FD537F32B5A0",
                    sub2: "A82C0550-2A18-44EA-B466-7343BFC45340",
                    ServiceID: PServiceID,
                    brand: PBrandID,
                    group: PGroupID,
                    year: PYear,
                    modelType: ttmodelType,
                    name: lang == "ar" ? VINDetails[0].CustNameAr : VINDetails[0].CustNameEn,
                    phone: VINDetails[0].Mobile,
                    email: VINDetails[0].Email,
                    myCarID: VINDetails[0].VIN,

                });
            }, 2000);


        }

    }, [PGroupID, PYear, settings, VINDetails, orderReservation])

    async function FetchSalesInvoiceTrans(VIN) {
        const Body = {
            "name": "site_FetchSalesInvoiceTransProtectionServiceByVIN",
            "values": {
                "VIN": VIN
            }
        }

        axios.request({
            url: config.baseURL + 'func',
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {
                //console.log("App_GetVINDetails:", response.data);
                var productList = response?.data?.map(s => s.ProductID);
                setSalesInvoiceTrans(productList);
            })
            .catch(function (error) {

                console.log(error);
                setSalesInvoiceTrans([]);
            });


    }
    async function GetVINDetails(VIN) {



        const Body = {
            "name": "App_GetVINDetails",
            "values": {
                "VIN": VIN,
                "UserId": PUser
            }
        }

        axios.request({
            url: config.baseURL + 'func',
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {
                //console.log("App_GetVINDetails:", response.data);
                setVINDetails(response.data);
            })
            .catch(function (error) {

                console.log(error);
                setVINDetails([]);
                setShowFinalPopupMessage(trans("employment:The_page_link_is_incorrect"));
                setShowFinalPopup(true);
            });


    }

    useEffect(() => {

        function handleClickOutside(event) {
            if (popRef.current && !popRef.current.contains(event.target) && showFinalPopup === true) {
                setShowFinalPopup(false);
                setResOrderID(null);
                if (location.pathname)
                    history.push(location.pathname);

            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showFinalPopup]);


    //--------------------------------------------------
    const [maintenanceObj, setMaintenanceObj] = useState({
        showPopup: false, type: "Notset", main: "", sub1: "", sub2: ""
    });
    const [Defult, setDefult] = useState({
        showPopup: false, type: "Notset", main: "", sub1: "", sub2: ""
    });



    const notLoginSchema = Joi.object({
        name: Joi.string().required().messages(GetMessages()),
        year: Joi.string().required().messages(GetMessages())
    });




    const handleFilteredModels = (option) => {

        setModelCode(vModelCode[option]);
        setGroup(null);
        setType(null);
        setFilteredSettings({ ...filteredSettings, models: [], productModels: [] });
        setGroup(option);

        var fModels = [];
        try {
            const currentgroupData = settings?.groups?.filter(a => a.brandID == brand && a.productGroupID == option);


            const ListTreeGroups = (currentgroupData && currentgroupData?.length > 0) ? currentgroupData[0].ListTreeGroups : option;
            // Split the comma-separated string into an array
            const listVArray = ListTreeGroups.split(',');

            // Assuming settings.productModels is an array of objects with properties productGroupID and Model
            fModels = settings?.productModels?.filter(a =>
                listVArray.includes(a.productGroupID)
            );


        } catch {

            fModels = settings?.productModels?.filter(a => a.productGroupID == option);
        }


        setFilteredSettings({ ...filteredSettings, productModels: fModels });

    };

    const handelSelectBrand = (option, vYear = year) => {
        setBrand(null);
        setGroup(null);
        setType(null);

        setFilteredSettings({ ...settings, groups: [], models: [], productModels: [] });

        setBrand(option);


        const fGroups = settings?.groups?.filter(a => a.brandID == option && a.Year == vYear);

        setFilteredSettings({ ...settings, groups: fGroups, models: [], productModels: [] });


    };


    useEffect(() => {

        if (settings?.brands && settings?.brands.length > 0 && Defult?.first != true
        ) {
            if (Defult?.year) {
                setYear(Defult?.year)
                const val = { ...requestForm };
                val.year = Defult?.year;
                setRequestForm(val);
            }
            if (Defult?.brand)
                handelSelectBrand(Defult?.brand, Defult?.year);
        }

    }, [Defult, settings]);


    useEffect(() => {

        try {

            if (requestForm?.date) {

                var md = new Date(requestForm?.date);
                try {
                    if (requestForm?.time) {

                        var Hours = requestForm?.time.split(":");

                        md.setHours(Hours[0], Hours[1], 0);
                    }
                } catch (error) {

                }


            }

        } catch (error) {
        }


    }, [requestForm]);

    useEffect(() => {


        if (settings?.brands && settings?.brands.length > 0 && filteredSettings?.groups?.length > 0 && Defult?.first != true) {

            setDefult({ ...Defult, first: true });
            if (Defult?.group)
                handleFilteredModels(Defult?.group);

            if (Defult?.modelType) {
                setType(Defult?.modelType);
            }
        }
    }, [filteredSettings]);



    const fetchSettings = async () => {
        try {

            if ("caches" in window) {
                var curl = "Settings-" + lang;

                // Opening that particular cache
                const cacheStorage = await caches.open("HJWeb");

                // Fetching that particular cache data
                const cachedResponse = await cacheStorage.match(
                    curl
                );
                var settingResponseCash = await cachedResponse?.json();

                const currentSettings = { ...settings };
                currentSettings.brands = settingResponseCash?.brands;
                currentSettings.groups = settingResponseCash?.groups;
                currentSettings.models = settingResponseCash?.models;
                currentSettings.productModels = settingResponseCash?.productModels;
                setSettings(currentSettings);

                const fGroups = currentSettings?.groups?.filter(a => a.brandID == brand);

                //---------------------------------------------------------------
                let currentvModelCode = {};
                currentSettings?.productModels?.map((item) => {
                    if (item.ModelCode) {
                        currentvModelCode[item.productGroupID] = item.ModelCode;
                    }
                })
                setVModelCode(currentvModelCode);
            }


            const settingResponse = await axios.request({
                baseURL: '/',
                url: config.controllURL + lang + "/api/Maintenance/Settings",
                method: "get"
            });

            const currentSettings = { ...settings };
            currentSettings.brands = settingResponse.data?.brands;
            currentSettings.groups = settingResponse.data?.groups;
            currentSettings.models = settingResponse.data?.models;
            currentSettings.productModels = settingResponse.data?.productModels;

            setSettings(currentSettings);

            const fGroups = currentSettings?.groups?.filter(a => a.brandID == brand);

            //---------------------------------------------------------------

            let currentvModelCode = {};
            currentSettings?.productModels?.map((item) => {
                currentvModelCode[item.productGroupID] = item.ModelCode

            })

            //////console.log("currentvModelCode>>>>>>>>>", currentvModelCode);
            setVModelCode(currentvModelCode);

            const data = new Response(JSON.stringify(settingResponse.data));

            if ("caches" in window) {
                // Opening given cache and putting our data into it
                caches.open("HJWeb").then((cache) => {
                    cache.put("Settings-" + lang, data);

                });
            }

        } catch (error) {
            //////console.log(error);
        }

    };
    const handleServiceOrder = (data) => {

        try {
            data['firstFetchPrice'] = null;
            data['first'] = null;
            setStepNum(1);
            setDataFullNum(0);


            setinfoPrice([{ Price: 0 }]);

            const value = { ...maintenanceObj, ...data };
            if (data?.main == '') {

                data['main'] = null;
            }
            if (data?.sub1 == '') {

                data['sub1'] = null;
            }
            if (data?.sub2 == '') {

                data['sub2'] = null;

            }

            setDefult(data);

            var sub2 = { value: data.sub2, ServiceID: data?.ServiceID }
            fetchService(sub2);



            setTimeout(() => {

                setMaintenanceObj(value)

            }, 2000);


        } catch (e) {
            console.error(e)

        }

    };
    const [Note, setNote] = useState("");

    const [lodingService, setLodingService] = useState(false);

    const [Branches, setBranches] = useState([]);
    let [modelCode, setModelCode] = useState("");
    const [ServiceID, setServiceID] = useState(null);
    const [allServiceID, setAllServiceID] = useState([]);
    const [Service, setService] = useState(null);
    const [serviceImportantAdditions, setServiceImportantAdditions] = useState(null);
    async function fetchService(option) {
        setService([]);
        setinfoPrice([{ Price: 0 }]);

        setLodingService(true);


        const Body = {
            //site_FetchSalesOrderTrans_Service_ByGUID
            "name": "site_FetchSalesOrderTrans_Service_ByGUID",
            "values": {
                "GUID": OrderGUID
            }
        }

        axios.request({
            url: config.baseURL + 'func',
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {
                // //////console.log(response.data.ApiObjectData);
                // selectService[lang == "ar" ? "ServiceDetails" : "ServiceDetailsEn"]?.includes("Tinting")
                // var filterservice = response?.data?.filter(s => !s.ServiceDetails?.includes("تلميع") && !s.ServiceDetails?.includes("الصغيرة") && !s.ServiceDetails?.includes("الصغيره"))
                var filterservice = response?.data;
                setTimeout(setService(filterservice), 1000);
                var ServiceIDList = filterservice?.map(s => s.ServiceID);
                setAllSelectServices(filterservice);

                setAllServiceID(ServiceIDList);
                var newServiceImportantAdditions = filterservice.find(s => (s["ServiceDetailsEn"].includes("rust") || s["ServiceDetailsEn"]?.includes("Undercoating")
                    || s["ServiceDetails"]?.includes("عازل صد")));
                setServiceImportantAdditions(newServiceImportantAdditions);
                if (option?.ServiceID) {
                    setServiceID(option?.ServiceID)
                    const val = { ...requestForm };
                    val.ServiceID = option?.ServiceID;
                    setRequestForm(val);
                    filterservice.map((B) => {
                        if (B.ServiceID === option?.ServiceID) {
                            const v = selsub0;
                            v.desAr = B.ServiceNameAR;
                            v.desEn = B.ServiceName;
                            v.ID = B.ServiceID;
                            setselsub0(v);
                            ////////console.log(v);
                        }
                    })
                }

                setLodingService(false);

            })
            .catch(function (error) {

                setLodingService(false);
                //////console.log(error);
            });



    }
    async function FetchPrice(modelC, SID) {

        setinfoPrice([{ Price: 0 }]);
        if (!modelC) {
            return;
        }
        if (!SID) return;



        const Body = {
            "name": "Site_fetchPriceService",
            "values": {
                "ModelCode": modelC,
                "ServicID": SID
            }
        }

        axios.request({
            url: config.baseURL + 'func',
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {
                // //////console.log(response.data.ApiObjectData);

                setinfoPrice(response.data);

            })
            .catch(function (error) {
                setinfoPrice([{ Price: 0 }]);

                //////console.log(error);
            });


    }

    const checkDataFullNum = async () => {

        if (group && paymentType && allServiceID && allServiceID.length > 0 && requestForm?.name) {

            setDataFullNum(2);
            return 2;
        } else if (group && paymentType && allServiceID && allServiceID.length > 0 && requestForm?.name) {

            setDataFullNum(2);
            return 2;
        } else {
            setDataFullNum(0);
            return 0;
        }


    }


    const [popupHandlers, setPopupHandlers] = useState({
        onConfirm: null,
        onCancel: null,
    });

    //---------------------

    const [error, setError] = useState({});
    async function SendResreve(VIN, ServecID, UserID, Amount) {


        const val = { ...requestForm };
        val.year = year;
        val.ServiceID = ServiceID;
        setRequestForm(val);
        var mainNote = Note;
        const result = formValidate(notLoginSchema, val) ?? {};

        const hasTintingService = allselectServices.find(s => allServiceID.includes(s.ServiceID) && (s?.text?.includes("تظليل") || s?.text?.includes("Tinting") || s["ServiceDetailsEn"]?.includes("tinting")
            || s["ServiceDetails"]?.includes("تظليل")));


        var noteTinting = "";
        if (hasTintingService) {

            if (isToggleSwitch == false) {
                if (evaluations[hasTintingService?.ServiceID + "_" + isToggleSwitch + "_" + 0] == null || evaluations[hasTintingService?.ServiceID + "_" + isToggleSwitch + "_" + 0] === "") {
                    noteTinting += "\\nزجاج امامي:" + "0";

                } else {
                    noteTinting += "\\nزجاج امامي:" + evaluations[hasTintingService?.ServiceID + "_" + isToggleSwitch + "_" + 0];
                }
                if (evaluations[hasTintingService?.ServiceID + "_" + isToggleSwitch + "_" + 1] == null || evaluations[hasTintingService?.ServiceID + "_" + isToggleSwitch + "_" + 1] === "") {
                    result[hasTintingService?.ServiceID + "_" + isToggleSwitch + "_" + 1] = trans("الاختيار اجباري");
                    setActiceMenu(hasTintingService?.MainWebGroup);
                    setIndexPanel(hasTintingService?.ServiceID);
                    setActiceService(hasTintingService);
                } else {
                    noteTinting += "\\nزجاج خلفي:" + evaluations[hasTintingService?.ServiceID + "_" + isToggleSwitch + "_" + 1];
                }
                if (evaluations[hasTintingService?.ServiceID + "_" + isToggleSwitch + "_" + 2] == null || evaluations[hasTintingService?.ServiceID + "_" + isToggleSwitch + "_" + 2] === "") {
                    result[hasTintingService?.ServiceID + "_" + isToggleSwitch + "_" + 2] = trans("الاختيار اجباري");
                    setActiceMenu(hasTintingService?.MainWebGroup);
                    setIndexPanel(hasTintingService?.ServiceID);
                    setActiceService(hasTintingService);
                } else {
                    noteTinting += "\\nزجاج جانبي امامي:" + evaluations[hasTintingService?.ServiceID + "_" + isToggleSwitch + "_" + 2];
                }
                if (evaluations[hasTintingService?.ServiceID + "_" + isToggleSwitch + "_" + 3] == null || evaluations[hasTintingService?.ServiceID + "_" + isToggleSwitch + "_" + 3] === "") {
                    result[hasTintingService?.ServiceID + "_" + isToggleSwitch + "_" + 3] = trans("الاختيار اجباري");
                    setActiceMenu(hasTintingService?.MainWebGroup);
                    setIndexPanel(hasTintingService?.ServiceID);
                    setActiceService(hasTintingService);
                } else {
                    noteTinting += "\\nزجاج جانبي خلفي:" + evaluations[hasTintingService?.ServiceID + "_" + isToggleSwitch + "_" + 3];
                }



            } else {

                if (evaluations[hasTintingService?.ServiceID + "_" + isToggleSwitch + "_" + 0] == null || evaluations[hasTintingService?.ServiceID + "_" + isToggleSwitch + "_" + 0] === "") {
                    result[hasTintingService?.ServiceID + "_" + isToggleSwitch + "_" + 0] = trans("الاختيار اجباري");
                    setActiceMenu(hasTintingService?.MainWebGroup);
                    setIndexPanel(hasTintingService?.ServiceID);
                    setActiceService(hasTintingService);
                } else {
                    noteTinting += "\\n كامل زجاج السياره:" + evaluations[hasTintingService?.ServiceID + "_" + isToggleSwitch + "_" + 0];
                }
            }

        }

        console.log(">>>>>>>>>>>>>>>>>result", result)
        setError(result);

        if (Object.keys(result).length == 0 && isSendeng == false) {

            console.log(">>>>>>>>>>>>>>>>>go to showConfirmationPopup", isSendeng)
            const userConfirmed = true;

            console.log(">>>>>>>>>>>>>>>>>showConfirmationPopup:", userConfirmed)
            if (true) {

                let listofServices;

                allServiceID.map((item) => {

                    if (listofServices)
                        listofServices += "," + item;
                    else
                        listofServices = item;
                });
                setIsSendeng(true);
                mainNote += noteTinting;
                try {
                    var myHeaders = new Headers();
                    myHeaders.append("Content-Type", "application/json");
                    var raw = {
                        "paytype": paymentType,
                        "userId": PUser,
                        "VIN": VIN,
                        "OrderGUID": OrderGUID,
                        "note": mainNote,
                        "servicelist": listofServices,
                        "referenceOrderId": pSalesorderid,
                        "callback": window.location.origin + "/" + lang + `/maintenance/SalesOrderService/${OrderGUID}`
                    };

                    console.log("raw>>>>>>>>>>>>>>>>:", raw);

                    await axios.request({
                        baseURL: config.paymentURL,
                        url: "/api/Payment/applyPaymentSalesOrdersService",
                        headers: myHeaders,
                        data: raw, method: "POST", responseType: "json"
                    })

                        .then(async response => {
                            console.log('Payment respons data:\n', response.data);


                            if (response.data.URL_Paytabs) {
                                window.location.href = response.data.URL_Paytabs;

                            } else if (response.data.url_payment) {
                                window.location.href = response.data.url_payment;
                                //site_fetchSadadCode_salesOrdersService
                            } else {
                                setIsSendeng(false);
                                if (response?.data?.MessageError)
                                    toast.error(response?.data?.MessageError);
                                else
                                    toast.error(trans("error:unknown_failure"));
                            }

                            setIsSendeng(false);
                        })
                        .catch(function (error) {

                            setIsSendeng(false);
                        });

                } catch (error) {
                    setIsSendeng(false);

                    console.log("error:", error);
                }
            } else {
                console.log('Operation canceled.');

                // Do nothing or handle cancellation here
            }
        }
    }



    const GetUrlfunc = config.baseURL + 'func';


    async function StatusPaymentTamaraSalesOrdersService(orderId) {
        console.log('StatusPaymentTamaraSalesOrdersService >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');

        if (paramGUID && orderId) {



            try {
                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                var raw = {
                    "GUID": paramGUID,
                    "orderId": orderId ?? ""
                };

                await axios.request({
                    baseURL: config.paymentURL,
                    url: "/api/Payment/StatusPaymentTamaraSalesOrdersService",
                    headers: myHeaders,
                    data: raw, method: "POST", responseType: "json"
                }).then(async response => {
                    console.log('StatusPaymentTamaraSalesOrdersService respons:', response);
                    if (response.data) {

                        var data = response.data;
                        console.log('PaymentPaytabsStatusFully data:', data);
                        if (data.Status == "success") {
                            setShowFinalPopup(true);
                            if (data.OrderId)
                                setResOrderID(data.OrderId);
                        }
                    }


                }
                ).catch(async error => {
                    console.log('error StatusPaymentTamaraSalesOrdersService:', error)

                });
            } catch (error) {
                console.log('error', error)


            }
        }
    }
    async function StatusPaymentPayTabsSalesOrdersService() {
        console.log('StatusPaymentPayTabsSalesOrdersService >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');

        if (paramGUID) {



            try {
                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                var raw = {
                    "GUID": paramGUID
                };

                await axios.request({
                    baseURL: config.paymentURL,
                    url: "/api/Payment/StatusPaymentPayTabsSalesOrdersService",
                    headers: myHeaders,
                    data: raw, method: "POST", responseType: "json"
                }).then(async response => {
                    console.log('PaymentPaytabsStatusFully respons:', response);
                    if (response.data && response.data.length > 0) {

                        var data = response.data.filter(p=>p.Status == "success")[0];
                        console.log('PaymentPaytabsStatusFully data:', data);
                        if (data.Status == "success") {
                            setShowFinalPopup(true);
                            if (data.OrderId)
                                setResOrderID(data.OrderId);
                        }
                    }

                }
                ).catch(async error => {
                    console.log('error PaymentPaytabsStatusFully:', error)

                });
            } catch (error) {
                console.log('error', error)


            }
        }
    }
    async function StatusPaymentSadadSalesOrdersService() {
        console.log('StatusPaymentSadadSalesOrdersService >>>>>>>', paramGUID);

        if (paramGUID) {



            try {
                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                var raw = {
                    "GUID": paramGUID
                };

                await axios.request({
                    baseURL: config.paymentURL,
                    url: "/api/Payment/StatusPaymentSadadSalesOrdersService",
                    headers: myHeaders,
                    data: raw, method: "POST", responseType: "json"
                }).then(async response => {
                    console.log('StatusPaymentSadadSalesOrdersService respons:', response);

                    var invoiceID = response.data.sadadNumber.replaceAll('"', "");

                    try {
                        if (invoiceID && parseInt(invoiceID)) {
                            var datasadad = response.data;
                            datasadad.sadadNumber = invoiceID;
                            setSadadInfo(datasadad);
                        } else {
                            setSadadInfo(response?.data);
                        }
                    } catch (error) {
                        setSadadInfo(response.data);
                    }
                    //! اهمم مرحلة في الفحص
                    if (invoiceID != null && invoiceID != undefined) {
                        setShowFinalPopup(true);
                    }

                }
                ).catch(async error => {
                    console.log('error StatusPaymentSadadSalesOrdersService:', error)

                });
            } catch (error) {
                console.log('error', error)


            }
        }
    }
    useEffect(() => {

        if (Branches && Branches?.length > 0 && !requestForm?.SiteID) {

            if (Branches?.length == 1) {
                let val = {};
                val.SiteID = Branches[0]?.SiteID;
                setRequestForm({ ...requestForm, ...val });
            }

        }

    }, [Branches, requestForm]);


    useEffect(() => {
        checkDataFullNum();
    }, [requestForm, ServiceID, allServiceID, year, type, paymentType]);



    useEffect(() => {
        if (vModelCode != null && ServiceID && Defult?.firstFetchPrice != true) {
            setDefult({ ...Defult, firstFetchPrice: true });
            if (Defult?.modelType) {
                if (vModelCode[Defult?.modelType] && ServiceID)
                    FetchPrice(vModelCode[Defult?.modelType], ServiceID);
            }



        }

    }, [vModelCode, ServiceID]);



    return (
        <>

            {
                (VINDetails && brand) ?

                    <div className={"protection-and-shading   " + (brand == 1 ? " toyota-theme " : " lexus-theme ")} >

                        {/* <div className="protection-and-shading-top" >
                    <button
                        onClick={() => {

                            if (window.location.href.includes("/ar") || window.location.href.includes("/en")) {

                                if (window.location.href.includes("/en"))
                                    window.location = window.location.href.replace("/en", "/ar");
                                if (window.location.href.includes("/ar"))
                                    window.location = window.location.href.replace("/ar", "/en");

                            } else {

                                window.location = window.location.origin + "/en" + window.location.pathname;
                            }
                        }}
                        className="protection-and-shading-top-lang h-full  flex justify-center items-center">

                        <span className='text-white font-bold pl-2 pr-2 capitalize'>{trans('common:changeLangCode')}</span>
                        <img src="/assets/icons/globle.svg" alt="change language" />
                    </button>
                </div> */}
                        <div className="protection-and-shading-head" >
                            <div className="protection-and-shading-head-Logo">

                                {
                                    lang == "ar" ?

                                        <div className="res-Logo-icon ">
                                            <img src="/assets/logo/logo_font_Black_AR.svg"
                                                alt="hassan jameel" className="hidden d-md-inline" />
                                            <img src="/assets/logo/logo_font_Black_AR.svg"
                                                alt="hassan jameel" className="d-inline d-md-hidden" />
                                        </div>


                                        :

                                        <div className="res-Logo-icon ">
                                            <img src="/assets/logo/logo_font_Black_EN.svg"
                                                alt="hassan jameel" className="hidden d-md-inline" />
                                            <img src="/assets/logo/logo_font_Black_EN.svg"
                                                alt="hassan jameel" className="d-inline d-md-hidden" />
                                        </div>
                                }
                            </div>

                            <div className="protection-and-shading-head-extra flex flex-worp">
                                <div className="protection-and-shading-head-brand flex flex-worp">
                                    {
                                        (brand == 1) ?
                                            <>
                                                <img src={`/assets/icons/brands/${"toyota-logo-withName.png"}`}
                                                    className="" alt={brand} />
                                            </>
                                            :
                                            (
                                                (brand == 2) ?
                                                    <>
                                                        <img src={`/assets/icons/brands/${"lexus-logo-withName.png"}`}
                                                            className="" alt={brand} />
                                                    </>
                                                    :
                                                    null

                                            )

                                    }
                                </div>

                                <button
                                    onClick={() => {

                                        if (window.location.href.includes("/ar") || window.location.href.includes("/en")) {

                                            if (window.location.href.includes("/en"))
                                                window.location = window.location.href.replace("/en", "/ar");
                                            if (window.location.href.includes("/ar"))
                                                window.location = window.location.href.replace("/ar", "/en");

                                        } else {

                                            window.location = window.location.origin + "/en" + window.location.pathname;
                                        }
                                    }}
                                    className="protection-and-shading-top-lang h-full  flex justify-center items-center">

                                    <span className='text-white font-bold pl-2 pr-2 capitalize'>{trans('common:changeLangCode')}</span>
                                    <img src="/assets/icons/globle.svg" alt="change language" />
                                </button>
                            </div>


                        </div >
                        {/* <div className="reservation-head-sub">
                {
                    lang == "ar" ?

                        <h1 className="text-balck text-bold text-20 text-center">لعمر افتراضي أطول، حافظ على سيارتك</h1>
                        :
                        <h1 className="text-balck text-bold text-20 text-center">For longer longevity, maintain your vehicle </h1>
                }

            </div> */}



                        <div className="protection-and-shading-titel nice-container nice-relative">
                            <div className=" p-4">
                                <div className="mainteance-popup-container-header py-5 flex flex-col ">
                                    <h3 className="text-balck text-bold text-40 my-5 ">
                                        {trans("info:Pamper_your_car_and_dont_skimp_on_it")}</h3>
                                    <p className="protection-and-shading-titel-sub1 text-24 text-bold ">{trans("info:Congratulations_on_your_new_car") +
                                        (requestForm?.name?.split(' ')[0] ? (lang == "ar" ? " يا" : ",") + requestForm.name.split(' ')[0] : " ")}</p>
                                    <p className="protection-and-shading-titel-sub2 text-24 text-bold ">{trans("info:These_are_some_additional_services_to_protect_the_car")}</p>
                                    {/* <div className="reservation-brand-Logo">
                            {
                                (brand == 1) ?

                                    <img src={`/assets/icons/brands/${"toyota"}.png`}
                                        alt={brand} onError={e => e.target.style.display = 'none'} />
                                    :
                                    (
                                        (brand == 2) ?
                                            <img src={`/assets/icons/brands/${"lexus-black"}.png`}
                                                alt={brand} onError={e => e.target.style.display = 'none'} />
                                            :
                                            null

                                    )

                            }
                        </div> */}
                                </div>

                                {/* <div className="my-8  flex flex-col nice-gap-2rem ">
                        {
                            stepNum == 1 &&
                            <>

                                {
                                    <>
                                        <div className="flex flex-row grid-2-cols nice-gap-2rem ">

                                            <div className="basis-2/4 grow">

                                                <InputField
                                                    disabled={true}
                                                    errorLabel={error?.name}
                                                    placeholder={trans("form:name")}
                                                    value={requestForm.name}
                                                    length={255}
                                                    onChangeValue={
                                                        (e) => {
                                                            const val = {};
                                                            val.name = e.target.value;
                                                            setRequestForm({ ...requestForm, ...val });
                                                        }
                                                    }
                                                />
                                            </div>


                                        </div>
                                        <div className="flex flex-row grid-3-cols-importent nice-gap-2rem ">
                                            <div className="basis2/4 grow">


                                                <InputField
                                                    disabled={true}
                                                    errorLabel={error?.brand}
                                                    placeholder={trans("info:company")}
                                                    value={settings?.brands?.filter(g => g.brandID == brand)[0]?.description}
                                                    length={255}
                                                    onChangeValue={
                                                        (e) => {

                                                        }
                                                    }
                                                />
                                            </div>
                                            <div className="basis2/4 grow">


                                                <InputField
                                                    disabled={true}
                                                    errorLabel={error?.group}
                                                    placeholder={trans("eparts:vehicle")}
                                                    value={settings?.groups?.filter(g => g.productGroupID == group)[0]?.description}
                                                    length={255}
                                                    onChangeValue={
                                                        (e) => {

                                                        }
                                                    }
                                                />
                                            </div>

                                            <div className="basis2/4 grow">

                                                <InputField
                                                    disabled={true}
                                                    errorLabel={error?.year}
                                                    placeholder={trans("info:created_year")}
                                                    value={year}
                                                    length={255}
                                                    onChangeValue={
                                                        (e) => {

                                                        }
                                                    }
                                                />
                                            </div>

                                        </div>


                                    </>

                                }
                            </>
                        }

                        {
                            <div className="flex flex-row grid-1-cols-importent nice-gap-2rem ">


                                {
                                    // Service &&
                                    // <DropDownInput
                                    //     options={[
                                    //         ...Service?.map(B => {
                                    //             return { value: B.ServiceID, text: lang === 'ar' ? B.ServiceNameAR : B.ServiceName, ...B };
                                    //         })]}
                                    //     errorLabel={error?.ServiceID}
                                    //     onfilter={(option) => {

                                    //         const val = { ...requestForm };
                                    //         val.ServiceID = option.value;
                                    //         setRequestForm(val);

                                    //         setServiceID(null);
                                    //         setServiceID(option.value);
                                    //         setSelectService(option);
                                    //         FetchPrice(modelCode, option.value);

                                    //     }}
                                    //     isepart={true}
                                    //     isLoading={lodingService}
                                    //     selectedValue={ServiceID ? ServiceID : requestForm?.ServiceID}
                                    //     defaultOption={{ value: selsub0.ID, text: trans("info:servce") }}

                                    // />

                                    // <InputField
                                    //     isLoading={lodingService}
                                    //     disabled={true}
                                    //     errorLabel={error?.ServiceID}
                                    //     placeholder={trans("info:servce")}
                                    //     value={lang === 'ar' ? Service?.ServiceNameAR : Service?.ServiceName}
                                    //     length={255}
                                    //     onChangeValue={
                                    //         (e) => {
                                    //             const val = {};
                                    //             val.ServiceID = e.target.value;
                                    //             setRequestForm({ ...requestForm, ...val });
                                    //         }
                                    //     }
                                    // />
                                }


                            </div>

                        }
                    </div> */}
                            </div>

                        </div >



                        {
                            Service &&
                            <div ref={targetRef} className="protection-and-shading-menu more-services bg-secondary-lighter border-radius py-2 alert-indicator-target-area">
                                <div className=" pb-10 my-5">
                                    {/* menu tabs */}

                                    {/* body */}
                                    <div className="flex flex-col  nice-relative ">
                                        {
                                            Service?.map((_Srtvice, _index) => {
                                                return (
                                                    <div key={_index} className="panel-section mx-5">



                                                        <div className={`panel-content  ${(indexPanel == _Srtvice.ServiceID) ? "show-panel-info" : ""}`}>
                                                            <div className="panel-content-top"

                                                                onClick={(e) => {
                                                                    setIndexPanel(null);
                                                                    setActiceService(null);
                                                                    if (indexPanel != _Srtvice.ServiceID) {
                                                                        setIndexPanel(_Srtvice.ServiceID);
                                                                        setActiceService(_Srtvice);
                                                                    }


                                                                }}


                                                            >
                                                                <div className="protection-and-shading-menu-servie">
                                                                    <h1 className={"text-20    font-bold " + (" text-super-gray ") + (allServiceID.includes(_Srtvice.ServiceID) ? " selectd " : "")}>
                                                                        {lang == "ar" ? _Srtvice.ServiceNameAR : _Srtvice.ServiceName}</h1>

                                                                    <div className='protection-and-shading-menu-price flex flex-row gap-2 text-bold nice-justifiy-end '>

                                                                        {
                                                                            _Srtvice?.PriceExcludeTax && <Price price={_Srtvice?.PriceExcludeTax} />
                                                                        }
                                                                    </div>
                                                                </div>

                                                                <img src="/assets/icons/arrow.svg" />
                                                            </div>

                                                            <div className="panel-content-body">

                                                                <ul>
                                                                    {
                                                                        _Srtvice[lang == "ar" ? "ServiceDetails" : "ServiceDetailsEn"]?.replaceAll("\\n\\n", "\\n")?.split('\n')?.map((note, index) => {

                                                                            return (

                                                                                note ?
                                                                                    <li key={index} className="text-16 text-gray"  >
                                                                                        {/* <span className="text-16 text-gray" >{note}</span> */}
                                                                                        <span className="text-16 text-gray" dangerouslySetInnerHTML={{ __html: note }} />
                                                                                    </li>
                                                                                    : null

                                                                            );
                                                                        })
                                                                    }


                                                                </ul>


                                                            </div>


                                                        </div>
                                                    </div>
                                                );
                                            })
                                            // < div className="panel-section panel-white bg-theme-up w-100 ">
                                            //     <div
                                            //         className={`panel-content  flex flex-col  nice-shadow-1 border-radius-sm   ${(indexPanel == "2000") ? "show-panel-info" : ""}`}>
                                            //         {

                                            //             <div className='z-whole-pupup-s   py-5 px-10  nice-shadow-0 '>
                                            //                 {

                                            //                     acticeService && acticeService[lang == "ar" ? "ServiceDetails" : "ServiceDetailsEn"] &&
                                            //                     <>

                                            //                         <ul className={'text-30 ' + (indexPanel != "2000" ? " text-line-clamp text-line-clamp-2 " : "")}>

                                            //                             {
                                            //                                 acticeService[lang == "ar" ? "ServiceDetails" : "ServiceDetailsEn"]?.replaceAll("\\n\\n", "\\n")?.split('\n').filter(a => a)?.map((note, index) => {
                                            //                                     return (


                                            //                                         <li key={index} className=' text-25 text-gray'>
                                            //                                             <span style={{ whiteSpace: 'pre-wrap' }} className="text-24 text-gray">{note}</span>
                                            //                                         </li>
                                            //                                     );
                                            //                                 })
                                            //                             }


                                            //                         </ul>
                                            //                         {
                                            //                             indexPanel != "2000" && acticeService[lang == "ar" ? "ServiceDetails" : "ServiceDetailsEn"]?.replaceAll("\\n\\n", "\\n")?.split('\n').filter(a => a)?.length > 2 &&
                                            //                             < span className="mx-10 text-30 text-bold text-primary">
                                            //                                 {"..."}</span>
                                            //                         }



                                            //                     </>

                                            //                 }

                                            //             </div>


                                            //         }



                                            //         <div
                                            //             className='flex flex-row nice-flex-center my-10 w-100'


                                            //             onClick={(e) => setIndexPanel(indexPanel == "2000" ? "000" : "2000")}
                                            //         >

                                            //             <img src="/assets/icons/arrow.svg"
                                            //                 className={"" + (indexPanel == "2000" ? " rotate-180 " : "  ")} />
                                            //         </div>
                                            //     </div>


                                            // </div>
                                        }


                                    </div>
                                </div>
                            </div>
                        }


                        {/* menu tabs */}
                        {
                            allselectServices && allselectServices.length > 0 &&
                            <div className=" more-services bg-secondary-lighter border-radius py-2">

                                <div className=" py-5 flex flex-col ">
                                    <h3 className="text-balck text-bold text-20  text-center ">
                                        {trans("info:الخدمات المضافة ")}</h3>
                                </div>
                                <div className="my-10 nice-apply-theme nice-container nice-relative ">
                                    <Swiper
                                        spaceBetween={3}
                                        slidesPerView={3}
                                        pagination
                                        breakpoints={{
                                            576: {
                                                slidesPerView: 3,
                                            },
                                        }}
                                        className={" my-8  nice-apply-theme"}
                                        resizeObserver={"true"}
                                    >

                                        {allselectServices?.map((_val, _index) => {
                                            return (

                                                <SwiperSlide key={_index} className={""}>
                                                    <div className='flex  nice-relative overflow-hidden'>

                                                        {/* <div className='border-box-periodic-checkInput'> */}
                                                        {/* <CheckInput
                                                                checked={true}
                                                                className="text-primary cursor-pointer"
                                                                oncheng={async (target) => {
                                                                    const isChecked = target.checked;

                                                                    if (isChecked) {
                                                                        // Add the service to the lists
                                                                        setAllSelectServices(prevServices => [...prevServices, _val]);

                                                                        setAllServiceID(prevServiceIDs => [...prevServiceIDs, _val.ServiceID]);
                                                                    } else {
                                                                        // Remove the service from the lists
                                                                        setAllSelectServices(prevServices =>
                                                                            prevServices.filter(service => service !== _val)
                                                                        );

                                                                        setAllServiceID(prevServiceIDs =>
                                                                            prevServiceIDs.filter(id => !_val.ServiceID.includes(id))
                                                                        );
                                                                    }

                                                                }}
                                                                Name={"23-44"}
                                                            >
                                                            </CheckInput> */}
                                                        {/* </div> */}
                                                        <CustomItemServices
                                                            // title={trans("mobileCommon:finance")}
                                                            title={lang == "ar" ? _val.ServiceNameAR : _val.ServiceName}
                                                            borderCSS={"border-primary-box nice-flex-important nice-align-item-center my-5"}

                                                        >

                                                        </CustomItemServices>
                                                    </div>
                                                </SwiperSlide>

                                            );
                                        })}



                                    </Swiper>
                                </div>
                            </div>
                        }
                        <div className=" nice-container nice-relative">
                            {
                                allselectServices && allselectServices.length > 0 &&
                                <div className="flex flex-col">

                                    {
                                        allselectServices?.map((_val, _index) => {
                                            return (

                                                <div key={_index} className="cart-grid-info-div ">
                                                    <div className={"text-14 text-gray mx-1"}>{lang == "ar" ? _val.ServiceNameAR : _val.ServiceName}&nbsp;</div>
                                                    <div className={"text-14 text-gray mx-1"}> {_val?.PriceExcludeTax ? <Price price={_val?.PriceExcludeTax} className={"white-space-pre"} /> : ""}</div>

                                                </div>

                                            );
                                        })
                                    }
                                </div>
                            }

                            {
                                allselectServices && allselectServices.length > 0 &&
                                <>
                                    <div className="cart-grid-info-div border-tb">
                                        <div className={"text-14  text-black"}>{trans("new:tax_value")}</div>
                                        <div className={"text-14 text-black "}> {allselectServices?.reduce((total, service) => total + service.Price, 0) ? <Price price={allselectServices?.reduce((total, service) => total + (service.Price - service.PriceExcludeTax), 0)} className={"white-space-pre"} /> : ""}</div>
                                    </div>
                                    <div className="cart-grid-info-div border-tb">
                                        <div className={"text-16 font-bold text-black"}>{trans("new:total")}&nbsp;<span className="text-14 text-gray mx-1">{trans("new:total_include_tax")}</span></div>
                                        <div className={"text-16 text-black font-bold"}> {allselectServices?.reduce((total, service) => total + service.Price, 0) ? <Price price={allselectServices?.reduce((total, service) => total + service.Price, 0)} className={"white-space-pre"} /> : ""}</div>
                                    </div>
                                </>

                            }

                        </div>


                        <div className=" nice-container nice-relative">
                            <div className=" mt-10 p-4">
                                <TextArea
                                    value={Note}
                                    row={4}
                                    placeholder={trans("info:notes")}
                                    onChangeValue={(data) => {
                                        setNote(data);
                                    }}
                                />
                            </div>
                        </div>

                        <div className="my-20 py-5  bg-gray-lighter ">
                            <div className="flex flex-col nice-gap-2rem nice-container ">
                                <div className="flex flex-row justify-between items-center nice-container">
                                    <h3 className="text-30 font-bold text-black"> {trans("new:pay_method")}</h3>

                                </div>
                                <div className="breakout!! flex flex-col nice-gap-2rem ">


                                    {

                                        <div className={"flex flex-col nice-gap-2rem border-radius p-5 " + (paymentType == "payTabs" ? " bg-gray-light " : "")}
                                            onClick={() => {
                                                setPaymentType("payTabs");
                                            }}
                                        >
                                            <label className={" text-20 font-bold flex flex-row nice-gap-1rem "}>
                                                <input type='radio' checked={paymentType == "payTabs" ? true : false} name='paymentType'


                                                    onChange={() => {



                                                    }}
                                                    id="primary"
                                                />
                                                {trans("new:online_all")}

                                            </label>
                                            <div className="flex flex-row items-center  mx-10">
                                                <img className="cart-payment-icon object-contain" src="/assets/icons/companies/master-card-3.png" alt={"master"} />
                                                <img className="cart-payment-icon object-contain" src="/assets/icons/companies/visa-3.png" alt={"visa"} />
                                                <img className="cart-payment-icon object-contain" src="/assets/icons/companies/mada-3.png" alt={"mada"} />

                                            </div>

                                        </div>


                                    }



                                    {
                                        <div className={"flex flex-col  border-radius p-5  " + (paymentType == "tamara" ? " bg-gray-light  " : "")}
                                            onClick={() => {

                                                setPaymentType("tamara");
                                            }}
                                        >
                                            <label className={" text-20 font-bold flex flex-row nice-gap-1rem "}>
                                                <input type='radio' checked={paymentType == "tamara" ? true : false}
                                                    name='paymentType'

                                                    onChange={() => {
                                                        console.log("<< PaymentType >>: ", paymentType);
                                                    }}

                                                    id="primary"

                                                />

                                                {trans("new:byTamara")}

                                                <div className="flex flex-row  cart-payment-icon">
                                                    <img className="cart-payment-icon object-contain"
                                                        src={lang == 'ar'
                                                            ? "/assets/icons/AR-tamara.svg"
                                                            : "/assets/icons/En-tamara.svg"}
                                                        alt={""} />
                                                </div>

                                            </label>
                                            <label className={" text-20  flex flex-row  pr-20 pl-20"}>
                                                {trans("new:byTamara3")}
                                            </label>
                                            <label className={" text-20  flex flex-row pr-20 pl-20 "}>
                                                {trans("new:byTamara4")}
                                            </label>
                                        </div>

                                    }


                                    <div className={"flex flex-col nice-gap-2rem border-radius p-5  " + (paymentType == "sadad" ? " bg-gray-light  " : "")}
                                        onClick={() => {

                                            setPaymentType("sadad");
                                        }}
                                    >
                                        <label className={" text-20 font-bold flex flex-row  items-center nice-gap-1rem "}>
                                            <input type='radio' checked={paymentType == "sadad" ? true : false} name='paymentType'

                                                onChange={() => {

                                                }}
                                                id="primary"
                                            />
                                            <img className=" object-contain"
                                                src={"/assets/icons/companies/SADAD-Logo.png"} width={"60px"} alt={"SADAD"} />

                                        </label>


                                    </div>
                                </div>
                            </div>
                        </div>

                        <ul className="reservation-privacy-link nice-container my-10">
                            {

                                <li className="text-balck text-bold text-24 text-center">
                                    <a className={"text-16 nice-pointer"}
                                        // href="/protectionAndShadingAgreement"
                                        onClick={(e) => {
                                            // e.defaultPrevented();
                                            setshowAgreements(true)
                                        }}
                                    >{trans('offers:terms')}</a>
                                </li>
                            }

                        </ul>

                        <div className="ruler-2 mb-3"></div>
                        {

                            <div className=" nice-container">
                                {

                                    <div className="flex flex-row nice-gap-2rem my-20  ">

                                        {
                                            browser && browser == "Safari" ?
                                                <div className=" w-100 mb-5 "
                                                    onClick={() => {

                                                        if (dataFullNum >= 2 && isSendeng == false)
                                                            SendResreve(PVIN, ServiceID, VINDetails[0]?.CustID, infoPrice[0].Price);

                                                    }}

                                                >
                                                    <div title="" className={"nice-style-btn nice-flex-row py-3  text-white text-20 " + (dataFullNum >= 2 ? " bg-black" : " bg-gray ")}
                                                    >
                                                        {
                                                            isSendeng && <span className='mx-2 loading-btn '>
                                                                <img className="object-max-full " src='/assets/icons/loading.svg' width='25' height='25' />
                                                            </span>
                                                        }
                                                        <img className="object-max-full" src="/assets/icons/apple-pay.png" alt="apple-pay" width='70' height='70' />
                                                    </div>
                                                </div>

                                                :


                                                <div className="w-100  mb-5">
                                                    <div className={"nice-bg-btn  text-white text-24 " + (dataFullNum >= 2 ? " bg-primary " : " bg-gray ")}
                                                        onClick={() => {


                                                            if (dataFullNum >= 2 && isSendeng == false)
                                                                SendResreve(PVIN, ServiceID, VINDetails[0]?.CustID, infoPrice[0].Price);

                                                        }}>
                                                        {
                                                            (orderReservation) ?
                                                                trans("info:save_changes")
                                                                :
                                                                trans("cartFile:comlete_buying")

                                                        }

                                                        {
                                                            isSendeng && <span className='mx-2 loading-btn '>
                                                                <img className="object-max-full " src='/assets/icons/loading.svg' width='25' height='25' />
                                                            </span>
                                                        }

                                                    </div>
                                                </div>

                                        }




                                    </div>



                                }

                            </div>
                        }



                    </div>

                    :
                    <>
                        <Skeleton count={1} borderRadius={"2rem"} width={"100%"} height={"20rem"}
                            containerClassName={""} className={"my-5"}
                            inline={true} />

                        <Skeleton count={3} borderRadius={"2rem"} width={"80%"} height={"5rem"}
                            containerClassName={"flex flex-col"} className={"my-5"}
                            inline={true} />

                        <Skeleton count={3} borderRadius={"2rem"} width={"30%"} height={"10rem"}
                            containerClassName={" flex justify-center items-center"} className={"my-5"}
                            inline={false} />
                        <Skeleton count={4} borderRadius={"2rem"} width={"100%"} height={"5rem"}
                            containerClassName={""} className={"my-5"}
                            inline={true} />
                        <Skeleton count={2} borderRadius={"2rem"} width={"100%"} height={"40rem"}
                            containerClassName={""} className={"my-5"}
                            inline={true} />
                        <Skeleton count={1} borderRadius={"2rem"} width={"100%"} height={"10rem"}
                            containerClassName={""} className={"my-5"}
                            inline={true} />

                    </>

            }

            <Popup show={showFinalPopup}>
                <div className="w-full h-full flex flex-column justify-center items-center" >
                    <div className="cart-finalized" >
                        <div className="cart-finalized-container flex flex-col">

                            <div className="cart-finalized-container-img flex flex-col">
                                <img src="/assets/images/finalized.svg" />
                            </div>

                            <div className="flex flex-wrap justify-center">
                                <span className="text-20 font-bold text-black mx-2 unselectable ">{trans("new:thank_to_choose")}</span>
                                <span className="text-20 font-bold text-primary mx-2"> {trans("new:HJ_cars")}</span>
                            </div>

                            {
                                showFinalPopupMessage &&
                                <>
                                    <div className="flex flex-wrap justify-center mx-20">
                                        <span className="text-20 font-bold  mx-2"> {showFinalPopupMessage}</span>
                                    </div>

                                </>

                            }
                            {
                                VINDetails && VINDetails.length > 0 &&
                                <>


                                    {filteredSettings?.groups?.filter(s => s.productGroupID == group).length > 0 &&
                                        <div className="flex flex-row justify-center">
                                            <span className="text-20 font-bold text-primary  mx-2">
                                                {filteredSettings?.groups?.filter(s => s.productGroupID == group)[0]?.description}
                                                {` (${PVIN}) `}</span>
                                        </div>
                                    }

                                    {

                                        resOrderID &&
                                        <div className="flex flex-row justify-center cart-finalized-code"
                                            onClick={() => {

                                                navigator.clipboard.writeText(resOrderID);
                                            }}

                                        >

                                            <span className="text-20 font-bold text-white  mx-2"> {trans("info:order-number") + " :"}</span>
                                            <span className="text-20 font-bold text-white  mx-2">{resOrderID && resOrderID}</span>
                                            <img src="/assets/icons/copy.svg" alt='' />
                                        </div>
                                    }
                                    -
                                </>

                            }
                            <div className="flex flex-center flex-col  mt-5 pt-5 w-80">
                                <div className=" mb-5 w-100">
                                    <div className="nice-sm-btn cart-notification-go  bg-primary text-white text-16" onClick={() => {

                                        setShowFinalPopup(false);
                                        setResOrderID(null);
                                        if (location.pathname)
                                            history.push("/");

                                    }}>
                                        {trans("cartFile:Continue_browsing")}
                                    </div>
                                </div>
                            </div>


                        </div>

                    </div>

                    {

                        orderReservation && orderReservation?.OrderStatusID && orderReservation?.OrderStatusID == "Created" &&


                        <div className="flex flex-center flex-col  mt-5 pt-5 w-80">
                            <div className=" mb-5 w-100">
                                <div className="nice-sm-btn cart-notification-go  text-white text-16 gap-1" onClick={() => {

                                    var threeDaysFromNow = new Date();
                                    if (orderReservation?.DateIn)
                                        threeDaysFromNow = new Date(orderReservation?.DateIn);
                                    if (orderReservation?.Note)
                                        setNote(orderReservation?.Note);

                                    setRequestForm({
                                        ...requestForm,
                                        date: threeDaysFromNow,
                                        time: orderReservation ? threeDaysFromNow : ""
                                    });

                                    setShowFinalPopup(false);

                                }}>


                                    {/* <img src="/assets/icons/edit.svg" width={20} height={20} alt=""></img> */}
                                    <svg
                                        id="SvgjsSvg1001"
                                        width="15"
                                        height="15"
                                        xmlns="http://www.w3.org/2000/svg"
                                        version="1.1"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        xmlnsSvgjs="http://svgjs.com/svgjs"
                                    >
                                        <defs id="SvgjsDefs1002"></defs>
                                        <g id="SvgjsG1008" transform="matrix(1,0,0,1,0,0)">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="15" height="15">
                                                <path
                                                    d="M18.293 31.707h6.414l24-24-6.414-6.414-24 24v6.414zm27.586-24l-3.586 3.586-3.586-3.586 3.586-3.586 3.586 3.586zM20.293 26.121l17-17 3.586 3.586-17 17h-3.586v-3.586z"
                                                    fill="#ffffff"
                                                    className="color000 svgShape"

                                                ></path>
                                                <path
                                                    d="M45.293 19.707h-2v27h-38v-40h28v-5h-38v50h60z"
                                                    fill="#ffffff"
                                                    className="color000 svgShape"
                                                ></path>
                                            </svg>
                                        </g>
                                    </svg>
                                    {trans("info:edit") + " " + trans("info:the_appointment")}

                                </div>
                            </div>
                        </div>

                    }
                </div>
            </Popup>

            <Popup show={showAgreements}>

                <div className="offer-popup  nice-flex nice-flex-center">
                    <div className="offer-popup-container p-4">
                        <div className="offer-popup-container-header">
                            <h1 className="text-balck text-bold text-24 text-center">{trans("offers:terms")}</h1>
                            <span className='offer-popup-container-header-close text-block text-gray' onClick={() => {
                                setshowAgreements(false)
                            }}>&times;</span>
                        </div>
                        <div className="ruler-2 mb-3"></div>
                        <div className='popup-content'>
                            <div className="mx-10 my-10">

                                {
                                    Agreements && Agreements.length > 0 ?
                                        <div className={"new-lines "}>
                                            <ul className={"text-20 my-10 mx-10 leading-relaxed"}>
                                                {
                                                    Agreements[0]?.explaination?.split('\n')?.map((item, index) => {

                                                        return <li className={"text-20 leading-relaxed font-bold"} key={index}>
                                                            {item?.replaceAll("*", " • ")}
                                                        </li>
                                                    })
                                                }
                                            </ul>

                                        </div>
                                        : null
                                }

                            </div>

                            <div className="ruler-2 mb-3"></div>
                        </div>
                    </div>
                </div>
            </Popup>

            {isArrowVisible && (
                <div
                    className="alert-indicator-arrow"
                    style={{
                        top: arrowPosition.top,
                        left: arrowPosition.left,
                        backgroundImage: `url('/assets/alert-indicator-arrow.svg')`, // Set image dynamically
                    }}
                ></div>
            )}
        </>
    );
}