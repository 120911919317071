import { useRef, useState } from "react";
import { quantity } from 'cart-localstorage'
import { Price } from "../../../components/__simple/_price";
import axios from "axios";
import config from "../../../configuration/config.json";
import { LikeButton } from "../../../components/__simple/_likeButton";
import { useTranslation } from "react-i18next";
import { useUserCart } from '../../../User/hooks/useUserCart';
import { getData } from "../../../utils/localStorageManager";
export function ServiceBox(
    { Account, img, brand, name, price, count, id, onDelete, Categior, Discount, Tax, Pay, modelCode, modelTypes,Orderdate }
) {
    const { t: trans, i18n } = useTranslation(["cartFile", "new"]);
    const [counter, setCounter] = useState(count);
    const specRef = useRef(null);
    const { removecartItems, changecartItem } = useUserCart();
    const [specHeight, setHeight] = useState(0);
    const [CartInfo, setCartInfo] = useState(null);
    let lang = (i18n.language || 'ar');
    const countF = async (num) => {
        if (num === -1 && counter > 1)
            setCounter(counter + num);
        else if (num === 1 && counter < 100)
            setCounter(counter + num);
        if (num === -1 && counter === 1) {
            // onDelete();

            return;
        }
        console.log("part id:" + id);

        const isLogin = getData('isAuthenticated');
        if (isLogin) {
            const resultchangecartItem = await changecartItem(id, null, null, num, modelCode);
            Account()
        } else {
            quantity(id, num);
            Account()
        }
    }
    function GetCartInfo() {

        const Body = {
            "name": "site_FetchInfoService",
            "values": {
                "ServiceID": id
            }
        }
        axios.request({
            url: config.baseURL + 'func',
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {

                var CuurentValue = {};

                try {
                    CuurentValue.Description = lang == "ar" ? response.data[0].ServiceNameAR : response.data[0].ServiceName;
                    CuurentValue.DiscountRate = response.data[0].DiscountAmount;
                    CuurentValue.name = name;
                    CuurentValue.img = response.data[0].img ? "data:image/png;base64," + response.data[0].img : "/assets/images/toolMaintenance.gif";
                    CuurentValue.price = price;
                } catch (error) {
                    
                }
          

                setCartInfo(CuurentValue);

            })
            .catch(function (error) {
                console.log(error);
            });
    }
    function showData() {

        setHeight(specHeight <= 0 ? specRef.current.scrollHeight : 0);
    }
    useState(() => {
        GetCartInfo();
    }, [])

    return (<div>

        <div className={"cart-card p-8 mb-8"}>
            <div className={"bg-second border-radius p-10 nice-flex nice-flex-center nice-dem-90"}>
                <img className={"object-full product-cart-image object-contain"} src={img ? config.mainURL + img : "/assets/images/toolMaintenance.gif"} />
            </div>
            <div className="cart-detail">
                <div className="nice-flex nice-flex-row">
                    <div className="nice-flex-1 nice-gap-3rem nice-flex nice-flex-column">
                        {
                            modelTypes &&
                            <div className="cart-detail-container">
                                <h3 className={"text-16"}>{trans("new:vehicle")}:</h3>
                                <p className={"text-16 font-bold"}>{modelTypes}</p>
                            </div>

                        }


                        <div className="cart-detail-container">
                            <h3 className={"text-16"}>{trans("new:service")}</h3>
                            <p className={"text-16 font-bold"}>{CartInfo?.Description}</p>
                        </div>
                    </div>
                    <div className="nice-flex nice-flex-column nice-gap-2rem ">
                        <div className="nice-flex nice-flex-row nice-justifiy-end nice-align-item-center">
                            <button onClick={onDelete} className={`cart-delete cart-delete-${lang == 'en' ? 'right' : 'left'}`}>
                                <img src={"/assets/icons/trash.svg"} alt={"minus"} />
                            </button>
                            <LikeButton long={true} />
                        </div>
                        <div style={{ display: "flex", flexFlow: "column nowrap" }}>
                            <div className={"flex items-center"}>
                                <div className={"text-12 ml-4"}>{trans("new:service_price")}</div>
                                <div className={"text-20 mx-3 font-bold text-primary"}><Price price={CartInfo?.price} /></div>
                            </div>
                            {false && <div className={"flex items-center"}>
                                <div className={"text-12 ml-4"}>{trans("cartFile:discount_rate")}</div>
                                <div className={"text-20 mx-3 font-bold text-primary"}>{CartInfo?.DiscountRate}%</div>
                            </div>}
                        </div>
                        <div >
                            <div className={"cart-counter"}>
                                <h3 className={"cart-counter-title text-12"}>{trans("cartFile:amount")}</h3>
                                <div className="nice-flex nice-flex-row ">
                                    <button onClick={() => countF(+1)} className="cart-counter-btn">
                                        <img src={"/assets/icons/plus.svg"} alt={"plus"} />
                                    </button>
                                    <div className={"cart-counter-num text-20 font-bold"}>{counter}</div>
                                    <button onClick={() => countF(-1)} className="cart-counter-btn">
                                        <img src={"/assets/icons/minus.svg"} alt={"minus"} />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}
